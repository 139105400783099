import { useCallback, useMemo } from 'react';
import type { Scene } from '../../../../../api/hooks/scene/useScenes';
import { useActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';
import { useSelectedScenes } from '../../../../../contexts/selectedScenes/useSelectedScenes';
import { isImageSceneAsset, isMainSceneAsset, isVideoSceneAsset } from '../../../../../helpers/sceneAssets/is';
import { useDownloadUrl } from '../../../../../hooks/download/useDownloadUrl';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';

const useTrackDownload = () => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (sceneId: string, textElementsCount: number, assetName: string, assetType: 'image' | 'video') => {
      tracking.trackEvent({
        event: 'SceneAssetDownloaded',
        details: {
          source: 'edit',
          sceneId,
          textElementsCount,
          assetType,
          assetName,
        },
      });
    },
    [tracking]
  );
};

const extractSceneDownloadInfo = (scene: Scene) => {
  const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);
  if (!mainSceneAsset) return;

  if (isVideoSceneAsset(mainSceneAsset)) {
    const { asset } = mainSceneAsset;
    const downloadUrl =
      asset?.enhancedAudioEnabled && asset.videoAssetWithEnhancedAudio
        ? asset.videoAssetWithEnhancedAudio.downloadUrl
        : asset?.videoAsset?.downloadUrl;

    if (!downloadUrl) return;

    return {
      downloadUrl,
      textElementsCount: scene.sceneTexts.length,
      sceneId: scene.id,
      type: 'video' as const,
    };
  }

  if (isImageSceneAsset(mainSceneAsset)) {
    const downloadUrl = mainSceneAsset.asset?.imageAsset?.downloadUrl;
    if (!downloadUrl) return;

    return {
      downloadUrl,
      textElementsCount: scene.sceneTexts.length,
      sceneId: scene.id,
      type: 'image' as const,
    };
  }
};

export const useDownloadSceneAssetsWithTracking = (scene: Scene) => {
  const trackDownload = useTrackDownload();
  const actionSceneIds = useActionSceneIds(scene.id);
  const { selectedItems } = useSelectedScenes();

  const download = useDownloadUrl();

  const downloadableScenes = useMemo(
    () =>
      [...selectedItems.filter((it) => it.id !== scene.id), scene]
        .filter((item) => actionSceneIds.includes(item.id))
        .filter((scene) => scene !== undefined)
        .map((scene) => {
          const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);
          if (mainSceneAsset && (isImageSceneAsset(mainSceneAsset) || isVideoSceneAsset(mainSceneAsset))) return scene;
        })
        .filter((scene) => scene !== undefined),
    [selectedItems, scene, actionSceneIds]
  );

  const scenesDownloadInfo = useMemo(
    () =>
      downloadableScenes
        .map(extractSceneDownloadInfo)
        .filter((info) => info !== undefined)
        .filter((info) => info.downloadUrl !== undefined),
    [downloadableScenes]
  );

  return useMemo(
    () => ({
      download: () => {
        scenesDownloadInfo.forEach(({ sceneId, textElementsCount, type, downloadUrl }, index) => {
          // chrome will not allow to download more than 10 files without setTimeout
          // safari does not work at all without setTimeout for 2 or more downloads
          setTimeout(() => {
            download(downloadUrl);
            trackDownload(sceneId, textElementsCount, downloadUrl, type);
          }, index * 200);
        });
      },
      hasAnythingToDownload: scenesDownloadInfo.length > 0,
    }),
    [download, trackDownload, scenesDownloadInfo]
  );
};
