import { type FC, useMemo } from 'react';

import type { IconSize } from '../../../assets/icons/Icon';
import { IconButton, type IconButtonProps } from '../../../controls/Button/IconButton';

export const SoundControlIconButton: FC<
  Omit<Partial<IconButtonProps>, 'size'> & {
    volume: number;
    size?: IconSize;
  }
> = ({ volume, size, ...rest }) => {
  const iconName = useMemo(() => {
    if (volume === 0) return 'SpeakerNoneIcon';
    if (volume > 0 && volume <= 0.5) return 'SpeakerLowIcon';
    return 'SpeakerHighIcon';
  }, [volume]);

  return (
    <IconButton
      {...rest}
      icon={iconName}
      iconSize={size}
      iconColor="white"
      hoverColor="grey300"
      hoverBackgroundColor="transparent"
      label="i18n.global.muted"
    />
  );
};
