import { CSS, type Transform } from '@dnd-kit/utilities';
import { type CSSProperties, useMemo } from 'react';

export const useDraggableStyles = ({
  isDragging,
  transform,
  transition,
}: {
  isDragging: boolean;
  transform: Transform | null;
  transition: CSSProperties['transition'];
}) => {
  return useMemo(() => getDraggableStyles(isDragging, transform, transition), [isDragging, transform, transition]);
};

export const getDraggableStyles = (
  isDragging: boolean,
  transform: Transform | null,
  transition: CSSProperties['transition']
) => {
  return {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : 'auto',
    filter: isDragging ? 'drop-shadow(0 0 32px rgb(0 0 0 / 0.2))' : undefined,
  };
};
