import { useCallback } from 'react';

import { type DeleteMainAssetFromScenesMutationVariables, useDeleteMainAssetFromScenesMutation } from '../../generated';

export const useDeleteMainAssetFromScenes = () => {
  const [deleteMainAssetFromScenes] = useDeleteMainAssetFromScenesMutation({
    refetchQueries: ['Scenes', 'SceneRenderDescription'],
    awaitRefetchQueries: true,
  });
  return useCallback(
    (ids: DeleteMainAssetFromScenesMutationVariables['ids']) => {
      return deleteMainAssetFromScenes({ variables: { ids } });
    },
    [deleteMainAssetFromScenes]
  );
};
