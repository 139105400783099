import { useMemo } from 'react';

import { type ConsentSettingsInfoQueryResult, useConsentSettingsInfoQuery } from '../../generated';

export type ConsentSettingsInfo = NonNullable<ConsentSettingsInfoQueryResult['data']>['consentSettingsInfo'];

export const useConsentSettingsInfo = () => {
  const { loading, error, data } = useConsentSettingsInfoQuery({
    fetchPolicy: 'cache-and-network',
  });
  const consentSettingsInfo = data?.consentSettingsInfo || undefined;
  return useMemo(() => ({ consentSettingsInfo, loading, error }), [consentSettingsInfo, loading, error]);
};
