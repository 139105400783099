import { useMemo } from 'react';

import { type RolesFilter, type RolesQueryResult, useRolesQuery } from '../../generated';

export type Roles = NonNullable<RolesQueryResult['data']>['roles'];
export type Role = NonNullable<Roles[number]>;

export const useRoles = (filter?: RolesFilter) => {
  const { loading, error, data } = useRolesQuery({
    variables: { filter },
  });
  const roles = data?.roles ?? [];

  return useMemo(() => ({ roles, loading, error }), [roles, loading, error]);
};
