import { type FC, type MouseEventHandler, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  Button,
  type ButtonProps,
  Icon,
  UpsellIcon,
  styled,
  useFeatureFlags,
  useLocalizedWebsiteUrl,
  withPopover,
} from '@cofenster/web-components';

import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import type { Scene } from '../../../contexts/scenes/ScenesContext';
import { useImportStockImage, useImportStockVideo } from '../../../hooks/stockLibrary/useImportStockAsset';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { UpsellBanner } from '../../featureFlag/UpsellBanner';

const UpsellPopoverContent = styled('div')(() => ({
  width: 400,
  containerType: 'inline-size',
}));

// 1. Not very elegant, but an `Icon` within a `Circle` (which is what the
//    `UpsellIcon` component is) is bigger than the typical icons we use in this
//    interface’s buttons, which causes this button to look taller. To fix the
//    problem, we use the `ms` size for the circle, but then the icon itself
//    looks cramped, so we reduced its visual size with CSS.
const StyledUpsellIcon = styled(UpsellIcon)(() => ({
  svg: {
    transform: 'scale(0.8)', // 1
  },
}));

export const StockAssetButton: FC<
  ButtonProps & {
    projectId: string;
    scene: Scene;
    videoFormat: VideoFormat;
    assetType: 'image' | 'video' | 'screen';
  }
> = ({ projectId, scene, videoFormat, assetType, variant, ...rest }) => {
  const withStockLibrary = useFeatureFlags().hasFeature('STOCK_LIBRARY');
  const { openDialog } = useDialogs();
  const importStockImage = useImportStockImage(scene, videoFormat);
  const importStockVideo = useImportStockVideo(scene, videoFormat);
  const learnMoreUrl = useLocalizedWebsiteUrl('STOCK_LIBRARY_INFO');
  const tracking = useWebManagerTracking();

  const track = useCallback(
    (stockAssetId: string) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId: scene.id,
          assetType: assetType,
          assetName: stockAssetId,
          uploadSource: 'stock-footage',
        },
      }),
    [assetType, scene.id, tracking]
  );

  const handlePick = useCallback(
    async (stockAssetId: string) => {
      if (assetType === 'video') await importStockVideo(stockAssetId);
      else if (assetType === 'image') await importStockImage(stockAssetId);
      track(stockAssetId);
    },
    [assetType, importStockImage, importStockVideo, track]
  );

  const handleStock: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog('StockLibraryDialog', {
        type: assetType === 'screen' ? 'video' : assetType,
        videoFormat,
        pick: handlePick,
      });
    },
    [openDialog, assetType, videoFormat, handlePick]
  );

  if (assetType === 'screen') return null;

  if (!withStockLibrary) {
    const UpsellTrigger = withPopover(
      Button,
      {
        anchorOriginX: 'left',
        anchorOriginY: 'top',
        transformOriginX: 'left',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: (
          <UpsellPopoverContent>
            <UpsellBanner
              title="i18n.upsellBanner.STOCK_LIBRARY.title"
              learnMoreUrl={learnMoreUrl}
              featureRelation="STOCK_LIBRARY"
            >
              i18n.upsellBanner.STOCK_LIBRARY.content
            </UpsellBanner>
          </UpsellPopoverContent>
        ),
      },
      // The upsell banner is currently used on top of the dropzone for the `STOCK_LIBRARY` feature.
      // Therefore we need to stop the propagation to avoid triggering the dropzone functionality
      { stopPropagation: true }
    );

    return (
      <UpsellTrigger variant={variant} endIcon={<StyledUpsellIcon size="ms" />}>
        {assetType === 'image' ? 'i18n.sceneAsset.stock.image' : 'i18n.sceneAsset.stock.video'}
      </UpsellTrigger>
    );
  }

  return (
    <Button
      variant={variant ?? 'secondary'}
      onClick={handleStock}
      startIcon={<Icon size="s" type={assetType === 'image' ? 'ImageIcon' : 'MovieIcon'} />}
      data-testid="stock-button"
      {...rest}
    >
      {`i18n.sceneAsset.stock.${assetType}`}
    </Button>
  );
};
