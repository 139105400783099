import { Checkbox as MuiCheckbox, type CheckboxProps as MuiCheckboxProps, styled } from '@mui/material';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';

const StyledCheckbox = styled(MuiCheckbox)(() => ({
  padding: 0,
  margin: 0,
}));

const UncheckedIcon = styled('span')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 3,
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.brand.carbon}`,
  backgroundColor: theme.palette.brand.grey100,

  'input:hover ~ &, input:focus ~ &': {
    borderColor: theme.palette.brand.blue,
  },

  'input:focus-visible ~ &': theme.mixins.focusRing,

  'input:disabled ~ &': {
    backgroundColor: theme.palette.brand.grey50,
    borderColor: 'transparent',
  },
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  padding: 3,
  borderRadius: 3,
  backgroundColor: theme.palette.brand.carbon,

  'input:focus-visible ~ &': theme.mixins.focusRing,

  'input:disabled ~ &': {
    boxShadow: 'none',
    backgroundColor: theme.palette.brand.grey50,
  },
}));

export type CheckboxProps = Pick<
  MuiCheckboxProps,
  'checked' | 'disabled' | 'onChange' | 'name' | 'id' | 'checkedIcon' | 'icon' | 'inputProps'
>;

export const Checkbox: FC<CheckboxProps> = (props) => (
  <StyledCheckbox
    {...props}
    disableRipple
    checkedIcon={<CheckedIcon type="CheckIcon" color={props.disabled ? 'grey500' : 'white'} />}
    icon={<UncheckedIcon />}
  />
);
