import { flushSync } from 'react-dom';

export const areViewTransitionsSupported = () => 'startViewTransition' in document;

export const transitionView = (update: VoidFunction) => {
  if (areViewTransitionsSupported()) {
    document.startViewTransition(() => flushSync(update));
  } else {
    update();
  }
};
