import { type FC, useCallback } from 'react';

import { Button } from '@cofenster/web-components';

import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProject } from '../../../contexts/project/useProject';
import { useTrackContributionRequestList } from '../hooks/useTrackContributionRequestList';

export const useOpenRequestRecordingsDialog = () => {
  const { openDialog } = useDialogs();
  const { project, refetch } = useProject();
  const track = useTrackContributionRequestList();
  return useCallback(() => {
    if (!project?.id) return;
    track('requestListModalOpened');
    openDialog('ContributionRequestListDialog', {
      project,
      refetchProject: refetch,
      trackCloseDialog: () => track('requestListModalClosed'),
    });
  }, [project, openDialog, refetch, track]);
};

export const SendRequestButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const openRequestRecordingsDialog = useOpenRequestRecordingsDialog();

  return (
    <Button variant="primary" onClick={onClick ?? openRequestRecordingsDialog} data-testid="request-recordings-button">
      i18n.projectContributions.RequestRecordingsButton.label
    </Button>
  );
};
