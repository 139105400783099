import { type FC, type PropsWithChildren, useMemo } from 'react';

import { CurrentTeamContext } from './CurrentTeamContext';

export type RootProjectFolderProviderProps = PropsWithChildren<{
  team?: CurrentTeamContext['team'];
  loading?: CurrentTeamContext['loading'];
}>;

export const CurrentTeamProvider: FC<RootProjectFolderProviderProps> = ({ children, team, loading }) => {
  const context = useMemo(() => ({ team, loading: loading ?? false }), [team, loading]);
  return <CurrentTeamContext.Provider value={context}>{children}</CurrentTeamContext.Provider>;
};
