import { useMemo } from 'react';
import {
  type CreateOrUpdateContributionSettingsMutationVariables,
  useCreateOrUpdateContributionSettingsMutation,
} from '../../generated';

export const useCreateOrUpdateContributionSettings = () => {
  const [createOrUpdateContributionSettings, { loading, error, data }] = useCreateOrUpdateContributionSettingsMutation({
    refetchQueries: ['ConsentSettings'],
  });

  return useMemo(
    () => ({
      createOrUpdateContributionSettings: (input: CreateOrUpdateContributionSettingsMutationVariables['input']) =>
        createOrUpdateContributionSettings({ variables: { input } }),
      loading,
      error,
      data,
    }),
    [createOrUpdateContributionSettings, loading, error, data]
  );
};
