import { forwardRef, useMemo } from 'react';

import { LoadingSpinner } from '@cofenster/web-components';
import { RemotionPlayer, type RemotionPlayerProps, type RemotionPlayerRef } from '@cofenster/web-remotion-player';
import { useProjectRenderDescription } from '../../contexts/projectRenderDescription/useProjectRenderDescription';

type RemotionProjectPreviewProps = Omit<RemotionPlayerProps, 'renderDescription'>;

export const RemotionProjectPreview = forwardRef<RemotionPlayerRef, RemotionProjectPreviewProps>(
  function RemotionProjectPreview(props, ref) {
    const { projectRenderDescription } = useProjectRenderDescription();

    // creates a new key whenever the projectRenderDescription changes
    // biome-ignore lint/correctness/useExhaustiveDependencies: safe
    const key = useMemo(() => +new Date(), [projectRenderDescription]);

    return projectRenderDescription?.totalDurationInSeconds ? (
      <RemotionPlayer key={key} renderDescription={projectRenderDescription} {...props} ref={ref} />
    ) : (
      <LoadingSpinner />
    );
  }
);
