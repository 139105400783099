import type { FC, PropsWithChildren } from 'react';

import { Icon, SpinningIcon, Text, styled } from '@cofenster/web-components';

import type { ThemeVideoType } from '../../../../../../api/hooks/user/useMe';
import { useIntroOutro } from '../../../../../../contexts/editorPlayer/useIntroOutro';
import type { Project } from '../../../../../../contexts/project/useProject';
import { useFindVideoAssetOnTheme } from '../../../../../../hooks/useFindVideoOnTheme';

import { SceneTypeIcon, Thumbnail } from './Thumbnail';

type Props = PropsWithChildren<{
  project: Project;
  themeVideoType: ThemeVideoType;
}>;

const StyledText = styled(Text)(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgb(10 36 44 / 0.3)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.brand.white,
}));

export const IntroOutroThumbnail: FC<Props> = ({ project, themeVideoType }) => {
  const themeVideoAsset = useFindVideoAssetOnTheme(project.theme, themeVideoType, project?.videoFormat);
  const { isEnabled } = useIntroOutro();
  const videoAsset = themeVideoAsset?.videoAsset;
  const label = <StyledText component="p">{themeVideoType}</StyledText>;

  if (!videoAsset) {
    if (!__INTEGRATED_INTRO_OUTRO_EDITOR__) return null;
    return (
      <Thumbnail
        hint={<SceneTypeIcon title="i18n.sceneTypes.video" iconType="MovieIcon" />}
        textElementsCount={0}
        data-scene-type={themeVideoType}
        isExcludedScene={!isEnabled(themeVideoType)}
      >
        {label}
      </Thumbnail>
    );
  }

  if (videoAsset.status === 'Waiting') {
    return (
      <Thumbnail hint={<SpinningIcon type="GearIcon" />} textElementsCount={0}>
        {label}
      </Thumbnail>
    );
  }

  if (videoAsset.status === 'Error') {
    return (
      <Thumbnail hint={<Icon type="CloudErrorIcon" color="negative" />} textElementsCount={0}>
        {label}
      </Thumbnail>
    );
  }

  return (
    <Thumbnail
      hint={<SceneTypeIcon title="i18n.sceneTypes.video" iconType="MovieIcon" />}
      textElementsCount={0}
      image={videoAsset.thumbnailUrl}
      data-scene-type={themeVideoType}
      isExcludedScene={!isEnabled(themeVideoType)}
    >
      {label}
    </Thumbnail>
  );
};
