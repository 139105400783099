import { type FC, useCallback } from 'react';

import {
  Icon,
  Select,
  SelectMenuProps,
  type SelectProps,
  Translatable,
  Typography,
  styled,
} from '@cofenster/web-components';

import type { ContributionRequestById } from '../../../../api/hooks/contributionRequest/useContributionRequestsById';

import { SelectSelectedValue } from './SelectSelectedValue';
import {
  CheckIconContainer,
  LimitedWidthTypography,
  OptionContainer,
  ResetFilterButton,
  SelectedOptionContainer,
  StyledMenuItem,
} from './SelectedOptionContainer';

type Contributor = ContributionRequestById['contributions'][number]['contributors'][number];

export const ContributorSelect: FC<
  Omit<SelectProps, 'label' | 'name'> & {
    name?: string;
    contributors: Contributor[];
  }
> = ({ id = 'contributors', name = 'contributors', contributors, ...rest }) => {
  const isSelected = useCallback(
    (contributor: Contributor) => {
      return Array.isArray(rest.value) && (rest.value as string[])?.includes(contributor.id);
    },
    [rest.value]
  );

  return (
    <Select
      id={id}
      name={name}
      label="i18n.projectContributionConfiguration.filter.byContributors.label"
      displayEmpty
      labelProps={{
        shrink: true,
      }}
      renderValue={(value) => {
        const arrayValue = value as string[];
        if (arrayValue.length === 0) {
          return (
            <SelectSelectedValue iconType="UsersIcon">
              i18n.projectContributionConfiguration.filter.byContributors.empty
            </SelectSelectedValue>
          );
        }

        return (
          <SelectedOptionContainer>
            <Translatable i18nParams={{ count: arrayValue.length }}>
              i18n.projectContributionConfiguration.filter.byContributors.notEmpty
            </Translatable>
          </SelectedOptionContainer>
        );
      }}
      MenuProps={{
        ...SelectMenuProps,
        PaperProps: {
          ...SelectMenuProps.PaperProps,
          sx: {
            ...SelectMenuProps.PaperProps?.sx,
            padding: 1,
            paddingBottom: 0,
          },
        },
      }}
      multiple
      pb={0}
      {...rest}
    >
      {contributors.map((contributor) => (
        <StyledMenuItem key={contributor.id} value={contributor.id}>
          <FirstLetterCircle>
            <Typography>{contributor.name[0]?.toUpperCase()}</Typography>
          </FirstLetterCircle>
          <OptionContainer>
            <LimitedWidthTypography>{contributor.name}</LimitedWidthTypography>
            <LimitedWidthTypography>{contributor.email}</LimitedWidthTypography>
          </OptionContainer>

          <CheckIconContainer selected={isSelected(contributor)}>
            <Icon type="CheckIcon" />
          </CheckIconContainer>
        </StyledMenuItem>
      ))}
      <ResetFilterButton fullWidth variant="tertiary" value="reset">
        i18n.projectContributionConfiguration.filter.reset
      </ResetFilterButton>
    </Select>
  );
};

const FirstLetterCircle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: theme.palette.brand.carbon,
  color: theme.palette.brand.white,
  margin: theme.spacing(0, 1),
}));
