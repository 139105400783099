import { type FC, useCallback } from 'react';

import { Button, Icon, styled, useGoto } from '@cofenster/web-components';

import { useProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { useUser } from '../../contexts/user/useUser';
import { routes } from '../../routes';

const CreateButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
}));

export const HomeCTA: FC = () => {
  const { user } = useUser();
  const { projectFolder, loading } = useProjectFolder(user?.rootProjectFolder.id);
  const { openDialog } = useDialogs();
  const goto = useGoto();

  const handleClick = useCallback(() => {
    if (!projectFolder) return;
    openDialog('CreateProjectDialog', {
      personalFolderId: projectFolder.id,
      goToProjectCreation: (folderId: string) => goto(routes.projectCreate, { folderId }, { search: '?from=home' }),
    });
  }, [openDialog, projectFolder, goto]);

  if (!loading && !projectFolder) {
    return null;
  }

  return (
    <CreateButton
      loading={loading}
      startIcon={<Icon size="s" type="PlusIcon" />}
      variant="primary"
      data-testid="create-project-cta"
      onClick={handleClick}
    >
      i18n.common.createProject
    </CreateButton>
  );
};
