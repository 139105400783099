import type { FC } from 'react';

import { PopoverMenuDivider, PopoverMenuItem, useLocalizedWebsiteUrl } from '@cofenster/web-components';

import type { Role as RolesItem } from '../../../../api/hooks/role/useRoles';
import type { Role } from '../../../../api/hooks/user/useUser';

import { RolePopoverMenuItem } from './RolePopoverMenuItem';

export type MemberPopoverProps = {
  canLeave: boolean;
  canRemove: boolean;
  canTransfer: boolean;
  roles: RolesItem[];
  changeRole?: (roleId: string) => void;
  onRemove?: VoidFunction;
  transferOwnership?: VoidFunction;
  currentRole?: Omit<Role, 'permissions'>;
  canManage?: boolean;
};

export const MemberPopover: FC<MemberPopoverProps> = ({
  canLeave,
  canRemove,
  canTransfer,
  roles,
  currentRole,
  changeRole,
  onRemove,
  transferOwnership,
  canManage,
}) => {
  const learnMoreUrl = useLocalizedWebsiteUrl('ROLES_INFO');

  return (
    <>
      {canTransfer && (
        <RolePopoverMenuItem onClick={transferOwnership} roleName={'Owner'} canTransfer={canTransfer} roleId="Owner" />
      )}

      {(canManage ? roles : []).map((role) => (
        <RolePopoverMenuItem
          key={role.roleId}
          onClick={() => changeRole?.(role.roleId)}
          roleName={role.name}
          isActive={role.roleId === currentRole?.roleId}
          roleId={role.roleId}
        />
      ))}

      {(canTransfer || canManage) && <PopoverMenuDivider />}

      <PopoverMenuItem icon="SupportIcon" href={learnMoreUrl} target="_blank" component="a">
        i18n.team.role.help
      </PopoverMenuItem>

      {(canRemove || canLeave) && (
        <PopoverMenuItem icon="UserRemoveIcon" onClick={onRemove} color="negative" data-testid="member-remove-button">
          {canLeave ? 'i18n.team.leave' : 'i18n.common.remove'}
        </PopoverMenuItem>
      )}
    </>
  );
};
