import { styled } from '@mui/material';
import { type FC, useEffect, useMemo, useState } from 'react';

import { useAudioVolume, useMediaDevice, useMediaStreamRecorder, useUserMediaStream } from '../../../../../hooks/media';
import { preventForwardProps } from '../../../../../utilities';
import { Button } from '../../../../controls/Button';
import { Dialog, DialogActions, DialogContent, type DialogProps } from '../../../../feedback/Dialog';
import { AudioPlayer } from '../../../AudioPlayer';
import { RecordButton, RecordingLayout, SelectMicrophone, TimeRecordingIndicator } from '../ui';

type RecordAudioDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  onFile: (file: File) => unknown;
  title?: DialogProps['title'];
  titleI18nParams?: DialogProps['titleI18nParams'];
};

const AudioFeedback = styled(
  'div',
  preventForwardProps(['volume'])
)<{ volume: number }>(({ theme, volume }) => ({
  height: '220px',
  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.purple,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    opacity: volume > 0 ? 1 : 0,
    height: `${volume * 100}%`,
    transition: 'height 500ms ease',
    borderTopStyle: 'dotted',
    borderTopColor: 'rgba(255, 255, 255, 0.5)',
  },
}));

export const RecordAudioDialog: FC<RecordAudioDialogProps> = ({
  isOpen,
  closeDialog,
  onFile,
  title = 'i18n.common.record.audio',
  titleI18nParams,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const url = useMemo(() => (file ? URL.createObjectURL(file) : null), [file]);

  const [accepting, setAccepting] = useState(false);

  const onAcceptClick = async () => {
    if (!file) return;
    setAccepting(true);
    try {
      await onFile(file);
    } finally {
      setAccepting(false);
      closeDialog();
    }
  };

  const [availableMicrophones, microphone, setMicrophone] = useMediaDevice('audioinput', 'first');
  const { value: stream } = useUserMediaStream(
    useMemo(
      () =>
        microphone
          ? {
              audio: {
                deviceId: { exact: microphone.deviceId },
                noiseSuppression: { ideal: true },
                echoCancellation: { ideal: true },
              },
            }
          : null,
      [microphone]
    )
  );

  const { volume } = useAudioVolume(stream);

  const recorder = useMediaStreamRecorder(stream);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we do not want to run it every time timeRecorded changes
  useEffect(() => {
    if (recorder?.bytesAvailable && recorder?.state === 'inactive') {
      setDuration(recorder.timeRecorded / 1000);
      setFile(recorder.asFile('recorded-audio'));
    }
  }, [recorder?.state, recorder?.bytesAvailable, onFile]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="confirm-dialog"
      title={title}
      titleI18nParams={titleI18nParams}
    >
      <DialogContent>
        {url ? (
          <AudioPlayer url={url} duration={duration ?? undefined} />
        ) : (
          <AudioFeedback volume={volume ?? 0}>
            <RecordingLayout
              isCompact
              leftButtons={
                <SelectMicrophone
                  devices={availableMicrophones}
                  onSelectDevice={setMicrophone}
                  selectedDevice={microphone}
                  disabled={false}
                />
              }
              mainButton={
                <RecordButton
                  onRecord={recorder?.state === 'recording' ? recorder?.stop : recorder?.start}
                  isRecording={recorder?.state === 'recording'}
                  isDisabled={!recorder}
                />
              }
              top={
                typeof recorder?.timeRecorded !== 'undefined' &&
                recorder?.timeRecorded > 0 && (
                  <TimeRecordingIndicator
                    recordingDuration={recorder?.timeRecorded}
                    isRecording={recorder?.state === 'recording'}
                  />
                )
              }
            />
          </AudioFeedback>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="tertiary"
          fullWidth
          onClick={
            file
              ? () => {
                  recorder?.cancel();
                  setDuration(null);
                  setFile(null);
                }
              : closeDialog
          }
          data-testid="record-dialog-cancel-action"
          disabled={accepting}
        >
          i18n.global.cancel
        </Button>
        <Button
          variant="primary"
          fullWidth
          onClick={onAcceptClick}
          data-testid="record-dialog-accept-action"
          loading={accepting}
          disabled={!file}
        >
          i18n.global.okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
