import { useCallback } from 'react';

import { LAYER_INDEXES, type VideoFormat } from '@cofenster/constants';

import type { Scene } from '../../api/hooks/scene/useScenes';
import { useCreateSceneAsset } from '../../api/hooks/sceneAsset/useCreateSceneAsset';
import { useImportStockImage as useImportStockImageHook } from '../../api/hooks/stockFootage/useImportStockImage';
import { useImportStockVideo as useImportStockVideoHook } from '../../api/hooks/stockFootage/useImportStockVideo';

export const useImportStockImage = (scene: Scene, videoFormat: VideoFormat) => {
  const importStockImage = useImportStockImageHook();
  const createSceneAsset = useCreateSceneAsset();

  return useCallback(
    async (stockAssetId: string) => {
      const { data } = await importStockImage(stockAssetId, { videoFormat });
      const imageAssetId = data?.importStockImage.imageAssetId;
      if (!imageAssetId) return;

      await createSceneAsset({
        assetId: imageAssetId,
        assetType: 'Image',
        sceneId: scene.id,
        layerIndex: LAYER_INDEXES.main,
        startTime: 0,
      });
    },
    [scene.id, videoFormat, importStockImage, createSceneAsset]
  );
};

export const useImportStockVideo = (scene: Scene, videoFormat: VideoFormat) => {
  const importStockVideo = useImportStockVideoHook();
  const createSceneAsset = useCreateSceneAsset();

  return useCallback(
    async (stockAssetId: string) => {
      const { data } = await importStockVideo(stockAssetId, { videoFit: 'Fit', videoFormat });
      const videoAssetId = data?.importStockVideo.videoAssetId;
      if (!videoAssetId) return;

      await createSceneAsset({
        assetId: videoAssetId,
        assetType: 'Video',
        layerIndex: LAYER_INDEXES.main,
        sceneId: scene.id,
        startTime: 0,
        duration: data.importStockVideo.duration,
      });
    },
    [scene.id, videoFormat, importStockVideo, createSceneAsset]
  );
};
