import { preventForwardProps, styled } from '@cofenster/web-components';

export const ThumbnailListItemWrapper = styled(
  'li',
  preventForwardProps(['dragCount'])
)<{ dragCount?: number }>(({ theme, dragCount }) => ({
  listStyle: 'none',
  counterIncrement: 'thumbnails',
  position: 'relative',

  ...(typeof dragCount === 'number' &&
    dragCount > 2 && {
      '&::before': {
        position: 'absolute',
        content: `"${dragCount}"`,
        zIndex: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        ...theme.typography.h6,
        top: theme.spacing(-1.5),
        color: theme.palette.brand.white,
        backgroundColor: theme.palette.brand.carbon,
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius,
      },
    }),
}));
