import { useCallback } from 'react';

import { type ImportStockVideoMutationVariables, useImportStockVideoMutation } from '../../generated';

export const useImportStockVideo = () => {
  const [importStockVideo] = useImportStockVideoMutation();
  return useCallback(
    (id: ImportStockVideoMutationVariables['id'], input: ImportStockVideoMutationVariables['input']) =>
      importStockVideo({ variables: { id, input } }),
    [importStockVideo]
  );
};
