import type { FC } from 'react';

import type { Scene, SceneVideo } from '../../../../../../api/hooks/scene/useScenes';

import { isColorSceneAsset, isImageSceneAsset, isMainSceneAsset } from '../../../../../../helpers/sceneAssets/is';
import { ColorThumbnail } from './ColorThumbnail';
import { ImageThumbnail } from './ImageThumbnail';
import { VideoThumbnail } from './VideoThumbnail';

type DraggableEditorThumbnailProps = {
  scene: Scene;
};

export const DraggableEditorThumbnail: FC<DraggableEditorThumbnailProps> = ({ scene }) => {
  const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);

  return (
    <>
      {scene.type === 'color' ? (
        <ColorThumbnail
          scene={scene}
          sceneColor={mainSceneAsset && isColorSceneAsset(mainSceneAsset) ? mainSceneAsset.asset : undefined}
        />
      ) : scene.type === 'video' || scene.type === 'screenRecording' ? (
        <VideoThumbnail
          scene={scene}
          sceneVideo={mainSceneAsset?.asset as SceneVideo | undefined}
          isRecording={scene.type === 'screenRecording'}
        />
      ) : scene.type === 'image' ? (
        <ImageThumbnail
          scene={scene}
          sceneImage={mainSceneAsset && isImageSceneAsset(mainSceneAsset) ? mainSceneAsset.asset : undefined}
        />
      ) : null}
    </>
  );
};
