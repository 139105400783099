import { useCallback } from 'react';

import { IconButton, styled } from '@cofenster/web-components';

import type { ListViewTypeContext } from '../../contexts/listViewType/ListViewTypeContext';
import { useListViewType } from '../../contexts/listViewType/useListViewType';

const ListViewTypeButton = styled(IconButton)(() => ({
  '&[aria-pressed="true"]': {
    backgroundColor: 'rgb(0 0 0 / 0.04)',
  },
}));

export const HomeListTypeButtons = () => {
  const { type, toggleViewType } = useListViewType();
  const toggleViewTypeHandler = useCallback(
    (viewType: ListViewTypeContext['type']) => {
      if (type !== viewType) toggleViewType();
    },
    [toggleViewType, type]
  );

  return (
    <>
      <ListViewTypeButton
        icon="ListBulletIcon"
        iconSize="l"
        iconColor={type === 'LIST' ? 'carbon' : 'grey600'}
        onClick={() => toggleViewTypeHandler('LIST')}
        label="i18n.common.listView"
        aria-pressed={type === 'LIST'}
        data-testid="view-type-list"
      />
      <ListViewTypeButton
        icon="FourSquaresIcon"
        iconSize="l"
        iconColor={type === 'GRID' ? 'carbon' : 'grey600'}
        onClick={() => toggleViewTypeHandler('GRID')}
        label="i18n.common.gridView"
        aria-pressed={type === 'GRID'}
        data-testid="view-type-grid"
      />
    </>
  );
};
