import { useMemo } from 'react';

import type { ApolloQueryResult } from '@cofenster/api-client-apollo';

import {
  type ProjectRenderDescriptionQuery,
  type ProjectRenderDescriptionQueryResult,
  type ProjectRenderDescriptionQueryVariables,
  useProjectRenderDescriptionQuery,
} from '../../generated';

import type { MaybeSubtitlesLanguage } from './types';

export type ProjectRenderDescription = NonNullable<
  NonNullable<ProjectRenderDescriptionQueryResult['data']>['projectRenderDescription']
>;

export type RefetchProjectRenderDescription = Promise<ApolloQueryResult<ProjectRenderDescriptionQuery>>;

export const useProjectRenderDescription = (
  projectId: ProjectRenderDescriptionQueryVariables['projectId'] | undefined,
  subtitlesLanguage: MaybeSubtitlesLanguage = null
) => {
  const { loading, error, data, refetch } = useProjectRenderDescriptionQuery({
    variables: { projectId: projectId ?? '', subtitlesLanguage },
    skip: !projectId,
    // It is necessary to use the `cache-and-network` policy so that the project
    // render description gets properly refreshed after, for instance, updating
    // the duration of the project (which matters when passing over the duration
    // threshold of 90 minutes).
    fetchPolicy: 'cache-and-network',
  });
  const projectRenderDescription = data?.projectRenderDescription ?? undefined;
  return useMemo(
    () => ({ projectRenderDescription, loading, error, refetch }),
    [projectRenderDescription, loading, error, refetch]
  );
};
