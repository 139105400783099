import { useField } from 'formik';
import type { FC } from 'react';

import { FormSelect, MenuItem, SelectMenuProps, Typography, styled } from '@cofenster/web-components';

import { FormTypeSelectBox } from './FormTypeSelectBox';
import type { TypeSelectOption } from './types';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),
  padding: theme.spacing(0),
  borderRadius: theme.shape.borderRadius,
}));

const SelectedOptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const FormTypeSelect: FC<{
  name: string;
  options: TypeSelectOption[];
}> = ({ name, options }) => {
  const [field] = useField({ name });
  return (
    <FormSelect
      label="i18n.sceneSubtitle.createForm.formType"
      data-testid="scene-subtitle-form-type-select"
      name={name}
      renderValue={(value) => {
        const selectedOption = options.find((option) => option.value === value);
        if (!selectedOption) return null;
        return (
          <SelectedOptionContainer key={selectedOption.value}>
            {selectedOption.mainIcon} <Typography variant="l">{selectedOption.title}</Typography>
          </SelectedOptionContainer>
        );
      }}
      pb={0}
      MenuProps={{
        ...SelectMenuProps,
        PaperProps: {
          ...SelectMenuProps.PaperProps,
          sx: {
            ...SelectMenuProps.PaperProps?.sx,
            paddingTop: 2,
            paddingLeft: 2,
            paddingRight: 2,
            paddingBottom: 1,
          },
        },
      }}
    >
      {options.map((option) => (
        <StyledMenuItem key={option.value} {...option}>
          <FormTypeSelectBox
            title={option.title}
            description={option.description}
            mainIcon={option.mainIcon}
            checked={field.value === option.value}
            data-testid={option.testId}
          />
        </StyledMenuItem>
      ))}
    </FormSelect>
  );
};
