import { useMemo } from 'react';

import {
  type SendContributionRequestListAccessEmailMutationVariables,
  useSendContributionRequestListAccessEmailMutation,
} from '../../generated';

export const useSendContributionRequestListAccessEmail = () => {
  const [sendContrinutionRequestListAccessEmail, { loading }] = useSendContributionRequestListAccessEmailMutation();
  return useMemo(
    () => ({
      sendContrinutionRequestListAccessEmail: (
        id: SendContributionRequestListAccessEmailMutationVariables['id'],
        input: SendContributionRequestListAccessEmailMutationVariables['input']
      ) => sendContrinutionRequestListAccessEmail({ variables: { id, input } }),
      loading,
    }),
    [sendContrinutionRequestListAccessEmail, loading]
  );
};
