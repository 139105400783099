import { styled } from '@cofenster/web-components';

import { RouterLink } from './RouterLink';

export const ExpandedRouterLink = styled(RouterLink)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.brand.blue,
  },

  '&:focus': { outline: 0 },
  '&:focus-visible::after': theme.mixins.focusRing,

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    zIndex: theme.zIndex.base,
    cursor: 'pointer',
    borderRadius: theme.shape['borderRadius-l'],
  },
}));
