import { createContext } from 'react';

export type PanelContext = {
  isPanelOpen: boolean;
  openPanel: VoidFunction;
  closePanel: VoidFunction;
  togglePanel: VoidFunction;
};

export const PanelContext = createContext<PanelContext | undefined>(undefined);
