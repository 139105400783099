import { type FC, useCallback } from 'react';

import {
  Icon,
  type IconType,
  Select,
  SelectOption,
  SpinningIcon,
  Translatable,
  styled,
} from '@cofenster/web-components';

import type { ContributionRequestAssetType } from '../../../../api/generated';
import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useUpdateContributionRequest } from '../../../../api/hooks/contributionRequest/useUpdateContributionRequest';
import { useTrackContributionRequest } from '../../hooks/useTrackContributionRequest';

const typeToIcon: Record<ContributionRequestAssetType, IconType> = {
  VIDEO: 'MovieIcon',
  SCREEN_RECORDING: 'ScreenShareIcon',
  IMAGE: 'ImageIcon',
};

const typeToTranslationKey: Record<ContributionRequestAssetType, string> = {
  VIDEO: 'i18n.contributionRequest.type.video',
  SCREEN_RECORDING: 'i18n.contributionRequest.type.screenRecording',
  IMAGE: 'i18n.contributionRequest.type.image',
};

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const ContributionRequestTypeForm: FC<{ item: ContributionRequest }> = ({ item }) => {
  const track = useTrackContributionRequest('requestTypeUpdated');
  const { updateContributionRequest, loading } = useUpdateContributionRequest();
  const updateType = useCallback(
    async (type: ContributionRequestAssetType) => {
      if (item.type === type) return;
      const result = await updateContributionRequest(item.id, { type });
      if (result.data?.updateContributionRequest) {
        track(result.data?.updateContributionRequest);
      }
    },
    [updateContributionRequest, item, track]
  );

  return (
    <Select
      fullWidth
      id="contribution-content-type"
      name="contribution-content-type"
      label="i18n.contributionRequest.typeSelect.label"
      value={item.type}
      disabled={loading}
      onChange={(event) => updateType(event.target.value as ContributionRequestAssetType)}
      renderValue={() => (
        <FlexRow data-testid="contribution-request-asset-type" data-type={item.type}>
          <Icon type={typeToIcon[item.type]} size="m" />
          <Translatable>{typeToTranslationKey[item.type]}</Translatable>
          {loading && <SpinningIcon type="LoadingIcon" speed="fast" />}
        </FlexRow>
      )}
      pb={0}
    >
      <SelectOption value="VIDEO">
        <FlexRow>
          <Icon type={typeToIcon.VIDEO} size="m" />
          <Translatable>{typeToTranslationKey.VIDEO}</Translatable>
        </FlexRow>
      </SelectOption>
      <SelectOption value="IMAGE">
        <FlexRow>
          <Icon type={typeToIcon.IMAGE} size="m" />
          <Translatable>{typeToTranslationKey.IMAGE}</Translatable>
        </FlexRow>
      </SelectOption>
      <SelectOption value="SCREEN_RECORDING">
        <FlexRow>
          <Icon type={typeToIcon.SCREEN_RECORDING} size="m" />
          <Translatable>{typeToTranslationKey.SCREEN_RECORDING}</Translatable>
        </FlexRow>
      </SelectOption>
    </Select>
  );
};
