import { Pagination as MuiPagination, type PaginationProps as MuiPaginationProps } from '@mui/material';
import type { FC } from 'react';

import { PaginationItem } from './PaginationItem';

export type PaginationProps = Omit<
  MuiPaginationProps,
  'renderItem' | 'shape' | 'size' | 'sx' | 'getItemAriaLabel' | 'color'
>;

export const Pagination: FC<PaginationProps> = ({ ...rest }) => {
  return <MuiPagination {...rest} renderItem={(props) => <PaginationItem {...props} />} />;
};
