import { useCallback, useMemo, useState } from 'react';

import { uploadFile } from '@cofenster/web-components';

import { useInitImageUpload } from '../../api/hooks/imageAsset/useInitImageUpload';

export const useImageAssetUpload = () => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const initImageUpload = useInitImageUpload();
  const upload = useCallback(
    async (file: File) => {
      setProgress(0);

      try {
        const result = await initImageUpload();

        if (!result.data?.initImageUpload) {
          throw new Error('Failed to initialize the image upload');
        }

        const { uploadUrl, imageAssetId } = result.data.initImageUpload;

        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => setProgress(progress),
        });

        return imageAssetId;
      } finally {
        setProgress(undefined);
      }
    },
    [initImageUpload]
  );

  return useMemo(() => [upload, progress] as const, [upload, progress]);
};
