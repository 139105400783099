import { useMemo } from 'react';

import { type TeamsQueryResult, useTeamsQuery } from '../../generated';

export type Team = NonNullable<TeamsQueryResult['data']>['teams'][number];

export const useTeams = () => {
  const { loading, error, data } = useTeamsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const teams = data?.teams || undefined;
  return useMemo(() => ({ teams, loading, error }), [teams, loading, error]);
};
