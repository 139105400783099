import { type FC, useEffect, useMemo, useState } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  Pagination,
  Typography,
  UserProfile,
  UserStatus,
  styled,
} from '@cofenster/web-components';

import type { Member } from '../../../api/hooks/team/useTeam';
import { useCurrentTeam } from '../../../contexts/currentTeam/useCurrentTeam';

import { RoleAndMembership } from './RoleAndMembership';

const TableAndPaginationLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'stretch',
  '& .MuiPagination-root': {
    alignSelf: 'center',
  },
}));

const HeaderCell: FC<{ column: ColumnConfig<Member> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<Member>['columns'] = [
  {
    id: 'nameAndEmail',
    name: 'i18n.team.member',
    cell: ({ item }) => <UserProfile user={item} />,
    header: HeaderCell,
  },
  {
    id: 'roleAndMembership',
    name: 'i18n.userRole.role',
    cell: ({ item }) => <RoleAndMembership member={item} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'userStatus',
    name: 'i18n.common.status',
    cell: ({ item }) => (
      <UserStatus createdAt={item.createdAt} deactivatedAt={item.deactivatedAt} isPending={item.isPending} />
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const TeamMembersList: FC = () => {
  const { team } = useCurrentTeam();

  const data = useMemo(() => team?.members ?? [], [team?.members]);

  const pageSize = 10;
  const totalPages = Math.ceil(data.length / pageSize) || 1;
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (page < 1) setPage(1);
    else if (totalPages < page) setPage(totalPages);
  }, [page, totalPages]);

  const pagedData = useMemo(() => {
    if (totalPages <= 1) return data;
    return data.slice((page - 1) * pageSize, page * pageSize);
  }, [data, page, totalPages]);

  return (
    <TableAndPaginationLayout>
      <CSSGridTable data={pagedData} columns={columns} data-testid="members-list" />
      {totalPages > 1 && <Pagination page={page} count={totalPages} onChange={(_event, page) => setPage(page)} />}
    </TableAndPaginationLayout>
  );
};
