import { useCallback } from 'react';

import { type UpdateSceneAudioMutationVariables, useUpdateSceneAudioMutation } from '../../generated';

export const useUpdateSceneAudio = () => {
  const [updateSceneVideo] = useUpdateSceneAudioMutation({
    refetchQueries: ['Scenes', 'ProjectRenderDescription', 'SceneRenderDescription'],
    update: (cache) => {
      // When changing audio settings of the scene, source (transcription) for subtitles
      // can change (for e.g. when switching original audio track to uploaded audio file
      // or when muting scene). In case user already accessed sceneSubtitle page in current
      // session (sceneSubtitles query data are in the cache), the query cache needs to be
      // updated. `refetchQueries` with query name as a string will not work in this case
      // because it isn't active anywhere on the screen.
      // Alternatively we can pass document with variables to `refetchQueries` array as below
      // {
      //    query: ScenesWithSubtitlesDocument,
      //    variables: { projectId },
      // }
      // This way also non-active query will be refetched, but it's not stated in the apollo
      // documentation, and we would need to pass `projectId` as a variable to the hook.
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
  });
  return useCallback(
    (id: UpdateSceneAudioMutationVariables['id'], input: UpdateSceneAudioMutationVariables['input']) =>
      updateSceneVideo({ variables: { id, input } }),
    [updateSceneVideo]
  );
};
