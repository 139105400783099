import { styled } from '@mui/material';
import { useField } from 'formik';
import { type ClipboardEventHandler, type FC, useCallback, useRef, useState } from 'react';

import { useI18n } from '../../../services/i18n/useI18n';
import { isHexColor } from '../../../utilities/isHexColor';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { BlankButton } from '../../controls/Button';
import { LazyLoadedColorInput } from '../../controls/ColorInput';
import { Popover } from '../../feedback/Popover';

import { FormTextField, type FormTextFieldProps } from './FormTextField';

const normalizeColor = (initialValue: string) => {
  const value = initialValue.trim().toLowerCase().replace(/^#/, '');

  if (/^[a-f0-9]{6}$/i.test(value)) return `#${value}`;
  if (/^[a-f0-9]{3}$/i.test(value))
    return `#${Array.from(value)
      .map((channel) => channel + channel)
      .join('')}`;

  return null;
};

const ColorPickAdornmentToggle = styled(
  BlankButton,
  preventForwardProps(['color'])
)<{ color?: string }>(({ theme, color }) => ({
  backgroundColor: color,
  flex: '1 0 auto',
  width: '1.75em',
  height: '1.75em',
  borderRadius: '0.35em',
  border: '1px solid rgb(0 0 0 / 0.2)',

  '&:focus-visible': theme.mixins.focusRing,
}));

export const FormColorPicker: FC<FormTextFieldProps> = (props) => {
  const { translate } = useI18n();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [field, , { setValue }] = useField(props.name);
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const handlePaste: ClipboardEventHandler = useCallback(
    (event) => {
      event.preventDefault();

      const value = normalizeColor(event.clipboardData.getData('text'));

      if (value) setValue(value);
    },
    [setValue]
  );

  return (
    <div ref={anchorRef}>
      <FormTextField
        {...props}
        {...field}
        onPaste={handlePaste}
        data-testid="color-field"
        placeholder={props.placeholder ?? 'i18n.global.colorPicker.placeholder'}
        InputProps={{
          readOnly: true,
          onClick: open,
          startAdornment: (
            <ColorPickAdornmentToggle
              color={field.value}
              onClick={isOpen ? close : open}
              type="button"
              aria-expanded={isOpen}
              data-testid="color-picker"
              aria-label={translate('global.colorPicker.button')}
            />
          ),
        }}
      />
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={anchorRef.current}
        anchorOriginX="center"
        anchorOriginY="top"
        transformOriginX="center"
        transformOriginY="bottom"
        offsetY={-20}
        data-testid="form-color-picker"
        disableScrollLock
      >
        <LazyLoadedColorInput value={isHexColor(field.value) ? field.value : '#000'} onChange={setValue} />
      </Popover>
    </div>
  );
};
