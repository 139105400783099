import { useCallback, useMemo } from 'react';

import { LAYER_INDEXES } from '@cofenster/constants';
import { useUpload } from '@cofenster/web-components';

import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';

const STAGE = process.env.STAGE;

export const useUploadSceneImageAsset = (sceneId?: string) => {
  const { startUpload, getUpload, cancelAndClearUpload } = useUpload();
  const createSceneAsset = useCreateSceneAsset();

  const cancelUpload = useCallback(() => {
    if (sceneId) cancelAndClearUpload('image', sceneId);
  }, [cancelAndClearUpload, sceneId]);

  const uploadProgress: number | undefined = sceneId ? getUpload('image', sceneId)?.progress : undefined;
  const uploadRecording = useCallback(
    async (file: File, _sceneId?: string) => {
      try {
        const resolvedSceneId = sceneId ?? _sceneId;

        if (!resolvedSceneId) {
          if (STAGE !== 'production') {
            console.log('No sceneId provided');
          }
          return;
        }

        const imageAssetId = await startUpload('image', resolvedSceneId, file);

        // Uploading file failed or was cancelled - there is not sense to create a scene asset
        // without the asset itself. By creating we would have InProgress status forever.
        if (!imageAssetId) return;

        await createSceneAsset({
          assetType: 'Image',
          assetId: imageAssetId,
          sceneId: resolvedSceneId,
          layerIndex: LAYER_INDEXES.main,
          startTime: 0,
        });

        return imageAssetId;
      } finally {
        cancelUpload();
      }
    },
    [sceneId, startUpload, createSceneAsset, cancelUpload]
  );

  return useMemo(
    () => [uploadProgress, uploadRecording, cancelUpload] as const,
    [uploadProgress, uploadRecording, cancelUpload]
  );
};
