import { useCallback, useMemo } from 'react';

import { useProject } from '../../../contexts/project/useProject';
import { useWebManagerTracking } from '../../useWebManagerTracking';

import { useUploadSceneImageAsset } from './useUploadSceneImageAsset';

export type ImageUploadOptions = {
  sceneId?: string;
  metadata?: Record<string, unknown>;
};

export const useUploadSceneImageWithTracking = (
  sceneId?: string,
  additionalTrackingDetails?: Record<string, string | number>
) => {
  const [uploadProgress, uploadImage, cancelUpload] = useUploadSceneImageAsset(sceneId);
  const tracking = useWebManagerTracking();
  const { project } = useProject();

  const trackSceneAssetUploaded = useCallback(
    (file: File, _sceneId?: string, metadata?: Record<string, unknown>) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId: sceneId ?? _sceneId,
          assetType: 'image',
          assetName: file.name,
          uploadSource: metadata?.source,
          ...additionalTrackingDetails,
          ...metadata,
        },
      }),
    [tracking, sceneId, additionalTrackingDetails]
  );

  const upload = useCallback(
    async (file: File, options?: ImageUploadOptions) => {
      const imageAssetId = await uploadImage(file, options?.sceneId);
      trackSceneAssetUploaded(file, options?.sceneId, options?.metadata);
      return imageAssetId;
    },
    [uploadImage, trackSceneAssetUploaded]
  );

  const cancel = useCallback(() => {
    cancelUpload();
    tracking.trackEvent({
      event: 'SceneAssetUploadCancelled',
      details: {
        projectId: project?.id,
        sceneId: sceneId,
        source: 'edit',
        assetType: 'video',
      },
    });
  }, [cancelUpload, tracking, project?.id, sceneId]);

  return useMemo(
    () => ({ imageUploadProgress: uploadProgress, uploadImage: upload, cancelImageUpload: cancel }) as const,
    [uploadProgress, upload, cancel]
  );
};
