import { type Theme, styled } from '@mui/material';
import {
  type CSSProperties,
  type ChangeEventHandler,
  type ComponentProps,
  type FC,
  useCallback,
  useEffect,
  useState,
} from 'react';

import type { BrandColor } from '../../../theming/theme';

const styleSliderThumb = ({ theme, brandColor }: { theme: Theme; brandColor: BrandColor }) => ({
  appearance: 'none',

  width: theme.spacing(2),
  height: theme.spacing(2),
  position: 'relative',
  zIndex: theme.zIndex.overlay,

  border: `3px solid ${theme.palette.brand[brandColor]}`,
  backgroundColor: theme.palette.brand.white,
  borderRadius: '50%',
  cursor: 'pointer',
});

export const StyledSlider = styled('input')<{ brandColor: BrandColor }>(({ theme, brandColor }) => ({
  appearance: 'none',

  position: 'relative',
  width: '100%',

  borderRadius: 3,

  height: 6,

  backgroundColor: theme.palette.brand.grey100,
  backgroundImage: `linear-gradient(to right, ${theme.palette.brand[brandColor]} calc(var(--zoom) * 10% - 5px), ${theme.palette.brand.grey100} calc(var(--zoom) * 10% - 5px))`,

  '&:focus': {
    outline: 0,

    '&::-webkit-slider-thumb': {
      boxShadow: `0 0 0 ${theme.spacing(1)} ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}`,
    },
    '&::-moz-range-thumb': {
      boxShadow: `0 0 0 ${theme.spacing(1)} ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}`,
    },
  },

  '&[type=range]::-webkit-slider-thumb': styleSliderThumb({ theme, brandColor }),
  '&[type=range]::-moz-range-thumb': styleSliderThumb({ theme, brandColor }),

  '&[type=range]:disabled': {
    '&::-webkit-slider-thumb': { borderColor: theme.palette.brand.grey600, opacity: 0.7, cursor: 'not-allowed' },
    '&::-moz-range-thumb': { borderColor: theme.palette.brand.grey600, opacity: 0.7, cursor: 'not-allowed' },
  },

  '&[type=range]:not(:disabled)': {
    '&::-webkit-slider-thumb': {
      '&:hover': { boxShadow: `0 0 0 5px ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}` },
      '&:active': { boxShadow: `0 0 0 8px ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}` },
    },
    '&::-moz-range-thumb': {
      '&:hover': { boxShadow: `0 0 0 5px ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}` },
      '&:active': { boxShadow: `0 0 0 8px ${theme.palette.toRgba(theme.palette.brand[brandColor], 0.1)}` },
    },
  },
}));

type SliderProps = ComponentProps<'input'> & { brandColor: BrandColor };

export const Slider: FC<SliderProps> = ({ brandColor, onChange, ...rest }) => {
  const [value, setValue] = useState<number>(1);

  useEffect(() => {
    setValue(Number(rest.value ?? 1));
  }, [rest.value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(+event.target.value);
      onChange?.(event);
    },
    [onChange]
  );

  return (
    <StyledSlider
      type="range"
      brandColor={brandColor}
      onChange={handleChange}
      {...rest}
      style={{ '--zoom': +value } as CSSProperties}
    />
  );
};
