import { type ComponentType, type MouseEventHandler, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { generatePath, transitionView } from '@cofenster/web-components';

import { routes } from '../../routes';

import type { RouterLinkProps } from './RouterLink';

type RequiredComponentsProps = {
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
};

export const withRouterLink = <OriginalProps extends RequiredComponentsProps>(
  Component: ComponentType<OriginalProps>
) => {
  const WrappedComponent = ({
    to,
    params,
    onClick,
    state,
    withViewTransition,
    reloadDocument,
    query,
    forwardQueryParams,
    ...rest
  }: OriginalProps & RouterLinkProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const destination = generatePath(routes[to], params) + (query ?? (forwardQueryParams ? location.search : ''));
    const navigateToDestination = useCallback(() => navigate(destination, { state }), [navigate, destination, state]);
    const onRouterClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
      (event) => {
        event.preventDefault();
        onClick?.(event);

        if (withViewTransition) transitionView(navigateToDestination);
        else navigateToDestination();
      },
      [onClick, withViewTransition, navigateToDestination]
    );

    return (
      <Component
        {...(rest as unknown as OriginalProps)}
        href={destination}
        onClick={reloadDocument ? onClick : onRouterClick}
        {...rest}
      />
    );
  };

  return WrappedComponent;
};
