import { useCurrentTeamSafe } from '../currentTeam/useCurrentTeam';
import { useRoleSafe } from './useRole';

export const useUserContextRole = (teamId?: string) => {
  const roles = useRoleSafe();
  const { team } = useCurrentTeamSafe();

  if (!roles) return undefined;

  const targetTeamId = teamId ?? team?.id;
  const { teamRoles, accountRole } = roles;
  const teamRole = teamRoles.find((role) => role.teamId === targetTeamId)?.role;

  if (!teamRole) return accountRole.name;

  return teamRole.name;
};
