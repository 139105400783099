import type { FC, ReactNode } from 'react';

import {
  Card,
  GridContainer,
  GridItem,
  Headline,
  Text,
  Translatable,
  styled,
  useFormatTimeDistance,
} from '@cofenster/web-components';

import type { AccountTheme } from '../../api/hooks/user/useMe';

import { ThemeBubbles } from './ThemeBubbles';

type ThemeCardProps = {
  theme: AccountTheme;
  renderName?: (theme: AccountTheme) => ReactNode;
};

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: `0 0 0 1px ${theme.palette.brand.grey200} inset`,

  '&[aria-current="true"], &[aria-pressed="true"]': {
    boxShadow: `0 0 0 1px ${theme.palette.brand.blue} inset`,
    backgroundColor: theme.palette.brand.blue50,
  },
}));

export const ThemeCard: FC<ThemeCardProps> = ({ theme, renderName, ...rest }) => {
  const formatTimeDistance = useFormatTimeDistance();

  return (
    <StyledCard {...rest} spacing={{ all: 3 }} data-testid="theme-card" id={`theme-${theme.id}`}>
      <GridContainer flexDirection="column-reverse" minHeight={130}>
        <GridItem>
          <Headline variant="h4" component="p">
            {renderName?.(theme) ?? theme.name}
          </Headline>
          <Text color="grey700" variant="s" component="p">
            <Translatable
              i18nParams={{
                timeAgo: formatTimeDistance(new Date(theme.updatedAt ?? theme.createdAt)),
              }}
            >
              i18n.branding.themes.themeCard.updatedAt
            </Translatable>
          </Text>
        </GridItem>
        <GridItem display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <ThemeBubbles theme={theme} />
        </GridItem>
      </GridContainer>
    </StyledCard>
  );
};
