import { type FC, Suspense, lazy } from 'react';

import { LoadingSpinner } from '../../feedback/LoadingSpinner';

import type { ColorInputProps } from './ColorInput';

export const ColorInput = lazy(() => import('./ColorInput'));

export const LazyLoadedColorInput: FC<ColorInputProps> = (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ColorInput {...props} />
  </Suspense>
);
