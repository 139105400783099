import { useCallback, useMemo } from 'react';

import { LAYER_INDEXES, type VideoCaptureSource, type VideoFit } from '@cofenster/constants';
import { useUpload } from '@cofenster/web-components';

import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';
import { useProject } from '../../../contexts/project/useProject';

const STAGE = process.env.STAGE;

export const useUploadSceneVideoAsset = (sceneId?: string) => {
  const { startUpload, getUpload, cancelAndClearUpload } = useUpload();
  const createSceneAsset = useCreateSceneAsset();

  const { project } = useProject();
  const videoFormat = project?.videoFormat;

  const cancelUpload = useCallback(() => {
    if (sceneId) cancelAndClearUpload('video', sceneId);
  }, [cancelAndClearUpload, sceneId]);

  const uploadProgress: number | undefined = sceneId ? getUpload('video', sceneId)?.progress : undefined;
  const upload = useCallback(
    async (file: File, videoFit?: VideoFit, videoCaptureSource?: VideoCaptureSource, _sceneId?: string) => {
      try {
        const resolvedSceneId = _sceneId ?? sceneId;

        if (!resolvedSceneId) {
          if (STAGE !== 'production') {
            console.log('No sceneId provided');
          }
          return;
        }

        const videoAssetId = await startUpload('video', resolvedSceneId, file, {
          videoFormat,
          videoFit: videoFit ?? undefined,
          videoCaptureSource: videoCaptureSource ?? undefined,
        });

        // Uploading file failed or was cancelled - there is not sense to create
        // the scene asset without the asset itself. By creating one we would
        // have `InProgress` status forever.
        if (!videoAssetId) return;

        await createSceneAsset({
          assetId: videoAssetId,
          assetType: 'Video',
          sceneId: resolvedSceneId,
          startTime: 0,
          layerIndex: LAYER_INDEXES.main,
        });
      } finally {
        cancelUpload();
      }
    },
    [sceneId, startUpload, createSceneAsset, cancelUpload, videoFormat]
  );

  return useMemo(() => [uploadProgress, upload, cancelUpload] as const, [uploadProgress, upload, cancelUpload]);
};
