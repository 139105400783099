import { styled } from '@cofenster/web-components';

export const ThumbnailsList = styled('ul')(({ theme }) => ({
  counterReset: 'thumbnails',

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  margin: 0,
  padding: 0,
}));
