import { useMemo } from 'react';

import {
  type SceneRenderDescriptionQueryResult,
  type SceneRenderDescriptionQueryVariables,
  useSceneRenderDescriptionQuery,
} from '../../generated';

export type SceneRenderDescription = NonNullable<
  NonNullable<SceneRenderDescriptionQueryResult['data']>['sceneRenderDescription']
>;

export type RenderDescriptionSceneVideo = Extract<
  SceneRenderDescription['scenes'][number]['sceneAssets'][number],
  { __typename?: 'RenderDescriptionSceneVideo' }
>;

export const useSceneRenderDescription = (
  projectId: SceneRenderDescriptionQueryVariables['projectId'],
  sceneId?: SceneRenderDescriptionQueryVariables['sceneId']
) => {
  const { loading, error, data, refetch } = useSceneRenderDescriptionQuery({
    variables: { projectId, sceneId: sceneId ?? '' },
    skip: !sceneId,
    // It is necessary to use the `cache-and-network` policy so that the scene
    // render description gets properly refreshed after, for instance, updating
    // the project theme (such as turning off the usage of the logo).
    fetchPolicy: 'cache-and-network',
  });
  const sceneRenderDescription = data?.sceneRenderDescription ?? undefined;
  return useMemo(
    () => ({ sceneRenderDescription, loading, error, refetch }),
    [sceneRenderDescription, loading, error, refetch]
  );
};
