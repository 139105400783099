import type { FC } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import type { CurrentTeamContext } from '../../../contexts/currentTeam/CurrentTeamContext';
import { useProjectSpace } from '../../../contexts/projectSpace/useProjectSpace';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { routes } from '../../../routes';
import { SubNavigationItem } from '../../button/SubNavigationItem';

export type ProjectSpaceSubNavigationProps = {
  folderId: string;
  team?: CurrentTeamContext['team'];
};

export const ProjectSpaceSubNavigation: FC<ProjectSpaceSubNavigationProps> = ({ folderId, team }) => {
  const { pathname } = useLocation();
  const [currentProjectSpace] = useProjectSpace();
  const isPrivate = 'private' === currentProjectSpace;

  return (
    <SubNavigation>
      <SubNavigationItem
        to="projectFolder"
        params={{ folderId: isPrivate ? 'personal' : folderId }}
        forwardQueryParams
        active={!!matchPath(routes.projectFolder, pathname)}
        iconType="FolderIcon"
        data-testid="subnav-active-projects"
      >
        i18n.common.projects
      </SubNavigationItem>

      {team && (
        <>
          {!team.public && (
            <SubNavigationItem
              to="teamMembers"
              params={{ teamId: team.id }}
              active={!!matchPath(routes.teamMembers, pathname)}
              iconType="UsersIcon"
              data-testid="subnav-team-members"
            >
              i18n.subNavigation.members
            </SubNavigationItem>
          )}

          <TeamPermissionRestriction has="TeamUpdate">
            <SubNavigationItem
              to="teamSettings"
              params={{ teamId: team.id }}
              active={!!matchPath(routes.teamSettings, pathname)}
              iconType="GearIcon"
              data-testid="subnav-team-settings"
            >
              i18n.subNavigation.settings
            </SubNavigationItem>
          </TeamPermissionRestriction>
        </>
      )}
    </SubNavigation>
  );
};
