import { useCallback, useState } from 'react';

import { Text } from '@cofenster/web-components';
import { useDeleteMainAssetFromScenes } from '../../../api/hooks/scene/useDeleteMainAssetFromScenes';
import { useActionSceneIds } from '../../../contexts/selectedScenes/useActionSceneIds';
import { useConfirmDialog } from '../../useConfirmDialog';

export const useDeleteImageSceneAsset = (sceneId: string) => {
  const [busy, setBusy] = useState(false);
  const deleteMainAssetFromScenes = useDeleteMainAssetFromScenes();
  const actionSceneIds = useActionSceneIds(sceneId);

  const confirmDeleteImageSceneAsset = useConfirmDialog({
    title: 'i18n.dialogs.DeleteAssetDialog.multiple.headline',
    titleI18nParams: { count: actionSceneIds.length },
    content: (
      <Text i18nParams={{ count: actionSceneIds.length }} variant="l" color="grey600">
        i18n.dialogs.DeleteAssetDialog.multiple.text
      </Text>
    ),
    confirm: 'i18n.dialogs.DeleteAssetDialog.button',
  });

  return useCallback(async () => {
    if (busy) return;
    try {
      setBusy(true);

      if (await confirmDeleteImageSceneAsset()) {
        await deleteMainAssetFromScenes(actionSceneIds);
      }
    } finally {
      setBusy(false);
    }
  }, [confirmDeleteImageSceneAsset, deleteMainAssetFromScenes, busy, actionSceneIds]);
};
