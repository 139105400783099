import { type FC, type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, Icon, Text, VisuallyHidden, styled } from '@cofenster/web-components';

// 1. Negate the bottom spacing from the Select component
// 2. Align the text of the button to the left instead of center
// 3. Give a bit more spacing between the icon and the text
const PlayButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.brand.grey100}`,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  marginTop: theme.spacing(-4), // 1
  justifyContent: 'flex-start', // 2
  gap: theme.spacing(1), // 3
}));

const useAudioPlayer = (audioRef: RefObject<HTMLAudioElement>) => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const reset = () => audio.load();
    const play = () => setIsPlaying(true);
    const pause = () => setIsPlaying(false);

    audio.addEventListener('ended', reset);
    audio.addEventListener('play', play);
    audio.addEventListener('pause', pause);

    return () => {
      audio.removeEventListener('ended', reset);
      audio.removeEventListener('play', play);
      audio.removeEventListener('pause', pause);
    };
  }, [audioRef]);

  return useMemo(() => ({ isPlaying }), [isPlaying]);
};

export const AudioOverlayPreview: FC<{ src: string }> = ({ src }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { isPlaying } = useAudioPlayer(audioRef);
  const handlePlayPause = useCallback(() => {
    const audio = audioRef.current;
    if (!audio) return;
    if (isPlaying) audio.pause();
    else audio.play().catch(() => {});
  }, [isPlaying]);

  return (
    <>
      <PlayButton
        variant="tertiary"
        type="button"
        aria-pressed={isPlaying}
        onClick={handlePlayPause}
        startIcon={<Icon type={isPlaying ? 'PauseIcon' : 'PlayIcon'} size="m" weight="fill" color="carbon" />}
        data-testid="scene-audio-preview-button"
      >
        <VisuallyHidden>i18n.global.play</VisuallyHidden>
        <Text variant="l" color="grey700">
          i18n.projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio.button
        </Text>
      </PlayButton>

      <audio ref={audioRef} src={src} controls={false} />
    </>
  );
};
