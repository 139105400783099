import type { FC, PropsWithChildren } from 'react';

import { IconButton, type IconButtonProps } from '@cofenster/web-components';

import { RequestPermissionIconButton } from '../../../components/requestPermissions/RequestPermissionIconButton';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../../contexts/user/useUser';
import { useStartRenderButtonProps } from './useStartRenderButtonProps';

export const StartRenderIconButton: FC<
  PropsWithChildren<IconButtonProps & { projectId: string; subtitlesLanguage: string | null }>
> = ({ children, projectId, subtitlesLanguage, ...rest }) => {
  const { user } = useUser();
  const isAllowedToRenderProject = useTeamPermissionStatus({ has: 'ProjectRender' }) === 'ALLOWED';
  const renderButtonProps = useStartRenderButtonProps(projectId, subtitlesLanguage);

  if (!isAllowedToRenderProject) {
    return user ? <RequestPermissionIconButton user={user} permission="ProjectRender" /> : null;
  }

  return <IconButton {...rest} {...renderButtonProps} />;
};
