import { useCallback } from 'react';

import {
  type CreateProjectFolderInput,
  type CreateProjectFolderMutationResult,
  type CreateProjectFolderMutationVariables,
  useCreateProjectFolderMutation,
} from '../../generated';

export type ProjectFolder = NonNullable<CreateProjectFolderMutationResult['data']>['createProjectFolder'];

export const useCreateProjectFolder = () => {
  const [createProjectFolder] = useCreateProjectFolderMutation({
    refetchQueries: [
      'ProjectFolders',
      // The ‘me’ query is used to figure out whether a given folder belongs to
      // the personal space (by checking if it exists in the `personalFolderIds`
      // array), which is why it is important to refetch the ‘me’ query after
      // creating a folder.
      'Me',
    ],
  });
  return useCallback(
    async (parentId: CreateProjectFolderMutationVariables['parentId'], input: CreateProjectFolderInput) =>
      createProjectFolder({ variables: { input, parentId } }),
    [createProjectFolder]
  );
};
