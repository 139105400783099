import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  SortableContext,
  type UniqueIdentifier,
  styled,
  useDraggableStyles,
  useSortable,
} from '@cofenster/web-components';

import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useSortContributionRequests } from '../../../../api/hooks/contributionRequest/useSortContributionRequests';
import { CardDragHandle } from '../../../../components/card';
import { ContributionRequestCard } from '../../ContributionRequestCard';
import { useTrackContributionRequest } from '../../hooks/useTrackContributionRequest';

const SliderContainer = styled('ul')(() => ({
  marginTop: 0,
  marginBottom: 0,
  paddingLeft: 0,
}));

type SortableListProps = {
  items: ContributionRequest[];
  projectId: string;
};

type SortableItemProps = SortableListProps & {
  value: ContributionRequest;
  index: number;
  insertionIndex: number | null;
  setInsertionIndex: (state: number | null) => unknown;
  disabled: boolean;
};

export const SortableContributionRequestList: FC<SortableListProps> = ({
  items,
  projectId,
  ...rest
}: SortableListProps) => {
  const [insertionIndex, setInsertionIndex] = useState<number | null>(null);
  // we cache to improve d'n'd performance
  const [cachedItems, setCachedItems] = useState<ContributionRequest[]>(items);
  const ids: UniqueIdentifier[] = useMemo(() => cachedItems.map((it) => it.id), [cachedItems]);
  const sortContributionRequests = useSortContributionRequests();
  const track = useTrackContributionRequest('requestReordered');

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedContributionRequests = arrayMove(items, oldIndex, newIndex);
      setCachedItems(sortedContributionRequests);
      const result = await sortContributionRequests(sortedContributionRequests.map((request) => request.id));
      if (result.data?.sortContributionRequests && sortedContributionRequests[newIndex]) {
        track({ id: sortedContributionRequests[newIndex].id });
      }
    },
    [items, sortContributionRequests, track]
  );

  useEffect(() => {
    setCachedItems(items);
  }, [items]);

  return (
    <SortableContext ids={ids} onSortEnd={onSortEnd} restrictTo="y">
      <SliderContainer>
        {cachedItems.map((request, index) => (
          <SortableItem
            key={request.id}
            value={request}
            index={index}
            items={items}
            setInsertionIndex={setInsertionIndex}
            insertionIndex={insertionIndex}
            projectId={projectId}
            disabled={cachedItems.length < 2}
            {...rest}
          />
        ))}
      </SliderContainer>
    </SortableContext>
  );
};

const DraggableItem = styled('li')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  listStyle: 'none',
  position: 'relative',
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),

  '&.dragged': {
    filter: `drop-shadow(0 0 ${theme.spacing(4)} rgb(0 0 0 / 0.2))`,
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const SortableItem: FC<SortableItemProps> = ({ value, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: value.id,
    disabled,
  });
  const draggableStyles = useDraggableStyles({ isDragging, transform, transition });

  return (
    <DraggableItem ref={setNodeRef} style={draggableStyles}>
      {!disabled && <CardDragHandle {...listeners} {...attributes} centerVertically />}
      <ContributionRequestCard item={value} />
    </DraggableItem>
  );
};
