import { type Dispatch, type SetStateAction, createContext } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import type { useSearch } from './useSearch';

export const SearchContext = createContext<{
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  page: number;
  setPage: (page: number) => void;
  search: ReturnType<typeof useSearch>[0];
  metadata: ReturnType<typeof useSearch>[1];
  type: 'image' | 'video';
  videoFormat: VideoFormat;
}>({
  searchTerm: '',
  setSearchTerm: () => undefined,
  page: 1,
  setPage: () => undefined,
  search: () => undefined,
  metadata: { data: undefined, loading: false, error: undefined },
  type: 'image',
  videoFormat: 'Horizontal',
});
