import { type Enhancer, useAutomaticTracking as useSharedAutomaticTracking } from '@cofenster/web-components';

import { useCallback } from 'react';

import { useEventTracking } from '../api/hooks/tracking/useEventTracking';
import { useUser } from '../contexts/user/useUser';

export const useAutomaticTracking = () => {
  const trackEvent = useEventTracking();
  const { user } = useUser();

  const enhancer = useCallback<Enhancer>(
    (context) => {
      if (user) {
        context.userId = user.id;
        context.accountId = user.account.id;
        context.language = user.locale;
      }
    },
    [user]
  );

  useSharedAutomaticTracking(trackEvent, enhancer);
};
