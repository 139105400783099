import { styled } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Translatable } from '../../../services/i18n';
import { ICON_SIZES as ICON_WIDTHS } from '../../assets/icons/Icon';
import { IconButton } from '../../controls/Button/IconButton';
import { Chip } from '../../display/Chip';
import { Typography } from '../../typography/Typography';
import { SIDEBAR_WIDTH } from '../Sidebar';

const HEADER_SPACING = 2;

const Container = styled('div')<{ mb?: number }>(({ theme, mb = 4 }) => ({
  marginBottom: theme.spacing(mb),
  position: 'relative',
  width: '100%',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(HEADER_SPACING),
}));

// 1. Buttons are full-height by default, this ensures they are top-aligned if
//    the title spans across several lines.
const ButtonWrapper = styled('div')(() => ({
  alignSelf: 'flex-start', // 1
}));

// 1. Offset the arrow in the margin to the left of the title when there is
//    enough room for that. In the local variables, the term “width” is used for
//    pixel values, while “size” is used for base-8 grid values.
//    |                               Window                                   |
//    | Sidebar (240)  |hpIph|                Main (1200)                |     |
//                      ^^^^^                            And same here ---^^^^^
//    | h (12) |     Icon button (40)     | h (12) |     h = header spacing
//    | h (12) | p (8) | Icon (M) | p (8) | h (12) |     p = icon button padding
const BackButtonWrapper = styled(ButtonWrapper)(({ theme }) => {
  const containerWidth = theme.breakpoints.values.lg - 24 * 2;
  const iconWidth = ICON_WIDTHS.m;
  const iconSize = iconWidth / 8;
  const headerSpacingWidth = HEADER_SPACING * 8;
  const iconButtonPaddingSize = 2;
  const buttonWidth = iconWidth + iconButtonPaddingSize * 8;
  const buttonAndHeaderSpacingWidth = buttonWidth + headerSpacingWidth * 2;

  const breakpoint = SIDEBAR_WIDTH + containerWidth + buttonAndHeaderSpacingWidth * 2;
  const offset = (iconSize + iconButtonPaddingSize + HEADER_SPACING) * -1;

  return {
    [`@media (min-width: ${breakpoint}px)`]: {
      marginLeft: theme.spacing(offset), // 1
    },
  };
});

const Title = styled(Typography)(() => ({
  wordBreak: 'break-word',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

// 1. Make sure the button slot never shrink to avoid wrapping buttons over
//    several lines.
// 2. Always push the right button to the very right of the container.
const RightButtonWrapper = styled(ButtonWrapper)(() => ({
  flexShrink: 0, // 1
  marginLeft: 'auto', // 2
}));

type Props = {
  title?: string;
  mb?: number;
  onBack?(): void;
  isPrivate?: boolean;
  popover?: ReactNode;
  rightAction?: ReactNode;
};

export const PageHeadline: FC<PropsWithChildren<Props>> = ({ title, mb, onBack, isPrivate, popover, rightAction }) => {
  if (!title) {
    return null;
  }

  return (
    <Container mb={mb}>
      <Header>
        {onBack && (
          <BackButtonWrapper>
            <IconButton
              icon="ArrowLeftIcon"
              iconSize="m"
              onClick={onBack}
              type="button"
              data-testid="page-back-button"
              label="i18n.global.back"
            />
          </BackButtonWrapper>
        )}

        <Title variant="h2" component="h1">
          <Translatable>{title}</Translatable>

          {isPrivate && (
            <StyledChip color="dark" icon="LockIcon">
              i18n.global.private
            </StyledChip>
          )}
        </Title>

        {popover && <ButtonWrapper>{popover}</ButtonWrapper>}
        {rightAction && <RightButtonWrapper>{rightAction}</RightButtonWrapper>}
      </Header>
    </Container>
  );
};
