import type { FC } from 'react';

import { Card, GridContainer, GridItem, Headline, Text, styled, useBooleanState } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { ExpandedRouterLink } from '../../button/ExpandedRouterLink';

import { FolderCircleIcon } from './FolderCircleIcon';
import { PopoverMenu } from './PopoverMenu';

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),

  '&:hover': {
    color: theme.palette.brand.blue,
  },
}));

const Name = styled(Headline)(() => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

type Props = {
  projectFolder: ProjectFolder;
};

export const FolderRow: FC<Props> = ({ projectFolder }) => {
  const [hovered, onMouseEnter, onMouseLeave] = useBooleanState(false);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="folder-row"
      id={`folder-${projectFolder.id}`}
    >
      <Card spacing={{ all: 3 / 2 }}>
        <GridContainer alignItems="center" spacing={2}>
          <GridItem>
            <FolderCircleIcon small open={hovered} />
          </GridItem>
          <GridItem xs={true} zeroMinWidth>
            <Name variant="h6" component="p" color="inherit">
              <ExpandedRouterLink to="projectFolder" params={{ folderId: projectFolder.id }}>
                {projectFolder.name}
              </ExpandedRouterLink>
            </Name>
          </GridItem>
          <GridItem>
            <Text variant="s" color="grey700" i18nParams={{ count: projectFolder.projectCount ?? 0 }}>
              i18n.projects.folderCard.videos
            </Text>
          </GridItem>
          <GridItem>
            <PopoverMenu projectFolder={projectFolder} />
          </GridItem>
        </GridContainer>
      </Card>
    </Container>
  );
};
