import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGoToRecordBriefingVideo = (projectId: string, contributionRequestListId: string) => {
  const goto = useGoto();
  return useCallback(() => {
    goto(routes.recordVideoBriefing, { projectId, contributionRequestListId });
  }, [goto, projectId, contributionRequestListId]);
};
