export const secondsToTime = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getSeconds();
  const time = [hh, mm, ss]

    .map((v) => String(v).padStart(2, '0'))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
  return time;
};
