import { useMemo } from 'react';

import {
  type CreateContributionRequestListMutationVariables,
  type CreateContributionRequestListInput as Input,
  useCreateContributionRequestListMutation,
} from '../../generated';

export type CreateContributionRequestListInput = Input;

export const useCreateContributionRequestList = () => {
  const [createContributionRequestList, { loading }] = useCreateContributionRequestListMutation({
    refetchQueries: ['ContributionRequestListByProject', 'ContributionRequestsByProject'],
  });
  return useMemo(
    () => ({
      createContributionRequestList: async (input: CreateContributionRequestListMutationVariables['input']) =>
        createContributionRequestList({
          variables: { input },
        }),
      loading,
    }),
    [createContributionRequestList, loading]
  );
};
