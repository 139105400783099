const pad = (value: number) => String(value).padStart(2, '0');

export const formatDuration = (duration: number, withLeadingZeros = true) => {
  const rounded = Math.round(duration);
  const hours = Math.floor(rounded / 3600);
  const minutes = Math.floor((rounded - hours * 3600) / 60);
  const seconds = rounded - hours * 3600 - minutes * 60;

  const withPad = withLeadingZeros ? pad : (value: number) => String(value);

  return [hours && withPad(hours), withPad(minutes), pad(seconds)].filter(Boolean).join(':');
};
