import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

import { useDeleteProjects } from '../../../api/hooks/project/useDeleteProjects';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export type DeleteProjectsDialogProps = {
  projectIds: string[];
  publishedProjectIds: string[];
  isOpen: boolean;
  closeDialog: () => unknown;
  trackingSource: 'projectCard' | 'bulkSelector' | 'projectDetail';
  onProjectsDeleted?: () => unknown;
  teamId?: string;
  refetchQueries?: string[];
};

export const DeleteProjectsDialog: FC<DeleteProjectsDialogProps> = ({
  isOpen,
  closeDialog,
  projectIds,
  publishedProjectIds,
  trackingSource,
  onProjectsDeleted,
  teamId,
  refetchQueries,
}) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking(teamId);
  const deleteProjects = useDeleteProjects(refetchQueries);

  const trackProjectsDeleted = useCallback(async () => {
    const projectDetails = projectIds.length === 1 ? { projectId: projectIds[0] } : { projectIds };
    tracking.trackEvent({
      event: 'projectsDeleted',
      details: {
        ...projectDetails,
        source: trackingSource,
      },
    });
  }, [projectIds, tracking, trackingSource]);

  const onAction = useCallback(async () => {
    await deleteProjects(projectIds);
    onProjectsDeleted?.();
    closeDialog();
    trackProjectsDeleted();

    openSnackbar({
      children: 'i18n.snackbars.projectsDeletedSnackbar.copy',
      i18nParams: { count: projectIds.length },
    });
  }, [deleteProjects, projectIds, closeDialog, openSnackbar, onProjectsDeleted, trackProjectsDeleted]);

  const count = projectIds.length;
  const publishedCount = publishedProjectIds.length;
  const published = publishedCount === count ? 'all' : publishedCount > 0 ? 'some' : 'none';

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-delete-dialog"
      title={
        {
          all: 'i18n.dialogs.deleteProjectsDialog.headline.all',
          some: 'i18n.dialogs.deleteProjectsDialog.headline.some',
          none: 'i18n.dialogs.deleteProjectsDialog.headline.none',
        }[published]
      }
      titleI18nParams={{ count, publishedCount }}
    >
      <DialogContent>
        <Text variant="l" color="grey600" i18nParams={{ count, publishedCount }} component="p">
          {
            {
              all: 'i18n.dialogs.deleteProjectsDialog.description.all',
              some: 'i18n.dialogs.deleteProjectsDialog.description.some',
              none: 'i18n.dialogs.deleteProjectsDialog.description.none',
            }[published]
          }
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button
          variant="destructive"
          fullWidth
          onClick={onAction}
          i18nParams={{ count }}
          data-testid="project-delete-dialog-button"
        >
          i18n.dialogs.deleteProjectsDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
