import { useCallback } from 'react';

import { type UnarchiveProjectsMutationVariables, useUnarchiveProjectsMutation } from '../../generated';

export const useUnarchiveProjects = (refetchQueries?: string[]) => {
  const [unarchiveProject] = useUnarchiveProjectsMutation({
    refetchQueries: [...(refetchQueries ?? []), 'Projects'],
    awaitRefetchQueries: true,
  });
  return useCallback(
    (ids: UnarchiveProjectsMutationVariables['ids']) => {
      return unarchiveProject({ variables: { ids } });
    },
    [unarchiveProject]
  );
};
