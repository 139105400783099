import { styled } from '@mui/material';
import type { ComponentProps } from 'react';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';

import { BaseButton } from './BaseButton';

export type BaseLinkProps = ComponentProps<typeof BaseLink>;

export const BaseLink = styled(
  BaseButton.withComponent('a'),
  preventForwardProps(['isSelected'])
)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.brand.grey900 : 'transparent',

  '&:hover, &:focus-visible': {
    backgroundColor: isSelected ? theme.palette.brand.grey800 : theme.palette.brand.grey900,
  },
}));
