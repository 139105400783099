import { useCallback } from 'react';

import { type ArchiveProjectsMutationVariables, useArchiveProjectsMutation } from '../../generated';

export const useArchiveProjects = (refetchQueries?: string[]) => {
  const [archiveProject] = useArchiveProjectsMutation({ refetchQueries: [...(refetchQueries ?? []), 'Projects'] });
  return useCallback(
    (ids: ArchiveProjectsMutationVariables['ids']) => {
      return archiveProject({ variables: { ids } });
    },
    [archiveProject]
  );
};
