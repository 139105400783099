import { useCallback, useMemo } from 'react';

import {
  type MoveScenesToExistingProjectMutationVariables,
  useMoveScenesToExistingProjectMutation,
} from '../../generated';

export const useMoveScenesToExistingProject = () => {
  const [moveScenesToExistingProjectMutation, metadata] = useMoveScenesToExistingProjectMutation({
    update: (cache) => {
      /**
       * @see useUpdateSceneVideo.ts
       */
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
    refetchQueries: ['Scenes'],
    awaitRefetchQueries: true,
  });
  const moveScenesToExistingProject = useCallback(
    (input: MoveScenesToExistingProjectMutationVariables['input']) => {
      return moveScenesToExistingProjectMutation({ variables: { input } });
    },
    [moveScenesToExistingProjectMutation]
  );

  return useMemo(() => [moveScenesToExistingProject, metadata] as const, [moveScenesToExistingProject, metadata]);
};
