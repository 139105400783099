import type { FC } from 'react';

import { Icon, Typography, styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.blue100,
  color: theme.palette.brand.blue,
  display: 'grid',
  padding: theme.spacing(2),
  borderRadius: theme.shape['borderRadius-l'],
  gridTemplateColumns: 'auto 1fr',
  columnGap: theme.spacing(1),
  gridTemplateRows: 'auto auto',
  '& > span': { gridColumn: '2' },
  '& > svg': { marginTop: theme.spacing(1), gridRow: '1 / 3' },
}));

export const TranslationQualityHint: FC = () => {
  return (
    <Container>
      <Icon type="InfoIcon" />
      <Typography variant="h6">i18n.sceneSubtitle.translationQualityHint.title</Typography>
      <Typography variant="m">i18n.sceneSubtitle.translationQualityHint.description</Typography>
    </Container>
  );
};
