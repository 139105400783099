import { type FC, Suspense, lazy } from 'react';

import type { MusicPlaybackProps } from './Component';

const MusicPlayback = lazy(() => import('./Component'));

export const LazyLoadedMusicPlayback: FC<MusicPlaybackProps> = (props) => (
  // `MusicPlayback` is just a wrapper for `react-sound`, which does not render
  // anything visual, so we fallback to nothing rather than a loading spinner
  <Suspense fallback={null}>
    <MusicPlayback {...props} />
  </Suspense>
);
