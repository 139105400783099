import { useContext } from 'react';

import { CurrentTeamContext } from './CurrentTeamContext';

export const useCurrentTeam = () => {
  const context = useContext(CurrentTeamContext);
  if (!context) throw new Error('CurrentTeamProvider missing in tree');
  return context;
};

export const useCurrentTeamSafe = () => {
  const context = useContext(CurrentTeamContext);
  return {
    loading: context?.loading,
    team: context?.team,
  };
};
