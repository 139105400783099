import { type FC, useEffect } from 'react';

import { INTERCOM_APP_ID } from '../config';
import { initialize } from './initialize';

type User = {
  id: string;
  createdAt: string;
  firstname?: string | null;
  lastname?: string | null;
  email: string;
  locale?: 'DE' | 'EN' | null;
  isImpersonated: boolean;
  intercomUserHash: string;
  account: { id: string; name: string };
};

const BASE_PARAMS = {
  api_base: 'https://api-iam.eu.intercom.io',
  app_id: INTERCOM_APP_ID,
  horizontal_padding: 20,
  vertical_padding: 20,
};

export const INTERCOM_TOGGLE_CLASS_NAME = 'open-intercom-chat';

export const Intercom: FC<{ user?: User; pathname?: string }> = ({ user, pathname }) => {
  useEffect(() => {
    initialize(INTERCOM_APP_ID);

    window.Intercom('hide');
    window.Intercom('shutdown');
    window.Intercom('boot', {
      ...BASE_PARAMS,
      custom_launcher_selector: `.${INTERCOM_TOGGLE_CLASS_NAME}`,
      hide_default_launcher: user?.isImpersonated ?? false,
      ...(user
        ? {
            user_id: user.id,
            created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
            name: `${user.firstname} ${user.lastname}`,
            email: user.email,
            user_hash: user.intercomUserHash,
            language_override: user.locale?.toLowerCase(),
            company: {
              id: user.account.id,
              name: user.account.name,
            },
          }
        : undefined),
    });
  }, [user]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we do want to update the Intercom instance when the URL changes
  useEffect(() => {
    if (!window.Intercom) return;
    window.Intercom('update', BASE_PARAMS);
  }, [pathname]);

  return null;
};
