import type { FC } from 'react';

import type { IconSize } from '../../../assets/icons/Icon';
import { IconButton } from '../../../controls/Button/IconButton';

type Props = {
  isFullScreen: boolean;
  onClick: VoidFunction;
  size?: IconSize;
};

export const FullScreenControlIcon: FC<Props> = ({ isFullScreen, onClick, size }) => {
  return (
    <IconButton
      icon={isFullScreen ? 'ExitFullScreenIcon' : 'FullScreenIcon'}
      iconSize={size}
      iconColor="white"
      hoverColor="grey300"
      hoverBackgroundColor="transparent"
      onClick={onClick}
      label="i18n.global.fullscreen"
      aria-pressed={isFullScreen}
    />
  );
};
