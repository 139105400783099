import { useMemo } from 'react';

import {
  type CloneContributionRequestMutationVariables,
  type CreateContributionRequestInput as Input,
  useCloneContributionRequestMutation,
} from '../../generated';

export type CreateContributionRequestInput = Input;

export const useCloneContributionRequest = (id: CloneContributionRequestMutationVariables['id']) => {
  const [cloneContributionRequest, { loading }] = useCloneContributionRequestMutation({
    refetchQueries: ['ContributionRequestsByProject'],
  });

  return useMemo(
    () => ({
      cloneContributionRequest: async () => cloneContributionRequest({ variables: { id } }),
      loading,
    }),
    [cloneContributionRequest, loading, id]
  );
};
