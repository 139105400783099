import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Select, SelectOption, type SelectProps, Translatable, useFeatureFlags } from '@cofenster/web-components';

export type VideoFormatSelectProps = {
  videoFormat: VideoFormat;
  setVideoFormat: (videoFormat: VideoFormat) => unknown;
} & SelectProps;

export const VideoFormatSelect: FC<VideoFormatSelectProps> = ({ videoFormat, setVideoFormat, onChange, ...rest }) => {
  const { hasFeature } = useFeatureFlags();

  const i18nVideoFormats: { value: VideoFormat; label: string; ifFeature: string | null }[] = [
    {
      value: 'Horizontal',
      label: 'i18n.common.horizontal',
      ifFeature: null,
    },
    {
      value: 'Vertical',
      label: 'i18n.common.vertical',
      ifFeature: null,
    },
    {
      value: 'Square',
      label: 'i18n.common.square',
      ifFeature: 'SPECIAL_FORMATS',
    },
    {
      value: 'SocialMedia',
      label: 'i18n.common.socialMedia',
      ifFeature: 'SPECIAL_FORMATS',
    },
  ];
  return (
    <Select
      onChange={(event, child) => {
        onChange?.(event, child);
        setVideoFormat(event.target.value as VideoFormat);
      }}
      value={videoFormat}
      renderValue={(value) => (
        <Translatable>{i18nVideoFormats.find((format) => format.value === value)?.label}</Translatable>
      )}
      data-testid="video-format-select"
      {...rest}
    >
      {i18nVideoFormats
        .filter((format) => !format.ifFeature || hasFeature(format.ifFeature))
        .map((format) => (
          <SelectOption key={format.value} value={format.value}>
            {format.label}
          </SelectOption>
        ))}
    </Select>
  );
};
