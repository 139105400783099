import { styled } from '@mui/material';
import type { FC } from 'react';

import { IconButton, type IconButtonProps } from '../Button/IconButton';

const DeleteContainer = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

export const AbsoluteDeleteIcon: FC<Partial<IconButtonProps>> = (props) => {
  return (
    <DeleteContainer
      icon="TrashIcon"
      iconSize="m"
      iconColor="white"
      hoverColor="white"
      backgroundColor="carbon_alpha30"
      hoverBackgroundColor="carbon_alpha50"
      label="i18n.global.delete"
      data-testid="delete-button"
      {...props}
    />
  );
};
