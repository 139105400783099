import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoEditorScene = (projectId: string) => {
  const goto = useGoto();
  return useCallback(
    (sceneId: string, _projectId?: string) =>
      goto(routes.projectEditScene, { projectId: _projectId ?? projectId, sceneId }),
    [goto, projectId]
  );
};
