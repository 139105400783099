import { styled } from '@mui/material';
import type { ComponentType, FC, PropsWithChildren } from 'react';

import { Divider } from './components/Divider';
import { Link } from './components/Link';
import { NavList } from './components/NavList';
import { NavSection } from './components/NavSection';
import { ProfileButton } from './components/ProfileButton';
import { SectionHeader } from './components/SectionHeader';
import { SectionHeaderButton } from './components/SectionHeaderButton';
import { SkipNavigationLink } from './components/SkipNavigationLink';

// 1. Ensure this container occupies the full available height from the sidebar.
const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1, // 1
  overflow: 'auto',
}));

// 1. Ensure the bottom section is always at the bottom of the sidebar
//    regardless of the amount of content.
const BottomSection = styled('div')(() => ({
  marginTop: 'auto', // 1
}));

export type MainNavigationProps = {
  renderBottom?: ComponentType;
  'data-testid'?: string;
};

export type MainNavigation = FC<PropsWithChildren<MainNavigationProps>> & {
  Link: typeof Link;
  Divider: typeof Divider;
  NavList: typeof NavList;
  ProfileButton: typeof ProfileButton;
  NavSection: typeof NavSection;
  SectionHeader: typeof SectionHeader;
  SectionHeaderButton: typeof SectionHeaderButton;
  SkipNavigationLink: typeof SkipNavigationLink;
};

export const MainNavigation: MainNavigation = ({ children, 'data-testid': dataTestId, renderBottom: RenderBottom }) => {
  return (
    <Container data-testid={dataTestId}>
      {children}

      {RenderBottom && (
        <BottomSection>
          <RenderBottom />
        </BottomSection>
      )}
    </Container>
  );
};

MainNavigation.Link = Link;
MainNavigation.Divider = Divider;
MainNavigation.NavList = NavList;
MainNavigation.ProfileButton = ProfileButton;
MainNavigation.NavSection = NavSection;
MainNavigation.SectionHeader = SectionHeader;
MainNavigation.SectionHeaderButton = SectionHeaderButton;
MainNavigation.SkipNavigationLink = SkipNavigationLink;
