import type { Permission } from '../api/generated';
import { useTeams } from '../api/hooks/team/useTeams';

import { useAccountPermissionStatus } from '../contexts/user/AccountPermissionRestriction';
import { useRole } from '../contexts/user/useRole';

export const useAvailableTeamsForPermission = (permission: Permission) => {
  const { teams = [] } = useTeams();
  const { teamRoles } = useRole();
  const isAccountAllowed = useAccountPermissionStatus({ has: permission }) === 'ALLOWED';
  const isTeamAllowed = (team: (typeof teams)[number]) =>
    teamRoles.find((teamRole) => teamRole.teamId === team.id)?.role.permissions.includes(permission);

  return teams.filter((team) => (team.public ? isAccountAllowed : isTeamAllowed(team)));
};
