import { useCallback } from 'react';

import {
  type AddContributionRequestToListMutationVariables,
  useAddContributionRequestToListMutation,
} from '../../generated';

export const useAddContributionRequestToList = () => {
  const [deleteContributionRequestFromList] = useAddContributionRequestToListMutation({
    refetchQueries: ['ContributionRequestsByProject'],
    awaitRefetchQueries: true,
  });

  return useCallback(
    ({ contributionRequestId, contributionRequestListId }: AddContributionRequestToListMutationVariables['input']) =>
      deleteContributionRequestFromList({
        variables: {
          input: {
            contributionRequestId,
            contributionRequestListId,
          },
        },
      }),
    [deleteContributionRequestFromList]
  );
};
