import { type FC, memo } from 'react';

export const DefaultSafeZoneSvg: FC = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width={88} height={168} fill="none">
    <rect width={85} height={165} x={1.5} y={1.5} fill="#223A41" stroke="#0A242C" strokeWidth={3} rx={10.5} />
    <path fill="#0A242C" d="M19 3h50a4 4 0 0 1-4 4H23a4 4 0 0 1-4-4Z" />
    <rect
      width={70}
      height={146}
      x={9}
      y={13}
      fill="#fff"
      fillOpacity={0.2}
      stroke="#fff"
      strokeDasharray="10 4"
      strokeWidth={2}
      rx={7}
    />
  </svg>
));
