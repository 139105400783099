import type { FC } from 'react';

import { Icon } from '@cofenster/web-components';

import { RouterButton, type RouterButtonProps } from '../../button/RouterButton';

const LABELS = {
  video: 'i18n.sceneAsset.recordYourselfButton.video',
  image: 'i18n.sceneAsset.recordYourselfButton.image',
  screen: 'i18n.sceneAsset.recordYourselfButton.screen',
};

export const CollectAssetButton: FC<
  Omit<RouterButtonProps, 'to' | 'params'> & {
    projectId: string;
    sceneId: string;
    assetType: 'image' | 'video' | 'screen';
  }
> = ({ projectId, sceneId, assetType, ...rest }) => (
  <RouterButton
    variant="secondary"
    to="projectCaptureSceneAsset"
    params={{ projectId, sceneId }}
    startIcon={<Icon size="s" type={assetType === 'screen' ? 'ScreenShareIcon' : 'CameraIcon'} />}
    data-testid="capture-button"
    {...rest}
  >
    {LABELS[assetType]}
  </RouterButton>
);
