import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

import { useDeleteProjects } from '../../../api/hooks/project/useDeleteProjects';
import type { Project } from '../../../contexts/project/useProject';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export type DeleteProjectDialogProps = {
  project: Pick<Project, 'id' | 'name' | 'sharingLink'>;
  isOpen: boolean;
  closeDialog: () => unknown;
  trackingSource: 'projectCard' | 'projectDetail';
  onProjectDeleted?: () => unknown;
  teamId?: string;
  refetchQueries?: string[];
};

export const DeleteProjectDialog: FC<DeleteProjectDialogProps> = ({
  isOpen,
  closeDialog,
  project,
  trackingSource,
  onProjectDeleted,
  teamId,
  refetchQueries,
}) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking(teamId);
  const deleteProject = useDeleteProjects(refetchQueries);

  const trackProjectDeleted = useCallback(async () => {
    tracking.trackEvent({
      event: 'projectsDeleted',
      details: { projectId: project.id, source: trackingSource },
    });
  }, [project.id, tracking, trackingSource]);

  const onAction = useCallback(async () => {
    await deleteProject([project.id]);
    onProjectDeleted?.();
    closeDialog();
    trackProjectDeleted();

    openSnackbar({
      children: 'i18n.snackbars.projectsDeletedSnackbar.copy',
      i18nParams: { count: 1 },
    });
  }, [deleteProject, project.id, closeDialog, openSnackbar, onProjectDeleted, trackProjectDeleted]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-delete-dialog"
      title="i18n.dialogs.deleteProjectDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" i18nParams={{ name: project.name }} component="p">
          i18n.dialogs.deleteProjectDialog.description
        </Text>
        {project.sharingLink && (
          <Text variant="l" color="grey600" component="p">
            i18n.dialogs.deleteProjectDialog.description.published
          </Text>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button variant="destructive" fullWidth onClick={onAction} data-testid="project-delete-dialog-button">
          i18n.dialogs.deleteProjectDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
