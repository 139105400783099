import { type FC, useCallback, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import { AssetDropzone, DropzoneStatus } from '@cofenster/web-components';

import { type SceneType, useCreateScene } from '../../../api/hooks/scene/useCreateScene';
import { useUploadSceneImageWithTracking } from '../../../hooks/sceneAsset/image/useUploadSceneImageWithTracking';
import { useUploadVideoSceneAssetWithTracking } from '../../../hooks/sceneAsset/video/useUploadVideoSceneAssetWithTracking';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n';

type TrackingData = {
  imageAssetCount: number;
  videoAssetCount: number;
  sceneIds: string[];
};

const useBulkTracking = () => {
  const tracking = useWebManagerTracking();

  return useCallback(
    ({ imageAssetCount, videoAssetCount, sceneIds }: TrackingData) => {
      tracking?.trackEvent({
        event: 'SceneAssetBulkUpload',
        details: {
          source: 'edit',
          imageAssetCount,
          videoAssetCount,
          sceneIds,
        },
      });
    },
    [tracking]
  );
};

export type BulkUploadProps = {
  projectId: string;
  index?: number;
  onCreate?: (sceneId: string) => void;
  onUploadStart?: (files: File[]) => void;
  onUploadEnd?: VoidFunction;
  disabled?: boolean;
};

export const BulkUpload: FC<BulkUploadProps> = ({
  projectId,
  index = 0,
  onCreate,
  onUploadStart,
  onUploadEnd,
  disabled,
}) => {
  const { translate } = useI18n();
  const track = useBulkTracking();
  const [createScene] = useCreateScene({ awaitRefetchQueries: true });

  const { uploadImage } = useUploadSceneImageWithTracking();
  const { uploadVideo } = useUploadVideoSceneAssetWithTracking();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);

  const getSceneTypeByMimeType = useCallback((mimeType: string): SceneType | undefined => {
    if (Object.keys(MimeTypes.image).includes(mimeType)) {
      return 'image';
    }
    if (Object.keys(MimeTypes.video).includes(mimeType)) {
      return 'video';
    }
  }, []);

  const createSceneForFile = useCallback(
    async (file: File, index: number) => {
      const sceneType = getSceneTypeByMimeType(file.type);

      if (!sceneType) {
        throw new Error(`[Bulk upload] No scene type found for file ${file.name}`);
      }

      const { data } = await createScene(projectId, { type: sceneType, index });
      const scene = data?.createScene;

      if (!scene) {
        throw new Error(`[Bulk upload] No scene created for file ${file.name}`);
      }

      const sceneId = scene.id;

      if (sceneId) onCreate?.(sceneId);

      return { sceneId, sceneType };
    },
    [createScene, getSceneTypeByMimeType, projectId, onCreate]
  );

  const uploadFileForScene = useCallback(
    (file: File, sceneId: string, type: SceneType) => {
      if (type === 'image') return uploadImage(file, { sceneId });
      if (type === 'video') return uploadVideo(file, { sceneId, videoFit: 'Fit' });

      throw new Error(`[Bulk upload] Unhandled scene type ${type}`);
    },
    [uploadImage, uploadVideo]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (files.length === 0) return;

      type UploadResponse = {
        status: 'fulfilled' | 'rejected';
        value: { sceneId: string; type: SceneType };
      }[];

      const upload = (await Promise.allSettled(
        Array.from(files).map(async (file) => {
          // Create a new scene for this file
          const { sceneId, sceneType } = await createSceneForFile(file, index);

          // Upload said file in that new scene
          await uploadFileForScene(file, sceneId, sceneType);

          // Increment the current bulk progress
          setUploadedFilesCount((prev) => prev + 1);

          // Return the data for tracking purposes
          return { sceneId, type: sceneType };
        })
      )) as UploadResponse;

      const fulfilled = upload.filter((promise) => promise.status === 'fulfilled');

      track({
        imageAssetCount: fulfilled.filter((promise) => promise.value.type === 'image').length,
        videoAssetCount: fulfilled.filter((promise) => promise.value.type === 'video').length,
        sceneIds: fulfilled.map((promise) => promise.value.sceneId),
      });

      onUploadEnd?.();
    },
    [index, track, onUploadEnd, createSceneForFile, uploadFileForScene]
  );

  const onMultipleFiles = useCallback(
    (files: File[]) => {
      if (files.length === 0) return;
      uploadFiles(files);
      onUploadStart?.(files);
      setSelectedFiles(files);
    },
    [onUploadStart, uploadFiles]
  );

  if (selectedFiles.length !== 0) {
    return (
      <DropzoneStatus
        size="medium"
        status="processing"
        text="i18n.bulkUpload.dropzone.processing.text"
        hintText={translate('bulkUpload.dropzone.processing.hint', {
          uploaded: uploadedFilesCount,
          total: selectedFiles.length,
        })}
        data-testid="scenes-bulk-upload-processing"
      />
    );
  }

  return (
    <AssetDropzone
      onMultipleFiles={onMultipleFiles}
      mime={{ ...MimeTypes.image, ...MimeTypes.video }}
      text="i18n.bulkUpload.dropzone.text"
      hintText="i18n.bulkUpload.dropzone.hint"
      size="medium"
      icon="UploadIcon"
      multiple
      data-testid="scenes-bulk-upload"
      hintFontWeight="normal"
      disabled={disabled}
    />
  );
};
