import { useMemo } from 'react';

import { useDeleteContributionRequestListMutation } from '../../generated';

export const useDeleteContributionRequestList = () => {
  const [deleteContributionRequestList, { loading }] = useDeleteContributionRequestListMutation({
    refetchQueries: ['ContributionRequestListByProject', 'ContributionRequestsByProject'],
  });

  return useMemo(
    () => ({
      deleteContributionRequestList: (contributionRequestId: string) =>
        deleteContributionRequestList({
          variables: { id: contributionRequestId },
        }),
      loading,
    }),
    [deleteContributionRequestList, loading]
  );
};
