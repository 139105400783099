import { type CSSProperties, type FC, useCallback } from 'react';

import { SidePanel } from '@cofenster/web-components';

import { AudioOverlayPanelContent } from './PanelContent';
import { AudioOverlayPanelFooter } from './PanelFooter';
import { useCancelAudioOverlay } from './useCancel';

const styles = {
  '--drawer-top-offset': 'var(--project-header-height, 88px)',
  '--drawer-bottom-offset': 'var(--project-editor-footer-height, 72px)',
} as CSSProperties;

export const AudioOverlayPanel: FC<{ isPanelOpen: boolean; closePanel: VoidFunction }> = ({
  isPanelOpen,
  closePanel,
}) => {
  const [cancel, isCanceling] = useCancelAudioOverlay();
  const handleCancel = useCallback(async () => {
    await cancel();
    closePanel();
  }, [cancel, closePanel]);

  return (
    <SidePanel
      isOpen={isPanelOpen}
      close={handleCancel}
      headline="i18n.SceneAudioOverlay.headline"
      footer={<AudioOverlayPanelFooter closePanel={closePanel} cancel={handleCancel} isCanceling={isCanceling} />}
      style={styles}
    >
      <AudioOverlayPanelContent />
    </SidePanel>
  );
};
