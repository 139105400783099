import { createContext } from 'react';

import type { Team as ProjectFolderTeam } from '../../api/hooks/projectFolder/useProjectFolder';
import type { Team } from '../../api/hooks/team/useTeam';

export type CurrentTeamContext = {
  team: Team | ProjectFolderTeam | undefined;
  loading: boolean;
};

export const CurrentTeamContext = createContext<CurrentTeamContext | undefined>(undefined);
