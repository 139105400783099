import { useCallback } from 'react';

import { useKeypress } from '../utils/useKeypress';

export const usePlayPauseKeyboardHandler = (
  isPaused: boolean | undefined,
  play?: VoidFunction,
  pause?: VoidFunction
) => {
  const handlePlayPause = useCallback(() => {
    if (isPaused) play?.();
    else pause?.();
  }, [isPaused, play, pause]);

  useKeypress(handlePlayPause, 'Space', { ignoreOnFocusedButton: true });
};
