import type { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text } from '@cofenster/web-components';

type DeleteContributionRequestListDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  onAction: () => unknown;
  name: string;
};

export const DeleteContributionRequestListDialog: FC<DeleteContributionRequestListDialogProps> = ({
  isOpen,
  closeDialog,
  onAction,
  name,
}) => {
  const handleAction = () => {
    closeDialog();
    onAction();
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.DeleteSharingListDialog.title">
      <DialogContent>
        <Text variant="l" color="grey600" component="p" i18nParams={{ name: <strong>{name}</strong> }}>
          i18n.dialogs.DeleteSharingListDialog.text
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button
          variant="destructive"
          fullWidth
          onClick={handleAction}
          data-testid="delete-sharing-list-dialog-delete-button"
        >
          i18n.global.delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
