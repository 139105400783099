import { styled } from '@mui/material';
import { useThrottleCallback } from '@react-hook/throttle';
import { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useRef } from 'react';

import { useRefOfState } from '../../../hooks/state/useRefOfState';
import { useTracking } from '../../../services/tracking/useTracking';
import { Range } from '../../../utilities/Range';
import { formatDuration } from '../../../utilities/formatDuration';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Text } from '../../typography/Text';

import { FullScreenControl, type FullScreenControlProps } from './FullScreenControl';
import { LoopControl, type LoopingControlProps } from './LoopControl';
import { PlayPause } from './PlayPause';
import { PlaybackSlider } from './PlaybackSlider';
import { RangeSlider } from './RangeSlider';
import { type PlayerControlVolumeProps, SoundControl } from './SoundControl';
import type { WithSize } from './types';

const Container = styled(
  'div',
  preventForwardProps(['size', 'disabled'])
)<WithSize & { disabled?: boolean }>(({ theme, size, disabled }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'content-box',
  padding: theme.spacing(size === 's' ? 1 : 2, 2),
  margin: theme.spacing(size === 's' ? 0.5 : 1),
  marginTop: 'auto',
  backgroundColor: theme.palette.brand.carbon_alpha30,
  opacity: 0.9,
  backdropFilter: 'blur(13px)',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 100ms ease',

  '&:hover, &:focus-within': disabled
    ? undefined
    : {
        backgroundColor: theme.palette.brand.carbon_alpha50,
      },
}));

const PlayerContainer = styled('div')<WithSize>(({ theme, size }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  marginTop: size === 's' ? 0 : theme.spacing(1.4),
}));

const SliderContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
}));

const TicksEverySecondContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  inset: `${theme.spacing(0.5)} 0`,
  display: 'flex',
  justifyContent: 'space-between',
}));

const TickEverySecond = styled('div')(({ theme }) => ({
  borderLeft: `solid 1px ${theme.palette.brand.white}`,
}));

const TimesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(-0.5),
}));

const ControlsMaskDisabler = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  zIndex: theme.zIndex.overlay,
  height: '100%',
}));

const TicksEverySecond: FC<{ duration: number }> = ({ duration }) => {
  const seconds = Math.floor(duration);
  return (
    <TicksEverySecondContainer style={{ width: `${(seconds / duration) * 100}%` }}>
      {Array.from({ length: duration + 1 }, (_, i) => (
        <TickEverySecond key={i} />
      ))}
    </TicksEverySecondContainer>
  );
};

type Props = {
  sceneId?: string;
  projectId?: string;
  ranges?: Range.Numeric[];
  currentTime: number;
  duration: number;
  paused: boolean;
  play: VoidFunction;
  pause: VoidFunction;
  goto: (time: number) => void;
  playButtonDisabled?: boolean;
  disabled?: boolean;
  size?: 's' | 'm';
  format?: (time: number) => string;
  showTicksEverySecond?: boolean;
  hideActions?: boolean;
  isDragging?: boolean;
  setIsDragging?: Dispatch<SetStateAction<boolean>>;
} & Partial<PlayerControlVolumeProps> &
  Partial<FullScreenControlProps> &
  Partial<LoopingControlProps>;

export const PlayerControls: FC<Props> = ({
  ranges = [Range.Numeric.create()],
  size = 'm',
  sceneId,
  projectId,
  currentTime,
  duration,
  play,
  pause,
  goto,
  paused,
  playButtonDisabled,
  disabled,
  // Fullscreen
  isFullScreen,
  toggleFullScreen,
  // Volume
  volume,
  changeVolume,
  // Loop
  isLooping,
  toggleLoop,
  format = formatDuration,
  showTicksEverySecond = false,
  hideActions,
  isDragging,
  setIsDragging,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const wasPlaying = useRef<boolean>(false);
  const isPlayingRef = useRefOfState(!paused);

  const tracking = useTracking();

  const onPlayPause = useCallback(() => {
    if (disabled || playButtonDisabled) return;

    if (paused) {
      play();
    } else {
      pause();
    }
    if (sceneId) {
      tracking.trackEvent({
        event: !paused ? 'SceneVideoPause' : 'SceneVideoPlay',
        details: {
          sceneId,
          projectId,
          sceneOriginalDuration: duration,
        },
      });
    }
  }, [tracking, projectId, sceneId, play, pause, paused, duration, disabled, playButtonDisabled]);

  useEffect(() => {
    if (isDragging) {
      if (isPlayingRef.current) {
        wasPlaying.current = true;
        pause();
      }
    } else if (wasPlaying.current) {
      wasPlaying.current = false;
      play();
    }
  }, [isDragging, pause, play, isPlayingRef]);

  const onDragEnd = useCallback(
    (value: number) => {
      goto(value * duration);
    },
    [duration, goto]
  );

  const onDrag = useCallback(
    (value: number) => {
      if (isDragging) {
        goto(value * duration);
      }
    },
    [isDragging, goto, duration]
  );

  const throttledOnDrag = useThrottleCallback(onDrag, 100);

  if (!duration || !ranges) return null;

  return (
    <Container size={size} disabled={disabled} ref={containerRef}>
      {disabled && <ControlsMaskDisabler />}

      {!hideActions && (
        <PlayPause
          isPlaying={!paused}
          onPlayPause={onPlayPause}
          size={size}
          disabled={disabled ?? playButtonDisabled}
        />
      )}

      <PlayerContainer size={size}>
        <SliderContainer>
          <PlaybackSlider
            label={`${format(currentTime)} / ${format(duration)}`}
            value={currentTime / duration}
            onDragEnd={onDragEnd}
            onUpdate={throttledOnDrag}
            setIsDragging={setIsDragging}
            disabled={disabled}
          />

          {ranges.map((range, index) => (
            <RangeSlider key={range.join('-')} index={index} range={[range[0], range[1]]} />
          ))}

          {showTicksEverySecond && <TicksEverySecond duration={duration} />}
        </SliderContainer>

        {size !== 's' && (
          <TimesContainer>
            <Text color="white" variant="xs" tabularNums>
              {format(currentTime)}
            </Text>
            <Text color="white" variant="xs" tabularNums>
              {format(duration)}
            </Text>
          </TimesContainer>
        )}
      </PlayerContainer>

      {changeVolume && volume !== undefined && <SoundControl changeVolume={changeVolume} volume={volume} size={size} />}

      {toggleFullScreen && isFullScreen !== undefined && (
        <FullScreenControl toggleFullScreen={toggleFullScreen} isFullScreen={isFullScreen} size={size} />
      )}

      {toggleLoop && isLooping !== undefined && (
        <LoopControl isLooping={isLooping} toggleLoop={toggleLoop} size={size} />
      )}
    </Container>
  );
};
PlayerControls.displayName = 'PlayerControls';
