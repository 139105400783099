import type { FC } from 'react';

import { type I18nParams, Icon, type IconType, Typography, styled } from '@cofenster/web-components';

const SnackbarContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

export const ContributionRequestShareSnackbarText: FC<{
  icon: IconType;
  children: string;
  i18nParams?: I18nParams;
}> = ({ icon, children, i18nParams }) => {
  return (
    <SnackbarContent>
      <Icon type={icon} />
      <Typography i18nParams={i18nParams}>{children}</Typography>
    </SnackbarContent>
  );
};
