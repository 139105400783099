import { useMemo } from 'react';

import { useDeleteContributionRequestMutation } from '../../generated';

export const useDeleteContributionRequest = (contributionRequestId: string) => {
  const [deleteContributionRequest, { loading }] = useDeleteContributionRequestMutation({
    variables: { id: contributionRequestId },
    refetchQueries: ['ContributionRequestsByProject'],
  });

  return useMemo(
    () => ({
      deleteContributionRequest,
      loading,
    }),
    [deleteContributionRequest, loading]
  );
};
