import { Formik } from 'formik';
import type { FC } from 'react';

import { Icon, Text, styled } from '@cofenster/web-components';

import type { AudioAsset, AudioSceneAsset } from '../../../../api/hooks/scene/useScenes';
import { AudioAssetUpload } from '../../../../components/form/input/upload/AudioAssetUpload';

const HintWrapper = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export const AudioOverlayAudioUploader: FC<{
  sceneId: string;
  sceneAsset?: AudioSceneAsset;
  audioAsset?: AudioAsset;
}> = ({ sceneId, sceneAsset, audioAsset }) => {
  return (
    <>
      <Formik initialValues={{ audioAssetId: audioAsset?.id }} enableReinitialize onSubmit={() => undefined}>
        <AudioAssetUpload name="audioAssetId" audioAsset={audioAsset} sceneAsset={sceneAsset} sceneId={sceneId} />
      </Formik>

      <HintWrapper>
        <Icon color="grey600" type="InfoIcon" size="s" />
        <Text variant="s" color="grey700">
          i18n.projectEdit.SceneAudioPanelContent.SceneAudioUploader.hint
        </Text>
      </HintWrapper>
    </>
  );
};
