import { type FC, type PropsWithChildren, useCallback } from 'react';

import { IconButton, styled } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolder';
import type { ListViewTypeContext } from '../../../contexts/listViewType/ListViewTypeContext';
import { useListViewType } from '../../../contexts/listViewType/useListViewType';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

// 1. Give some room between the bottom of these icons and the bottom of the
//    header area. `marginBottom` wouldn’t work as it would push the bottom of
//    the header down which would cause the left-side navigation to look odd.
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(-1), // 1
  },
}));

type ProjectFolderIconsProps = {
  projectFolder?: ProjectFolder;
};

const ListViewTypeButton = styled(IconButton)(() => ({
  '&[aria-pressed="true"]': {
    backgroundColor: 'rgb(0 0 0 / 0.04)',
  },
}));

export const ProjectFolderIcons: FC<PropsWithChildren<ProjectFolderIconsProps>> = ({ children, projectFolder }) => {
  const { type, toggleViewType } = useListViewType();
  const tracking = useWebManagerTracking();

  const toggleViewTypeHandler = useCallback(
    (viewType: ListViewTypeContext['type']) => {
      if (type === viewType) return;
      toggleViewType();
      if (projectFolder) {
        tracking.trackEvent({
          event: viewType === 'LIST' ? 'listViewSelected' : 'gridViewSelected',
          details: {
            teamId: projectFolder.team?.id,
            teamName: projectFolder.team?.name,
            folderId: projectFolder.id,
            folderName: projectFolder.name,
            folderType: projectFolder.team ? 'team' : 'private',
          },
        });
      }
    },
    [toggleViewType, type, tracking, projectFolder]
  );

  return (
    <Container>
      {children}

      <ListViewTypeButton
        icon="ListBulletIcon"
        iconSize="l"
        iconColor={type === 'LIST' ? 'carbon' : 'grey600'}
        onClick={() => toggleViewTypeHandler('LIST')}
        label="i18n.common.listView"
        aria-pressed={type === 'LIST'}
        data-testid="view-type-list"
      />
      <ListViewTypeButton
        icon="FourSquaresIcon"
        iconSize="l"
        iconColor={type === 'GRID' ? 'carbon' : 'grey600'}
        onClick={() => toggleViewTypeHandler('GRID')}
        label="i18n.common.gridView"
        aria-pressed={type === 'GRID'}
        data-testid="view-type-grid"
      />
    </Container>
  );
};
