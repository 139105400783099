import { styled } from '@mui/material';
import type { FC, MouseEventHandler } from 'react';

import type { BrandColor } from '../../../../theming/theme';
import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import { Icon } from '../../../assets/icons/Icon';
import { IconButton } from '../../../controls/Button/IconButton';
import { ProgressCircle } from '../../../feedback/ProgressCircle';
import { Box } from '../../../layout/Box';

import { type AssetDropzoneSize, DropzoneContent } from './DropzoneContent';

const StyledBox = styled(
  Box,
  preventForwardProps(['size'])
)<{ size: AssetDropzoneSize }>(({ theme, size }) => ({
  padding: size === 'small' ? theme.spacing(2, 3) : theme.spacing(4, 6),
  border: `1px solid ${theme.palette.brand.grey100}`,
}));

const CancelIconButton = styled(
  IconButton,
  preventForwardProps(['cancelIconButtonTopRight'])
)<{ cancelIconButtonTopRight?: number }>(({ theme, cancelIconButtonTopRight = 2 }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.above,
  top: theme.spacing(cancelIconButtonTopRight),
  right: theme.spacing(cancelIconButtonTopRight),
}));

export type DropzoneStatusProps = {
  progress?: number | 'indefinite';
  text?: string;
  hintText?: string;
  size?: AssetDropzoneSize;
  status: 'uploading' | 'processing' | 'error';
  backgroundColor?: BrandColor;
  'data-testid'?: string;
  onCancel?: MouseEventHandler;
  cancelIconButtonTopRight?: number;
};

export const DropzoneStatus: FC<DropzoneStatusProps> = ({
  progress = 'indefinite',
  text,
  hintText,
  size = 'small',
  status,
  backgroundColor = 'white',
  'data-testid': dataTestId,
  onCancel,
  cancelIconButtonTopRight,
}) => (
  <StyledBox size={size} backgroundColor={backgroundColor} data-testid={dataTestId} fullHeight>
    {onCancel && (
      <CancelIconButton
        icon="CloseIcon"
        iconSize="s"
        iconColor="carbon"
        onClick={onCancel}
        label="i18n.global.cancel"
        cancelIconButtonTopRight={cancelIconButtonTopRight}
      />
    )}

    <DropzoneContent
      icon={
        status === 'error' ? (
          <Icon type="WarningCircleIcon" size={size === 'small' ? 'xl' : 'xxl'} color="negative" />
        ) : (
          <div>
            <ProgressCircle
              size={size === 'small' ? 48 : 64}
              strokeWidth={3}
              color="blue"
              progress={status === 'uploading' ? progress : 'indefinite'}
              trackColor="blue50"
            >
              <Icon type={status === 'uploading' ? 'CloudUploadIcon' : 'GearIcon'} size="m" color="carbon" />
            </ProgressCircle>
          </div>
        )
      }
      text={text}
      hintText={hintText}
      size={size}
    />
  </StyledBox>
);
