import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { BlankButton, type ButtonProps, Icon, Text, preventForwardProps, styled } from '@cofenster/web-components';

import { RouterLink, type RouterLinkProps } from '../../components/button/RouterLink';

type BaseProps = Omit<ButtonProps, 'variant' | 'ref'> & {
  variant?: 'normal' | 'compact';
};

type NewLinkProps = BaseProps & RouterLinkProps;
type NewButtonProps = BaseProps & { onClick: VoidFunction };

const Action: FC<PropsWithChildren<NewLinkProps | NewButtonProps>> = (props) => {
  if ('to' in props) {
    return <RouterLink {...props}>{props.children}</RouterLink>;
  }

  return (
    <BlankButton {...props} type="button">
      {props.children}
    </BlankButton>
  );
};

const StyledContainer = styled(
  Action,
  preventForwardProps(['variant'])
)<{ variant?: BaseProps['variant'] }>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'var(--direction, row)' as CSSProperties['flexDirection'],
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),

  width: '100%',
  minHeight: '100%',
  padding: theme.spacing(variant === 'compact' ? 2 : 4, 2),

  borderRadius: theme.shape['borderRadius-l'],
  backgroundColor: theme.palette.brand.linen50,

  '&:hover': { color: theme.palette.brand.blue },
  '&:focus-visible': theme.mixins.focusRing,
}));

export const NewButton: FC<PropsWithChildren<NewLinkProps | NewButtonProps>> = ({
  children,
  variant = 'normal',
  ...rest
}) => (
  <StyledContainer {...rest} variant={variant}>
    <Icon
      type="PlusIcon"
      size={variant === 'compact' ? 'm' : 'l'}
      background={{ size: variant === 'compact' ? 'l' : 'xl', color: 'linen' }}
    />
    <Text variant={variant === 'compact' ? 'l' : 'xl'}>{children}</Text>
  </StyledContainer>
);
