import { type FC, type MouseEventHandler, useCallback, useMemo } from 'react';

import { PopoverMenuTriggerIcon, VisuallyHidden, withPopoverMenu } from '@cofenster/web-components';

import type { ThemeVideoType } from '../../../../../api/hooks/user/useMe';
import { useIntroOutro } from '../../../../../contexts/editorPlayer/useIntroOutro';
import type { Project } from '../../../../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useFindVideoAssetOnTheme } from '../../../../../hooks/useFindVideoOnTheme';
import { useIntroOutroActionsPopoverContent } from '../hooks/useIntroOutroActionsPopoverContent';
import { useTrackSceneSelected } from '../hooks/useTrackSceneSelected';

import { DraggableEditorThumbnailButton } from './DraggableEditorThumbnail/Button';
import { IntroOutroThumbnail as Thumbnail } from './DraggableEditorThumbnail/IntroOutroThumbnail';
import { ActionsContainer } from './Thumbnail';
import { ThumbnailContainer } from './ThumbnailContainer';
import { ThumbnailListItemWrapper } from './ThumbnailListItemWrapper';

export const IntroOutroThumbnail: FC<{
  project: Project;
  themeVideoType: ThemeVideoType;
  isCurrent: boolean;
  onSelect?: () => unknown;
}> = ({ project, themeVideoType, isCurrent, onSelect }) => {
  const sceneType = themeVideoType.toLowerCase() as Lowercase<ThemeVideoType>;

  const { isEnabled } = useIntroOutro();

  const themeVideoAsset = useFindVideoAssetOnTheme(project.theme, themeVideoType, project.videoFormat);

  const trackSceneSelected = useTrackSceneSelected();
  const popoverActions = useIntroOutroActionsPopoverContent({ project, themeVideoType });

  const onClick = useCallback(() => {
    onSelect?.();
    trackSceneSelected(sceneType);
  }, [onSelect, trackSceneSelected, sceneType]);

  const onContextMenu: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      // Not the most elegant approach, but there is no good way for us to
      // programmatically open the popover menu from here, so we go through the
      // DOM which is not React-y, but works fine.
      event.preventDefault();
      document.querySelector<HTMLButtonElement>(`#scene-actions-button-${sceneType}`)?.click();
    },
    [sceneType]
  );

  const isAllowedToEditProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const shouldRenderPopoverMenu = isAllowedToEditProject && popoverActions.length;
  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: popoverActions,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [popoverActions]
  );

  if (!project?.theme) return null;
  if (!themeVideoAsset && !__INTEGRATED_INTRO_OUTRO_EDITOR__) return null;

  return (
    <ThumbnailListItemWrapper
      className="editorThumbnail"
      id={`thumbnail-${sceneType}`}
      onContextMenu={onContextMenu}
      aria-selected={false}
      aria-current={isCurrent}
      aria-invalid={false}
    >
      <ThumbnailContainer
        isExcluded={!isEnabled(themeVideoType)}
        isSelected={false}
        isCurrent={isCurrent}
        hasErrors={false}
      >
        <Thumbnail project={project} themeVideoType={themeVideoType}>
          {themeVideoType}
        </Thumbnail>

        <DraggableEditorThumbnailButton
          onClick={onClick}
          data-testid={`select-${sceneType}-button`}
          aria-current={isCurrent}
          className="editorThumbnail-button"
        >
          <VisuallyHidden i18nParams={{ title: themeVideoType }}>i18n.projectEdit.scene.edit.button</VisuallyHidden>
        </DraggableEditorThumbnailButton>

        <ActionsContainer>
          {shouldRenderPopoverMenu && (
            <ActionsPopoverTrigger
              backgroundColor="blurred"
              iconColor="white"
              hoverColor="white"
              id={`scene-actions-button-${sceneType}`}
              data-testid="scene-actions-button"
            />
          )}
        </ActionsContainer>
      </ThumbnailContainer>
    </ThumbnailListItemWrapper>
  );
};
