import { type CSSProperties, forwardRef, memo, useCallback, useMemo, useRef, useState } from 'react';

import { ErrorBoundary, useTheme } from '@cofenster/web-components';

import { RemotionPlayerControls } from './Controls';
import { ErrorState, RescaledErrorState } from './ErrorState';
import { LazyRemotionPlayer, type LazyRemotionPlayerProps } from './LazyPlayer';

export type RemotionPlayerRef = {
  play: VoidFunction;
  pause: VoidFunction;
  currentTime: number;
  setCurrentTime: (currentTime: number) => void;
  addEventListener?: (name: string, callback: VoidFunction) => void;
  removeEventListener?: (name: string, callback: VoidFunction) => void;
  readonly duration: number;
  readonly paused: boolean;
};

export type RemotionPlayerProps = LazyRemotionPlayerProps & {
  projectId?: string;
};

const usePlayerStyle = () => {
  const theme = useTheme();
  return useMemo<CSSProperties>(() => {
    return {
      backgroundColor: theme.palette.brand.linen50,
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
    };
  }, [theme]);
};

export const RemotionPlayer = memo(
  forwardRef<RemotionPlayerRef, RemotionPlayerProps>(function RemotionPlayer(
    { controls, onError, onLoadingStateChanged, renderDescription, projectId, ...rest },
    ref
  ) {
    const playerRef = useRef<RemotionPlayerRef | null>(null);
    const setRef = useCallback(
      (player: RemotionPlayerRef) => {
        playerRef.current = player;
        if (ref) {
          if (typeof ref === 'function') ref(player);
          else ref.current = player;
        }
      },
      [ref]
    );

    const [hasError, setHasError] = useState(false);
    const _onError = useCallback(() => {
      setHasError(true);
      onError?.();
    }, [onError]);

    const [isLoading, setIsLoading] = useState(false);
    const _onLoadingStateChanged = useCallback(
      (isLoading: boolean) => {
        setIsLoading(isLoading);
        onLoadingStateChanged?.(isLoading);
      },
      [onLoadingStateChanged]
    );

    const playerStyle = usePlayerStyle();

    // We use an error boundary to catch dynamic loading errors; for instance
    // if we could not find or load the template bundle. But for actual
    // rendering errors, the @remotion/player has its on `errorFallback` prop.
    // The default error fallback is pretty ridiculous (see link below) so we
    // want to render our own. The problem is that once Remotion has loaded,
    // it applies CSS downscaling to fit the project within the rendered
    // container. This would cause our error state to be tiny, so we want to
    // negate the scale so it renders at a normal size.
    // See: https://github.com/remotion-dev/remotion/blob/971c9cc2ed335b2fd1380bd8f962f827f14bfb00/packages/player/src/Player.tsx#L109
    const errorFallback = useCallback(
      () => <RescaledErrorState formatWidth={renderDescription.format.width} />,
      [renderDescription.format.width]
    );

    // Note that this error boundary really is only for cases where the
    // `LazyRemotionPlayer` component would fail to lazy-load the template
    // bundle as Remotion has its own error boundary (which is why we pass the
    // `errorFallback` prop for).
    return (
      <ErrorBoundary fallback={<ErrorState />} onError={_onError}>
        <LazyRemotionPlayer
          renderDescription={renderDescription}
          ref={setRef}
          onError={_onError}
          onLoadingStateChanged={_onLoadingStateChanged}
          errorFallback={errorFallback}
          {...rest}
          style={playerStyle}
        />

        {!!controls && !hasError && (
          <RemotionPlayerControls
            playerRef={playerRef}
            projectId={projectId}
            duration={renderDescription.totalDurationInSeconds}
            isLoading={isLoading}
          />
        )}
      </ErrorBoundary>
    );
  })
);
