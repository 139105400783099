import type { FC } from 'react';

import { Button, Card, Icon, Spacing, Tooltip, Typography, styled } from '@cofenster/web-components';

import type { Project } from '../../../contexts/project/useProject';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useProjectExportStatus } from '../../../hooks/project/useProjectExportStatus';

const ColumnContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  textWrap: 'balance',
  maxInlineSize: '50ch',
}));

const StyledCard = styled(Card)(() => ({
  alignItems: 'center',
}));

type EnableSharingLinkProps = {
  onSharingLinkChange: VoidFunction;
  project: Project;
};

export const EnableSharingLink: FC<EnableSharingLinkProps> = ({ onSharingLinkChange, project }) => {
  const status = useProjectExportStatus(project, null);
  const videoNotRendered = status !== 'RENDERED';
  const hasSharingLinkCreatePermissions = useTeamPermissionStatus({ has: 'SharingLinkCreate' }) === 'ALLOWED';
  const disabled = videoNotRendered || !hasSharingLinkCreatePermissions;

  const enableButton = (
    <Button
      type="button"
      onClick={onSharingLinkChange}
      disabled={videoNotRendered || !hasSharingLinkCreatePermissions}
      data-testid="sharing-link-switch"
    >
      i18n.videoAnalytics.enableSharing.actionButton
    </Button>
  );

  return (
    <StyledCard spacing={{ all: { xs: 3, sm: 6 } }}>
      <ColumnContainer>
        <Icon type="LinkIcon" size="m" color="blue" background={{ size: 'xl', color: 'blue50' }} />
        <Typography variant="h5" component="p">
          i18n.videoAnalytics.enableSharing.title
        </Typography>
        <Typography align="center" component="p">
          i18n.videoAnalytics.enableSharing.description
        </Typography>
        <Spacing top={1}>
          {hasSharingLinkCreatePermissions ? (
            enableButton
          ) : (
            <Tooltip title="i18n.videoAnalytics.sharingLinkDisabledTooltip">
              {
                // A disabled button does not fire event, which causes the tooltip not
                // to work; which is why we add an extra wrapping div so the tooltip
                // remain usable even though the wrapped button is disabled.
                disabled ? <div>{enableButton}</div> : enableButton
              }
            </Tooltip>
          )}
        </Spacing>
      </ColumnContainer>
    </StyledCard>
  );
};
