import { useCallback } from 'react';

import { type ArchiveProjectFoldersMutationVariables, useArchiveProjectFoldersMutation } from '../../generated';

export const useArchiveProjectFolder = () => {
  const [archiveProjectFolder] = useArchiveProjectFoldersMutation({ refetchQueries: ['ProjectFolders'] });

  return useCallback(
    (ids: ArchiveProjectFoldersMutationVariables['ids']) => archiveProjectFolder({ variables: { ids } }),
    [archiveProjectFolder]
  );
};
