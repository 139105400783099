import { useMemo } from 'react';

import { type ProjectMusicsQueryResult, useProjectMusicsQuery } from '../../generated';

export type Music = NonNullable<ProjectMusicsQueryResult['data']>['projectMusics']['private'][number];

export const useProjectMusics = () => {
  const { loading, error, data } = useProjectMusicsQuery();
  const projectMusics = data?.projectMusics;
  return useMemo(
    () => ({ projectMusics: projectMusics ?? { private: [], public: [] }, loading, error }),
    [projectMusics, loading, error]
  );
};
