import { type FC, useCallback } from 'react';

import {
  Select,
  type SelectChangeEvent,
  SelectOption,
  type SelectProps,
  Translatable,
} from '@cofenster/web-components';

import type { MediaLibraryAssetType } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';

import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';

type MediaLibraryAssetTypeWithAll = 'ALL' | MediaLibraryAssetType;

const options: MediaLibraryAssetTypeWithAll[] = ['ALL', 'VIDEO', 'IMAGE'];

const translations: Record<MediaLibraryAssetTypeWithAll, string> = {
  ALL: 'i18n.mediaLibrary.assetType.ALL',
  VIDEO: 'i18n.mediaLibrary.assetType.VIDEO',
  IMAGE: 'i18n.mediaLibrary.assetType.IMAGE',
};

export const MediaLibraryAssetTypeSelect: FC<Omit<SelectProps, 'label' | 'name' | 'value'>> = ({
  id = 'type',
  ...rest
}) => {
  const { filter, setFilter } = useMediaLibraryFilterAndPagination();

  const onChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as MediaLibraryAssetTypeWithAll;
      setFilter('type', value === 'ALL' ? undefined : value);
    },
    [setFilter]
  );

  return (
    <Select
      id={id}
      name="type"
      label="i18n.mediaLibrary.filter.byAssetType"
      value={filter.type ?? 'ALL'}
      data-testId="filter-media-library-item"
      renderValue={(value) => {
        const typedValue = value as MediaLibraryAssetTypeWithAll;
        return <Translatable>{translations[typedValue]}</Translatable>;
      }}
      onChange={onChange}
      pb={0}
      {...rest}
    >
      {options.map((option) => (
        <SelectOption key={option} value={option}>
          {translations[option]}
        </SelectOption>
      ))}
    </Select>
  );
};
