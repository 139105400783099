import type { FC } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Text,
  styled,
} from '@cofenster/web-components';

import { useDeleteTeam } from '../../../api/hooks/team/useDeleteTeam';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

const FormContent = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

type Values = {
  name: string;
};

const useValidationSchema = (teamName: string) => {
  const validations = {
    name: Yup.string()
      .trim()
      .required('i18n.dialogs.DeleteTeamDialog.teamName.error.required')
      .equals([teamName], 'i18n.dialogs.DeleteTeamDialog.teamName.error.equal') as Yup.StringSchema<string>,
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);

  return baseSchema;
};

type DeleteTeamProps = {
  teamId: string;
  teamName: string;
  isOpen: boolean;
  closeDialog: () => unknown;
  gotoHome: VoidFunction;
};
const initial = { name: '' };

export const DeleteTeamDialog: FC<DeleteTeamProps> = ({ teamId, teamName, isOpen, closeDialog, gotoHome }) => {
  const tracking = useWebManagerTracking(teamId);
  const deleteTeam = useDeleteTeam();
  const validationSchema = useValidationSchema(teamName);

  const onSubmit = async () => {
    const result = await deleteTeam(teamId);
    if (result.data?.deleteTeam) {
      tracking.trackEvent({
        event: 'teamSpaceDeleted',
        details: {
          teamId: result.data.deleteTeam.id,
          teamName: result.data.deleteTeam.name,
          teamType: result.data.deleteTeam.public ? 'public' : 'private',
        },
      });
    }
    closeDialog();
    gotoHome();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="delete-team-dialog"
      title="i18n.dialogs.DeleteTeamDialog.headline"
    >
      <Form initialValues={initial} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <Text variant="l" color="grey600" component="p" i18nParams={{ teamName: <strong>{teamName}</strong> }}>
            i18n.dialogs.DeleteTeamDialog.text.paragraph1
          </Text>
          <Text variant="l" color="grey600" component="p">
            i18n.dialogs.DeleteTeamDialog.text.paragraph2
          </Text>

          <FormContent>
            <FormTextField
              id={teamId}
              name="name"
              label="i18n.team.name"
              placeholder="i18n.team.name"
              autoComplete="off"
              data-testid="team-name-input"
            />
          </FormContent>
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog} type="button">
            i18n.global.cancel
          </Button>
          <FormSubmitButton variant="destructive" fullWidth data-testid="delete-team-dialog-save">
            i18n.common.confirm
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
