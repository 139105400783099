import { styled } from '@mui/material';
import { type ComponentProps, useMemo } from 'react';

import { createTable } from './Table';
import type {
  CellProps,
  FooterCellProps,
  HeaderCellProps,
  TableComponent,
  TableConfig,
  TableProps,
  WrapperConfig,
} from './types';

type Align = 'left' | 'center' | 'right';
type SizeValue =
  | '0'
  | `${number}fr`
  | `${number}px`
  | `${number}%`
  | `${number}em`
  | 'auto'
  | 'min-content'
  | 'max-content';
type SizeMinMax = `minmax(${SizeValue}, ${SizeValue})`;
type Size = SizeValue | SizeMinMax;

type TableExtra = {
  align?: Align;
  size?: Size;
};

export type CSSGridTableConfig<Item> = Omit<TableConfig<Item, TableExtra>, 'wrappers'>;

export const CSSGridTable = <Item,>({
  data,
  columns,
  'data-testid': dataTestId,
  onRowClick,
  wrappers = Wrappers,
}: CSSGridTableConfig<Item> & ComponentProps<TableComponent<Item>>) => {
  const Table = useMemo(() => createTable<Item, TableExtra>({ columns, wrappers }), [columns, wrappers]);

  return <Table data={data} data-testid={dataTestId} onRowClick={onRowClick} />;
};

const Table = styled('table')<TableProps<TableExtra>>(({ theme, columns }) => ({
  borderCollapse: 'collapse',
  border: 0,
  display: 'grid',
  gridTemplateColumns: columns.map((c) => c.extra?.size ?? '1fr').join(' '),
  columnGap: 0,

  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

// For CSS grid to be effective across all cells and not in an independent
// manner, any container between the grid and the cells needs to sort of
// “nullified”, which is what `display: contents` does. This works fine across
// the browsers we support, although Safari has accessibility issues with
// `display: contents` on table-related elements.
// See: https://caniuse.com/css-display-contents
// See: https://bugs.webkit.org/show_bug.cgi?id=239479
// See: https://bugs.webkit.org/show_bug.cgi?id=239478
const Header = styled('thead')(({ theme }) => ({
  display: 'contents',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const Body = styled('tbody')(() => ({ display: 'contents' }));
const Footer = styled('tfoot')(() => ({ display: 'contents' }));
const Row = styled('tr')<{ isInteractive?: boolean }>(({ theme, isInteractive }) => ({
  display: 'contents',
  '&:hover': isInteractive ? { cursor: 'pointer' } : undefined,

  '> td': {
    animation: 'fade-in 350ms calc(var(--index) * 50ms) both',
    '@keyframes fade-in': { from: { opacity: 0, transform: 'scaleY(0.8) translateY(100%)' } },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.brand.white,
    borderRadius: theme.shape['borderRadius-l'],
    width: '100%',
  },
}));

const HeaderCell = styled('th')<HeaderCellProps<TableExtra>>(({ theme, extra }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: extra?.align ?? 'left',
  marginBottom: theme.spacing(2),
  position: 'relative',

  '&:first-of-type': { paddingLeft: theme.spacing(4) },
  '&:last-of-type': { paddingRight: theme.spacing(4) },
}));

const Cell = styled('td')<CellProps<TableExtra>>(({ theme, extra }) => ({
  backgroundColor: theme.palette.brand.white,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: extra?.align ?? 'left',
  marginBottom: theme.spacing(2),
  position: 'relative',
  cursor: 'inherit',

  '&:first-of-type': {
    paddingLeft: theme.spacing(4),
    borderTopLeftRadius: theme.shape['borderRadius-l'],
    borderBottomLeftRadius: theme.shape['borderRadius-l'],
    [theme.breakpoints.down('sm')]: { padding: 0 },
  },

  '&:last-of-type': {
    paddingRight: theme.spacing(4),
    borderTopRightRadius: theme.shape['borderRadius-l'],
    borderBottomRightRadius: theme.shape['borderRadius-l'],
    [theme.breakpoints.down('sm')]: { padding: 0 },
  },

  [theme.breakpoints.down('sm')]: {
    display: 'inline-block',
    padding: 0,
    marginBottom: 0,
  },
}));

const FooterCell = styled('td')<FooterCellProps<TableExtra>>(({ theme, extra }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: extra?.align ?? 'left',

  '&:first-of-type, &:last-of-type': {
    padding: theme.spacing(0, 4),
  },
}));

export const Wrappers: WrapperConfig<TableExtra> = {
  Table: Table,
  Header: Header,
  HeaderCell: HeaderCell,
  Data: Body,
  Row: Row,
  Cell: Cell,
  Footer: Footer,
  FooterCell: FooterCell,
};
