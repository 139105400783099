import type { FC, PropsWithChildren, ReactNode } from 'react';
import type { PermissionSelection } from './PermissionSelection';
import type { PermissionStatus } from './PermissionStatus';
import { checkPermission } from './checkPermission';
import { useUser } from './useUser';

export const AccountPermissionRestriction: FC<
  PropsWithChildren<
    {
      fallback?: ReactNode;
      loader?: ReactNode | null;
    } & PermissionSelection
  >
> = (props) => {
  const { children, fallback, loader } = props;
  const permissionStatus = useAccountPermissionStatus(props);
  if (permissionStatus === 'UNKNOWN') return <>{loader}</>;
  return permissionStatus === 'ALLOWED' ? <>{children}</> : <>{fallback}</>;
};

export const useAccountPermissionStatus = (permissionSelection: PermissionSelection): PermissionStatus => {
  const { user } = useUser();
  return checkPermission(permissionSelection, user?.roles.account.permissions) ? 'ALLOWED' : 'DENIED';
};
