import { type Dispatch, type FC, type FormEventHandler, type SetStateAction, useCallback, useContext } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  IconButton,
  LoadingSpinner,
  ResultPagination,
  SearchField,
  SearchResults,
  styled,
} from '@cofenster/web-components';

import type { StockImage } from '../../../api/hooks/stockFootage/useSearchStockImages';
import type { StockVideo } from '../../../api/hooks/stockFootage/useSearchStockVideos';

import { DefaultState } from './DefaultState';
import { PexelsMention } from './PexelsMention';
import { SearchContext } from './SearchContext';
import { SearchResults as Results } from './SearchResults';

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const SubmitIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
}));

export const SearchState: FC<{
  setSelectedAsset: Dispatch<SetStateAction<StockImage | StockVideo | null>>;
}> = ({ setSelectedAsset }) => {
  const { type, searchTerm, setSearchTerm, page, setPage, search, metadata } = useContext(SearchContext);
  const { loading, data, error } = metadata;

  const handleSearch: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      // Reset the page when changing the search term to go back to page 1
      setPage(1);
      search(searchTerm);
    },
    [setPage, search, searchTerm]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
      search(searchTerm, page);
    },
    [search, searchTerm, setPage]
  );

  return (
    <>
      <SearchContainer>
        <SearchField
          id="stock-search"
          label="i18n.dialogs.StockLibraryDialog.search.label"
          search={searchTerm}
          onSearch={setSearchTerm}
          onSubmit={handleSearch}
          alwaysExtended
          fullWidth
          autoFocus
          InputProps={{ required: true }}
          debounceTime={0}
          data-testid="search-field"
        >
          <SubmitIconButton
            type="submit"
            icon="SearchIcon"
            label="i18n.global.search"
            backgroundColor="blue"
            hoverBackgroundColor="blueDark"
            iconColor="white"
            hoverColor="white"
            data-testid="search-submit-button"
          />
        </SearchField>
      </SearchContainer>

      <PexelsMention />

      <SearchResults id="stock-search" count={data?.totalItems ?? 0} search={searchTerm}>
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <EmptyState
            iconType="CloudErrorIcon"
            title="i18n.dialogs.StockLibraryDialog.search.error.title"
            description="i18n.dialogs.StockLibraryDialog.search.error.description"
          />
        ) : data?.totalItems === 0 ? (
          <EmptyState
            iconType="SearchIcon"
            title="i18n.dialogs.StockLibraryDialog.search.noResults.title"
            description={
              type === 'video'
                ? 'i18n.dialogs.StockLibraryDialog.search.noResults.description.video'
                : 'i18n.dialogs.StockLibraryDialog.search.noResults.description.image'
            }
          />
        ) : data ? (
          <>
            <Results data={data} setSelectedAsset={setSelectedAsset} />
            <GridContainer mt={1}>
              <GridItem xs={12} display="flex" justifyContent="center">
                <ResultPagination
                  page={page}
                  limit={data?.itemsPerPage}
                  total={data?.totalItems}
                  onChange={handlePageChange}
                />
              </GridItem>
            </GridContainer>
          </>
        ) : (
          <DefaultState setSelectedAsset={setSelectedAsset} />
        )}
      </SearchResults>
    </>
  );
};
