import { type ChangeEventHandler, type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  SpinningIcon,
  Switch,
  Text,
  Tooltip,
  styled,
} from '@cofenster/web-components';

import { useCreateEnhanceAudioJobOnSceneVideo } from '../../../../api/hooks/enhanceAudioJob/useCreateCreateEnhanceAudioJobOnSceneVideo';
import type { Scene } from '../../../../api/hooks/scene/useScenes';
import { usePollingVideoAsset } from '../../../../api/hooks/videoAsset/usePollingVideoAsset';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

import { AudioBox } from './AudioBox';
import { useAudioOverlayContext } from './Context';

const CallToActionButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.brand.grey100}`,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const ErrorText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledSwitch = styled(Switch)(() => ({
  width: '100%',
}));

const EnhancedAudioContainer: FC<PropsWithChildren> = ({ children, ...rest }) => (
  <GridContainer mt={2} {...rest}>
    <GridItem xs={12}>
      <Headline variant="h6" component="p">
        i18n.projectEdit.EnhancedAudio.title
      </Headline>
    </GridItem>
    <GridItem xs={12} mt={-1}>
      {children}
    </GridItem>
  </GridContainer>
);

const useRequestEnhancement = (scene?: Scene, sceneVideoId?: string) => {
  const createEnhanceAudioJobOnSceneVideo = useCreateEnhanceAudioJobOnSceneVideo();
  const tracking = useWebManagerTracking();

  const trackBackgroundNoiseRemoval = useCallback(
    (sceneVideoId: string) =>
      tracking.trackEvent({
        event: 'backgroundNoiseRemoval',
        details: { sceneId: scene?.id, textElementsCount: scene?.sceneTexts.length, sceneVideoId },
      }),
    [tracking, scene]
  );

  return useCallback(async () => {
    if (sceneVideoId) {
      await createEnhanceAudioJobOnSceneVideo(sceneVideoId);
      trackBackgroundNoiseRemoval(sceneVideoId);
    }
  }, [sceneVideoId, createEnhanceAudioJobOnSceneVideo, trackBackgroundNoiseRemoval]);
};

export const AudioOverlayEnhancement: FC = () => {
  const { videoSceneAsset, isEnhanced, setIsEnhanced } = useAudioOverlayContext();
  const sceneVideo = videoSceneAsset?.asset ?? undefined;
  const { currentScene } = useScenes();

  // Poll the enhanced asset to know when it’s done processing, and use that
  // status to update the UI (loading, success, error…)
  const enhancedVideoAsset = usePollingVideoAsset(sceneVideo?.videoAssetWithEnhancedAudio?.id);

  const isMainVideoAssetReady = sceneVideo?.videoAsset?.status === 'Ready';

  const requestEnhancement = useRequestEnhancement(currentScene, sceneVideo?.id);
  const enhanceAudio = useCallback(() => {
    requestEnhancement();
    setIsEnhanced(true);
  }, [requestEnhancement, setIsEnhanced]);

  // This synchronizes the parent state with the state of the HTML checkbox
  // element
  const updateEnhancementSettings: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setIsEnhanced(event.target.checked),
    [setIsEnhanced]
  );

  const enhanceAudioButton = useMemo(
    () => (
      <CallToActionButton
        data-testid="enhanced-audio-button"
        variant="tertiary"
        onClick={enhanceAudio}
        startIcon={<Icon type="MagicWandIcon" size="m" />}
        fullWidth
        disabled={!isMainVideoAssetReady}
      >
        i18n.projectEdit.EnhancedAudio.remove
      </CallToActionButton>
    ),
    [isMainVideoAssetReady, enhanceAudio]
  );

  if (enhancedVideoAsset?.status === 'Error') {
    return (
      <EnhancedAudioContainer>
        <CallToActionButton
          data-testid="audio-enhancement-error-button"
          variant="tertiary"
          onClick={enhanceAudio}
          startIcon={<Icon type="MagicWandIcon" size="m" />}
          disabled={!sceneVideo?.id}
          fullWidth
          aria-describedby="enhanced-audio-error"
        >
          i18n.projectEdit.EnhancedAudio.remove
        </CallToActionButton>
        <ErrorText variant="m" color="negative" component="p" id="enhanced-audio-error">
          i18n.projectEdit.EnhancedAudio.error
        </ErrorText>
      </EnhancedAudioContainer>
    );
  }

  if (enhancedVideoAsset?.status === 'Waiting' || enhancedVideoAsset?.status === 'Processing') {
    return (
      <EnhancedAudioContainer data-testid="enhanced-audio-processing">
        <AudioBox>
          <SpinningIcon speed="fast" type="LoadingIcon" size="m" color="blue" />
          <Text component="p">i18n.projectEdit.EnhancedAudio.removing</Text>
        </AudioBox>
      </EnhancedAudioContainer>
    );
  }

  if (enhancedVideoAsset?.videoUrl) {
    return (
      <EnhancedAudioContainer>
        <AudioBox>
          <Icon type="MagicWandIcon" size="m" />
          <StyledSwitch
            data-testid="enhanced-audio-switch-scene"
            id="enhance"
            checked={isEnhanced}
            onChange={updateEnhancementSettings}
            switchPosition="right"
            isBlock
          >
            <Text variant="m">i18n.projectEdit.EnhancedAudio.remove</Text>
          </StyledSwitch>
        </AudioBox>
      </EnhancedAudioContainer>
    );
  }

  if (!isMainVideoAssetReady) {
    return (
      <EnhancedAudioContainer>
        <Tooltip title="i18n.projectEdit.EnhancedAudio.mainVideoAssetNotReady">
          <span>{enhanceAudioButton}</span>
        </Tooltip>
      </EnhancedAudioContainer>
    );
  }

  return <EnhancedAudioContainer>{enhanceAudioButton}</EnhancedAudioContainer>;
};
