import { useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import type { ThemeVideoType } from '../api/hooks/user/useMe';
import type { AccountTheme } from '../contexts/user/useUser';

export const useFindVideoAssetOnTheme = (
  theme: AccountTheme | null | undefined,
  videoType: ThemeVideoType,
  videoFormat: VideoFormat | null | undefined
) => {
  return useMemo(
    () =>
      theme?.themeVideoAssets
        ?.filter((it) => it?.videoFormat === videoFormat)
        ?.find((it) => it?.videoType === videoType),
    [theme, videoType, videoFormat]
  );
};
