import { createContext } from 'react';

import type { Project } from '../../api/hooks/project/useProject';

export type { Project } from '../../api/hooks/project/useProject';

export type ProjectContext = {
  project: Project | undefined;
  loading: boolean;
  refetch: VoidFunction;
};

export const ProjectContext = createContext<ProjectContext>({
  project: undefined,
  loading: true,
  refetch: () => undefined,
});
