import { useCallback, useMemo } from 'react';

import type { VideoCaptureSource, VideoFit } from '@cofenster/constants';

import { useWebManagerTracking } from '../../useWebManagerTracking';

import { useUploadSceneVideoAsset } from './useUploadSceneVideoAsset';

export type VideoUploadOptions = {
  videoCaptureSource?: VideoCaptureSource;
  videoFit?: VideoFit;
  metadata?: Record<string, unknown>;
  sceneId?: string;
};

export const useUploadVideoSceneAssetWithTracking = (
  sceneId?: string,
  additionalTrackingDetails?: Record<string, string | number>
) => {
  const [uploadProgress, uploadVideo, cancelUpload] = useUploadSceneVideoAsset(sceneId);
  const tracking = useWebManagerTracking();

  const trackSceneAssetUploaded = useCallback(
    (file: File, _sceneId?: string, metadata?: Record<string, unknown>) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId: sceneId ?? _sceneId,
          assetType: 'video',
          assetName: file.name,
          uploadSource: metadata?.source,
          ...additionalTrackingDetails,
          ...metadata,
        },
      }),
    [tracking, sceneId, additionalTrackingDetails]
  );

  const upload = useCallback(
    async (file: File, options?: VideoUploadOptions) => {
      const videoAssetId = await uploadVideo(file, options?.videoFit, options?.videoCaptureSource, options?.sceneId);

      trackSceneAssetUploaded(file, options?.sceneId, options?.metadata);

      return videoAssetId;
    },
    [uploadVideo, trackSceneAssetUploaded]
  );

  const cancel = useCallback(() => {
    cancelUpload();
    tracking.trackEvent({
      event: 'SceneAssetUploadCancelled',
      details: {
        sceneId: sceneId,
        source: 'edit',
        assetType: 'video',
      },
    });
  }, [sceneId, cancelUpload, tracking]);

  return useMemo(
    () => ({ videoUploadProgress: uploadProgress, uploadVideo: upload, cancelVideoUpload: cancel }) as const,
    [uploadProgress, upload, cancel]
  );
};
