import throttle from 'lodash.throttle';
import { useCallback, useSyncExternalStore } from 'react';

import type { RemotionPlayerRef } from './Player';

export type UseRemotionPlayerCurrentTimeOptions = {
  eventType: 'frameupdate' | 'timeupdate';
  frameUpdateThrottle?: number;
};

const PLAYER_CURRENT_TIME_DEFAULTS: UseRemotionPlayerCurrentTimeOptions = {
  eventType: 'frameupdate',
  frameUpdateThrottle: 0,
};

export const useRemotionPlayerCurrentTime = (
  remotionPlayer: RemotionPlayerRef | null,
  { eventType, frameUpdateThrottle }: UseRemotionPlayerCurrentTimeOptions = PLAYER_CURRENT_TIME_DEFAULTS
) => {
  const subscribe = useCallback(
    (onFrameUpdate: () => void) => {
      if (!remotionPlayer) return () => 0;

      const callback = eventType === 'frameupdate' ? throttle(onFrameUpdate, frameUpdateThrottle) : onFrameUpdate;

      remotionPlayer.addEventListener?.(eventType, callback);

      return () => {
        remotionPlayer.removeEventListener?.(eventType, callback);
      };
    },
    [remotionPlayer, eventType, frameUpdateThrottle]
  );

  return useSyncExternalStore<number>(
    subscribe,
    () => remotionPlayer?.currentTime ?? 0,
    () => 0
  );
};
