import { useCallback, useMemo } from 'react';

import { useUpdateProject } from '../../api/hooks/project/useUpdateProject';
import type { ThemeVideoType } from '../../api/hooks/user/useMe';
import { useProject } from '../../contexts/project/useProject';
import { useFindVideoAssetOnTheme } from '../../hooks/useFindVideoOnTheme';

export const useIntroOutro = () => {
  const { project } = useProject();
  const [updateProject] = useUpdateProject({
    refetchQueries: __INTEGRATED_INTRO_OUTRO_EDITOR__
      ? ['Project', 'ProjectRenderDescription', 'Scenes']
      : ['Project', 'ProjectRenderDescription'],
  });
  const projectId = project?.id;

  const intro = useFindVideoAssetOnTheme(project?.theme, 'Intro', project?.videoFormat);
  const outro = useFindVideoAssetOnTheme(project?.theme, 'Outro', project?.videoFormat);

  const isIntroUploaded = !!intro?.videoAsset?.videoUrl;
  const isIntroEnabled = project?.withIntro;
  const isIntroUploadedAndEnabled = isIntroUploaded && isIntroEnabled;

  const isOutroUploaded = !!outro?.videoAsset?.videoUrl;
  const isOutroEnabled = project?.withOutro;
  const isOutroUploadedAndEnabled = isOutroUploaded && isOutroEnabled;

  const isUploaded = useCallback(
    (input: ThemeVideoType | Lowercase<ThemeVideoType>) => {
      if (input.toLowerCase() === 'intro') return isIntroUploaded;
      if (input.toLowerCase() === 'outro') return isOutroUploaded;
      throw new Error(
        `Invalid input for ‘isUploaded’. Expected ‘intro’, ‘outro’, ‘Intro’ or ‘Outro’ but received ‘${input}’.`
      );
    },
    [isIntroUploaded, isOutroUploaded]
  );

  const isEnabled = useCallback(
    (input: ThemeVideoType | Lowercase<ThemeVideoType>) => {
      if (input.toLowerCase() === 'intro') return isIntroEnabled;
      if (input.toLowerCase() === 'outro') return isOutroEnabled;
      throw new Error(
        `Invalid input for ‘isEnabled’. Expected ‘intro’, ‘outro’, ‘Intro’ or ‘Outro’ but received ‘${input}’.`
      );
    },
    [isIntroEnabled, isOutroEnabled]
  );

  const isUploadedAndEnabled = useCallback(
    (input: ThemeVideoType | Lowercase<ThemeVideoType>) => {
      if (input.toLowerCase() === 'intro') return isIntroUploadedAndEnabled;
      if (input.toLowerCase() === 'outro') return isOutroUploadedAndEnabled;
      throw new Error(
        `Invalid input for ‘isUploadedAndEnabled’. Expected ‘intro’, ‘outro’, ‘Intro’ or ‘Outro’ but received ‘${input}’.`
      );
    },
    [isIntroUploadedAndEnabled, isOutroUploadedAndEnabled]
  );

  const enable = useCallback(
    async (input: ThemeVideoType | Lowercase<ThemeVideoType>) => {
      let params = null;
      if (input.toLowerCase() === 'intro') params = { withIntro: true };
      if (input.toLowerCase() === 'outro') params = { withOutro: true };
      if (projectId && params) await updateProject(projectId, params);
    },
    [projectId, updateProject]
  );

  const disable = useCallback(
    async (input: ThemeVideoType | Lowercase<ThemeVideoType>) => {
      let params = null;
      if (input.toLowerCase() === 'intro') params = { withIntro: false };
      if (input.toLowerCase() === 'outro') params = { withOutro: false };
      if (projectId && params) await updateProject(projectId, params);
    },
    [projectId, updateProject]
  );

  return useMemo(
    () => ({
      intro,
      outro,
      enable,
      disable,
      isUploaded,
      isEnabled,
      isUploadedAndEnabled,
    }),
    [intro, outro, enable, disable, isUploaded, isEnabled, isUploadedAndEnabled]
  );
};
