export function isElementInViewport(el: HTMLElement, upperTreshold = 0) {
  const { top, bottom } = el.getBoundingClientRect();
  const { innerHeight } = window;
  const { clientHeight } = document.documentElement;

  if (top >= upperTreshold && bottom <= (innerHeight || clientHeight)) {
    return true;
  }

  return false;
}
