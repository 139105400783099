import { ButtonWithBubble } from '@cofenster/web-components';

import { useScenes } from '../../../../contexts/scenes/useScenes';

import { useSceneEditorNotesState } from './SceneEditorNotesStateContext';

export const SceneEditorNotesButton = () => {
  const { currentScene } = useScenes();
  const [notesOpen, setNotesOpen] = useSceneEditorNotesState();

  if (!currentScene) return null;

  return (
    <ButtonWithBubble
      variant="tertiary"
      bubbleIconType={currentScene.editorNotes ? 'NoteIcon' : 'NoteBlankIcon'}
      showBubble={!!currentScene.editorNotes}
      onClick={() => setNotesOpen((prev) => !prev)}
      aria-expanded={notesOpen}
      data-testid="notes-button"
    >
      i18n.projectEdit.SceneEditorNotes.button.text
    </ButtonWithBubble>
  );
};
