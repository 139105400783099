import { useMemo } from 'react';

import { type LatestProjectsQuery, type LatestProjectsQueryVariables, useLatestProjectsQuery } from '../../generated';

export type Project = LatestProjectsQuery['latestProjects'][number]['project'];
export type Team = LatestProjectsQuery['latestProjects'][number]['team'];

export type ProjectsFilter = NonNullable<LatestProjectsQueryVariables['filter']>;

type FetchPolicy = NonNullable<Parameters<typeof useLatestProjectsQuery>[0]>['fetchPolicy'];

export const useLatestProjects = (filter?: ProjectsFilter, fetchPolicy: FetchPolicy = 'cache-and-network') => {
  const { error, loading, data } = useLatestProjectsQuery({
    variables: { filter },
    fetchPolicy,
  });

  const projects = data?.latestProjects;

  return useMemo(() => ({ projects, loading, error }), [projects, loading, error]);
};
