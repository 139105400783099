import type { CSSProperties, FC } from 'react';

import { StyledSlider, preventForwardProps, styled } from '@cofenster/web-components';

import type { Music } from '../../../api/hooks/music/useProjectMusics';
import { useI18n } from '../../../i18n';
import type { usePlayer } from '../hooks';

const StyledStyledSlider = styled(
  StyledSlider,
  preventForwardProps(['brandColor'])
)(() => ({
  '&:disabled': {
    '&::-webkit-slider-thumb': { display: 'none' },
    '&::-moz-range-thumb': { display: 'none' },
  },
}));

export const MusicRange: FC<{ music: Music; player: ReturnType<typeof usePlayer> }> = ({ music, player }) => {
  const { translate } = useI18n();
  const { move, playback, progress } = player;
  const isInPlayer = playback.id === music.id;

  return (
    <StyledStyledSlider
      brandColor="blue"
      type="range"
      aria-label={translate('projectMusic.music.progress')}
      min={0}
      max={100}
      value={isInPlayer ? progress : 0}
      disabled={!isInPlayer}
      onChange={(event) => move(Number(event.target.value))}
      style={{ '--zoom': isInPlayer ? progress / 10 : 0 } as CSSProperties}
    />
  );
};
