import { type Dispatch, type SetStateAction, createContext } from 'react';

import type { RemotionPlayerRef } from '@cofenster/web-remotion-player';

export type RemotionPlayerRefContext = {
  playerRef: RemotionPlayerRef | null;
  setPlayerRef: Dispatch<SetStateAction<RemotionPlayerRef | null>>;
};

export const RemotionPlayerRefContext = createContext<RemotionPlayerRefContext>({
  playerRef: null,
  setPlayerRef: () => undefined,
});
