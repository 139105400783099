import { useCallback } from 'react';

import { type UserLocales as Locales, type UpdateMeMutationVariables, useUpdateMeMutation } from '../../generated';

export type UserLocales = Locales;

export const useUpdateMe = () => {
  const [updateMe] = useUpdateMeMutation({
    refetchQueries: ['Me'],
  });
  return useCallback(
    (userId: UpdateMeMutationVariables['id'], input: UpdateMeMutationVariables['input']) => {
      return updateMe({
        variables: {
          id: userId,
          input,
        },
      });
    },
    [updateMe]
  );
};
