import { useField } from 'formik';
import type { FC } from 'react';
import { SelectableBoxInput } from './SelectableBoxInput';
import type { SelectableBoxInputProps } from './types';

export type FormSelectableBoxInputProps = Omit<SelectableBoxInputProps, 'name'> & {
  name: string;
};

export const FormSelectableBoxInput: FC<FormSelectableBoxInputProps> = ({ name, value, type, ...rest }) => {
  const [field] = useField({ name, value, type });

  return <SelectableBoxInput {...rest} type={type} {...field} />;
};
