import { useCallback } from 'react';

import { type Permission, useRequestRoleWithPermissionsMutation } from '../../generated';

export const useRequestRoleWithPermissions = () => {
  const [requestRoleWithPermissions] = useRequestRoleWithPermissionsMutation({ refetchQueries: ['UserRoleRequest'] });
  return useCallback(
    (permissions: Permission[], teamId: string | null = null) =>
      requestRoleWithPermissions({ variables: { input: { permissions, teamId } } }),
    [requestRoleWithPermissions]
  );
};
