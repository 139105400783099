import { createContext } from 'react';

import type {
  ProjectRenderDescription,
  RefetchProjectRenderDescription,
} from '../../api/hooks/renderDescription/useProjectRenderDescription';

export type { ProjectRenderDescription } from '../../api/hooks/renderDescription/useProjectRenderDescription';

export type ProjectRenderDescriptionContext = {
  projectRenderDescription: ProjectRenderDescription | undefined;
  loading: boolean;
  error?: Error;
  refetch: () => RefetchProjectRenderDescription;
};

export const ProjectRenderDescriptionContext = createContext<ProjectRenderDescriptionContext | undefined>(undefined);
