import { useCallback } from 'react';

import { useRemotionPlayerRef } from '../../../contexts/editorPlayer/useRemotionPlayerRef';

type PreviewDetails = {
  sceneId: string;
  projectId: string;
  videoPlayerTime: number;
  videoPlayerStatus: 'paused' | 'playing';
};

export const usePreviewDetails = () => {
  const { playerRef } = useRemotionPlayerRef(false);

  return useCallback(
    (override: Partial<PreviewDetails> = {}) => ({
      videoPlayerTime: playerRef?.currentTime,
      videoPlayerStatus: playerRef?.paused ? 'paused' : 'playing',
      ...override,
    }),
    [playerRef]
  );
};
