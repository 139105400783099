import { type FC, useCallback } from 'react';

import { Pagination, type PaginationProps } from './Pagination';

type ResultPaginationProps = Omit<PaginationProps, 'count' | 'onChange'> & {
  total: number;
  limit: number;
  showAlways?: boolean;
  onChange?: (page: number) => unknown;
};

export const ResultPagination: FC<ResultPaginationProps> = ({
  total,
  limit,
  showAlways = false,
  onChange,
  ...paginationProps
}) => {
  const count = limit ? Math.ceil(total / limit) : 1;
  const onPaginationChange = useCallback<NonNullable<PaginationProps['onChange']>>(
    (_, page) => onChange?.(page),
    [onChange]
  );

  if (!showAlways && count <= 1) {
    return null;
  }

  return <Pagination {...paginationProps} count={count} onChange={onPaginationChange} />;
};
