import type { FC } from 'react';

import { Icon, Responsive } from '@cofenster/web-components';

import type { Project } from '../../../../api/hooks/project/useProject';
import type { Scene } from '../../../../api/hooks/scene/useScenes';
import type { SceneWithSubtitles } from '../../../../api/hooks/sceneSubtitle/useScenesWithSubtitles';
import { RouterButton } from '../../../../components/button/RouterButton';
import { TeamPermissionRestriction } from '../../../../contexts/user/TeamPermissionRestriction';
import {
  isAudioSceneAsset,
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../../../helpers/sceneAssets/is';

export const hasReadyMainAsset = (scene: Pick<Scene, 'sceneAssets'> | Pick<SceneWithSubtitles, 'sceneAssets'>) => {
  const mainAsset = scene.sceneAssets.find(isMainSceneAsset);
  if (!mainAsset) return false;
  if (isVideoSceneAsset(mainAsset)) return !!mainAsset.asset?.videoAsset?.videoUrl;
  if (isImageSceneAsset(mainAsset)) return !!mainAsset.asset?.imageAsset?.imageUrl;
  if (isColorSceneAsset(mainAsset)) return true;
  if (isAudioSceneAsset(mainAsset)) return !!mainAsset.asset?.audioAsset?.audioUrl;
  return false;
};

type EditSubtitlesProps = {
  scene: Scene;
  project: Project;
  disabled?: boolean;
};

export const SceneSubtitlesButton: FC<EditSubtitlesProps> = ({ disabled, scene, project }) => {
  if (!project.hasSceneSubtitlesAvailable) return null;

  return (
    <TeamPermissionRestriction has="SubtitlesCreate">
      <Responsive up="md">
        <RouterButton
          data-testid="edit-scene-subtitles-button"
          disabled={disabled || !hasReadyMainAsset(scene)}
          to="projectSceneSubtitle"
          params={{ projectId: project.id, sceneId: scene.id }}
          variant="tertiary"
          startIcon={<Icon type="SubtitlesIcon" size="s" />}
        >
          i18n.projectEdit.editorTools.EditSceneSubtitles.button.text
        </RouterButton>
      </Responsive>
    </TeamPermissionRestriction>
  );
};
