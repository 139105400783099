import type { FC } from 'react';

import { Select, SelectOption, type SelectProps, Translatable, styled } from '@cofenster/web-components';

// 1. Assign an arbitrary minimum width so the width does not vary based on the
//    picked option
const StyledSelect = styled(Select)(() => ({
  minWidth: 110, // 1
}));

export const StatusSelect: FC<Omit<SelectProps, 'label' | 'name'> & { name?: string }> = ({
  id = 'status',
  name = 'status',
  ...rest
}) => (
  <StyledSelect
    id={id}
    name={name}
    label="i18n.projects.filters.status.label"
    renderValue={(value) => <Translatable>{`i18n.projects.filters.status.${value}`}</Translatable>}
    pb={0}
    {...rest}
  >
    <SelectOption value="ALL">i18n.projects.filters.status.ALL</SelectOption>
    <SelectOption value="ACTIVE">i18n.projects.filters.status.ACTIVE</SelectOption>
    <SelectOption value="ARCHIVED">i18n.projects.filters.status.ARCHIVED</SelectOption>
  </StyledSelect>
);
