import {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { LoadingSpinner, styled } from '@cofenster/web-components';

import { type User, useMe } from '../../api/hooks/user/useMe';
import { useSignin as useSigninMutation } from '../../api/hooks/user/useSignin';
import { useSignout as useSignoutMutation } from '../../api/hooks/user/useSignout';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

import { UserContext } from './UserContext';

const useSignin = (): UserContext['signin'] => {
  const signin = useSigninMutation();
  return useCallback(
    async (email, password) => {
      const result = await signin(email, password);
      return result.data?.signin;
    },
    [signin]
  );
};

const useSignout = (): UserContext['signout'] => {
  const signout = useSignoutMutation();
  return useCallback(async () => {
    const signoutResult = await signout();
    return signoutResult;
  }, [signout]);
};

const useIdentifyUser = (user: User | undefined) => {
  const tracking = useWebManagerTracking();
  return useEffect(() => {
    if (!tracking || !user || user.isImpersonated) return;
    const userRole = user.roles.account.name;

    tracking.identifyUser({
      userId: user.id,
      userRole,
      details: {
        id: user.id,
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        locale: user.locale,
        createdAt: user.createdAt,
        accountId: user.account.id,
        accountName: user.account.name,
        company: {
          id: user.account.id,
          name: user.account.name,
        },
        userRoles: user.roles,
      },
    });
    tracking.trackGroup({
      groupId: user.account.id,
      details: {
        name: user.account.name,
        accountId: user.account.id,
      },
    });
  }, [user, tracking]);
};

const LoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  backgroundColor: theme.palette.brand.linen,
}));

export const UserProvider: FC<
  PropsWithChildren<{ hasSessionExpired: boolean; setHasSessionExpired: Dispatch<SetStateAction<boolean>> }>
> = ({ children, hasSessionExpired, setHasSessionExpired }) => {
  const { user, loading } = useMe();
  const signin = useSignin();
  const signout = useSignout();
  const context = useMemo(
    () => ({ user, signin, signout, setHasSessionExpired, hasSessionExpired }),
    [user, signin, signout, setHasSessionExpired, hasSessionExpired]
  );

  useIdentifyUser(user);

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};
