import { type FC, useCallback } from 'react';

import {
  BlankButton,
  Chip,
  EmptyState,
  GridContainer,
  GridItem,
  ThumbnailBar,
  ThumbnailContainer,
  ThumbnailImage,
  ThumbnailOverlayContainer,
  ThumbnailVideo,
  ThumbnailVideoInteraction,
  formatDuration,
  styled,
} from '@cofenster/web-components';

import type { MediaLibraryItem } from '../../api/hooks/mediaLibraryItem/useMediaLibraryItems';

import { MediaLibraryItemAssetStatus } from './MediaLibraryItemAssetStatus';
import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';

const StyledBlankButton = styled(BlankButton)(({ theme }) => ({
  '&:focus-visible': theme.mixins.focusRing,
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],
}));

const MediaLibraryTileButton: FC<{
  mediaLibraryItem: MediaLibraryItem;
  onItemClick: (mediaLibraryItem: MediaLibraryItem) => unknown;
}> = ({ mediaLibraryItem, onItemClick }) => {
  const onClick = useCallback(() => {
    onItemClick(mediaLibraryItem);
  }, [mediaLibraryItem, onItemClick]);

  return (
    <ThumbnailVideoInteraction>
      {({ ref, onInteractionEnter, onInteractionLeave }) => (
        <StyledBlankButton
          onClick={onClick}
          onMouseEnter={onInteractionEnter}
          onMouseLeave={onInteractionLeave}
          onFocus={onInteractionEnter}
          onBlur={onInteractionLeave}
        >
          <ThumbnailContainer background="checkerboard">
            {mediaLibraryItem.videoAsset && (
              <>
                <ThumbnailVideo ref={ref} videoAsset={mediaLibraryItem.videoAsset} />
                {mediaLibraryItem.videoAsset.status !== 'Ready' && (
                  <ThumbnailOverlayContainer anchor="TopRight">
                    <MediaLibraryItemAssetStatus status={mediaLibraryItem.videoAsset.status} />
                  </ThumbnailOverlayContainer>
                )}
                {mediaLibraryItem.videoAsset.duration && (
                  <ThumbnailOverlayContainer anchor="BottomRight">
                    <Chip color="dark">{formatDuration(mediaLibraryItem.videoAsset.duration)}</Chip>
                  </ThumbnailOverlayContainer>
                )}
              </>
            )}
            {mediaLibraryItem.imageAsset && (
              <>
                <ThumbnailImage imageAsset={mediaLibraryItem.imageAsset} />
                {mediaLibraryItem.imageAsset.status !== 'Ready' && (
                  <ThumbnailOverlayContainer anchor="TopRight">
                    <MediaLibraryItemAssetStatus status={mediaLibraryItem.imageAsset.status} />
                  </ThumbnailOverlayContainer>
                )}
              </>
            )}
          </ThumbnailContainer>
        </StyledBlankButton>
      )}
    </ThumbnailVideoInteraction>
  );
};

export const MediaLibraryItemsGridView: FC<{
  mediaLibraryItems: MediaLibraryItem[];
  onItemClick: (mediaLibraryItem: MediaLibraryItem) => unknown;
  compact?: boolean;
}> = ({ mediaLibraryItems, onItemClick, compact = false }) => {
  const { filter } = useMediaLibraryFilterAndPagination();

  if (mediaLibraryItems.length === 0) {
    const { search } = filter;
    return (
      <EmptyState
        iconType="XCircleIcon"
        title={
          search
            ? 'i18n.mediaLibrary.emptySearchResults.withSearchPopulated.title'
            : 'i18n.mediaLibrary.emptySearchResults.title'
        }
        titleProps={search ? { i18nParams: { search: filter.search } } : undefined}
        description={
          search
            ? 'i18n.mediaLibrary.emptySearchResults.withSearchPopulated.description'
            : 'i18n.mediaLibrary.emptySearchResults.description'
        }
      />
    );
  }

  return (
    <GridContainer>
      {mediaLibraryItems.map((mediaLibraryItem) => (
        <GridItem xs={12} sm={6} md={4} key={mediaLibraryItem.id} data-testId="media-library-item-tile">
          <MediaLibraryTileButton mediaLibraryItem={mediaLibraryItem} onItemClick={onItemClick} />
          {!compact && <ThumbnailBar title={mediaLibraryItem.title} />}
        </GridItem>
      ))}
    </GridContainer>
  );
};
