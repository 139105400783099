import { createContext } from 'react';

export type BulkSelection = {
  projects: string[];
  folders: string[];
};

export type BulkSelectionContext = {
  setSelectables: (selectables: BulkSelection) => void;

  selection: BulkSelection;
  setSelection: (selection: BulkSelection) => void;
  deselectAll: VoidFunction;

  setSelectableProjects: (ids: string[]) => void;
  selectProjects: (ids: string[]) => void;
  toggleProject: (id: string) => void;

  setSelectableFolders: (ids: string[]) => void;
  selectFolders: (ids: string[]) => void;
  toggleFolder: (id: string) => void;
};

export const BulkSelectionContext = createContext<BulkSelectionContext>({
  setSelectables: () => undefined,

  selection: { projects: [], folders: [] },
  setSelection: () => undefined,
  deselectAll: () => undefined,

  setSelectableProjects: () => undefined,
  selectProjects: () => undefined,
  toggleProject: () => undefined,

  setSelectableFolders: () => undefined,
  selectFolders: () => undefined,
  toggleFolder: () => undefined,
});
