import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

import { useSetUserActive } from '../../../api/hooks/user/useSetUserActive';
import type { User } from '../../../api/hooks/user/useUser';

const useSetActive = (user: User, closeDialog: () => unknown) => {
  const setUserActive = useSetUserActive();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    await setUserActive(user.id, { isActive: false });
    closeDialog();
    openSnackbar({
      children: 'i18n.snackbars.userDeactivatedSnackbar.copy',
    });
  }, [user, setUserActive, closeDialog, openSnackbar]);
};

export type SetUserActiveDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const DeactivateUserDialog: FC<SetUserActiveDialogProps> = ({ isOpen, closeDialog, user }) => {
  const onAction = useSetActive(user, closeDialog);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="user-deactivate-dialog"
      title="i18n.dialogs.deactivateUserDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.deactivateUserDialog.text.paragraph1
        </Text>

        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.deactivateUserDialog.text.paragraph2
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button variant="destructive" fullWidth onClick={onAction} data-testid="user-deactivate-dialog-confirm-button">
          i18n.dialogs.deactivateUserDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
