import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGoToSceneSubtitleEdit = (replace = true) => {
  const goto = useGoto({ replace });
  return useCallback(
    (projectId: string, sceneId: string, language: string) => {
      goto(routes.projectSceneSubtitleEdit, {
        projectId: projectId,
        sceneId: sceneId,
        language,
      });
    },
    [goto]
  );
};
