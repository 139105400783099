import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';
import type { UploadType } from '@cofenster/web-components';

import { useUploadAudioAsset } from '../api/hooks/audioAsset/useUploadAudioAsset';
import { useUploadImageAsset } from '../api/hooks/imageAsset/useUploadImageAsset';
import { useUploadVideoAsset } from '../api/hooks/videoAsset/useUploadVideoAsset';

export const useAssetUpload = () => {
  const uploadVideo = useUploadVideoAsset();
  const uploadImage = useUploadImageAsset();
  const uploadAudio = useUploadAudioAsset();

  return useCallback(
    async (
      type: UploadType,
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ) => {
      switch (type) {
        case 'video':
          return uploadVideo(file, uploadId, onProgress, abortSignal, options);
        case 'image':
          return uploadImage(file, uploadId, onProgress, abortSignal);
        case 'audio':
          return uploadAudio(file, uploadId, onProgress, abortSignal);
        default:
          return undefined;
      }
    },
    [uploadVideo, uploadImage, uploadAudio]
  );
};
