import { useField } from 'formik';
import { type FC, memo, useCallback } from 'react';

import { MimeTypes } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  type AssetDropzoneProps,
  AudioPlayer,
  DropzoneContainer,
  DropzoneStatus,
  IconButton,
  styled,
  useUploadHint,
} from '@cofenster/web-components';
import type { AudioAssetFragmentFragment as AudioAssetFragment } from '../../../../api/generated';
import type { AudioSceneAsset } from '../../../../api/hooks/scene/useScenes';
import { useDialogs } from '../../../../contexts/dialogs';
import { useDeleteAudioSceneAssetWithTracking } from '../../../../hooks/sceneAsset/audio/useDeleteAudioSceneAssetWithTracking';
import { useUploadSceneAudioAsset } from '../../../../hooks/sceneAsset/audio/useUploadSceneAudioAsset';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

type Props = {
  name: string;
  audioAsset: AudioAssetFragment | null | undefined;
  sceneAsset?: AudioSceneAsset;
  sceneId: string;
  assetDropzoneProps?: Partial<AssetDropzoneProps>;
};

const RecordButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export const AudioAssetUpload: FC<Props> = memo(({ name, audioAsset, assetDropzoneProps, sceneAsset, sceneId }) => {
  const [, , { setTouched, setValue, setError }] = useField(name);
  const tracking = useWebManagerTracking();
  const hintText = useUploadHint('audio');

  const [progress, upload, cancelUpload] = useUploadSceneAudioAsset(sceneId);
  const deleteAudioSceneAsset = useDeleteAudioSceneAssetWithTracking(sceneId, sceneAsset);
  const deleteFile = useCallback(async () => {
    const resetField = async () => {
      await deleteAudioSceneAsset();
      setValue(undefined);
      setTouched(true);
    };

    await resetField();
  }, [deleteAudioSceneAsset, setValue, setTouched]);

  const onError = useCallback(
    (error: string) => {
      setError(error);
      setTouched(true);
    },
    [setError, setTouched]
  );
  const trackSceneAssetUploaded = useCallback(
    (file: File) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId: sceneId,
          assetType: 'audio',
          assetName: file.name,
          uploadSource: 'desktop-library',
        },
      }),
    [tracking, sceneId]
  );

  const handleUpload = useCallback(
    (file: File) => {
      upload(file);
      trackSceneAssetUploaded(file);
    },
    [upload, trackSceneAssetUploaded]
  );

  const { openDialog } = useDialogs();

  if (!audioAsset?.status && progress !== undefined) {
    return (
      <DropzoneContainer size="small">
        <DropzoneStatus
          status="uploading"
          text="i18n.common.upload.audio.state.uploading"
          data-testid="audio-uploading"
          progress={progress || 0}
          onCancel={cancelUpload}
        />
      </DropzoneContainer>
    );
  }

  if (audioAsset?.status === 'Processing') {
    return (
      <DropzoneContainer size="small">
        <DropzoneStatus
          status="processing"
          text="i18n.common.upload.audio.state.processing"
          data-testid="audio-processing"
          onCancel={deleteFile}
        />
      </DropzoneContainer>
    );
  }

  if (audioAsset?.status === 'Error') {
    return (
      <DropzoneContainer size="small">
        <DropzoneStatus
          status="error"
          text="i18n.common.upload.audio.state.error"
          data-testid="audio-error"
          onCancel={deleteFile}
        />
      </DropzoneContainer>
    );
  }

  if (audioAsset?.audioUrl) {
    return (
      <DropzoneContainer size="small" style={{ position: 'relative' }}>
        <AudioPlayer url={audioAsset.audioUrl} duration={audioAsset.duration} filename={audioAsset.filename} />
        <AbsoluteDeleteIcon onClick={deleteFile} />
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="small">
      <AssetDropzone
        data-testid="audio-upload"
        mime={{ ...MimeTypes.audio, ...MimeTypes.video }}
        onFile={handleUpload}
        icon="CloudUploadIcon"
        text="i18n.common.upload.audio.text"
        hintText={hintText}
        onError={onError}
        {...assetDropzoneProps}
      >
        {__AUDIO_RECORDING__ && (
          <RecordButton
            icon="RecordIcon"
            iconColor="purple"
            label="i18n.common.record.audio"
            onClick={(event) => {
              event.stopPropagation();
              openDialog('RecordAudioDialog', {
                onFile: handleUpload,
              });
            }}
          />
        )}
      </AssetDropzone>
    </DropzoneContainer>
  );
});
AudioAssetUpload.displayName = 'AudioAssetUpload';
