import { type FC, type PropsWithChildren, memo } from 'react';

import { useRoles } from '../../api/hooks/role/useRoles';

export const RolesProvider: FC<PropsWithChildren> = memo(({ children }) => {
  // Load and simply store in grapqhql cache
  useRoles();

  return <>{children}</>;
});
RolesProvider.displayName = 'RolesProvider';
