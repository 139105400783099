import type { FC, PropsWithChildren, ReactNode } from 'react';

import { type FeatureFlagName, useFeatureFlags } from '@cofenster/web-components';

type WithHas = { has: FeatureFlagName; not?: never };
type WithNot = { has?: never; not: FeatureFlagName };
type FeatureFlagRestrictionProps = WithHas | WithNot;

export const FeatureFlagRestriction: FC<PropsWithChildren<FeatureFlagRestrictionProps & { fallback?: ReactNode }>> = ({
  children,
  has,
  not,
  fallback,
}) => {
  const { hasFeature } = useFeatureFlags();
  const isValid = (has && hasFeature(has)) || (not && !hasFeature(not));

  return isValid ? <>{children}</> : <>{fallback}</>;
};
