import { useCallback } from 'react';

import type { ContributionRequestList } from '../../../api/generated';
import { useUserContextRole } from '../../../contexts/user/useRole';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

type ContributionRequestListTrackingData = Pick<ContributionRequestList, 'name' | 'id'> & {
  requests?: unknown[];
};

export const useTrackContributionRequestList = () => {
  const tracking = useWebManagerTracking();
  const contextRole = useUserContextRole();

  return useCallback(
    (eventName: string, request?: ContributionRequestListTrackingData, moreDetails: Record<string, unknown> = {}) => {
      tracking.trackEvent({
        event: eventName,
        details: {
          ...(request && {
            requestListId: request.id,
            requestListName: request.name,
            requestCount: request.requests?.length,
          }),
          ...contextRole,
          ...moreDetails,
        },
      });
    },
    [tracking, contextRole]
  );
};
