import { styled } from '@cofenster/web-components';

export const AudioBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey100}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2, 3),
  minHeight: 64,
}));
