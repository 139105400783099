import { useCallback } from 'react';

import {
  type CreateEnhanceAudioJobOnSceneVideoMutationVariables,
  useCreateEnhanceAudioJobOnSceneVideoMutation,
} from '../../generated';

export const useCreateEnhanceAudioJobOnSceneVideo = () => {
  const [createEnhanceAudioJobOnSceneVideoMutation] = useCreateEnhanceAudioJobOnSceneVideoMutation({
    refetchQueries: ['Scenes'],
  });

  return useCallback(
    (sceneVideoId: CreateEnhanceAudioJobOnSceneVideoMutationVariables['sceneVideoId']) =>
      createEnhanceAudioJobOnSceneVideoMutation({ variables: { sceneVideoId } }),
    [createEnhanceAudioJobOnSceneVideoMutation]
  );
};
