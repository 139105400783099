import type { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text } from '@cofenster/web-components';
import { routes } from '../../../routes';

export type SessionExpiredDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const SessionExpiredDialog: FC<SessionExpiredDialogProps> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      data-testid="session-expired-dialog"
      title="i18n.dialogs.SessionExpiredDialog.headline"
      // Basically make this dialog unclosable: neither with ESC nor by clicking
      // on the backdrop. The only way out is to click the link within the
      // dialog which will perform a proper navigation (not router-based) to the
      // signin page.
      onClose={onClose}
      withCloseButton={false}
      role="alertdialog"
      disableEscapeKeyDown
    >
      <DialogContent>
        <Text variant="l" component="p">
          i18n.dialogs.SessionExpiredDialog.description
        </Text>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" fullWidth href={routes.signin}>
          i18n.dialogs.SessionExpiredDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
