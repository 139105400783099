import { useCallback } from 'react';

import {
  type CreateSharingLinkInput as CreateSharingLinkInputApollo,
  type SharingLink as SharingLinkApollo,
  useCreateSharingLinkMutation,
} from '../../generated';

export type CreateSharingLinkInput = CreateSharingLinkInputApollo;
export type SharingLink = SharingLinkApollo;

export const useCreateSharingLink = () => {
  const [createSharingLink] = useCreateSharingLinkMutation({ refetchQueries: ['Project'] });
  return useCallback(
    async (projectId: string) => createSharingLink({ variables: { input: { projectId } } }),
    [createSharingLink]
  );
};
