import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import type { VideoFormat } from '@cofenster/constants';
import { Form, FormToggleSwitch, GridContainer, GridItem, Headline, Spacing } from '@cofenster/web-components';

import { useUpdateProject } from '../../../../api/hooks/project/useUpdateProject';
import { IntroOutroUpload } from '../../../../components/branding/IntroOutroUpload';
import type { Project, ProjectTheme } from '../../../../contexts/project/useProject';
import type { AccountTheme } from '../../../../contexts/user/useUser';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../../i18n';

import { OutroSection } from './OutroSection';
import { getConstraints } from './getConstraints';

type Values = {
  withIntro: boolean;
  withOutro: boolean;
  outroText: string | undefined;
};

const useValidationSchema = (videoFormat: VideoFormat) => {
  const { maxLines, maxLineLength } = getConstraints(videoFormat);
  const { translate } = useI18n();
  const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
    introId: Yup.string().trim().nullable(),
    outroId: Yup.string().trim().nullable(),
    withIntro: Yup.boolean().required(),
    withOutro: Yup.boolean().required(),
    outroText: Yup.string()
      .trim()
      .test('lineLength', translate('form.error.generic.lineLength', { lineLength: maxLineLength }), async (value) => {
        const isValid = value
          ? value.split('\n').every((line: string) => line.trimEnd().length <= maxLineLength)
          : true;
        return isValid;
      })
      .test('lineCount', translate('form.error.generic.lineCount', { lineCount: maxLines }), async (value) => {
        const isValid = value ? value.split('\n').length <= maxLines : true;
        return isValid;
      }),
  });
  return validationSchema;
};

const useValues = (theme: ProjectTheme | AccountTheme | undefined, project: Project | undefined) => {
  return useMemo(
    () => ({
      outroText: theme?.outroText || undefined,
      withIntro: project?.withIntro || false,
      withOutro: project?.withOutro || false,
    }),
    [theme, project]
  );
};

const useSubmit = (themeId: string | undefined, projectId: string | undefined) => {
  const tracking = useWebManagerTracking();
  // Updating the theme has an impact on the preview and therefore should cause
  // a refetch of the render description.
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', 'ProjectRenderDescription'] });

  return useCallback(
    async (values: Values) => {
      if (!themeId || !projectId) return;
      try {
        await updateProject(projectId, values);

        tracking.trackEvent({
          event: 'ProjectThemeUpdated',
          details: { data: values },
        });
      } catch {
        throw new Error('i18n.global.error.generic.unknown');
      }
    },
    [themeId, projectId, updateProject, tracking]
  );
};

type Props = {
  project: Project;
};

export const VideoForm: FC<Props> = ({ project }) => {
  const theme = project.theme || undefined;
  const videoFormat = project.videoFormat;
  const initialValues = useValues(theme, project);
  const validationSchema = useValidationSchema(videoFormat);
  const onSubmit = useSubmit(theme?.id, project.id);

  if (!theme) return null;

  return (
    <Form autoSubmit initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Spacing bottom={2}>
            <FormToggleSwitch id="withIntro" name="withIntro" switchPosition="right" isBlock data-testid="intro-switch">
              <Headline variant="h4" color="grey700" component="span">
                i18n.projectDesign.themeForm.intro
              </Headline>
            </FormToggleSwitch>
          </Spacing>
          <IntroOutroUpload
            videoType={'Intro'}
            videoFormat={videoFormat}
            theme={theme}
            size="small"
            data-testid="intro-upload-dropzone"
          />
        </GridItem>

        <GridItem xs={12} md={6}>
          <Spacing bottom={2}>
            <FormToggleSwitch id="withOutro" name="withOutro" switchPosition="right" isBlock data-testid="outro-switch">
              <Headline variant="h4" color="grey700" component="span">
                i18n.projectDesign.themeForm.outro
              </Headline>
            </FormToggleSwitch>
          </Spacing>
          <OutroSection project={project} />
        </GridItem>
      </GridContainer>
    </Form>
  );
};
