import { type FC, type Ref, useEffect, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useTitle } from '../../../hooks/dom/useTitle';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

export const DocumentTitleAnnouncer: FC<{ documentTitle: string; ignorePaths?: string[] }> = ({
  documentTitle,
  ignorePaths,
}) => {
  useTitle(documentTitle);

  const [title, setTitle] = useState(documentTitle);
  const pageTitleHolder = useRef<HTMLParagraphElement | null>();
  const { pathname } = useLocation();

  useEffect(() => {
    if (ignorePaths?.some((match) => matchPath(match, pathname))) return;

    // If the pathname has changed, it probably means the user has followed a
    // router link, in which case:
    // 1. the title of the new page needs to be set in the state so it can be
    //    rendered in the accessibility title announcer.
    setTitle(document.title);

    // 2. the focus needs to be moved to the page title holder so the title of
    //    the new “page” gets announced as if there was a page refresh.
    pageTitleHolder.current?.focus();
  }, [pathname, ignorePaths]);

  return (
    <VisuallyHidden
      as="p"
      tabIndex={-1}
      ref={pageTitleHolder as Ref<HTMLElement>}
      id="a11y-document-title"
      style={{ position: 'fixed' }}
    >
      {title}
    </VisuallyHidden>
  );
};
