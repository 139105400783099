import { useCallback, useMemo } from 'react';

import type { MutationHookOptions } from '@cofenster/api-client-apollo';

import {
  type UpdateSubtitleSocialMediaSafeZoneMutationVariables,
  useUpdateSubtitleSocialMediaSafeZoneMutation,
} from '../../generated';

export const useUpdateSubtitleSocialMediaSafeZone = (options?: Omit<MutationHookOptions, 'variables'>) => {
  const [update, metadata] = useUpdateSubtitleSocialMediaSafeZoneMutation(options);

  const updateSubtitleSocialMediaSafeZone = useCallback(
    (
      projectId: UpdateSubtitleSocialMediaSafeZoneMutationVariables['projectId'],
      input: UpdateSubtitleSocialMediaSafeZoneMutationVariables['input']
    ) => {
      return update({ variables: { projectId, input } });
    },
    [update]
  );

  return useMemo(
    () => [updateSubtitleSocialMediaSafeZone, metadata] as const,
    [updateSubtitleSocialMediaSafeZone, metadata]
  );
};
