import type { FC } from 'react';
import { Navigate, type NavigateProps, type Params, useParams } from 'react-router-dom';

import { createQueryString, generatePath } from '@cofenster/web-components';

import { type RouteName, routes } from '../../routes';

type RedirectProps = Omit<NavigateProps, 'to'> & {
  to: RouteName;
  params?: Params;
  search?: { [key: string]: string } | string;
};

export const Redirect: FC<RedirectProps> = ({ to, params, search, state }) => (
  <Navigate
    to={{
      pathname: generatePath(routes[to], params),
      search: createQueryString(search),
    }}
    replace
    state={state}
  />
);

export const RedirectWithParams: FC<RedirectProps> = (props) => {
  const params = useParams();

  return <Redirect {...props} params={params} />;
};
