import { type FC, useEffect, useRef, useState } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { IconButton } from '../../controls/Button/IconButton';
import { FormTextField, type FormTextFieldProps } from './FormTextField';

type PasswordAdornmentProps = {
  showPassword: boolean;
  showPasswordSetter: (value: boolean) => void;
  id?: string;
};

const PasswordAdornment: FC<PasswordAdornmentProps> = ({ showPassword, showPasswordSetter, id }) => (
  <IconButton
    aria-controls={id}
    aria-pressed={showPassword}
    hoverColor="blue"
    hoverBackgroundColor="grey50"
    icon={showPassword ? 'EyeIcon' : 'EyeSlashIcon'}
    onClick={() => showPasswordSetter(!showPassword)}
    label="i18n.global.password.toggle"
    // Make the hover/focus state a little nicer by ensuring the side-spacing is even with the vertical one
    style={{ transform: 'translateX(0.4em)' }}
    data-testid="password-visibility-button"
  />
);

export type FormPasswordFieldProps = Omit<FormTextFieldProps, 'inputRef' | 'type'>;

export const FormPasswordField: FC<FormPasswordFieldProps> = ({ id, InputProps, ...rest }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  // The following effect is implemented due to security reasons
  // We need to make sure to revert that to password when submitting
  // the form so that browsers do not index the content of the field for future autocompletion.
  // Here is a great resource that will clear all the unknowns:
  // https://technology.blog.gov.uk/2021/04/19/simple-things-are-complicated-making-a-show-password-option/
  useEffect(() => {
    const input = inputRef.current;
    const form = input?.form;
    const callback = () => setShowPassword(false);

    form?.addEventListener('submit', callback);
    return () => form?.removeEventListener('submit', callback);
  }, []);

  return (
    <FormTextField
      id={id}
      type={showPassword ? 'text' : 'password'}
      inputRef={inputRef}
      autoComplete={showPassword ? 'off' : undefined}
      InputProps={{
        startAdornment: <Icon type="LockIcon" />,
        endAdornment: <PasswordAdornment showPassword={showPassword} showPasswordSetter={setShowPassword} id={id} />,
        ...InputProps,
      }}
      {...rest}
    />
  );
};
