import type { FC } from 'react';

import { IconButton } from '../../../controls/Button/IconButton';
import type { WithSize } from '../types';

export type LoopingControlProps = {
  isLooping: boolean;
  toggleLoop: VoidFunction;
} & WithSize;

export const LoopControl: FC<LoopingControlProps> = ({ isLooping, toggleLoop, size }) => {
  return (
    <IconButton
      icon={isLooping ? 'RepeatIcon' : 'RepeatOnceIcon'}
      iconSize={size}
      iconColor="white"
      hoverColor="grey300"
      hoverBackgroundColor="transparent"
      onClick={toggleLoop}
      label="i18n.global.loop"
      aria-pressed={isLooping}
    />
  );
};
