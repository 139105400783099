import { styled } from '@mui/material';
import type { ComponentProps, PropsWithChildren } from 'react';

import { Icon } from '../../../assets/icons/Icon';
import { Typography } from '../../../typography/Typography';

import { BaseLink, type BaseLinkProps } from './BaseLink';

const StyledBaseLink = styled(BaseLink)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),

  '&:not(:focus)': {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

export type SkipNavigationLinkProps = Omit<BaseLinkProps, 'children'> & {
  children: string;
} & ComponentProps<'button'>;

export const SkipNavigationLink = ({ children, ...rest }: PropsWithChildren<SkipNavigationLinkProps>) => {
  return (
    <StyledBaseLink {...rest}>
      <Typography variant="l">{children}</Typography>
      <Icon type="ArrowRightIcon" size="m" />
    </StyledBaseLink>
  );
};
