import type { FC } from 'react';

import type { ColorSceneAsset, Scene } from '../../../../../api/hooks/scene/useScenes';
import { useEditorPlayer } from '../../../../../contexts/editorPlayer/useEditorPlayer';
import type { Project } from '../../../../../contexts/project/useProject';

import { SceneColorControls } from './SceneColorControls';

export const EditorToolsSceneColor: FC<{
  project: Project;
  scene: Scene;
  colorSceneAsset?: ColorSceneAsset;
}> = ({ project, scene, colorSceneAsset }) => {
  const { hasPreviewLoadingError } = useEditorPlayer();

  const withDisabledControls = hasPreviewLoadingError || Boolean(project.archivedAt);

  return (
    <SceneColorControls
      project={project}
      scene={scene}
      colorSceneAsset={colorSceneAsset}
      withDisabledControls={withDisabledControls}
    />
  );
};
