import { styled } from '@cofenster/web-components';

// 1. Enable flex layout to absolutely center the image or icon inside it.
// 2. Make the thumbnail area a 16/9 with rounded corners.
// 3. Pseudo-element used to create the blurred effect in the background, since
//    it relies on the `blur()` CSS filter.
// 4. Mark interactive children as hidden until the thumbnail is interacted with
//    either hover or focus. It is important to use `> *` as there is a visual
//    glitch when the opacity and the blur are applied on different elements.
//    See: https://x.com/KittyGiraudel/status/1713861958733033489?s=20
export const Masthead = styled('div')(({ theme }) => ({
  display: 'flex', // 1
  position: 'relative',

  aspectRatio: 16 / 9, // 2
  borderRadius: theme.shape['borderRadius-l'], // 2
  overflow: 'hidden', // 2

  backgroundColor: theme.palette.brand.linen50,
  backgroundImage: 'url("/assets/images/missing-thumbnail.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  '&:has(> img)': {
    backgroundImage: 'none',
  },

  '::before': {
    content: '""', // 3
    position: 'absolute',
    inset: 0,
    backgroundImage: 'var(--image)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(20px)',
  },

  '> [data-interactive] > *': {
    opacity: 0, // 4
    transition: 'opacity 250ms',
  },

  '&:hover > [data-interactive] > *, &:focus-within > [data-interactive] > *': {
    opacity: 1, // 4
  },
}));
