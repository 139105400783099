import type { FC, PropsWithChildren } from 'react';

import { useProject } from '../project/useProject';

import { CurrentTeamProvider } from './CurrentTeamProvider';

export const FromProjectTeamProvider: FC<PropsWithChildren> = ({ children }) => {
  const { project, loading } = useProject();

  if (!loading && !project)
    throw new Error('Project not found. Without a project, `FromProjectTeamProvider` cannot find a team.');

  return (
    <CurrentTeamProvider loading={loading} team={project?.projectFolders[0]?.team ?? undefined}>
      {children}
    </CurrentTeamProvider>
  );
};
