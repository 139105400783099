///<reference types="webpack-env" />

import React, { type ExoticComponent, forwardRef, lazy, memo, type Ref, Suspense, useMemo } from 'react';

import type { RemotionPreviewProps } from '@cofenster/render-template-engine';

import { Loading } from './Loading';
import type { RemotionPlayerRef } from './Player';

const loadPlayer = async (bundleUrl: string) => {
  const windowAsModule: Record<string, unknown> = window as unknown as Record<string, unknown>;

  // This is a hack to make sure that the “multiple versions warning” doesn’t show up
  // See: https://github.com/remotion-dev/remotion/commit/3c0a97defdf5d23b79465da79499d87f43c5dcfc
  windowAsModule.remotion_imported = undefined;

  // The preview bundle relies on React being available on the global object, so
  // set it before loading it, and delete it afterwards.
  windowAsModule.react = React;
  await import(/* webpackIgnore: true */ bundleUrl);
  // biome-ignore lint/performance/noDelete: we need to actually unset the variable and not just make it undefined.
  delete windowAsModule.react;

  const { preview } = windowAsModule;

  // Do not mutate the windowAsModule if hot reloading is enabled, as it can be loaded
  // several times within the lifetime of the application.
  // See: https://www.notion.so/cofenster/Dynamic-template-loading-causes-the-app-to-crash-locally-due-to-hot-reloading-e668ca20fa7b45209005d93d9eeff640
  // biome-ignore lint/performance/noDelete: we need to actually unset the variable and not just make it undefined.
  if (!module.hot) delete windowAsModule.preview;

  return preview as ReturnType<Parameters<typeof lazy>[0]>;
};

type LazyPlayer = ExoticComponent<
  Omit<LazyRemotionPlayerProps, 'bundleUrl' | 'templateIdentifier'> & {
    ref?: Ref<RemotionPlayerRef>;
  }
>;

const PLAYER_CACHE = new Map<string, LazyPlayer>();

// We need to maintain a memory cache on top of Webpack, because we mutate the
// object from the bundle. Without that memory cache, we would end up with a
// bundle in a broken state, which would cause runtime errors.
// See: https://cofenster.sentry.io/issues/3964327387/?environment=production&project=5260770&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=24h
const getLazyPlayer = (bundleUrl: string) => {
  if (!PLAYER_CACHE.has(bundleUrl)) {
    PLAYER_CACHE.set(
      bundleUrl,
      lazy(() => loadPlayer(bundleUrl))
    );
  }
  return PLAYER_CACHE.get(bundleUrl) as LazyPlayer;
};

export type LazyRemotionPlayerProps = RemotionPreviewProps & {
  bundleUrl: string;
  templateIdentifier: string;
  onPlayerLoading?: VoidFunction;
};

export const LazyRemotionPlayer = memo(
  forwardRef<RemotionPlayerRef, LazyRemotionPlayerProps>(
    ({ bundleUrl, templateIdentifier, onPlayerLoading, ...rest }, ref) => {
      const Player = useMemo(() => {
        // When we are not in a phase where we attempt to upgrade Remotion to a
        // new major version, we can directly import the template preview bundle
        // from the templates package. However, when we want to use Remotion NG,
        // we need to load the template preview bundle via its asset URL because
        // it was bundled and deployed differently with the new Remotion version
        if (__DISABLE_REMOTION_NG__) {
          return lazy(
            () =>
              // Note that we use `../..` and not `@cofenster` on purpose as the
              // latter is *incredibly* slow (~40s vs ~3.5mn) due to the way
              // webpack resolves dynamic paths for dynamic imports.
              // Even if we import from the relative path instead of `@cofenster`,
              // we should still list it as a package dependency to ensure that
              // Lerna and NX properly detect any changes in the templates.
              import(/* webpackChunkName: "[request]" */ `../../render-templates/src/${templateIdentifier}/preview.tsx`)
          );
        }

        return getLazyPlayer(bundleUrl);
      }, [bundleUrl, templateIdentifier]);

      return (
        <Suspense fallback={<Loading onMount={onPlayerLoading} />}>
          <Player {...rest} ref={ref} />
        </Suspense>
      );
    }
  )
);
LazyRemotionPlayer.displayName = 'LazyRemotionPlayer';
