import { useCallback } from 'react';

import { type UpdateSharingLinkSettingsMutationVariables, useUpdateSharingLinkSettingsMutation } from '../../generated';

export const useUpdateSharingLinkSettings = () => {
  const [updateSharingLinkSettings] = useUpdateSharingLinkSettingsMutation({ refetchQueries: ['Project'] });
  return useCallback(
    async (
      sharingLinkId: UpdateSharingLinkSettingsMutationVariables['id'],
      input: UpdateSharingLinkSettingsMutationVariables['input']
    ) => updateSharingLinkSettings({ variables: { id: sharingLinkId, input } }),
    [updateSharingLinkSettings]
  );
};
