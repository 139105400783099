import { type FC, useMemo } from 'react';

import { Button, Icon, PopoverMenuItem, Text, styled, withPopover } from '@cofenster/web-components';

import type { Project } from '../../../contexts/project/useProject';
import { useProjectMusicDetails, useSelectVolume } from '../hooks';

const VOLUMES = {
  low: 20,
  medium: 35,
  high: 100,
};

const VolumeLabel = styled(Text)(() => ({ fontWeight: 'bold' }));

const Flex = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

export const VolumePickerPopover: FC<{ project: Project }> = ({ project }) => {
  const { selectVolume } = useSelectVolume(project);
  const { volume } = useProjectMusicDetails(project);

  const PopoverButton = useMemo(
    () =>
      withPopover(Button, {
        anchorOriginX: 'right',
        anchorOriginY: 'bottom',
        transformOriginX: 'right',
        transformOriginY: 'top',
        children: (
          <>
            <PopoverMenuItem
              icon="SpeakerHighIcon"
              onClick={() => selectVolume(VOLUMES.high)}
              aria-current={volume === VOLUMES.high}
              data-testid="volume-high-button"
            >
              <Flex>
                <VolumeLabel component="p">i18n.projectMusic.MusicLoudnessForm.High.title</VolumeLabel>
                <Text component="p">i18n.projectMusic.MusicLoudnessForm.High.description</Text>
              </Flex>
            </PopoverMenuItem>
            <PopoverMenuItem
              icon="SpeakerLowIcon"
              onClick={() => selectVolume(VOLUMES.medium)}
              aria-current={volume === VOLUMES.medium}
              data-testid="volume-medium-button"
            >
              <Flex>
                <VolumeLabel component="p">i18n.projectMusic.MusicLoudnessForm.Medium.title</VolumeLabel>
                <Text component="p">i18n.projectMusic.MusicLoudnessForm.Medium.description</Text>
              </Flex>
            </PopoverMenuItem>
            <PopoverMenuItem
              icon="SpeakerNoneIcon"
              onClick={() => selectVolume(VOLUMES.low)}
              aria-current={volume === VOLUMES.low}
              data-testid="volume-low-button"
            >
              <Flex>
                <VolumeLabel component="p">i18n.projectMusic.MusicLoudnessForm.Soft.title</VolumeLabel>
                <Text component="p">i18n.projectMusic.MusicLoudnessForm.Soft.description</Text>
              </Flex>
            </PopoverMenuItem>
          </>
        ),
      }),
    [selectVolume, volume]
  );

  return (
    <PopoverButton
      startIcon={<Icon type="SpeakerHighIcon" size="s" />}
      type="button"
      data-testid="volume-popover-button"
      variant="tertiary"
    >
      i18n.projectMusic.volume.title
    </PopoverButton>
  );
};
