import { useMemo } from 'react';

import { type ProjectFolderQuery, useProjectFolderQuery } from '../../generated';

export type ProjectFolder = NonNullable<ProjectFolderQuery['projectFolder']>;
export type Team = NonNullable<ProjectFolder['team']>;

type FetchPolicy = NonNullable<Parameters<typeof useProjectFolderQuery>[0]>['fetchPolicy'];

export const useProjectFolder = (id?: string, fetchPolicy: FetchPolicy = 'cache-and-network') => {
  const { error, loading, data } = useProjectFolderQuery({
    variables: id ? { id } : undefined,
    fetchPolicy,
    skip: !id,
  });
  const projectFolder = data?.projectFolder;
  return useMemo(() => ({ projectFolder: projectFolder, loading, error }), [projectFolder, loading, error]);
};
