import { useCallback } from 'react';

import { type UpdateAudioAssetMutationVariables, useUpdateAudioAssetMutation } from '../../generated';

export const useUpdateAudioAsset = () => {
  const [updateAudioAsset] = useUpdateAudioAssetMutation({ refetchQueries: ['Scenes'] });
  return useCallback(
    (id: UpdateAudioAssetMutationVariables['id'], input: UpdateAudioAssetMutationVariables['input']) =>
      updateAudioAsset({ variables: { id, input } }),
    [updateAudioAsset]
  );
};
