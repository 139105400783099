import { type FC, useCallback } from 'react';

import {
  PopoverMenuDivider,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  withPopoverMenu,
} from '@cofenster/web-components';

import { useCloneContributionRequest } from '../../../../api/hooks/contributionRequest/useCloneContributionRequest';
import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useDeleteContributionRequest } from '../../../../api/hooks/contributionRequest/useDeleteContributionRequest';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';
import { useProject } from '../../../../contexts/project/useProject';
import { useTrackContributionRequest } from '../../hooks/useTrackContributionRequest';

export const ContributionRequestMoreOptionsMenu: FC<{
  contributionRequest: ContributionRequest;
}> = ({ contributionRequest }) => {
  const PopoverMenuButton = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: [
      <AddGroupTabMenuItem key="group-tag" contributionRequestId={contributionRequest.id} />,
      <CloneContributionRequestMenuItem key="clone" contributionRequestId={contributionRequest.id} />,
      <PopoverMenuDivider key="divider" />,
      <DeleteContributionRequestMenuItem key="delete" contributionRequestId={contributionRequest.id} />,
    ],
  });

  return <PopoverMenuButton data-testid="contribution-request-more-options" />;
};

const AddGroupTabMenuItem: FC<{ contributionRequestId: string }> = ({ contributionRequestId }) => {
  const { project } = useProject();
  const { openDialog } = useDialogs();

  const onClick = useCallback(() => {
    if (!project) return;
    openDialog('NewContributionRequestListDialog', {
      projectId: project.id,
      contributionRequestId,
    });
  }, [openDialog, project, contributionRequestId]);

  return (
    <PopoverMenuItem icon="TagIcon" onClick={onClick} data-testid="add-group-tag">
      i18n.projectContributions.ContributionRequestCard.addToListChipTitle
    </PopoverMenuItem>
  );
};

const CloneContributionRequestMenuItem: FC<{
  contributionRequestId: string;
}> = ({ contributionRequestId }) => {
  const { cloneContributionRequest } = useCloneContributionRequest(contributionRequestId);
  const trackClone = useTrackContributionRequest('requestCloned');
  const onClickClone = useCallback(async () => {
    const result = await cloneContributionRequest();
    if (result.data?.cloneContributionRequest) {
      trackClone(result.data?.cloneContributionRequest);
    }
  }, [trackClone, cloneContributionRequest]);

  return (
    <PopoverMenuItem icon="CopyIcon" onClick={onClickClone} data-testid="contribution-request-copy">
      i18n.common.duplicate
    </PopoverMenuItem>
  );
};

const DeleteContributionRequestMenuItem: FC<{
  contributionRequestId: string;
}> = ({ contributionRequestId }) => {
  const { openDialog } = useDialogs();
  const { deleteContributionRequest } = useDeleteContributionRequest(contributionRequestId);
  const trackDelete = useTrackContributionRequest('requestDeleted');
  const onAction = useCallback(async () => {
    const result = await deleteContributionRequest();
    if (result.data?.deleteContributionRequest) {
      trackDelete(result.data?.deleteContributionRequest);
    }
  }, [trackDelete, deleteContributionRequest]);

  return (
    <PopoverMenuItem
      key="delete"
      icon="TrashIcon"
      color="negative"
      data-testid="contribution-request-delete"
      onClick={() =>
        openDialog('DeleteContributionRequestDialog', {
          onAction,
        })
      }
    >
      i18n.global.delete
    </PopoverMenuItem>
  );
};
