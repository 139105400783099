import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { useI18n } from '@cofenster/web-components';

import { useProjectMusics } from '../../api/hooks/music/useProjectMusics';
import type { Project } from '../../contexts/project/useProject';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

import { useUpdateProject } from '../../api/hooks/project/useUpdateProject';

export const useProjectMusicDetails = (project: Project) => {
  const { locale } = useI18n();
  const currentMusicId = project.music?.id;
  const currentMusicName = locale === 'DE' ? project.music?.name : project.music?.nameEN;
  const volume = Math.abs(project.musicLoudness || 0);

  return useMemo(
    () => ({ musicId: currentMusicId, musicName: currentMusicName, volume }),
    [currentMusicId, currentMusicName, volume]
  );
};

export const useMusics = () => {
  const { projectMusics } = useProjectMusics();
  const privateMusics = projectMusics.private.filter((music) => Boolean(music.musicAsset?.audioUrl));
  const publicMusics = projectMusics.public.filter((music) => Boolean(music.musicAsset?.audioUrl));

  return useMemo(
    () => ({
      privateMusics,
      publicMusics,
      allMusics: [...privateMusics, ...publicMusics],
    }),
    [privateMusics, publicMusics]
  );
};

const useFindMusic = () => {
  const { allMusics } = useMusics();
  return useCallback(
    (musicId: string | null | undefined) => allMusics.find((music) => music.id === musicId),
    [allMusics]
  );
};

type PlayerState =
  | { id: null; status: 'STOPPED'; position: 0 }
  | { id: string; status: 'PLAYING' | 'PAUSED'; position: number };
type PlayerAction = { type: 'PAUSE' | 'STOP' } | { type: 'PLAY'; value: string } | { type: 'MOVE'; value: number };

const INITIAL_PLAYER_STATE: PlayerState = { id: null, status: 'STOPPED', position: 0 };

function playerReducer(state: PlayerState, action: PlayerAction) {
  switch (action.type) {
    case 'PLAY': {
      return { id: action.value, status: 'PLAYING', position: state.position } as PlayerState;
    }
    case 'PAUSE': {
      return { id: state.id, status: 'PAUSED', position: state.position } as PlayerState;
    }
    case 'MOVE': {
      return { id: state.id, status: state.status, position: action.value } as PlayerState;
    }
    case 'STOP': {
      return { id: null, status: 'STOPPED', position: 0 } as PlayerState;
    }
    default:
      return state;
  }
}

export const usePlayer = () => {
  const [playback, dispatch] = useReducer(playerReducer, INITIAL_PLAYER_STATE);
  const findMusic = useFindMusic();
  const tracking = useWebManagerTracking();
  const music = findMusic(playback.id);

  // Some `MusicAsset` do not have a duration specific on non-production
  // environments, so we fall back to an arbitrary duration so the interface
  // keeps working.
  const duration = music?.musicAsset?.duration ?? 90;
  const seconds = playback.position / 1000;
  const progress = (seconds / duration) * 100;

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset the progress bar on music change
  useEffect(() => {
    dispatch({ type: 'MOVE', value: 0 });
  }, [playback.id]);

  const trackMusicPlayed = useCallback(
    (newPlaybackId: string) =>
      tracking.trackEvent({
        event: 'musicPlayed',
        details: { musicTitle: findMusic(newPlaybackId)?.name },
      }),
    [findMusic, tracking]
  );

  const play = useCallback(
    (newPlaybackId: string) => {
      dispatch({ type: 'PLAY', value: newPlaybackId });
      trackMusicPlayed(newPlaybackId);
    },
    [trackMusicPlayed]
  );

  const pause = useCallback(() => {
    dispatch({ type: 'PAUSE' });
  }, []);

  const move = useCallback(
    (percentile: number) => {
      dispatch({ type: 'MOVE', value: ((duration * percentile) / 100) * 1000 });
    },
    [duration]
  );

  const onPlaying = useCallback(({ position }: { position: number; duration: number }) => {
    dispatch({ type: 'MOVE', value: position });
  }, []);

  const onFinishedPlaying = useCallback(() => {
    dispatch({ type: 'MOVE', value: 0 });
  }, []);

  return useMemo(
    () => ({ playback, music, progress, play, pause, move, onPlaying, onFinishedPlaying }),
    [playback, music, progress, play, pause, move, onPlaying, onFinishedPlaying]
  );
};

export const useSelectVolume = (project: Project) => {
  const [updateProject, { loading }] = useUpdateProject({
    refetchQueries: ['Project'],
    awaitRefetchQueries: true,
  });
  const onSubmit = useCallback(
    (musicLoudness: number) => updateProject(project.id, { musicLoudness }),
    [project.id, updateProject]
  );

  return useMemo(() => ({ selectVolume: onSubmit, loading }), [onSubmit, loading]);
};

export const useSelectMusic = (project: Project) => {
  // Updating the music has an impact on the preview and therefore should cause
  // a refetch of the render description.
  const [updateProject, { loading }] = useUpdateProject({
    refetchQueries: ['Project', 'ProjectRenderDescription'],
  });
  const onSubmit = useCallback(
    (musicId: string | null) => updateProject(project.id, { musicId }),
    [project.id, updateProject]
  );
  const tracking = useWebManagerTracking();
  const findMusic = useFindMusic();
  const { musicId: currentMusicId, musicName: currentMusicName } = useProjectMusicDetails(project);

  const trackMusicSelected = useCallback(
    (musicId: string, wasSelected: boolean) =>
      tracking.trackEvent({
        event: wasSelected ? 'MusicDeselected' : 'MusicSelected',
        details: {
          projectId: project.id,
          musicTitle: wasSelected ? currentMusicName : findMusic(musicId)?.name,
        },
      }),
    [findMusic, project.id, currentMusicName, tracking]
  );

  const toggleMusic = useCallback(
    async (musicId: string) => {
      const wasSelected = currentMusicId === musicId;
      await onSubmit(wasSelected ? null : musicId);
      trackMusicSelected(musicId, wasSelected);
    },
    [currentMusicId, onSubmit, trackMusicSelected]
  );

  return useMemo(() => ({ toggleMusic, loading }), [toggleMusic, loading]);
};
