import { useCallback } from 'react';

import { type CopyScenesToProjectMutationVariables, useCopyScenesToProjectMutation } from '../../generated';

export const useCopyScenesToProject = ({ awaitRefetchQueries = false, refetchRenderDescription = false } = {}) => {
  const [copySceneToProject] = useCopyScenesToProjectMutation({
    refetchQueries: refetchRenderDescription ? ['Scenes', 'ProjectRenderDescription'] : ['Scenes'],
    awaitRefetchQueries,
    update: (cache) => {
      /**
       * @see useUpdateSceneVideo.ts
       */
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
  });
  return useCallback(
    (
      sceneIds: CopyScenesToProjectMutationVariables['sceneIds'],
      projectId: CopyScenesToProjectMutationVariables['projectId'],
      index?: CopyScenesToProjectMutationVariables['index']
    ) => {
      return copySceneToProject({ variables: { sceneIds, projectId, index } });
    },
    [copySceneToProject]
  );
};
