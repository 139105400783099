import { styled } from '@mui/material';
import type { ComponentProps, FC, ReactElement, ReactNode } from 'react';

import type { BrandColor } from '../../../theming/theme/palette';
import { Icon, type IconType } from '../../assets/icons/Icon';
import { Box } from '../../layout/Box';
import { Spacing } from '../../layout/Spacing';
import { Text } from '../../typography/Text';
import { Typography, type TypographyProps } from '../../typography/Typography';

type EmptyStateProps = ComponentProps<'div'> & {
  iconType?: IconType;
  icon?: ReactElement;
  title: string;
  titleProps?: TypographyProps;
  description?: ReactNode;
  cta?: ReactNode;
  backgroundColor?: BrandColor;
};

// 1. The dimensions are necessary for Safari to constrain the content within
//    the ratio box. `height` should not be set to preserve scroll.
const Container = styled('div')(({ theme }) => ({
  textAlign: 'center',
  aspectRatio: '1 / 1',
  width: '100%', // 1

  [theme.breakpoints.up('md')]: {
    aspectRatio: '16 / 9',
  },
}));

export const EmptyState: FC<EmptyStateProps> = ({
  icon,
  iconType,
  title,
  description,
  cta,
  backgroundColor = 'linen50',
  titleProps,
  ...rest
}) => (
  <Container {...rest}>
    <Box fullHeight backgroundColor={backgroundColor} padding={2} gap={1}>
      {icon || (iconType && <Icon type={iconType} size="l" background={{ size: 'xxl', color: 'linen' }} />)}

      <Typography color="carbon" variant="h4" component="p" {...titleProps}>
        {title}
      </Typography>

      {description && (
        <Text color="grey700" variant="l" component="p">
          {description}
        </Text>
      )}

      {cta && <Spacing top={3}>{cta}</Spacing>}
    </Box>
  </Container>
);
