import type { FC } from 'react';

import type { Team } from '../../../api/hooks/team/useTeam';
import { RoleRequests } from '../../../components/roleRequests';
import { TeamMembersList } from '../../../components/team/TeamMembersList';
import { CurrentTeamProvider } from '../../../contexts/currentTeam/CurrentTeamProvider';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';

type TeamMembersContentProps = {
  team: Team;
};

export const TeamMembersContent: FC<TeamMembersContentProps> = ({ team }) => {
  return (
    <CurrentTeamProvider team={team}>
      <TeamMembersList />
      <TeamPermissionRestriction has="MemberUpdate">
        <RoleRequests teamId={team.id} />
      </TeamPermissionRestriction>
    </CurrentTeamProvider>
  );
};
