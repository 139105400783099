import { styled } from '@mui/material';
import type { FC } from 'react';

import type { BrandColor } from '../../../theming/theme';
import { formatDateTime } from '../../../utilities/formatDateTime';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Tooltip } from '../../feedback/Tooltip';
import { Text } from '../../typography/Text';

const Label = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const Circle = styled(
  'div',
  preventForwardProps(['color'])
)<{ color: BrandColor }>(({ theme, color }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.brand[color],
}));

type UserStatusProps = {
  createdAt: string;
  deactivatedAt?: string | null;
  isPending: boolean;
};

export const UserStatus: FC<UserStatusProps> = ({ deactivatedAt, createdAt, isPending }) => {
  if (deactivatedAt) {
    return (
      <Tooltip title="i18n.global.user.status.deactivated.tooltip">
        <Label data-testid="user-status-deactivated">
          <Circle color="negative" />
          <Text>i18n.global.user.status.deactivated</Text>
        </Label>
      </Tooltip>
    );
  }

  if (isPending) {
    return (
      <Tooltip title="i18n.global.user.status.pending.tooltip" i18nParams={{ invitedAt: formatDateTime(createdAt) }}>
        <Label data-testid="user-status-pending">
          <Circle color="warning" />
          <Text>i18n.global.user.status.pending</Text>
        </Label>
      </Tooltip>
    );
  }

  return (
    <Label data-testid="user-status-active">
      <Circle color="positive" />
      <Text>i18n.global.user.status.active</Text>
    </Label>
  );
};
