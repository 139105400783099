import { useCallback, useMemo } from 'react';

import { type MoveScenesToNewProjectMutationVariables, useMoveScenesToNewProjectMutation } from '../../generated';

export const useMoveScenesToNewProject = () => {
  const [moveScenesToNewProjectMutation, metadata] = useMoveScenesToNewProjectMutation({
    update: (cache) => {
      /**
       * @see useUpdateSceneVideo.ts
       */
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
    refetchQueries: ['Scenes'],
    awaitRefetchQueries: true,
  });
  const moveScenesToNewProject = useCallback(
    (input: MoveScenesToNewProjectMutationVariables['input']) => {
      return moveScenesToNewProjectMutation({ variables: { input } });
    },
    [moveScenesToNewProjectMutation]
  );

  return useMemo(() => [moveScenesToNewProject, metadata] as const, [moveScenesToNewProject, metadata]);
};
