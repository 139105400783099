import { useCallback } from 'react';

import { useResolveRoleRequestMutation } from '../../generated';

export const useResolveRoleRequest = (id: string, approve: boolean) => {
  const [resolveRoleRequest] = useResolveRoleRequestMutation({
    variables: {
      id,
      approve,
    },
    refetchQueries: ['Team', 'Me', 'RoleRequests'],
  });
  return useCallback(() => resolveRoleRequest(), [resolveRoleRequest]);
};
