import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoResetSent = () => {
  const goto = useGoto();
  return useCallback(() => goto(routes.resetSent), [goto]);
};
