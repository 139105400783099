import { useCallback, useMemo, useState } from 'react';

import { useCreateSharingLink } from '../../../api/hooks/sharingLink/useCreateSharingLink';
import { useDeleteSharingLink } from '../../../api/hooks/sharingLink/useDeleteSharingLink';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import type { Project } from '../ProjectVideoContent';

export const useSharingLink = (project: Project) => {
  const [sharingEnabled, setSharingEnabled] = useState(!!project.sharingLink);
  const createSharingLink = useCreateSharingLink();
  const deleteSharingLink = useDeleteSharingLink();
  const tracking = useWebManagerTracking();

  const enableSharingLink = useCallback(async () => {
    setSharingEnabled(true);
    await createSharingLink(project.id);
    tracking.trackEvent({ event: 'videoLinkEnabled' });
  }, [createSharingLink, project.id, tracking]);

  const disableSharingLink = useCallback(async () => {
    if (!project.sharingLink) return;
    setSharingEnabled(false);
    await deleteSharingLink(project.sharingLink.id);
    tracking.trackEvent({ event: 'videoLinkDisabled' });
  }, [deleteSharingLink, project.sharingLink, tracking]);

  return useMemo(
    () => ({
      enableSharingLink,
      disableSharingLink,
      sharingEnabled,
    }),
    [enableSharingLink, disableSharingLink, sharingEnabled]
  );
};
