import type { FC, PropsWithChildren } from 'react';

import { AriaDisabledButton, Button, type ButtonProps, Tooltip } from '@cofenster/web-components';

import { RequestPermissionButton } from '../../../components/requestPermissions/RequestPermissionButton';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../../contexts/user/useUser';
import { useStartRenderButtonProps } from './useStartRenderButtonProps';

export const StartRenderButton: FC<
  PropsWithChildren<ButtonProps & { projectId: string; subtitlesLanguage: string | null }>
> = ({ children, projectId, subtitlesLanguage, ...rest }) => {
  const { user } = useUser();
  const isAllowedToRenderProject = useTeamPermissionStatus({ has: 'ProjectRender' }) === 'ALLOWED';
  const renderButtonProps = useStartRenderButtonProps(projectId, subtitlesLanguage);

  if (!isAllowedToRenderProject) {
    return user ? <RequestPermissionButton user={user} permission="ProjectRender" /> : null;
  }

  const { disabled, label, i18nParams, ...restRenderButtonProps } = renderButtonProps;

  if (disabled) {
    return (
      <Tooltip title={label} i18nParams={i18nParams}>
        <AriaDisabledButton {...restRenderButtonProps}>{children}</AriaDisabledButton>
      </Tooltip>
    );
  }

  return (
    <Button {...rest} {...restRenderButtonProps}>
      {children}
    </Button>
  );
};
