import { useMemo } from 'react';

import { type CanRenderProjectQueryVariables, useCanRenderProjectQuery } from '../../generated';

export const useCanRenderProject = (projectId: CanRenderProjectQueryVariables['id']) => {
  const { loading, error, data, refetch } = useCanRenderProjectQuery({
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
  });
  const canRenderProject = !!data?.canRenderProject;

  return useMemo(() => ({ canRenderProject, loading, error, refetch }), [canRenderProject, loading, error, refetch]);
};
