import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import { type ProjectQueryResult, type ProjectQueryVariables, useProjectQuery } from '../../generated';

export type Project = NonNullable<NonNullable<ProjectQueryResult['data']>['project']>;
export type Template = NonNullable<Project['template']>;

export const useProject = (projectId: ProjectQueryVariables['id']) => {
  const { loading, error, data, startPolling, stopPolling, refetch } = useProjectQuery({
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
    skipPollAttempt: () => document.hidden,
  });
  const project = data?.project ?? undefined;

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({ project, loading, error, startPolling, stopPolling, refetch }),
    [project, loading, error, startPolling, stopPolling, refetch]
  );
};
