import type { FC } from 'react';

import { usePlayVideoOnHover } from '../../../hooks/media/usePlayVideoOnHover';
import { NativeVideoPlayer, type NativeVideoPlayerProps } from '../NativeVideoPlayer';

export const VideoAssetPreview: FC<NativeVideoPlayerProps> = (props) => {
  const handler = usePlayVideoOnHover();

  return <NativeVideoPlayer {...props} {...handler} controls={false} loop muted />;
};
