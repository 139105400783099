import type { FC } from 'react';

import type { Scene, VideoSceneAsset } from '../../../../../api/hooks/scene/useScenes';
import { useEditorPlayer } from '../../../../../contexts/editorPlayer/useEditorPlayer';
import type { Project } from '../../../../../contexts/project/useProject';

import { canPlayVideoAsset } from '../../../../../helpers/sceneAssets/canPlayVideoAsset';
import { SceneVideoControls } from './SceneVideoControls';

export const EditorToolsSceneVideo: FC<{
  project: Project;
  scene: Scene;
  videoSceneAsset?: VideoSceneAsset;
}> = ({ project, scene, videoSceneAsset }) => {
  const { hasPreviewLoadingError } = useEditorPlayer();

  const videoAsset = videoSceneAsset?.asset?.videoAsset;
  const videoUrl = videoAsset?.videoUrl;
  const canPlayAsset = !!videoAsset && canPlayVideoAsset(videoAsset);
  const withDisabledControls = (videoAsset && !canPlayAsset) || hasPreviewLoadingError || Boolean(project.archivedAt);

  return (
    <SceneVideoControls
      project={project}
      scene={scene}
      isEmpty={!videoUrl}
      withDisabledControls={withDisabledControls}
    />
  );
};
