import { type FC, useCallback } from 'react';

import { BlankButton, Icon, LoadingSpinner, Text, preventForwardProps, styled } from '@cofenster/web-components';

import { useCreateContributionRequest } from '../../../api/hooks/contributionRequest/useCreateContributionRequest';
import { useTrackContributionRequest } from '../hooks/useTrackContributionRequest';

const Container = styled(
  BlankButton,
  preventForwardProps(['isTight'])
)<{ isTight?: boolean }>(({ theme, isTight }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: isTight ? 'column' : 'row',
  gap: theme.spacing(1.5),
  height: 152,
  width: '100%',
  backgroundColor: theme.palette.brand.linen50,
  borderRadius: theme.shape['borderRadius-l'],

  '&:hover, &:focus': {
    color: theme.palette.brand.blue,
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

export const AddContributionRequestCard: FC<{
  projectId: string;
}> = ({ projectId }) => {
  const { createContributionRequest, loading } = useCreateContributionRequest(['ContributionRequestsByProject']);
  const track = useTrackContributionRequest('requestCreated');

  const onClick = useCallback(async () => {
    const result = await createContributionRequest(projectId, [{ type: 'VIDEO' }]);
    if (result.data?.createContributionRequest) {
      result.data.createContributionRequest.map((request) => track(request));
    }
  }, [createContributionRequest, projectId, track]);

  if (loading) return <LoadingSpinner />;

  return (
    <Container onClick={onClick} data-testid="add-contribution-request" type="button">
      <Icon type="PlusIcon" background={{ size: 'l', color: 'linen' }} />
      <Text variant={'xl'}>i18n.projectContribution.AddContributionRequestCard.text</Text>
    </Container>
  );
};
