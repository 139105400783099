import { useMemo } from 'react';

import {
  type CuratedStockImagesQuery,
  type CuratedStockImagesQueryVariables,
  useCuratedStockImagesQuery,
} from '../../generated';

export type StockImage = NonNullable<CuratedStockImagesQuery['curatedStockImages']>['items'][number];
export type StockImageResponse = NonNullable<CuratedStockImagesQuery['curatedStockImages']>;
export type Attributor = NonNullable<StockImage['attributor']>;
export type Filter = NonNullable<CuratedStockImagesQueryVariables['filter']>;

export const useCuratedStockImages = (filter: Filter, skip = false) => {
  const { loading, error, data } = useCuratedStockImagesQuery({
    variables: { filter },
    skip,
  });
  const curatedStockImages = data?.curatedStockImages || undefined;

  return useMemo(() => ({ curatedStockImages, loading, error }), [curatedStockImages, loading, error]);
};
