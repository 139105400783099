import { type FC, type PropsWithChildren, useCallback } from 'react';

import {
  Button,
  FormSubmitButton,
  Icon,
  type IconType,
  styled,
  useI18n,
  useSnackbars,
} from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useTrackContributionRequestList } from '../../../../pages/ProjectContributionConfiguration/hooks/useTrackContributionRequestList';
import { ContributionRequestShareSnackbarText } from '../ContributionRequestShareSnackbarText';

const PushedRightButton = styled(FormSubmitButton)(() => ({
  marginLeft: 'auto',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridTemplateRows: 'repeat(2, auto)',
    flexDirection: 'column',
  },
}));

export const ContributionRequestShareButtons: FC<{
  contributionRequestList: ContributionRequestList;
  loading: boolean;
}> = ({ contributionRequestList, loading }) => {
  const track = useTrackContributionRequestList();

  const trackCopyToClipboard = useCallback(
    (type: 'code' | 'link' | 'qrCode') => {
      if (type === 'code') {
        track('requestListAppCodeCopied', contributionRequestList);
      } else if (type === 'link') {
        track('requestListLinkCopied', contributionRequestList);
      } else if (type === 'qrCode') {
        track('requestListQRDownloaded', contributionRequestList);
      }
    },
    [track, contributionRequestList]
  );

  return (
    <ButtonsContainer>
      <ClipboardCopyOrDownloadLink
        icon="QRcodeIcon"
        listName={contributionRequestList.name}
        isDefaultList={contributionRequestList.default}
        qrcode={contributionRequestList.qrCode}
        onCopyToClipboard={trackCopyToClipboard}
        data-testid="share-menu-download-qr-code-button"
      >
        i18n.projectContributions.ContributionRequestListDialog.share.qrCode
      </ClipboardCopyOrDownloadLink>
      <ClipboardCopyOrDownloadLink
        icon="DeviceMobileSpeakerIcon"
        listName={contributionRequestList.name}
        isDefaultList={contributionRequestList.default}
        code={contributionRequestList.code}
        onCopyToClipboard={trackCopyToClipboard}
        data-testid="share-menu-copy-code-button"
      >
        i18n.projectContributions.ContributionRequestListDialog.share.appCode
      </ClipboardCopyOrDownloadLink>
      <ClipboardCopyOrDownloadLink
        icon="LinkIcon"
        listName={contributionRequestList.name}
        isDefaultList={contributionRequestList.default}
        link={contributionRequestList.link}
        onCopyToClipboard={trackCopyToClipboard}
        data-testid="share-menu-copy-link-button"
      >
        i18n.projectContributions.ContributionRequestListDialog.share.copyLink
      </ClipboardCopyOrDownloadLink>
      <PushedRightButton type="submit" loading={loading}>
        i18n.projectContributions.ContributionRequestListDialog.share.sendEmails
      </PushedRightButton>
    </ButtonsContainer>
  );
};

type ClipboardCopyOrDownloadLinkProps = {
  icon: IconType;
  link?: string;
  code?: string;
  qrcode?: string;
  listName: string;
  isDefaultList: boolean;
  onCopyToClipboard?: (type: 'code' | 'link' | 'qrCode') => unknown;
  'data-testid'?: string;
};

const ClipboardCopyOrDownloadLink: FC<PropsWithChildren<ClipboardCopyOrDownloadLinkProps>> = ({
  children,
  icon,
  link,
  code,
  qrcode,
  listName,
  isDefaultList,
  onCopyToClipboard,
  'data-testid': dataTestId,
}) => {
  const { openSnackbar } = useSnackbars();
  const { translate } = useI18n();

  const copyCodeToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(code || link || '');
    if (code) onCopyToClipboard?.('code');
    else if (link) onCopyToClipboard?.('link');
    else onCopyToClipboard?.('qrCode');

    if (qrcode) return;
    openSnackbar({
      variant: 'info',
      children: (
        <ContributionRequestShareSnackbarText
          icon={link ? 'LinkIcon' : 'DeviceMobileSpeakerIcon'}
          i18nParams={{
            for: isDefaultList
              ? translate('projectContributions.ContributionRequestListDialog.share.defaultList')
              : listName,
          }}
        >
          {link
            ? 'i18n.projectContributions.ContributionRequestListDialog.share.linkCopiedSnackbar'
            : 'i18n.projectContributions.ContributionRequestListDialog.share.codeCopiedSnackbar'}
        </ContributionRequestShareSnackbarText>
      ),
    });
  }, [isDefaultList, listName, translate, code, link, onCopyToClipboard, qrcode, openSnackbar]);

  return (
    <Button
      variant="tertiary"
      onClick={copyCodeToClipboard}
      href={qrcode}
      download={qrcode ? `qr_code-${listName}.png` : undefined}
      type={qrcode ? undefined : 'button'}
      data-testid={dataTestId}
      startIcon={<Icon type={icon} />}
    >
      {children}
    </Button>
  );
};
