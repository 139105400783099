import { useMemo } from 'react';

import {
  type DeleteContributionRequestFromListMutationVariables,
  useDeleteContributionRequestFromListMutation,
} from '../../generated';

export const useDeleteContributionRequestFromList = ({
  contributionRequestId,
  contributionRequestListId,
}: DeleteContributionRequestFromListMutationVariables['input']) => {
  const [deleteContributionRequestFromList, { loading, error }] = useDeleteContributionRequestFromListMutation({
    variables: { input: { contributionRequestId, contributionRequestListId } },
    refetchQueries: ['ContributionRequestsByProject'],
  });

  return useMemo(
    () => ({
      deleteContributionRequestFromList,
      loading,
      error,
    }),
    [deleteContributionRequestFromList, loading, error]
  );
};
