import { Icon, type IconType, Translatable, styled } from '@cofenster/web-components';
import type { FC, PropsWithChildren } from 'react';

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const SelectSelectedValue: FC<PropsWithChildren<{ iconType: IconType }>> = ({ iconType, children }) => {
  return (
    <Row>
      <Icon type={iconType} />
      <Translatable>{children}</Translatable>
    </Row>
  );
};
