import { type FC, useMemo } from 'react';

import { Button, Icon } from '@cofenster/web-components';

import { useContributionRequestListByProject } from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';

export const usePreviewButtonHref = (projectId: string) => {
  const { contributionRequestLists } = useContributionRequestListByProject(projectId);
  return useMemo(() => {
    const defaultList = contributionRequestLists.find((list) => list.default);
    return defaultList?.link;
  }, [contributionRequestLists]);
};

export const PreviewButton: FC<{ projectId: string }> = ({ projectId }) => {
  const href = usePreviewButtonHref(projectId);

  if (!href) return null;

  return (
    <Button
      href={href}
      target="_blank"
      variant="tertiary"
      startIcon={<Icon type="EyeIcon" />}
      type={null}
      data-testid="contribution-request-default-list-preview-button"
    >
      i18n.projectContributionConfiguration.preview
    </Button>
  );
};
