import { useContext } from 'react';

import { BulkSelectionContext } from './BulkSelectionContext';

export const useBulkSelection = (failOnMissingContext = true) => {
  const context = useContext(BulkSelectionContext);

  if (!context && failOnMissingContext) {
    throw new Error('BulkSelectionContext missing in tree');
  }

  return context;
};
