import { type FC, useContext } from 'react';

import { Chip, VideoAssetPreview, formatDuration, styled } from '@cofenster/web-components';

import type { StockImage, StockVideo } from '../../../api/generated';

import { SearchContext } from './SearchContext';

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
  display: 'block',
  width: '100%',
  maxWidth: '100%',
}));

const ImageResultPreview: FC<{ asset: StockImage }> = ({ asset }) => (
  <Image src={asset.thumbnailUrl} alt={asset.alt ?? ''} />
);

const VideoResultPreview: FC<{ asset: StockVideo }> = ({ asset }) => (
  <VideoAssetPreview src={asset.thumbnailVideoUrl} poster={asset.posterUrl} crossOrigin="anonymous" />
);

const VideoContainer = styled('div')(() => ({ position: 'relative' }));
const Duration = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1.5),
}));

export const ResultPreview: FC<{ asset: StockImage | StockVideo }> = ({ asset }) => {
  const { type } = useContext(SearchContext);

  return type === 'image' ? (
    <ImageResultPreview asset={asset as StockImage} />
  ) : (
    <VideoContainer>
      <VideoResultPreview asset={asset as StockVideo} />
      <Duration color="dark" size="small">
        {formatDuration((asset as StockVideo).duration)}
      </Duration>
    </VideoContainer>
  );
};
