import type { FC } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  Typography,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import type { Team as ProjectFolderTeam } from '../../../api/hooks/projectFolder/useProjectFolder';
import type { Team } from '../../../api/hooks/team/useTeam';
import type { User } from '../../../contexts/user/useUser';

type RequestPermissionDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  requestRole: VoidFunction;
  user: User;
  team?: Team | ProjectFolderTeam;
};

const scopeToTranslation = {
  account: 'i18n.dialogs.requestPermission.message.headline.account',
  team: 'i18n.dialogs.requestPermission.message.headline.team',
};

export const RequestPermissionDialog: FC<RequestPermissionDialogProps> = ({
  isOpen,
  closeDialog,
  requestRole,
  user,
  team,
}) => {
  const scope = team && !team.public ? 'team' : 'account';
  const learnMoreUrl = useLocalizedWebsiteUrl('ROLES_INFO');

  const role =
    team && !team.public ? user?.roles.team.find(({ teamId }) => teamId === team?.id)?.role : user?.roles.account;

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.requestPermission.title">
      <DialogContent>
        <Typography component="p" i18nParams={{ roleName: role?.name }} variant="l">
          {scopeToTranslation[scope]}
        </Typography>
        <Typography component="p" variant="l">
          i18n.dialogs.requestPermission.message.subline
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          component="a"
          target="_blank"
          variant="tertiary"
          href={learnMoreUrl}
          startIcon={<Icon type="ArrowSquareOutIcon" />}
        >
          i18n.dialogs.requestPermission.learnMore
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            requestRole();
            closeDialog();
          }}
        >
          i18n.dialogs.requestPermission.requestPermissionButton
        </Button>
      </DialogActions>
    </Dialog>
  );
};
