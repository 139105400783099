import { styled } from '@mui/material';
import type { ComponentProps, PropsWithChildren } from 'react';

import { ProfileCircle } from '../../../display/ProfileCircle';
import { Typography } from '../../../typography/Typography';

import { BaseButton, type BaseButtonProps } from './BaseButton';

const Button = styled(BaseButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),
  alignItems: 'center',
}));

export type ProfileButtonProps = {
  name: string;
} & BaseButtonProps &
  ComponentProps<'button'>;

export const ProfileButton = ({ children, name, ...rest }: PropsWithChildren<ProfileButtonProps>) => {
  return (
    <Button {...rest} data-testid="profile-button">
      <ProfileCircle size="small" color="light" aria-hidden="true">
        {name}
      </ProfileCircle>

      <Typography variant="l">{children}</Typography>
    </Button>
  );
};
