import { type FC, type MouseEventHandler, useCallback, useMemo } from 'react';

import { BlankButton, Checkbox, GridContainer, GridItem, IconButton } from '@cofenster/web-components';

import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useAddContributionRequestToList } from '../../../../api/hooks/contributionRequestList/useAddContributionRequestToList';
import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useDeleteContributionRequestFromList } from '../../../../api/hooks/contributionRequestList/useDeleteContributionRequestFromList';
import { useDeleteContributionRequestList } from '../../../../api/hooks/contributionRequestList/useDeleteContributionRequestList';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';

type ContributionRequestRowProps = {
  contributionRequest: ContributionRequest;
  contributionRequestList: ContributionRequestList;
};

export const ContributionRequestRow: FC<ContributionRequestRowProps> = ({
  contributionRequest,
  contributionRequestList,
}) => {
  const { deleteContributionRequestList, loading } = useDeleteContributionRequestList();
  const addContributionRequestToList = useAddContributionRequestToList();
  const { deleteContributionRequestFromList } = useDeleteContributionRequestFromList({
    contributionRequestListId: contributionRequestList.id,
    contributionRequestId: contributionRequest.id,
  });
  const { openDialog } = useDialogs();

  const checked = useMemo(() => {
    return contributionRequest.contributionRequestLists.some((it) => it.id === contributionRequestList.id);
  }, [contributionRequest, contributionRequestList]);

  const onChange = useCallback(async () => {
    if (checked) {
      return deleteContributionRequestFromList();
    }
    return addContributionRequestToList({
      contributionRequestListId: contributionRequestList.id,
      contributionRequestId: contributionRequest.id,
    });
  }, [
    checked,
    contributionRequest,
    contributionRequestList,
    addContributionRequestToList,
    deleteContributionRequestFromList,
  ]);

  const deleteList: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog('DeleteContributionRequestListDialog', {
        name: contributionRequestList.name,
        onAction: () => deleteContributionRequestList(contributionRequestList.id),
      });
    },
    [contributionRequestList.id, contributionRequestList.name, deleteContributionRequestList, openDialog]
  );

  return (
    <BlankButton style={{ width: '100%' }} onClick={onChange}>
      <GridContainer spacing={1} alignItems="center">
        <GridItem
          xs
          display="flex"
          gap={2}
          alignItems="center"
          data-testid={`contribution-request-list-${contributionRequestList.name}`}
        >
          <Checkbox checked={checked} />
          {contributionRequestList.name}
        </GridItem>
        <GridItem>
          <IconButton
            icon="TrashIcon"
            loading={loading}
            iconSize="s"
            iconColor="carbon"
            onClick={deleteList}
            label="i18n.global.delete"
            data-testid={`contribution-request-list-${contributionRequestList.name}-delete`}
          />
        </GridItem>
      </GridContainer>
    </BlankButton>
  );
};
