import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { BulkSelector, Button, IconButton, Text, styled, useAnimount, useSnackbars } from '@cofenster/web-components';

import { useArchiveProjects } from '../../../api/hooks/project/useArchiveProjects';
import type { Project } from '../../../api/hooks/project/useProjects';
import { useUnarchiveProjects } from '../../../api/hooks/project/useUnarchiveProjects';
import { useBulkSelection } from '../../../contexts/bulkSelection/useBulkSelection';
import { useCurrentTeam } from '../../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProjectFolder } from '../../../contexts/projectFolder/useProjectFolder';
import { TeamPermissionRestriction, useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import type { ProjectFolderRouteParams } from '../../../routes';

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const useArchive = (ids: string[]) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const archiveProjects = useArchiveProjects();
  const { deselectAll } = useBulkSelection();

  const trackProjectsArchived = useCallback(
    () =>
      tracking.trackEvent({
        event: 'projectsArchived',
        details: {
          source: 'bulkSelector',
          projectIds: ids,
        },
      }),
    [tracking, ids]
  );

  const giveFeedback = useCallback(
    () =>
      openSnackbar({
        children: 'i18n.snackbars.projectsArchivedSnackbar.copy',
        i18nParams: { count: ids.length },
      }),
    [openSnackbar, ids.length]
  );

  const archive = useCallback(async () => {
    await archiveProjects(ids);
    giveFeedback();
    trackProjectsArchived();
    deselectAll();
  }, [archiveProjects, ids, giveFeedback, trackProjectsArchived, deselectAll]);
  const confirmArchiveProjects = useConfirmDialog({
    title: 'i18n.dialogs.ConfirmBulkArchiveDialog.headline',
    content: 'i18n.dialogs.ConfirmBulkArchiveDialog.text',
    confirm: 'i18n.dialogs.ConfirmBulkArchiveDialog.button',
    variant: 'primary',
  });

  return useCallback(async () => {
    if (await confirmArchiveProjects()) {
      await archive();
    }
  }, [confirmArchiveProjects, archive]);
};

const useUnarchive = (ids: string[]) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();
  const unarchiveProjects = useUnarchiveProjects();
  const { deselectAll } = useBulkSelection();
  return useCallback(async () => {
    await unarchiveProjects(ids);
    openSnackbar({
      children: 'i18n.snackbars.projectsUnarchivedSnackbar.copy',
      i18nParams: { count: ids.length },
    });
    deselectAll();
    tracking.trackEvent({
      event: 'projectsUnarchived',
      details: {
        source: 'bulkSelector',
        projectIds: ids,
      },
    });
  }, [ids, unarchiveProjects, deselectAll, openSnackbar, tracking]);
};

const useMoveProjects = (projectIds: string[], fromProjectFolderId: string, archived: boolean) => {
  const { openDialog } = useDialogs();
  const { projectFolder } = useProjectFolder();
  const rootProjectFolderId = projectFolder.parentFolder?.id ?? projectFolder.id;
  const privateRootFolderId = projectFolder.team ? undefined : rootProjectFolderId;

  return useCallback(() => {
    openDialog('MoveProjectsDialog', {
      projectIds,
      fromProjectFolderId,
      rootProjectFolderId,
      privateRootFolderId,
      status: archived ? 'ARCHIVED' : 'ACTIVE',
      trackingSource: 'bulkSelector',
    });
  }, [openDialog, projectIds, fromProjectFolderId, archived, rootProjectFolderId, privateRootFolderId]);
};

const useDeleteProjects = (projectIds: string[], publishedProjectIds: string[], teamId?: string) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('DeleteProjectsDialog', { projectIds, publishedProjectIds, trackingSource: 'bulkSelector', teamId });
  }, [openDialog, projectIds, publishedProjectIds, teamId]);
};

type Props = {
  projects: Project[];
};

export const ProjectBulkSelector: FC<Props> = ({ projects }) => {
  const params = useParams() as ProjectFolderRouteParams;
  const { setSelectableProjects, selection, deselectAll, selectProjects } = useBulkSelection();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const animountStatus = useAnimount(isSelectorOpen, 300);
  const { team } = useCurrentTeam();

  const folderId = params.folderId;
  const selectedProjectIds = selection.projects;
  const selectedProjectsCount = selectedProjectIds.length;
  const projectsAreArchived = !!projects[0]?.archivedAt;
  const selectedPublishedProjectIds = useMemo(
    () =>
      selectedProjectIds
        .map((id) => projects.find((project) => project.id === id))
        .filter((project) => project !== undefined)
        .filter((project) => project.sharingLink)
        .map((project) => project.id),
    [selectedProjectIds, projects]
  );

  useEffect(() => {
    setSelectableProjects(projects.map(({ id }) => id));
  }, [projects, setSelectableProjects]);

  useEffect(() => {
    return () => {
      setSelectableProjects([]);
    };
  }, [setSelectableProjects]);

  useEffect(() => {
    selectedProjectsCount ? setIsSelectorOpen(true) : setIsSelectorOpen(false);
  }, [selectedProjectsCount]);

  const selectAllProjects = useCallback(() => {
    selectProjects(projects.map((project) => project.id));
  }, [projects, selectProjects]);

  const archiveProjects = useArchive(selectedProjectIds);
  const unarchiveProjects = useUnarchive(selectedProjectIds);
  const moveProjects = useMoveProjects(selectedProjectIds, folderId, projectsAreArchived);
  const deleteProjects = useDeleteProjects(selectedProjectIds, selectedPublishedProjectIds, team?.id);

  const isAllowedToUpdate = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const isAllowedToDelete = useTeamPermissionStatus({ has: 'ProjectDelete' }) === 'ALLOWED';

  if (animountStatus === 'EXITED' || (!isAllowedToUpdate && !isAllowedToDelete)) {
    return null;
  }

  return (
    <BulkSelector
      status={animountStatus}
      left={
        <Text variant="l" i18nParams={{ count: selectedProjectsCount }}>
          i18n.projects.bulkSelector.projects
        </Text>
      }
      center={
        <Buttons>
          <TeamPermissionRestriction has="ProjectUpdate">
            <IconButton
              icon="ArrowBoxedRightIcon"
              iconColor="white"
              iconSize="m"
              hoverColor="grey200"
              onClick={moveProjects}
              label="i18n.common.move"
            />
            <IconButton
              icon={projectsAreArchived ? 'UndoIcon' : 'ArchiveIcon'}
              iconColor="white"
              iconSize="m"
              hoverColor="grey200"
              onClick={projectsAreArchived ? unarchiveProjects : archiveProjects}
              label={projectsAreArchived ? 'i18n.common.unarchive' : 'i18n.common.archive'}
            />
          </TeamPermissionRestriction>
          <TeamPermissionRestriction has="ProjectDelete">
            <IconButton
              icon="TrashIcon"
              iconColor="white"
              iconSize="m"
              hoverColor="negative"
              onClick={deleteProjects}
              label="i18n.global.delete"
            />
          </TeamPermissionRestriction>
        </Buttons>
      }
      right={
        <Buttons>
          <Button variant="tertiary" onClick={deselectAll}>
            i18n.global.cancel
          </Button>
          <Button variant="secondary" onClick={selectAllProjects}>
            i18n.projects.bulkSelector.selectAll
          </Button>
        </Buttons>
      }
    />
  );
};
