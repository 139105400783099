import type { FC } from 'react';

import { SubNavigation } from '@cofenster/web-components';

import { useProject } from '../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import { useIsRouteActive } from '../../hooks/navigation/useIsRouteActive';
import { SubNavigationItem } from '../button/SubNavigationItem';

export const ProjectVideoSubNavigation: FC = () => {
  const { project } = useProject();
  const isRouteActive = useIsRouteActive();

  if (!project || project.hasSceneSubtitlesAvailable) {
    return null;
  }

  return (
    <SubNavigation>
      <SubNavigationItem
        to="projectEdit"
        params={{ projectId: project.id }}
        active={false}
        iconType="ArrowLeftIcon"
        data-testid="back-to-editing-button"
      >
        i18n.subNavigation.backToEditing
      </SubNavigationItem>
      <SubNavigationItem
        to="projectVideo"
        params={{ projectId: project.id }}
        active={isRouteActive('projectVideo', { projectId: project.id })}
        iconType="VideoIcon"
      >
        i18n.subNavigation.video
      </SubNavigationItem>
      <TeamPermissionRestriction has="SubtitlesRead">
        <SubNavigationItem
          to="projectSubtitles"
          params={{ projectId: project.id }}
          active={isRouteActive('projectSubtitles', { projectId: project.id })}
          iconType="SubtitlesIcon"
        >
          i18n.subNavigation.subtitles
        </SubNavigationItem>
      </TeamPermissionRestriction>
    </SubNavigation>
  );
};
