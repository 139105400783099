import { useCallback } from 'react';
import { useActionSceneIds } from '../../../contexts/selectedScenes/useActionSceneIds';
import { useWebManagerTracking } from '../../useWebManagerTracking';
import { useDeleteColorSceneAsset } from './useDeleteColorSceneAsset';

export const useDeleteColorSceneAssetWithTracking = (
  sceneId: string,
  additionalTrackingDetails?: Record<string, string | number | undefined | null>
) => {
  const actionSceneIds = useActionSceneIds(sceneId);
  const [deleteColorSceneAsset] = useDeleteColorSceneAsset(sceneId);
  const tracking = useWebManagerTracking();
  const trackSceneAssetDeleted = useCallback(
    () =>
      tracking.trackEvent({
        event: 'SceneAssetDeleted',
        details: {
          sceneIds: actionSceneIds,
          source: 'edit',
          ...additionalTrackingDetails,
        },
      }),
    [actionSceneIds, tracking, additionalTrackingDetails]
  );

  return useCallback(async () => {
    await deleteColorSceneAsset();
    trackSceneAssetDeleted();
  }, [deleteColorSceneAsset, trackSceneAssetDeleted]);
};
