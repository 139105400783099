import { type FC, useCallback } from 'react';

import { VIDEO_FORMATS } from '@cofenster/constants';
import {
  AspectRatioBox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  NativeVideoPlayer,
  Typography,
  styled,
} from '@cofenster/web-components';

import { useCreateContributionRequest } from '../../../api/hooks/contributionRequest/useCreateContributionRequest';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import type { ContributionRequestPreset } from '../../../pages/ProjectContributionConfiguration/ProjectContributionConfigurationContent/types';

const ContributionRequestTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const PushRightButton = styled(Button)(() => ({
  marginLeft: 'auto',
}));

const FlexCenterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  margin: '0 auto',
  gap: theme.spacing(2),
}));

type ContributionRequestFromPresetDialogProps = {
  closeDialog: () => unknown;
  isOpen: boolean;
  preset: ContributionRequestPreset;
  projectId: string;
};

export const ContributionRequestFromPresetDialog: FC<ContributionRequestFromPresetDialogProps> = ({
  closeDialog,
  isOpen,
  preset,
  projectId,
}) => {
  const { createContributionRequest, loading } = useCreateContributionRequest();
  const tracking = useWebManagerTracking();

  const onUsePreset = useCallback(async () => {
    await createContributionRequest(projectId, preset.requests);
    tracking.trackEvent({
      event: 'contributionRequestPresetUsed',
      details: {
        presetName: preset.title,
        presetId: preset.id,
      },
    });
    closeDialog();
  }, [createContributionRequest, preset, closeDialog, projectId, tracking.trackEvent]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title={preset.title} size="l" withHeaderBottomBorder>
      <DialogContent>
        <FlexCenterContainer>
          <AspectRatioBox ratio={VIDEO_FORMATS.Horizontal.aspectRatio} height="100%">
            <NativeVideoPlayer src={preset.videoUrl} actions={['FULLSCREEN']} />
          </AspectRatioBox>

          {preset.requests.map((request) => (
            <div key={request.title}>
              <ContributionRequestTitleContainer>
                <Icon type="ClipboardIcon" />
                <Typography variant="h5">{request.title}</Typography>
              </ContributionRequestTitleContainer>
              <Typography variant="l">{request.instruction}</Typography>
            </div>
          ))}
        </FlexCenterContainer>
      </DialogContent>
      <DialogActions>
        <PushRightButton variant="tertiary" onClick={closeDialog}>
          i18n.global.cancel
        </PushRightButton>
        <Button variant="primary" onClick={onUsePreset} loading={loading}>
          i18n.projectContributionConfiguration.usePresetButton
        </Button>
      </DialogActions>
    </Dialog>
  );
};
