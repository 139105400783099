import { Autocomplete as MuiAutocomplete, type AutocompleteProps as MuiAutocompleteProps, styled } from '@mui/material';

export const CustomPaper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows['1'],
  marginTop: theme.spacing(1),

  '.MuiAutocomplete-listbox': {
    padding: theme.spacing(1),
    maxHeight: 'min(400px, 90vh)',

    '.MuiAutocomplete-option': {
      padding: 0,
      borderRadius: theme.shape.borderRadius,

      '&[aria-disabled="true"]': {
        opacity: 1,
        pointerEvents: 'none',
      },

      '&.Mui-focused': {
        backgroundColor: theme.palette.brand.grey50,
      },

      '+ .MuiAutocomplete-option': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

export const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  return <MuiAutocomplete PaperComponent={CustomPaper} {...props} />;
};
