import { useMemo } from 'react';

import {
  type ProjectNeedsRerenderQueryResult,
  type QueryProjectNeedsRerenderArgs,
  useProjectNeedsRerenderQuery,
} from '../../generated';

export type ProjectNeedsRerenderStatus = NonNullable<
  NonNullable<ProjectNeedsRerenderQueryResult['data']>['projectNeedsRerender']
>['status'];

export const useProjectNeedsRerender = (
  projectId: QueryProjectNeedsRerenderArgs['id'],
  language: QueryProjectNeedsRerenderArgs['language']
) => {
  const { loading, error, data, refetch } = useProjectNeedsRerenderQuery({
    variables: { id: projectId, language },
    fetchPolicy: 'cache-and-network', // make sure to always get fresh data
  });
  const projectNeedsRerender = data?.projectNeedsRerender ?? undefined;
  return useMemo(
    () => ({ projectNeedsRerender, loading, error, refetch }),
    [projectNeedsRerender, loading, error, refetch]
  );
};
