import type { FC } from 'react';

import { RouterLink } from '../button/RouterLink';

import { QuickActionButton } from './QuickActionButton';

export const CreateProject: FC<{ folderId: string }> = ({ folderId }) => {
  return (
    <QuickActionButton
      component={RouterLink}
      iconType="FilmIcon"
      variant="primary"
      data-testid="create-project-cta"
      to="projectCreate"
      params={{ folderId }}
      type={null}
    >
      i18n.common.createProject
    </QuickActionButton>
  );
};
