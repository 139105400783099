import type { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Redirect } from '../../components/common/Redirect';
import { ProjectSpaceProvider } from '../projectSpace/ProjectSpaceProvider';

import { useSessionExpiredDialog } from '../../hooks/useSessionExpiredDialog';
import { useUser } from './useUser';

export const EnsureUser: FC = () => {
  const { user } = useUser();
  const params = useLocation();

  useSessionExpiredDialog();

  if (!user) return <Redirect to="signin" state={{ redirect: params.pathname }} />;

  return (
    <ProjectSpaceProvider>
      <Outlet />
    </ProjectSpaceProvider>
  );
};
