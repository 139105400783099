import { styled } from '@mui/material';
import type { FC } from 'react';

const Container = styled('div')(() => ({
  position: 'absolute',
  height: 'var(--preview-track-height, 4px)',
  width: '100%',
}));

const Bar = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: 'var(--preview-track-height, 4px)',
  background: theme.palette.toRgba(theme.palette.brand.white, 0.1),
}));

export const Rail: FC = () => {
  return (
    <Container>
      <Bar />
    </Container>
  );
};
