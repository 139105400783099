import { styled } from '@mui/material';
import { type FC, type PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useI18n } from '../../../../services/i18n';
import type { TextVariants } from '../../../../theming/theme';
import { formatFileSize } from '../../../../utilities/formatFileSize';
import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import { Circle } from '../../../assets/icons/Circle';
import { Icon, type IconSize, type IconType } from '../../../assets/icons/Icon';
import { DEFAULT_DROPZONE_MAX_SIZE, Dropzone, type DropzoneProps } from '../Dropzone';
import { type AssetDropzoneSize, DropzoneContent, type FontWeight } from './DropzoneContent';
import { DropzoneStatus } from './DropzoneStatus';

const StyledCircle = styled(
  Circle,
  preventForwardProps(['active', 'disabled'])
)<{ active?: boolean; disabled?: boolean }>(({ theme, active, disabled }) => ({
  transform: active ? 'scale(1.15)' : undefined,
  transition: 'transform 150ms ease-out',
  backgroundColor: disabled ? theme.palette.brand.carbon_alpha5 : theme.palette.brand.blue_alpha40,
  marginBottom: theme.spacing(1.5),
}));

export type AssetDropzoneProps = Omit<DropzoneProps, 'children'> & {
  icon: IconType;
  text: string;
  hintText?: string;
  size?: AssetDropzoneSize;
  hintFontWeight?: FontWeight;
  error?: string;
};

export const ASSET_DROPZONE_SIZING: {
  [key in AssetDropzoneSize]: {
    icon: IconSize;
    iconContainer: IconSize;
    errorText: TextVariants;
    errorWeight: FontWeight;
  };
} = {
  small: {
    icon: 'm',
    iconContainer: 'xl',
    errorText: 'l',
    errorWeight: 'normal',
  },
  medium: {
    icon: 'l',
    iconContainer: 56,
    errorText: 'xl',
    errorWeight: 'bold',
  },
};

export const AssetDropzone: FC<PropsWithChildren<AssetDropzoneProps>> = ({
  icon,
  text,
  hintText,
  onError,
  onFile,
  multiple,
  onMultipleFiles,
  size = 'small',
  children,
  hintFontWeight,
  error: initialError,
  ...rest
}) => {
  const [error, setError] = useState<string | undefined>(initialError ?? undefined);
  const [errorOptions, setErrorOptions] = useState<{ fileName: string } | undefined>(undefined);
  const { translatable } = useI18n();

  const handleError = useCallback(
    (error: string, options: { fileName: string } | undefined) => {
      setError(error);
      setErrorOptions(options);
      onError?.(error);
    },
    [onError]
  );
  const handleFile = useCallback(
    (file: File) => {
      setError(undefined);
      onFile?.(file);
    },
    [onFile]
  );

  useEffect(() => {
    if (initialError) setError(initialError);
  }, [initialError]);

  const handleMultipleFiles = useCallback(
    (files: File[]) => {
      setError(undefined);
      onMultipleFiles?.(files);
    },
    [onMultipleFiles]
  );

  const selectedSize = ASSET_DROPZONE_SIZING[size];

  const fileUpload = multiple
    ? ({ multiple: true, onMultipleFiles: handleMultipleFiles } as const)
    : ({ multiple: false, onFile: handleFile } as const);

  if (error) {
    return (
      <DropzoneStatus
        status="error"
        // Use `translatable(..)` instead of `translate(..)` here as the
        // `DEFAULT_UPLOAD_ERRORS` translation keys contain the `i18n.` prefix,
        // which would cause `translate(..)` to fail translating the error.
        text={translatable(error, {
          ...errorOptions,
          maxSize: formatFileSize(rest.maxSize ?? DEFAULT_DROPZONE_MAX_SIZE),
        })}
        onCancel={() => setError(undefined)}
        size={size}
      />
    );
  }

  return (
    <Dropzone backgroundColor="white" {...rest} onError={handleError} {...fileUpload}>
      {({ isInteractedWith, isDragActive }) => (
        <>
          <DropzoneContent
            icon={
              <StyledCircle
                active={isInteractedWith && !rest.disabled}
                size={selectedSize.iconContainer}
                disabled={rest.disabled}
              >
                <Icon size={selectedSize.icon} type={icon} color={rest.disabled ? 'carbon_alpha50' : 'blue'} />
              </StyledCircle>
            }
            text={text}
            hintText={hintText}
            size={size}
            isActive={isDragActive}
            hintFontWeight={hintFontWeight}
          />
          {children}
        </>
      )}
    </Dropzone>
  );
};
