import { useMemo } from 'react';

import { type VerifyUserPasswordTokenQueryResult, useVerifyUserPasswordTokenQuery } from '../../generated';

export type { VerifyUserPasswordTokenQueryResult } from '../../generated';
export type VerifyToken = NonNullable<
  NonNullable<VerifyUserPasswordTokenQueryResult['data']>['verifyUserPasswordToken']
>;

export const useVerifyToken = (token: string) => {
  const { loading, error, data } = useVerifyUserPasswordTokenQuery({
    variables: { token },
    fetchPolicy: 'network-only',
  });
  const status =
    data?.verifyUserPasswordToken.__typename === 'PasswordTokenResponse'
      ? data.verifyUserPasswordToken.status
      : undefined;
  const user =
    data?.verifyUserPasswordToken.__typename === 'PasswordTokenResponse'
      ? data.verifyUserPasswordToken.user
      : undefined;
  return useMemo(() => ({ status, user, loading, error }), [status, user, loading, error]);
};
