import type { FC } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Icon,
  IconButton,
  Responsive,
  Typography,
  VisuallyHidden,
  formatDuration,
  styled,
  useI18n,
} from '@cofenster/web-components';

import type { Music } from '../../../api/hooks/music/useProjectMusics';
import type { Project } from '../../../contexts/project/useProject';
import { MusicRange } from '../MusicRange';
import { VolumePickerPopover } from '../VolumePickerPopover';
import { type usePlayer, useSelectMusic } from '../hooks';

const Container = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape['borderRadius-l'],
  border: '1px solid transparent',

  '&[aria-current="true"]': {
    color: theme.palette.brand.blue,
    borderColor: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue50,
  },
}));

const MusicName = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const SelectButton: FC<{ project: Project; music: Music }> = ({ project, music }) => {
  const { locale } = useI18n();
  const { toggleMusic } = useSelectMusic(project);
  const musicName = locale === 'DE' ? music.name : music.nameEN;

  return (
    <Button
      startIcon={<Icon type="MinusCircleIcon" size="s" />}
      type="button"
      onClick={() => toggleMusic(music.id)}
      i18nParams={{ name: <VisuallyHidden>{musicName}</VisuallyHidden> }}
      data-testid="music-card-remove-button"
      variant="tertiary"
    >
      i18n.projectMusic.music.remove
    </Button>
  );
};

export const MusicCard: FC<{
  project: Project;
  music: Music;
  player: ReturnType<typeof usePlayer>;
}> = ({ project, music, player }) => {
  const { locale } = useI18n();
  const { playback, play, pause } = player;

  const isInPlayer = playback.id === music.id;
  const isPlaying = isInPlayer && playback.status === 'PLAYING';
  const musicName = locale === 'DE' ? music.name : music.nameEN;

  return (
    <Container data-testid="current-music-card" id={`music-${music.id}`}>
      <GridContainer alignItems="center">
        <GridItem>
          <IconButton
            style={{ borderRadius: '50%' }}
            type="button"
            icon={isPlaying ? 'PauseIcon' : 'PlayIcon'}
            label={isPlaying ? 'i18n.global.pause' : 'i18n.global.play'}
            iconWeight="fill"
            hoverBackgroundColor="carbon"
            backgroundColor="carbon"
            hoverColor="white"
            iconColor="white"
            iconSize="l"
            onClick={() => (isPlaying ? pause() : play(music.id))}
            aria-pressed={isPlaying}
            data-testid="music-card-play-button"
          />
        </GridItem>

        <GridItem>
          <MusicName variant="m" component="p">
            {musicName}
          </MusicName>
          <Typography variant="m" component="p">
            {formatDuration(music.musicAsset?.duration ?? 0)}
          </Typography>
        </GridItem>

        <GridItem xs>
          <Responsive up="md">
            <MusicRange music={music} player={player} />
          </Responsive>
        </GridItem>

        <GridItem ml="auto" xs={6} sm="auto">
          <VolumePickerPopover project={project} />
        </GridItem>

        <GridItem xs={6} sm="auto">
          <SelectButton project={project} music={music} />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
