import type { FC } from 'react';

import type { Scene } from '../../../../api/hooks/scene/useScenes';
import { SceneImageUpload } from '../../../../components/sceneAsset/SceneImageUpload';
import { isImageSceneAsset, isMainSceneAsset } from '../../../../helpers/sceneAssets/is';
import { useDeleteImageSceneAssetWithTracking } from '../../../../hooks/sceneAsset/image/useDeleteImageSceneAssetWithTracking';
import { useUploadSceneImageWithTracking } from '../../../../hooks/sceneAsset/image/useUploadSceneImageWithTracking';

export const SceneImageNoAsset: FC<{
  scene: Scene;
  disabled?: boolean;
}> = ({ scene, disabled }) => {
  const imageSceneAsset = scene.sceneAssets.filter(isMainSceneAsset).find(isImageSceneAsset);
  const sceneImage = imageSceneAsset?.asset ?? undefined;
  const {
    imageUploadProgress: uploadProgress,
    uploadImage,
    cancelImageUpload: cancelUpload,
  } = useUploadSceneImageWithTracking(scene.id);
  const onDelete = useDeleteImageSceneAssetWithTracking(scene.id);

  return (
    <SceneImageUpload
      uploadProgress={uploadProgress}
      startUpload={uploadImage}
      cancelUpload={cancelUpload}
      cancelProcessing={onDelete}
      sceneImage={sceneImage}
      disabled={disabled}
    />
  );
};
