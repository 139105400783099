import type { FC, PropsWithChildren, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { useMe } from '../../api/hooks/user/useMe';
import { useCurrentTeam } from '../currentTeam/useCurrentTeam';
import { useProject } from '../project/useProject';
import { useProjectFolder } from '../projectFolder/useProjectFolder';

import type { PermissionSelection } from './PermissionSelection';
import type { PermissionStatus } from './PermissionStatus';
import { checkPermission } from './checkPermission';
import { checkPersonalSpacePermissions } from './checkPersonalSpacePermissions';
import { useUser } from './useUser';

type HasTeamPermissionProps = {
  fallback?: ReactNode;
  loader?: ReactNode;
} & PermissionSelection;

export const TeamPermissionRestriction: FC<PropsWithChildren<HasTeamPermissionProps>> = (props) => {
  const { fallback, children, loader } = props;
  const permissionStatus = useTeamPermissionStatus(props);
  if (permissionStatus === 'UNKNOWN') return <>{loader}</>;
  return permissionStatus === 'ALLOWED' ? <>{children}</> : <>{fallback}</>;
};

export const useTeamPermissionStatus = (params: PermissionSelection): PermissionStatus => {
  const { user } = useUser();
  const { team, loading } = useCurrentTeam();
  const isPersonalSpace = useIsPersonalSpace();

  const accountRole = user?.roles.account;

  if (isPersonalSpace) {
    return checkPersonalSpacePermissions(params, accountRole) ? 'ALLOWED' : 'DENIED';
  }

  if (loading && !team) {
    return 'UNKNOWN';
  }

  const { id: teamId, public: isTeamPublic } = team ?? {};
  if (isTeamPublic) {
    const permissions = accountRole?.permissions;
    return checkPermission(params, permissions) ? 'ALLOWED' : 'DENIED';
  }

  const teamRole = user?.roles.team.find((it) => it.teamId === teamId)?.role;
  const teamPermissions = teamRole?.permissions;
  return checkPermission(params, teamPermissions) ? 'ALLOWED' : 'DENIED';
};

export const useIsPersonalSpace = () => {
  const { user } = useMe();
  const { project } = useProject();
  let { folderId } = useParams();
  const { projectFolder } = useSafeUseProjectFolder();
  const parentFolderId = projectFolder?.parentFolder?.id;

  // If folderId is not on the URL
  if (!folderId) {
    // We need to pick it from the current project folder
    folderId = project?.projectFolders[0]?.id;
  }

  return (
    folderId === 'personal' ||
    folderId === user?.rootProjectFolder.id ||
    parentFolderId === user?.rootProjectFolder.id ||
    !!user?.personalFolderIds?.includes(folderId ?? '')
  );
};

const useSafeUseProjectFolder = () => {
  try {
    // biome-ignore lint/correctness/useHookAtTopLevel: safe with the try/catch
    return useProjectFolder();
  } catch {
    return { projectFolder: null };
  }
};
