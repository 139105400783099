import type { FC } from 'react';

import type { ThemeVideoType } from '../../../../api/hooks/user/useMe';
import type { Project } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { TeamPermissionRestriction } from '../../../../contexts/user/TeamPermissionRestriction';

import {
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../../../helpers/sceneAssets/is';
import { EditorToolsIntroOutro } from './EditorToolsIntroOutro';
import { EditorToolsSceneColor } from './EditorToolsSceneColor';
import { EditorToolsSceneImage } from './EditorToolsSceneImage';
import { EditorToolsSceneVideo } from './EditorToolsSceneVideo';

export const toThemeVideoType = (value: Lowercase<ThemeVideoType>) =>
  (value.slice(0, 1).toUpperCase() + value.slice(1)) as ThemeVideoType;

export const EditorTools: FC<{ project: Project; sceneId?: string }> = ({ project, sceneId }) => {
  const { currentScene } = useScenes();

  if (!currentScene) return null;

  if (sceneId === 'intro' || sceneId === 'outro') {
    const themeVideoType = toThemeVideoType(sceneId as Lowercase<ThemeVideoType>);
    return (
      <TeamPermissionRestriction has="ProjectUpdate">
        <EditorToolsIntroOutro project={project} themeVideoType={themeVideoType} />
      </TeamPermissionRestriction>
    );
  }

  const mainSceneAsset = currentScene.sceneAssets.find(isMainSceneAsset);

  return (
    <>
      {(currentScene.type === 'video' || currentScene.type === 'screenRecording') && (
        <EditorToolsSceneVideo
          project={project}
          scene={currentScene}
          videoSceneAsset={mainSceneAsset && isVideoSceneAsset(mainSceneAsset) ? mainSceneAsset : undefined}
        />
      )}
      {currentScene.type === 'image' && (
        <EditorToolsSceneImage
          project={project}
          scene={currentScene}
          imageSceneAsset={mainSceneAsset && isImageSceneAsset(mainSceneAsset) ? mainSceneAsset : undefined}
        />
      )}
      {currentScene.type === 'color' && (
        <EditorToolsSceneColor
          project={project}
          scene={currentScene}
          colorSceneAsset={mainSceneAsset && isColorSceneAsset(mainSceneAsset) ? mainSceneAsset : undefined}
        />
      )}
    </>
  );
};
