import { useMemo } from 'react';

import {
  type PopularStockVideosQuery,
  type PopularStockVideosQueryVariables,
  usePopularStockVideosQuery,
} from '../../generated';

export type StockVideo = NonNullable<PopularStockVideosQuery['popularStockVideos']>['items'][number];
export type StockVideoResponse = NonNullable<PopularStockVideosQuery['popularStockVideos']>;
export type Attributor = NonNullable<StockVideo['attributor']>;
export type Filter = NonNullable<PopularStockVideosQueryVariables['filter']>;

export const usePopularStockVideos = (filter: Filter, skip = false) => {
  const { loading, error, data } = usePopularStockVideosQuery({
    variables: { filter },
    skip,
  });
  const popularStockVideos = data?.popularStockVideos || undefined;

  return useMemo(() => ({ popularStockVideos, loading, error }), [popularStockVideos, loading, error]);
};
