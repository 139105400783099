import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Headline, type I18nParams, Spacing, styled } from '@cofenster/web-components';

const SectionHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

export const HomeSection: FC<PropsWithChildren<{ title: string; i18nParams?: I18nParams; controls?: ReactNode }>> = ({
  title,
  i18nParams,
  children,
  controls,
}) => (
  <Spacing bottom={8}>
    <SectionHeader>
      <Headline variant="h3" component="h2" i18nParams={i18nParams}>
        {title}
      </Headline>

      {controls && <ButtonsContainer>{controls}</ButtonsContainer>}
    </SectionHeader>

    {children}
  </Spacing>
);
