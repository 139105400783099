import { type FC, type PropsWithChildren, useMemo } from 'react';

import { FeatureFlagsProvider as CommonFeatureFlagsProvider } from '@cofenster/web-components';

import { useMe } from '../../api/hooks/user/useMe';

export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useMe();
  const userFlags = useMemo(() => user?.account.featureFlags ?? [], [user?.account.featureFlags]);

  return <CommonFeatureFlagsProvider featureFlags={userFlags}>{children}</CommonFeatureFlagsProvider>;
};
