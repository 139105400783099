import { type FC, type PropsWithChildren, type RefObject, useCallback, useEffect, useState } from 'react';

import {
  PlayerControls,
  styled,
  useKeypress,
  usePlayPauseKeyboardHandler,
  useRefOfState,
} from '@cofenster/web-components';

import type { RemotionPlayerRef } from './Player';
import { useRemotionPlayerControls } from './useControls';

const ControlsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  zIndex: theme.zIndex.above,
}));

export type RemotionPlayerControlsProps = {
  autoPlay?: boolean;
  duration: number;
  playerRef: RefObject<RemotionPlayerRef>;
  projectId?: string;
  isLoading?: boolean;
  Wrapper?: FC<PropsWithChildren>;
  format?: (t: number) => string;
  loop?: boolean;
  size?: 's' | 'm';
  showTicksEverySecond?: boolean;
};

export const RemotionPlayerControls: FC<RemotionPlayerControlsProps> = ({
  duration,
  playerRef,
  projectId,
  isLoading = false,
  Wrapper = ControlsWrapper,
  format,
  loop,
  size,
  showTicksEverySecond,
}) => {
  const [seeked, setSeeked] = useState(false);
  const { currentTime, setCurrentTime, paused, play, pause } = useRemotionPlayerControls(playerRef.current, {
    eventType: 'frameupdate',
  });
  const currentTimeRef = useRefOfState(currentTime);
  usePlayPauseKeyboardHandler(paused, play, pause);

  const [isLooping, setIsLooping] = useState(loop);
  const toggleLoop = useCallback(() => setIsLooping((prev) => !prev), []);

  const ended = currentTime === duration;

  const goto = useCallback(
    (t: number) => {
      setCurrentTime(t);
      setSeeked(true);
    },
    [setCurrentTime]
  );

  useEffect(() => {
    if (!paused && ended) {
      setCurrentTime(0);
      pause();

      if (isLooping) play();
    }
  }, [paused, ended, pause, setCurrentTime, isLooping, play]);

  useEffect(() => {
    if (!playerRef.current) return;
    if (paused) {
      playerRef.current.pause();
    } else {
      playerRef.current.currentTime = currentTimeRef.current;
      playerRef.current.play();
    }
  }, [paused, playerRef, currentTimeRef]);

  useEffect(() => {
    if (!playerRef.current) return;
    if (seeked) {
      playerRef.current.currentTime = currentTimeRef.current;
      setSeeked(false);
    }
  }, [seeked, playerRef, currentTimeRef]);

  useGoFrameKeyboardHandler(currentTime, duration, goto);

  return duration ? (
    <Wrapper>
      <PlayerControls
        play={play}
        pause={pause}
        currentTime={currentTime}
        duration={duration}
        paused={paused}
        goto={goto}
        playButtonDisabled={isLoading}
        projectId={projectId}
        format={format}
        isLooping={isLooping}
        toggleLoop={toggleLoop}
        size={size}
        showTicksEverySecond={showTicksEverySecond}
      />
    </Wrapper>
  ) : null;
};

const useGoFrameKeyboardHandler = (currentTime: number, duration: number, goto: (t: number) => void) => {
  const handleGoFurther = useCallback(() => {
    goto(Math.min(duration, currentTime + 1 / 30));
  }, [goto, currentTime, duration]);

  const handleGoBackOneFrame = useCallback(() => {
    goto(Math.max(0, currentTime - 1 / 30));
  }, [goto, currentTime]);

  useKeypress(handleGoFurther, 'ArrowRight');
  useKeypress(handleGoBackOneFrame, 'ArrowLeft');
};
