import { type FC, useCallback } from 'react';

import { useSnackbars } from '@cofenster/web-components';

import { useGoToCollectSceneAssetPage } from '../../hooks/navigation/useGoToCollectSceneAssetPage';
import { useCreateProjectWithScene } from '../../hooks/quickActions/useCreateProjectWithScene';

import { QuickActionButton } from './QuickActionButton';

export const RecordScreen: FC<{ folderId: string }> = ({ folderId }) => {
  const gotoCollectSceneAssetPage = useGoToCollectSceneAssetPage();

  const createProjectWithScene = useCreateProjectWithScene();
  const { openSnackbar } = useSnackbars();

  const onClick = useCallback(async () => {
    const { projectId, sceneId, error } = await createProjectWithScene({
      folderId,
      videoFormat: 'Horizontal',
      sceneType: 'screenRecording',
      source: 'screen record button',
    });
    if (error) {
      return openSnackbar({
        variant: 'error',
        children: 'i18n.global.error.generic.unknown',
      });
    }
    gotoCollectSceneAssetPage(projectId, sceneId, { source: 'quickAction', parentFolderId: folderId });
  }, [folderId, createProjectWithScene, gotoCollectSceneAssetPage, openSnackbar]);

  return (
    <QuickActionButton
      onClick={onClick}
      variant="secondary"
      iconType="ScreenShareIcon"
      data-testid="record-screen-button"
    >
      i18n.quickActions.recordScreen.button
    </QuickActionButton>
  );
};
