import { useCallback } from 'react';

import type { SceneImage } from '../../../api/hooks/scene/useScenes';
import { useUpdateSceneImage } from '../../../api/hooks/sceneImage/useUpdateSceneImage';
import type { Scene } from '../../../contexts/scenes/useScenes';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export const useUpdateDurationImage = (scene: Scene, sceneImage?: SceneImage) => {
  const updateSceneImage = useUpdateSceneImage();
  const tracking = useWebManagerTracking();

  const trackSceneDurationUpdated = useCallback(
    (duration: number) =>
      tracking.trackEvent({
        event: 'SceneDurationUpdated',
        details: {
          sceneId: scene.id,
          textElementsCount: scene.sceneTexts.length,
          sceneType: 'image',
          duration,
        },
      }),
    [scene.id, scene.sceneTexts.length, tracking]
  );

  return useCallback(
    async (duration: number) => {
      if (sceneImage) {
        await updateSceneImage(sceneImage.id, { duration });
        trackSceneDurationUpdated(duration);
        return true;
      }
      return false;
    },
    [sceneImage, updateSceneImage, trackSceneDurationUpdated]
  );
};
