import { DndContext, type DragEndEvent, type Modifier, type UniqueIdentifier, closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext as DndSortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { type FC, type PropsWithChildren, useCallback } from 'react';

import { useSortableSensors } from './useSortableSensors';

const RESTRICTIONS: Record<SortableContextProps['restrictTo'], Modifier[]> = {
  none: [],
  x: [restrictToHorizontalAxis],
  y: [restrictToVerticalAxis],
  parent: [restrictToParentElement],
};

export type SortableContextProps = {
  ids: UniqueIdentifier[];
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  restrictTo: 'x' | 'y' | 'parent' | 'none';
  disabled?: boolean;
};

export const SortableContext: FC<PropsWithChildren<SortableContextProps>> = ({
  children,
  ids,
  onSortEnd,
  restrictTo = 'none',
  disabled = false,
}) => {
  const sensors = useSortableSensors();
  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        onSortEnd({
          oldIndex: ids.indexOf(active.id),
          newIndex: ids.indexOf(over.id),
        });
      }
    },
    [onSortEnd, ids]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={RESTRICTIONS[restrictTo]}
    >
      <DndSortableContext items={ids} strategy={rectSortingStrategy} disabled={disabled}>
        {children}
      </DndSortableContext>
    </DndContext>
  );
};
