import { useCallback } from 'react';

import { type CreateSceneAssetMutationVariables, useCreateSceneAssetMutation } from '../../generated';

export type SceneAssetType = CreateSceneAssetMutationVariables['input']['assetType'];

export const useCreateSceneAsset = () => {
  const [createSceneAsset] = useCreateSceneAssetMutation({
    refetchQueries: ['Scenes', 'ProjectRenderDescription', 'SceneRenderDescription'],
  });
  return useCallback(
    (input: CreateSceneAssetMutationVariables['input']) => createSceneAsset({ variables: { input } }),
    [createSceneAsset]
  );
};
