import type { FC } from 'react';

import { Button, GridContainer, GridItem, Headline, VisuallyHidden, useBooleanState } from '@cofenster/web-components';

import { CardSeparator } from '../../../components/card';
import type { Project } from '../../../contexts/project/useProject';
import { useAccountPermissionStatus } from '../../../contexts/user/AccountPermissionRestriction';

import { DesignForm } from './DesignForm';
import { ThemesPanel } from './ThemesPanel';
import { VideoForm } from './VideoForm';

type Props = {
  project: Project;
};

export const ProjectThemeForm: FC<Props> = ({ project }) => {
  const [panelIsOpen, openPanel, closePanel] = useBooleanState(false);
  const canReadThemes = useAccountPermissionStatus({ has: 'ThemeRead' }) === 'ALLOWED';

  return (
    <>
      <GridContainer alignItems="center">
        <GridItem>
          <Headline variant="h3" color="carbon" component="h2">
            i18n.projectDesign.themeForm.headline.theme
          </Headline>
        </GridItem>
        {canReadThemes && (
          <GridItem flex={1} textAlign="right">
            <Button
              onClick={openPanel}
              variant="tertiary"
              data-testid="project-theme-button"
              i18nParams={{ hidden: (chunks) => <VisuallyHidden>{chunks}</VisuallyHidden> }}
            >
              i18n.projectDesign.themeForm.changeTheme
            </Button>
          </GridItem>
        )}
      </GridContainer>

      <CardSeparator />

      <DesignForm project={project} />
      <VideoForm project={project} />

      <ThemesPanel isOpen={panelIsOpen} project={project} close={closePanel} />
    </>
  );
};
