import type { FC } from 'react';

import { GridContainer, GridItem, Icon, ProfileCircle, Typography, styled } from '@cofenster/web-components';

import type { OptionType } from '.';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));

export type OptionProps = {
  name: string;
  email: string;
  teamRole: OptionType['teamRole'];
};

export const Option: FC<OptionProps> = ({ name, email, teamRole }) => {
  return (
    <Container data-testid="member-option">
      <GridContainer spacing={1} alignItems="center">
        <GridItem>
          <ProfileCircle color="dark" size="large">
            {name}
          </ProfileCircle>
        </GridItem>
        <GridItem xs>
          <Typography variant="h6" component="p">
            {name}
          </Typography>
          <Typography variant="s" color="grey600">
            {email}
          </Typography>
        </GridItem>
        <GridItem>
          {teamRole !== undefined && (
            <GridContainer spacing={0.5} alignItems="center">
              <GridItem marginTop={0.5}>
                <Icon type="CheckIcon" size="xs" />
              </GridItem>
              <GridItem>
                <Typography variant="s" color="grey600">
                  {teamRole === 'owner' ? 'i18n.team.owner' : 'i18n.team.member'}
                </Typography>
              </GridItem>
            </GridContainer>
          )}
        </GridItem>
      </GridContainer>
    </Container>
  );
};
