import { styled } from '@mui/material';
import type { FC } from 'react';
import { SpinningIcon } from '../../assets/icons/SpinningIcon';
import { Text } from '../../typography/Text';

const Container = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: 0,
  padding: theme.spacing(1.5, 3, 1.5, 2),
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.carbon_alpha50,
  backdropFilter: 'blur(13px)',
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'nowrap',
}));

export const ProcessingIndicator: FC = (props) => (
  <Container {...props}>
    <SpinningIcon type="LoadingIcon" speed="fast" />
    <Text>i18n.global.processingIndicator</Text>
  </Container>
);
