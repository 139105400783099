import { styled } from '@mui/material';
import type { ComponentProps, FC } from 'react';

import { Circle, type CircleProps } from './Circle';
import { Icon } from './Icon';

const StyledCircle = styled(Circle)(({ theme }) => ({
  backgroundColor: theme.palette.brand.purple,
  color: theme.palette.brand.white,
}));

export const UpsellIcon: FC<Partial<CircleProps> & ComponentProps<'div'>> = ({ ...rest }) => (
  <StyledCircle as="span" size="l" {...rest}>
    <Icon type="RocketLaunchIcon" size="s" />
  </StyledCircle>
);
