import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { useScenes } from '../../api/hooks/scene/useScenes';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { FromProjectTeamProvider } from '../../contexts/currentTeam/FromProjectTeamProvider';
import { ProjectProvider } from '../../contexts/project/ProjectProvider';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import type { ProjectContributionsConfigurationRouteParams } from '../../routes';
import { NoAccessLayout } from '../NoAccess';
import { ProjectContributionConfigurationContent } from './ProjectContributionConfigurationContent';

export const ProjectContributionConfiguration: FC = () => {
  const { projectId, type } = useParams() as ProjectContributionsConfigurationRouteParams;
  // We don't need scenes, but we need to refetch scenes after adding Contribution Request.
  // Let's keep this query as an active query (active on current render tree).
  useScenes(projectId);

  return (
    <ProjectProvider projectId={projectId}>
      <CurrentTeamProvider>
        <FromProjectTeamProvider>
          <TeamPermissionRestriction has="ProjectUpdate" fallback={<NoAccessLayout />} loader={<LoadingSpinner />}>
            <ProjectContributionConfigurationContent projectId={projectId} type={type} />
          </TeamPermissionRestriction>
        </FromProjectTeamProvider>
      </CurrentTeamProvider>
    </ProjectProvider>
  );
};
