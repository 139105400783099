import { type FC, useCallback } from 'react';

import { useBooleanState } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';

import { AudioOverlayButton } from './Button';
import { AudioOverlayContextProvider } from './Context';
import { AudioOverlayPanel } from './Panel';

export const AudioOverlay: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [isPanelOpen, openPanel, closePanel] = useBooleanState(false);
  const { pause } = useEditorPlayer();
  const openPanelAndPausePlayer = useCallback(() => {
    pause();
    openPanel();
  }, [pause, openPanel]);

  return (
    <AudioOverlayContextProvider>
      <AudioOverlayButton disabled={disabled} isPanelOpen={isPanelOpen} openPanel={openPanelAndPausePlayer} />
      <AudioOverlayPanel isPanelOpen={isPanelOpen} closePanel={closePanel} />
    </AudioOverlayContextProvider>
  );
};
