import { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * A convenient wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 */
export const useSearchParams = (defaultInit?: URLSearchParamsInit) => {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit));
  const location = useLocation();
  const searchParams = useMemo(() => {
    const searchParams = createSearchParams(location.search);

    defaultSearchParamsRef.current.forEach((_, key) => {
      if (!searchParams.has(key)) {
        defaultSearchParamsRef.current.getAll(key).forEach((value) => {
          searchParams.append(key, value);
        });
      }
    });

    return searchParams;
  }, [location.search]);

  const navigate = useNavigate();
  const setSearchParams = useCallback(
    (nextInit: URLSearchParamsInit, navigateOptions?: { replace?: boolean; state?: unknown }) => {
      const search = `?${createSearchParams(nextInit)}`;
      navigateOptions?.replace
        ? navigate(search, { replace: true })
        : navigate(search, { state: navigateOptions?.state });
    },
    [navigate]
  );

  return [searchParams, setSearchParams] as const;
};

export type ParamKeyValuePair = [string, string];

export type URLSearchParamsInit = string | ParamKeyValuePair[] | Record<string, string | string[]> | URLSearchParams;

/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ]);
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   });
 */
export const createSearchParams = (init: URLSearchParamsInit = '') => {
  return new URLSearchParams(
    typeof init === 'string' || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo, key) => {
          const value = init[key] as string | string[];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
        }, [] as ParamKeyValuePair[])
  );
};
