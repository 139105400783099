import type { FC } from 'react';

import type { WithSize } from '../types';

import { FullScreenControlIcon } from './FullScreenControlIcon';

export type FullScreenControlProps = {
  isFullScreen: boolean;
  toggleFullScreen(): void;
  fullScreenElement?: Element;
} & WithSize;

export const FullScreenControl: FC<FullScreenControlProps> = ({ toggleFullScreen, size, isFullScreen }) => (
  <FullScreenControlIcon isFullScreen={!!isFullScreen} onClick={toggleFullScreen} size={size} />
);
