import { useCallback } from 'react';

import { type SortScenesMutationVariables, useSortScenesMutation } from '../../generated';

export const useSortScenes = ({ awaitRefetchQueries = false, refetchRenderDescription = false } = {}) => {
  const [sortScenes] = useSortScenesMutation({
    refetchQueries: ['Scenes', refetchRenderDescription && 'ProjectRenderDescription'].filter(Boolean) as (
      | 'Scenes'
      | 'ProjectRenderDescription'
    )[],
    awaitRefetchQueries,
    update: (cache) => {
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
  });
  return useCallback((ids: SortScenesMutationVariables['ids']) => sortScenes({ variables: { ids } }), [sortScenes]);
};
