import type { FC, PropsWithChildren } from 'react';

import { Button, Icon, type IconType, styled } from '@cofenster/web-components';

import type { RouterButtonProps } from '../button/RouterButton';

// 1. The icon on the left of the button removes the need from left spacing.
const BaseQuickActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  paddingLeft: theme.spacing(1.5), // 1
  width: '100%',
}));

const IconContainer = styled('span')<{ variant: QuickActionButtonProps['variant'] }>(({ theme, variant }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  backgroundColor: variant === 'secondary' ? theme.palette.brand.blue50 : 'transparent',
}));

const PrimaryButton = styled(BaseQuickActionButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.blue,
  color: theme.palette.brand.white,

  '&:hover': { backgroundColor: theme.palette.brand.blueDark },
  '&:focus': { backgroundColor: theme.palette.brand.blue },
}));

const SecondaryButton = styled(BaseQuickActionButton)(({ theme }) => ({
  backgroundColor: theme.palette.brand.white,
  color: theme.palette.brand.carbon,

  '&:hover, &:focus': {
    backgroundColor: theme.palette.brand.white,
    color: theme.palette.brand.blue,
  },
}));

type QuickActionButtonProps = Omit<Partial<RouterButtonProps>, 'variant'> & {
  variant: 'primary' | 'secondary';
  iconType: IconType;
};

export const QuickActionButton: FC<PropsWithChildren<QuickActionButtonProps>> = ({
  children,
  iconType,
  variant,
  ...rest
}) => {
  const ButtonComponent = variant === 'secondary' ? SecondaryButton : PrimaryButton;
  const IconColor = variant === 'secondary' ? 'blue' : 'white';
  const icon = (
    <IconContainer variant={variant}>
      <Icon type={iconType} size="m" color={IconColor} />
    </IconContainer>
  );

  return (
    <ButtonComponent {...rest} startIcon={icon}>
      {children}
    </ButtonComponent>
  );
};
