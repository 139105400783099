import type { FC, ReactNode } from 'react';

import { EmptyState } from '@cofenster/web-components';

import { useI18n } from '../../../i18n';
import { useSubtitlesLanguage } from '../SubtitlesLanguageContext';

export const NotYetRendered: FC<{ cta?: ReactNode }> = ({ cta }) => {
  const { subtitlesLanguage } = useSubtitlesLanguage();
  const { translate, languageNames } = useI18n();
  return (
    <EmptyState
      iconType="VideoIcon"
      title={
        subtitlesLanguage
          ? translate('projectVideo.notYetRendered.withSubtitles.title', {
              language: languageNames.of(subtitlesLanguage),
            })
          : 'i18n.projectVideo.notYetRendered.title'
      }
      description={
        subtitlesLanguage
          ? translate('projectVideo.notYetRendered.withSubtitles.subtitle', {
              language: languageNames.of(subtitlesLanguage),
            })
          : 'i18n.projectVideo.notYetRendered.subtitle'
      }
      cta={cta}
    />
  );
};
