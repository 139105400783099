import type { FC } from 'react';

import { Card, GridContainer, GridItem } from '@cofenster/web-components';

import type { ContributionRequest } from '../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { CardSeparator } from '../../../components/card';

import { ContributionRequestListChipSection } from './ContributionRequestListChipSection/ContributionRequestChipSection';
import { ContributionRequestTypeForm } from './ContributionRequestTypeForm';
import { UpdateContributionRequestForm } from './UpdateContributionRequestForm';

export const ContributionRequestCard: FC<{
  item: ContributionRequest;
}> = ({ item }) => {
  return (
    <Card data-testid="contribution-request-card">
      <GridContainer spacing={1}>
        <GridItem xs={12}>
          <UpdateContributionRequestForm item={item} />
        </GridItem>
        <GridItem xs={12}>
          <GridContainer alignItems="center">
            <GridItem xs={12} md={6}>
              <ContributionRequestTypeForm item={item} />
            </GridItem>
          </GridContainer>
        </GridItem>
        {item.contributionRequestLists.length > 0 && (
          <GridItem xs={12}>
            <CardSeparator fullWidth={false} />
          </GridItem>
        )}
        <GridItem xs={12}>
          <ContributionRequestListChipSection item={item} />
        </GridItem>
      </GridContainer>
    </Card>
  );
};
