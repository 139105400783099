import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoDeactivatedUser = () => {
  const goto = useGoto();
  return useCallback(() => goto(routes.deactivatedUser), [goto]);
};
