import { type FC, useCallback, useState } from 'react';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  LoadingSpinner,
  Spacing,
  Text,
  Typography,
  useSnackbars,
} from '@cofenster/web-components';

import { useMoveProjectsToProjectFolder } from '../../../api/hooks/project/useMoveProjectsToProjectFolder';
import { useProjectFolders } from '../../../api/hooks/projectFolder/useProjectFolders';
import { type Team, useTeams } from '../../../api/hooks/team/useTeams';
import { useAvailableTeamsForPermission } from '../../../hooks/useAvailableTeamsForPermission';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { RootFolderSelect } from '../../form/RootFolderSelect';

import { DialogFolderRow } from './DialogFolderRow';

const useMove = (
  projectIds: string[],
  folderId: string,
  closeDialog: () => unknown,
  trackingSource: MoveProjectDialogProps['trackingSource'],
  team?: Team,
  refetchQueries?: string[]
) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking(team?.id);

  const moveProjectsToProjectFolder = useMoveProjectsToProjectFolder(refetchQueries);

  const trackProjectsMovedToFolder = useCallback(async () => {
    if (!projectIds || !folderId) return;

    tracking.trackEvent({
      event: 'projectsMovedToFolder',
      details: {
        projectIds,
        folderId,
        teamId: team?.id,
        teamName: team?.name,
        source: trackingSource,
      },
    });
  }, [projectIds, folderId, tracking, trackingSource, team?.id, team?.name]);

  return useCallback(async () => {
    if (!projectIds || !folderId) return;
    await moveProjectsToProjectFolder(projectIds, folderId);
    closeDialog();
    trackProjectsMovedToFolder();

    openSnackbar({
      children: 'i18n.snackbars.projectsMovedSnackbar.copy',
      i18nParams: { count: projectIds.length },
    });
  }, [projectIds, folderId, moveProjectsToProjectFolder, closeDialog, openSnackbar, trackProjectsMovedToFolder]);
};

export type MoveProjectDialogProps = {
  rootProjectFolderId: string;
  fromProjectFolderId: string;
  projectIds: string[];
  isOpen: boolean;
  closeDialog: () => unknown;
  privateRootFolderId?: string;
  status?: 'ALL' | 'ACTIVE' | 'ARCHIVED';
  trackingSource: 'projectCard' | 'bulkSelector' | 'projectDetail';
  refetchQueries?: string[];
};

export const MoveProjectsDialog: FC<MoveProjectDialogProps> = ({
  isOpen,
  closeDialog,
  projectIds,
  fromProjectFolderId,
  rootProjectFolderId,
  privateRootFolderId,
  status,
  trackingSource,
  refetchQueries,
}) => {
  const { teams = [] } = useTeams();
  const [toFolderId, setToFolderId] = useState('');
  const [selectedRootFolderId, setSelectedRootFolderId] = useState(rootProjectFolderId);
  const selectedTeam = teams.find((team) => team.rootProjectFolder.id === selectedRootFolderId);
  const availableTeams = useAvailableTeamsForPermission('ProjectUpdate');
  const onMove = useMove(projectIds, toFolderId, closeDialog, trackingSource, selectedTeam, refetchQueries);
  const { error, loading, projectFolders } = useProjectFolders(selectedRootFolderId, { status });

  const count = projectIds.length;
  const isInPrivateSpace = !!privateRootFolderId;
  const hasTeams = !!teams.length;
  const selectedSpaceHasFolders = !!projectFolders.length;
  const selectedSpaceIsCurrentSpace = fromProjectFolderId === selectedRootFolderId;

  const handleFolderSelect = useCallback(
    (selectedFolderId: string) => {
      setToFolderId(selectedFolderId !== toFolderId ? selectedFolderId : '');
    },
    [toFolderId]
  );

  if (error || (!loading && !projectFolders)) {
    closeDialog();
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-move-dialog"
      title="i18n.dialogs.moveProjectsDialog.headline"
      titleI18nParams={{ count }}
    >
      <DialogContent>
        {selectedSpaceHasFolders && selectedSpaceIsCurrentSpace && (
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" i18nParams={{ count }} component="p">
              i18n.dialogs.moveProjectsDialog.description
            </Text>
          </Spacing>
        )}

        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {hasTeams && (
              <RootFolderSelect
                label="i18n.dialogs.moveProjectsDialog.teamSelect.label"
                name="destinationTeam"
                teams={availableTeams}
                onChange={setSelectedRootFolderId}
                value={selectedRootFolderId}
                privateRootFolderId={privateRootFolderId}
              />
            )}

            {selectedTeam && isInPrivateSpace && (
              <Spacing bottom={2}>
                <Alert
                  severity="info"
                  icon={<Icon type="InfoIcon" size="m" />}
                  i18nParams={{ team: <strong>{selectedTeam.name}</strong> }}
                >
                  i18n.dialogs.moveProjectsDialog.moveOutOfPrivateSpace
                </Alert>
              </Spacing>
            )}

            <Typography variant="h4" component="h4" paragraph>
              i18n.dialogs.moveProjectsDialog.folderSelect.label
            </Typography>

            <>
              {!selectedSpaceIsCurrentSpace && (
                <DialogFolderRow
                  name="i18n.dialogs.moveProjectsDialog.teamRootFolder"
                  iconType="UsersIcon"
                  onClick={() => handleFolderSelect(selectedRootFolderId)}
                  selected={selectedRootFolderId === toFolderId}
                />
              )}

              {selectedSpaceHasFolders
                ? projectFolders
                    .filter((folder) => folder.id !== fromProjectFolderId)
                    .map((folder) => (
                      <DialogFolderRow
                        key={folder.id}
                        projectCount={folder.projectCount ?? 0}
                        name={folder.name}
                        onClick={() => handleFolderSelect(folder.id)}
                        selected={folder.id === toFolderId}
                      />
                    ))
                : selectedSpaceIsCurrentSpace && (
                    <Text variant="l" color="grey600" i18nParams={{ count }}>
                      i18n.dialogs.moveProjectsDialog.noFolders
                    </Text>
                  )}
            </>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button
          onClick={onMove}
          fullWidth
          disabled={!toFolderId}
          i18nParams={{ count }}
          data-testid="project-move-dialog-button"
        >
          i18n.dialogs.moveProjectsDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
