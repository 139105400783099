import type { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';

import {
  BlankButton,
  GridContainer,
  GridItem,
  ICON_SIZES,
  Icon,
  type IconType,
  Tooltip,
  VisuallyHidden,
  styled,
} from '@cofenster/web-components';

const Thumb = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  width: 'var(--size, 160px)',
  height: 'var(--size, 160px)',
  padding: theme.spacing(1),

  backgroundColor: theme.palette.brand.linen50,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  transition: '150ms box-shadow ease-in-out',

  '&:focus-visible': theme.mixins.focusRing,
}));

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  zIndex: theme.zIndex.above,

  display: 'flex',

  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'center',
}));

const MiddleContainer = styled(Container)(() => ({
  top: '50%',
  left: '50%',
  pointerEvents: 'none',
  transform: 'translate(calc(-50% + 14px), -50%)',
}));

const IconContainer = styled('div')<{ borderRadius?: string }>(({ theme, borderRadius }) => ({
  display: 'flex',

  alignItems: 'center',
  justifyContent: 'center',

  borderRadius: borderRadius ?? theme.shape.borderRadius,
  minWidth: ICON_SIZES.xl,
  width: 'min-content',
  height: ICON_SIZES.xl,

  backgroundColor: 'rgb(10 36 44 / 0.3)',
  backdropFilter: 'blur(10px)',
}));

const StyledLeftGridItem = styled(GridItem)(({ theme }) => ({
  '&::before': {
    color: 'currentcolor',
    content: 'counter(thumbnails)',
    display: 'block',
    textAlign: 'center',
    ...theme.typography.h5,
  },
}));

const Metadata = styled('div')(() => ({
  gridArea: 'metadata',
  textAlign: 'center',
}));

const Content = styled('div')(() => ({
  gridArea: 'content',
}));

// 1. These tooltip’d buttons are very small, and the usual 8px border-radius
//    results in essentially a circle, which is why we use a smaller curve.
const TooltipButton = styled(BlankButton)(({ theme }) => ({
  isolation: 'isolate',
  color: 'inherit',
  borderRadius: 4, // 1
  '& > svg': { display: 'block' },
  '&:focus-visible': theme.mixins.focusRing,
}));

const TextElementIcon: FC<{ count: number }> = ({ count }) => (
  <Tooltip title="i18n.projectEdit.textElement.tooltip" i18nParams={{ count }} placement="right">
    <TooltipButton>
      <Icon type="TextIcon" size="ms" />
    </TooltipButton>
  </Tooltip>
);

export const SceneTypeIcon: FC<{ title: string; iconType: IconType }> = ({ title, iconType }) => (
  <Tooltip title={title} placement="right">
    <TooltipButton>
      <Icon type={iconType} size="ms" />
    </TooltipButton>
  </Tooltip>
);

const TransitionIcon: FC<{ isExcludedScene?: boolean; isLastIncludedScene?: boolean }> = ({
  isExcludedScene,
  isLastIncludedScene,
}) => {
  if (isExcludedScene) return <Icon type="AddTransitionIcon" size="ms" />;

  return (
    <Tooltip
      title={
        isLastIncludedScene
          ? 'i18n.projectEditor.sceneAssetThumbs.transition.isLastIncludedScene'
          : 'i18n.projectEditor.sceneAssetThumbs.transition'
      }
      placement="right"
    >
      <TooltipButton>
        <Icon type="AddTransitionIcon" size="ms" style={{ opacity: isLastIncludedScene ? 0.5 : 1 }} />
      </TooltipButton>
    </Tooltip>
  );
};

type ThumbnailProps = {
  hint: ReactNode;
  hasTransition?: boolean;
  textElementsCount: number;
  image?: string | null;
  color?: string;
  isExcludedScene?: boolean;
  isLastIncludedScene?: boolean;
};

export const Thumbnail: FC<PropsWithChildren<ThumbnailProps>> = ({
  children,
  hasTransition,
  textElementsCount,
  hint,
  image,
  color,
  isExcludedScene,
  isLastIncludedScene,
  ...rest
}) => {
  return (
    <>
      <Metadata className="editorThumbnail__metadata">
        <GridContainer flexDirection="column" spacing={0} gap={0} height="100%">
          <StyledLeftGridItem>{hint}</StyledLeftGridItem>
          {textElementsCount > 0 && (
            <GridItem>
              <TextElementIcon count={textElementsCount} />
            </GridItem>
          )}
          {hasTransition && (
            <GridItem mt="auto">
              <TransitionIcon isExcludedScene={isExcludedScene} isLastIncludedScene={isLastIncludedScene} />
            </GridItem>
          )}
        </GridContainer>
      </Metadata>
      <ThumbnailContent image={image} color={color} isExcludedScene={isExcludedScene} {...rest}>
        {children}
      </ThumbnailContent>
    </>
  );
};

export const ThumbnailContent: FC<PropsWithChildren<Pick<ThumbnailProps, 'image' | 'color' | 'isExcludedScene'>>> = ({
  children,
  image,
  color,
  isExcludedScene,
  ...rest
}) => {
  return (
    <Content>
      <Thumb
        {...rest}
        style={
          {
            backgroundImage: image ? `url(${image})` : undefined,
            backgroundColor: color,
          } as CSSProperties
        }
        data-testid="scene-thumbnail"
      >
        {children}

        {isExcludedScene && (
          <MiddleContainer>
            <IconContainer borderRadius="100%">
              <Icon type="EyeSlashIcon" color="white" />
              <VisuallyHidden>i18n.sceneExcluded</VisuallyHidden>
            </IconContainer>
          </MiddleContainer>
        )}
      </Thumb>
    </Content>
  );
};
