import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Icon, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useTeam } from '../../api/hooks/team/useTeam';
import { Redirect } from '../../components/common/Redirect';
import { AppLayout } from '../../components/page/Layout';
import { ProjectSpaceSubNavigation } from '../../components/project/ProjectSpaceBar/ProjectSpaceSubNavigation';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { useSetProjectSpace } from '../../contexts/projectSpace/useProjectSpace';
import { RolesProvider } from '../../contexts/roles/RolesProvider';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import type { TeamRouteParams } from '../../routes';
import { NoAccessLayout } from '../NoAccess';

import { TeamMembersContent } from './TeamMembersContent';

export const TeamMembers: FC = () => {
  const { teamId } = useParams() as TeamRouteParams;
  const { team, error } = useTeam(teamId);
  const { openDialog } = useDialogs();
  const teamName = team?.name ?? '';
  const isPrivate = team ? !team.public : false;

  useSetProjectSpace(team?.id);

  if (error) return <NoAccessLayout resource="Team" />;
  if (team && !isPrivate) return <Redirect to="teamSettings" params={{ teamId: team.id }} />;

  return (
    <RolesProvider>
      <CurrentTeamProvider team={team}>
        <AppLayout
          documentTitle={team?.name ?? 'i18n.team.members'}
          header={{
            topLeft: <PageHeadline mb={0} title={teamName} isPrivate={isPrivate} />,
            topRight: team ? (
              <TeamPermissionRestriction has="MemberUpdate">
                <Button
                  startIcon={<Icon type="UserIcon" size="s" />}
                  onClick={() => openDialog('AddTeamMembersDialog', { team })}
                  data-testid="add-member-button"
                >
                  i18n.team.addMembers
                </Button>
              </TeamPermissionRestriction>
            ) : null,
            bottomLeft: team && <ProjectSpaceSubNavigation team={team} folderId={team.rootProjectFolder.id} />,
          }}
        >
          {team ? <TeamMembersContent team={team} /> : <LoadingSpinner />}
        </AppLayout>
      </CurrentTeamProvider>
    </RolesProvider>
  );
};
