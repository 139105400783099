import { useCallback } from 'react';

import { type UnarchiveProjectFoldersMutationVariables, useUnarchiveProjectFoldersMutation } from '../../generated';

export const useUnarchiveProjectFolder = () => {
  const [unarchiveProjectFolder] = useUnarchiveProjectFoldersMutation({ refetchQueries: ['ProjectFolders'] });

  return useCallback(
    (ids: UnarchiveProjectFoldersMutationVariables['ids']) => unarchiveProjectFolder({ variables: { ids } }),
    [unarchiveProjectFolder]
  );
};
