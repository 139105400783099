import { type Dispatch, type SetStateAction, createContext } from 'react';

import type { User } from '../../api/hooks/user/useMe';
import type { SigninResult } from '../../api/hooks/user/useSignin';
export type { User } from '../../api/hooks/user/useMe';

export type UserContext = {
  user: User | undefined;
  signin: (email: string, password: string) => Promise<SigninResult | undefined>;
  signout: () => Promise<boolean>;
  hasSessionExpired: boolean;
  setHasSessionExpired: Dispatch<SetStateAction<boolean>>;
};

export const UserContext = createContext<UserContext>({
  user: undefined,
  signin: () => Promise.reject(new Error('Missing UserContext in tree')),
  signout: () => Promise.reject(new Error('Missing UserContext in tree')),
  hasSessionExpired: false,
  setHasSessionExpired: () => undefined,
});
