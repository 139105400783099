import {
  ICON_SIZES,
  Icon,
  ProgressCircle,
  preventForwardProps,
  styled,
  useRenderJobProgress,
  useRenderJobProgressIconAndDescription,
} from '@cofenster/web-components';
import type { FC } from 'react';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';

const RotatingIcon = styled(
  Icon,
  preventForwardProps(['enabled'])
)<{ enabled: boolean }>(({ enabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: enabled ? 'rotate 10s linear infinite' : undefined,
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0turn)' },
    '10%, 60%': { transform: 'rotate(0.5turn)' },
    '70%, 100%': { transform: 'rotate(1turn)' },
  },
}));

export const RenderJobProgressIconInformation: FC<{
  step: RenderJob['step'];
  status: RenderJob['status'];
  stepProgress: RenderJob['stepProgress'];
}> = ({ step, status, stepProgress }) => {
  const hasFailed = status === 'Failed';
  const progress = useRenderJobProgress(status, step, stepProgress ?? 0);
  const { iconType } = useRenderJobProgressIconAndDescription(progress, hasFailed);

  return (
    <ProgressCircle
      size={ICON_SIZES.l}
      color={hasFailed ? 'negative' : 'blue'}
      progress={progress}
      trackColor="white"
      strokeWidth={2}
    >
      {hasFailed ? (
        <Icon type="WarningCircleIcon" size={18} color="negativeDark" weight="fill" />
      ) : (
        <RotatingIcon enabled={progress < 0.1} type={progress < 0.1 ? 'HourglassIcon' : iconType} size={18} />
      )}
    </ProgressCircle>
  );
};
