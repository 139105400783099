import { useCallback } from 'react';

import { LAYER_INDEXES } from '@cofenster/constants';
import { useUpsertColorAsset } from '../../../api/hooks/colorAsset/useUpsertColorAsset';
import type { ColorSceneAsset } from '../../../api/hooks/scene/useScenes';
import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export const useUpdateColor = (sceneId: string, sceneAsset?: ColorSceneAsset) => {
  const upsertColorAsset = useUpsertColorAsset();
  const createSceneAsset = useCreateSceneAsset();
  const tracking = useWebManagerTracking();

  const trackSceneAssetUploaded = useCallback(
    (color: string) =>
      tracking.trackEvent({
        event: 'SceneAssetUploaded',
        details: {
          sceneId,
          assetType: 'color',
          assetName: color,
          uploadSource: 'app-interface',
        },
      }),
    [sceneId, tracking]
  );

  return useCallback(
    async (color: string) => {
      const colorAssetId = sceneAsset?.asset?.colorAsset?.id;
      const { data } = await upsertColorAsset(colorAssetId, { color });

      if (data) {
        if (!colorAssetId) {
          await createSceneAsset({
            assetType: 'Color',
            assetId: data.upsertColorAsset.id,
            sceneId: sceneId,
            layerIndex: LAYER_INDEXES.main,
            startTime: 0,
          });

          trackSceneAssetUploaded(color);
          return true;
        }
      }

      return false;
    },
    [sceneAsset?.asset?.colorAsset?.id, createSceneAsset, upsertColorAsset, sceneId, trackSceneAssetUploaded]
  );
};
