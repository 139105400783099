import { useCallback, useEffect, useState } from 'react';
import type { RemotionPlayerRef } from './Player';

const log = 'Attempting to call `%s()` but there are no player controls yet; skipping.';

export const usePlayerPausedState = (remotionPlayer: RemotionPlayerRef | null) => {
  // Deriving the `paused` state from the player itself as an external store
  // (i.e. `useSyncExternalStore`) does not play well when rapidly playing and
  // pausing the player. To avoid desynchronized states, we maintain a local
  // state for the paused state.
  const [paused, setPaused] = useState<boolean>(remotionPlayer?.paused ?? true);
  const [playerControls, setPlayerControls] = useState<RemotionPlayerRef | null>(null);

  useEffect(() => {
    const setIsPaused = () => setPaused(true);
    const setIsNotPaused = () => setPaused(false);

    remotionPlayer?.addEventListener?.('pause', setIsPaused);
    remotionPlayer?.addEventListener?.('play', setIsNotPaused);
    return () => {
      remotionPlayer?.removeEventListener?.('pause', setIsPaused);
      remotionPlayer?.removeEventListener?.('play', setIsNotPaused);
    };
  }, [remotionPlayer]);

  const play = useCallback(() => {
    if (!playerControls) return console.warn(log, 'play');
    setPaused(false);
    playerControls.play();
  }, [playerControls]);

  const pause = useCallback(() => {
    if (!playerControls) return console.warn(log, 'pause');
    setPaused(true);
    playerControls.pause();
  }, [playerControls]);

  return { paused, play, pause, setPlayerControls };
};
