import type { FC } from 'react';

import { Container, Headline, styled } from '@cofenster/web-components';

import { useUser } from '../../contexts/user/useUser';

import { HomeCTA } from './HomeCTA';
import { HomeSearchField } from './HomeSearchField';

const Header = styled('div')(({ theme }) => ({
  backgroundColor: '#00F6CA',
  backgroundImage: 'url("/assets/images/home-hero.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  margin: theme.spacing(2),
  borderRadius: theme.shape['borderRadius-l'],
}));

const MainSection = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 2),
  gap: theme.spacing(4),
}));

export const HomeHeader: FC = () => {
  const { user } = useUser();

  return (
    <Header>
      <ContentContainer>
        <Headline align="center" variant="h1" component="h1" i18nParams={{ name: user?.firstname }}>
          i18n.home.headline
        </Headline>

        <MainSection>
          <HomeSearchField />
          <HomeCTA />
        </MainSection>
      </ContentContainer>
    </Header>
  );
};
