import { useCallback } from 'react';

import { useScenes } from '../../../../../contexts/scenes/useScenes';

export const useIsLastIncludedScene = () => {
  const { scenes } = useScenes();

  return useCallback(
    (sceneId: string) => {
      const includedScenes = scenes.filter((scene) => !scene.excluded);
      const lastScene = includedScenes[includedScenes.length - 1];

      return lastScene?.id === sceneId;
    },
    [scenes]
  );
};
