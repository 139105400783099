import { useCallback } from 'react';

import { type RemoveTeamMembersMutationVariables, useRemoveTeamMembersMutation } from '../../generated';

export const useRemoveTeamMembers = () => {
  const [removeTeamMembers] = useRemoveTeamMembersMutation({
    refetchQueries: ['Team', 'ProjectFolder'],
    awaitRefetchQueries: true,
  });
  return useCallback(
    async (id: RemoveTeamMembersMutationVariables['id'], input: RemoveTeamMembersMutationVariables['input']) =>
      removeTeamMembers({ variables: { id, input } }),
    [removeTeamMembers]
  );
};
