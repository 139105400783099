import { useCallback } from 'react';

import { type DeleteUserMutationVariables, useDeleteUserMutation } from '../../generated';

export const useDeleteUser = () => {
  const [deleteUser] = useDeleteUserMutation({ refetchQueries: ['Me'], awaitRefetchQueries: true });
  return useCallback(
    (id: DeleteUserMutationVariables['id']) => {
      return deleteUser({ variables: { id } });
    },
    [deleteUser]
  );
};
