import type { FC } from 'react';

import { GridContainer, GridItem, styled } from '@cofenster/web-components';

import type { Project } from '../../../api/hooks/project/useProjects';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { NewButton } from '../NewButton';

import { ProjectThumbnail } from './ProjectThumbnail';

type Props = {
  projects: Project[];
  folderId: string;
  archived?: boolean;
  withOwner?: boolean;
};

// 1. Set up the same aspect ratio as the thumbnail of a project tile
// 2. Prevent the button from spreading the entire height of its parent
const CreateProjectButton = styled(NewButton)(() => ({
  '--direction': 'column',
  aspectRatio: 16 / 9, // 1
  minHeight: 0, // 2
}));

export const ProjectsGridView: FC<Props> = ({ projects, folderId, archived, withOwner }) => {
  return (
    <GridContainer pt={1} spacing={2}>
      {!archived && (
        <TeamPermissionRestriction has="ProjectCreate">
          <GridItem xs={12} md={6} lg={4}>
            <CreateProjectButton to="projectCreate" params={{ folderId }}>
              i18n.projects.buttons.project
            </CreateProjectButton>
          </GridItem>
        </TeamPermissionRestriction>
      )}
      {projects.map((project) => (
        <GridItem key={project.id} xs={12} md={6} lg={4}>
          <ProjectThumbnail
            project={project}
            projectFolderId={folderId}
            withOwner={withOwner}
            refetchQueries={['Projects']}
          />
        </GridItem>
      ))}
    </GridContainer>
  );
};
