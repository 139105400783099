import { type FC, useCallback } from 'react';

import { Button, Card, GridContainer, GridItem, Typography, styled } from '@cofenster/web-components';

import type { Team } from '../../../api/hooks/team/useTeam';
import { CardHeadline } from '../../../components/card/CardHeadline';
import { FeatureFlagRestriction } from '../../../components/featureFlag/FeatureFlagRestriction';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useSetProjectSpace } from '../../../contexts/projectSpace/useProjectSpace';
import { useGotoHomeHandler } from '../../../hooks/navigation/useGotoHomeHandler';

import { AccountPermissionRestriction } from '../../..//contexts/user/AccountPermissionRestriction';
import { TeamNameForm } from './TeamNameForm';

const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: theme.spacing(3.125),
}));

const useOnDelete = (teamId: string, teamName: string) => {
  const { openDialog } = useDialogs();
  const gotoHome = useGotoHomeHandler();
  return useCallback(async () => {
    openDialog('DeleteTeamDialog', { teamId, teamName, gotoHome });
  }, [teamName, openDialog, teamId, gotoHome]);
};

type TeamSettingsContentProps = {
  team: Team;
};

export const TeamSettingsContent: FC<TeamSettingsContentProps> = ({ team }) => {
  const teamName = team.name;
  const onDelete = useOnDelete(team.id, teamName);

  useSetProjectSpace(team?.id);

  return (
    <GridContainer spacing={5}>
      <GridItem xs={12} lg={6} pb={1}>
        <TeamNameForm teamId={team.id} teamName={teamName} />
      </GridItem>

      {team.deletable && (
        <AccountPermissionRestriction has="TeamDelete">
          <FeatureFlagRestriction has="MULTIPLE_TEAMS">
            <GridItem xs={12} lg={6} pb={1}>
              <Card>
                <CardHeadline color="carbon" variant="h4" component="h2">
                  i18n.team.delete
                </CardHeadline>

                <Typography>i18n.team.delete.info</Typography>

                <CardFooter>
                  <Button variant="destructive" onClick={onDelete} data-testid="delete-team-button">
                    i18n.team.delete
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </FeatureFlagRestriction>
        </AccountPermissionRestriction>
      )}
    </GridContainer>
  );
};
