import { useCallback } from 'react';

import { type UpsertColorAssetMutationVariables, useUpsertColorAssetMutation } from '../../generated';

export const useUpsertColorAsset = () => {
  const [upsertColorAsset] = useUpsertColorAssetMutation({
    refetchQueries: ['Scenes', 'ProjectRenderDescription', 'SceneRenderDescription'],
  });
  return useCallback(
    (id: UpsertColorAssetMutationVariables['id'], input: UpsertColorAssetMutationVariables['input']) =>
      upsertColorAsset({ variables: { id, input } }),
    [upsertColorAsset]
  );
};
