import { styled } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { TextLink } from '../../controls/TextLink';
import { Typography } from '../../typography/Typography';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

// 1. Buttons are full-height by default, this ensures they are top-aligned if
//    the title spans across several lines.
const ButtonWrapper = styled('div')(() => ({
  alignSelf: 'flex-start', // 1
}));

const Title = styled(Typography)(() => ({
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

type Props = {
  title?: string;
  popover?: ReactNode;
};

const SkipLink = styled(TextLink)(({ theme }) => ({
  whiteSpace: 'nowrap',
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1),

  '&:not(:focus)': {
    marginLeft: 0,
    width: 0,
    height: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
}));

export const ProjectPageHeadline: FC<PropsWithChildren<Props>> = ({ title, popover }) => {
  if (!title) {
    return null;
  }

  return (
    <Container>
      <Title variant="h5" component="h1">
        {title}
      </Title>

      <SkipLink href="#content" data-testid="skip-link">
        <Typography variant="l">i18n.global.skipNavigation</Typography>
        <Icon type="ArrowRightIcon" size="m" />
      </SkipLink>

      {popover && <ButtonWrapper>{popover}</ButtonWrapper>}
    </Container>
  );
};
