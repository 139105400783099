import type { FC, PropsWithChildren } from 'react';

import type { Scene, SceneColor } from '../../../../../../api/hooks/scene/useScenes';
import { useIsLastIncludedScene } from '../../hooks/useIsLastIncludedScene';

import { SceneTypeIcon, Thumbnail } from './Thumbnail';

type ColorThumbnailProps = PropsWithChildren<{
  scene: Scene;
  sceneColor?: SceneColor | null;
}>;

export const ColorThumbnail: FC<ColorThumbnailProps> = ({ children, scene, sceneColor }) => {
  const textElementsCount = scene.sceneTexts.length;
  const isLastIncludedScene = useIsLastIncludedScene()(scene.id);

  return (
    <Thumbnail
      hint={<SceneTypeIcon title="i18n.sceneTypes.color" iconType="EyedropperIcon" />}
      hasTransition={scene.withTransition}
      isLastIncludedScene={isLastIncludedScene}
      textElementsCount={textElementsCount}
      color={sceneColor?.colorAsset?.color}
      isExcludedScene={scene.excluded}
      data-scene-type="color"
    >
      {children}
    </Thumbnail>
  );
};
