import { useCallback } from 'react';
import { useActionSceneIds } from '../../../contexts/selectedScenes/useActionSceneIds';
import { useWebManagerTracking } from '../../useWebManagerTracking';
import { useDeleteVideoSceneAsset } from './useDeleteVideoSceneAsset';

export const useDeleteVideoSceneAssetWithTracking = (
  sceneId: string,
  additionalTrackingDetails?: Record<string, string | number | undefined | null>
) => {
  const actionSceneIds = useActionSceneIds(sceneId);
  const deleteVideoSceneAsset = useDeleteVideoSceneAsset(sceneId);
  const tracking = useWebManagerTracking();

  const trackSceneAssetDeleted = useCallback(
    () =>
      tracking.trackEvent({
        event: 'SceneAssetDeleted',
        details: {
          sceneIds: actionSceneIds,
          location: 'projectEdit',
          ...additionalTrackingDetails,
        },
      }),
    [tracking, actionSceneIds, additionalTrackingDetails]
  );

  return useCallback(async () => {
    await deleteVideoSceneAsset();
    trackSceneAssetDeleted();
  }, [deleteVideoSceneAsset, trackSceneAssetDeleted]);
};
