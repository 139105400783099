import { useMemo } from 'react';

import type { QueryHookOptions } from '@cofenster/api-client-apollo';

import {
  type SearchProjectsQuery,
  type SearchProjectsQueryResult,
  type SearchProjectsQueryVariables,
  useSearchProjectsQuery,
} from '../../generated';

export type ExtendedProject = SearchProjectsQuery['searchProjects']['items'][number];

export type ProjectsFilter = NonNullable<SearchProjectsQueryVariables['filter']>;

export type PaginatedProjects = NonNullable<SearchProjectsQueryResult['data']>['searchProjects'];

export type SearchProjectsResponse = SearchProjectsQuery;

type FetchPolicy = NonNullable<Parameters<typeof useSearchProjectsQuery>[0]>['fetchPolicy'];

export const useSearchProjects = (filter: ProjectsFilter, options: Omit<QueryHookOptions, 'variables'>) => {
  const fetchPolicy: FetchPolicy = options.fetchPolicy ?? 'cache-and-network';
  const { error, loading, data } = useSearchProjectsQuery({
    ...options,
    fetchPolicy,
    variables: { filter },
  });

  const paginatedProjects = data?.searchProjects;

  return useMemo(() => ({ paginatedProjects, loading, error }), [paginatedProjects, loading, error]);
};
