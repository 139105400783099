import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FromProjectTeamProvider } from '../../contexts/currentTeam/FromProjectTeamProvider';
import { ProjectProvider } from '../../contexts/project/ProjectProvider';
import { ScenesProvider } from '../../contexts/scenes/ScenesProvider';
import type { ProjectRouteParams } from '../../routes';

import { ProjectDesignContent } from './ProjectDesignContent';

export const ProjectDesign: FC = () => {
  const { projectId } = useParams() as ProjectRouteParams;

  return (
    <ProjectProvider projectId={projectId}>
      <ScenesProvider projectId={projectId} pollingInterval={60000}>
        <FromProjectTeamProvider>
          <ProjectDesignContent />
        </FromProjectTeamProvider>
      </ScenesProvider>
    </ProjectProvider>
  );
};
