import { styled } from '@mui/material';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { preventForwardProps } from '../../../utilities/preventForwardProps';

// 1. Negate the lateral padding from the navigation items.
const Container = styled(
  'div',
  preventForwardProps(['fullWidth', 'withBorderBottom'])
)<{ fullWidth?: boolean; withBorderBottom?: boolean }>(({ theme, fullWidth, withBorderBottom }) => ({
  alignItems: 'space-around',
  position: 'relative',
  display: 'flex',
  width: fullWidth ? '100%' : `calc(100% + ${theme.spacing(2)} * 2)`,
  margin: theme.spacing(0, -2), // 1
  borderBottom: withBorderBottom ? `1px solid ${theme.palette.brand.linen50}` : undefined,
}));

// 1. Enable momentum-based scrolling on supporting touch devices.
//    See: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
// 2. Add subtle shadows on the scrollable side(s) to hint that there is more
//    content beyond the edge.
//    See: https://lea.verou.me/2012/04/background-attachment-local/
// 3. Hide the scrollbar as it looks a bit odd to have a horizontal scrollbar
//    for an overflowing navigation.
const Main = styled('div')(({ theme }) => ({
  alignItems: 'center',
  flex: 1,
  display: 'flex',

  overflow: 'auto', // 1
  WebkitOverflowScrolling: 'touch', // 1

  '--semi-transparent': 'rgb(0 0 0 / 0.1)',

  background: [
    'linear-gradient(to right, var(--sub-navigation-color) 30%, transparent)',
    'linear-gradient(to right, transparent, var(--sub-navigation-color) 70%) 100% 0',
    'radial-gradient(farthest-side at 0 50%, var(--semi-transparent), transparent)',
    'radial-gradient(farthest-side at 100% 50%, var(--semi-transparent), transparent) 100% 0',
  ].join(', '), // 2
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'var(--sub-navigation-color)',
  backgroundSize: '40px 100%, 40px 100%, 14px 100%, 14px 100%',
  backgroundAttachment: 'local, local, scroll, scroll',

  [theme.breakpoints.down('sm')]: {
    scrollbarWidth: 'none', // 3
    MsOverflowStyle: 'none', // 3
    '&::-webkit-scrollbar': { width: 0, display: 'none' }, // 3
  },
}));

const SearchContainer = styled(Main)(() => ({
  flex: 0,
  justifyContent: 'flex-end',
}));

export type SubNavigationProps = {
  fullWidth?: boolean;
  withBorderBottom?: boolean;
  search?: ReactNode;
  role?: string;
};

export const SubNavigation: FC<PropsWithChildren<SubNavigationProps>> = ({
  children,
  role,
  search: Search,
  fullWidth,
  withBorderBottom,
}) => {
  return (
    <Container fullWidth={fullWidth} withBorderBottom={withBorderBottom}>
      <Main role={role}>{children}</Main>
      <SearchContainer>{Search}</SearchContainer>
    </Container>
  );
};
