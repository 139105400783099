import { useCallback } from 'react';

import { type ImportStockImageMutationVariables, useImportStockImageMutation } from '../../generated';

export const useImportStockImage = () => {
  const [importStockImage] = useImportStockImageMutation();
  return useCallback(
    (id: ImportStockImageMutationVariables['id'], input: ImportStockImageMutationVariables['input']) =>
      importStockImage({ variables: { id, input } }),
    [importStockImage]
  );
};
