import type { AudioAsset, ImageAsset, SceneAsset, VideoAsset } from '../../api/hooks/scene/useScenes';
import { isAudioSceneAsset, isImageSceneAsset, isVideoSceneAsset } from '../../helpers/sceneAssets/is';

export const getAssetFromSceneAsset = (sceneAsset: SceneAsset): VideoAsset | ImageAsset | AudioAsset | undefined => {
  if (isVideoSceneAsset(sceneAsset)) {
    if (sceneAsset.asset?.enhancedAudioEnabled && sceneAsset.asset.videoAssetWithEnhancedAudio) {
      return sceneAsset.asset.videoAssetWithEnhancedAudio;
    }
    return sceneAsset.asset?.videoAsset ?? undefined;
  }
  if (isImageSceneAsset(sceneAsset)) return sceneAsset.asset?.imageAsset ?? undefined;
  if (isAudioSceneAsset(sceneAsset)) return sceneAsset.asset?.audioAsset ?? undefined;
  return undefined;
};
