import { styled } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import { Icon, type IconType } from '../../../assets/icons/Icon';
import { Typography } from '../../../typography/Typography';

import { BaseLink, type BaseLinkProps } from './BaseLink';

const StyledBaseLink = styled(
  BaseLink,
  preventForwardProps(['isLarge'])
)<{ isLarge: boolean }>(({ theme, isLarge }) => ({
  paddingTop: isLarge ? theme.spacing(1.5) : undefined,
  paddingBottom: isLarge ? theme.spacing(1.5) : undefined,
}));

export type LinkProps = Omit<BaseLinkProps, 'children'> & {
  children: string;
  startIcon?: IconType;
};

export const Link = ({ children, startIcon, isSelected, ...rest }: PropsWithChildren<LinkProps>) => (
  <StyledBaseLink {...rest} isSelected={isSelected} aria-current={!!isSelected} isLarge={!!startIcon}>
    {startIcon && <Icon type={startIcon} size="m" />}
    <Typography variant="l">{children}</Typography>
  </StyledBaseLink>
);
