import { type FC, type PropsWithChildren, useMemo } from 'react';

import { useProjectRenderDescription } from '../../api/hooks/renderDescription/useProjectRenderDescription';
import { NoAccessLayout } from '../../pages/NoAccess';

import { ProjectRenderDescriptionContext } from './ProjectRenderDescriptionContext';

type Props = {
  projectId?: string;
  subtitlesLanguage?: string;
};

export const ProjectRenderDescriptionProvider: FC<PropsWithChildren<Props>> = ({
  children,
  projectId,
  subtitlesLanguage,
}) => {
  const { loading, error, projectRenderDescription, refetch } = useProjectRenderDescription(
    projectId,
    subtitlesLanguage
  );

  const context = useMemo(
    () => ({
      projectRenderDescription,
      loading,
      error,
      refetch,
    }),
    [projectRenderDescription, loading, error, refetch]
  );

  if (error) return <NoAccessLayout resource="Project" />;

  return (
    <ProjectRenderDescriptionContext.Provider value={context}>{children}</ProjectRenderDescriptionContext.Provider>
  );
};
