import type { FC } from 'react';

import { LoadingSpinner, SearchResults } from '@cofenster/web-components';

import type { PaginatedProjects } from '../../api/hooks/project/useSearchProjects';

import { useProjectSearchFilter } from '../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { HomeProjectSearchResults } from './HomeProjectSearchResults';
import { HomeSection } from './HomeSection';
import { HomeSectionControls } from './HomeSectionControls';

export const HomeSearch: FC<{
  paginatedProjects?: PaginatedProjects;
  loading: boolean;
}> = ({ paginatedProjects, loading }) => {
  const { filter } = useProjectSearchFilter();
  const search = filter.search ?? '';
  const count = paginatedProjects?.total ?? 0;

  return (
    <SearchResults id="search-projects" search={search} count={count}>
      <HomeSection
        title={loading ? 'i18n.global.search.results.header.loading' : 'i18n.global.search.results.header'}
        i18nParams={{ search, count }}
        controls={<HomeSectionControls />}
      >
        {loading ? <LoadingSpinner /> : <HomeProjectSearchResults paginatedProjects={paginatedProjects} />}
      </HomeSection>
    </SearchResults>
  );
};
