import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

import { useSetUserActive } from '../../../api/hooks/user/useSetUserActive';
import type { User } from '../../../api/hooks/user/useUser';

const useSetActive = (user: User, closeDialog: () => unknown) => {
  const setUserActive = useSetUserActive();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    await setUserActive(user.id, { isActive: true });
    closeDialog();
    openSnackbar({
      children: 'i18n.snackbars.userReactivatedSnackbar.copy',
      variant: 'success',
    });
  }, [user, setUserActive, closeDialog, openSnackbar]);
};

export type ReactivateUserDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const ReactivateUserDialog: FC<ReactivateUserDialogProps> = ({ isOpen, closeDialog, user }) => {
  const onAction = useSetActive(user, closeDialog);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="user-reactivate-dialog"
      title="i18n.dialogs.reactivateUserDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.reactivateUserDialog.description
        </Text>
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button variant="primary" fullWidth onClick={onAction} data-testid="user-reactivate-dialog-confirm-button">
          i18n.dialogs.reactivateUserDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
