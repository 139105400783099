import { type CSSProperties, type FC, type Key, type MouseEventHandler, type PropsWithChildren, useMemo } from 'react';

import { VisuallyHidden } from '../../utilities/VisuallyHidden';

import type { TableComponent, TableConfig } from './types';

export function createTable<Item, Extra = unknown>({
  wrappers,
  columns,
}: TableConfig<Item, Extra>): TableComponent<Item> {
  const displayHeader = columns.some((column) => column.header);
  const displayFooter = columns.some((column) => column.footer);
  const Header: FC<PropsWithChildren> = ({ children }) =>
    displayHeader ? (
      <wrappers.Header>{children}</wrappers.Header>
    ) : (
      <VisuallyHidden as="thead">{children}</VisuallyHidden>
    );

  return function Table({ data, 'data-testid': dataTestId, onRowClick }) {
    const handleClick = useMemo<MouseEventHandler<HTMLElement> | undefined>(() => {
      if (typeof onRowClick !== 'function') return;

      return (event) => {
        const target = event.target as HTMLElement;
        const row = target.closest('tr');

        // If we do not recognize the DOM structure, or are clicking the table
        // headers, do nothing as this is not a legitimate row click
        if (!row || row.closest('thead') || !row.parentNode) return undefined;

        // If we clicked an interactive element, we had a specific intent
        // already and therefore should not capture the row click
        if (target.closest('a, button')) return undefined;

        const rowIndex = Array.prototype.indexOf.call(row.parentNode.childNodes, row);
        const dataRow = data[rowIndex];

        if (dataRow) onRowClick(dataRow);
      };
    }, [data, onRowClick]);

    return (
      <wrappers.Table data-testid={dataTestId} columns={columns} onClick={handleClick}>
        <Header>
          <wrappers.Row>
            {columns.map((column, colIndex) => (
              <wrappers.HeaderCell key={colIndex} extra={column.extra} scope="col">
                {typeof column.header === 'function' ? column.header({ column }) : column.header}
              </wrappers.HeaderCell>
            ))}
          </wrappers.Row>
        </Header>
        <wrappers.Data>
          {data.map((item, rowIndex) => (
            <wrappers.Row
              key={typeof item === 'object' && item !== null && 'id' in item ? (item.id as Key) : rowIndex}
              isInteractive={Boolean(onRowClick)}
              style={{ '--index': rowIndex } as CSSProperties}
            >
              {columns.map((column, colIndex) => (
                <wrappers.Cell key={colIndex} extra={column.extra}>
                  {typeof column.cell === 'function' ? column.cell({ item, column }) : column.cell}
                </wrappers.Cell>
              ))}
            </wrappers.Row>
          ))}
        </wrappers.Data>
        {displayFooter && (
          <wrappers.Footer>
            <wrappers.Row>
              {columns.map((column, colIndex) => (
                <wrappers.FooterCell key={colIndex} extra={column.extra}>
                  {typeof column.footer === 'function' ? column.footer({ column }) : column.footer}
                </wrappers.FooterCell>
              ))}
            </wrappers.Row>
          </wrappers.Footer>
        )}
      </wrappers.Table>
    );
  };
}
