import { styled } from '@mui/material';
import { forwardRef } from 'react';

import { Button, type ButtonProps } from '../Button/Button';

// 1. Apply disabled styles to the button without making it actually `disabled`,
//    which would hinder discoverability by assistive technologies (as disabled
//    controls are essentially removed from the DOM/AOM) and would cause the
//    tooltip not to work either.
export const StyledButton = styled(Button)(({ theme }) => ({
  '&[aria-disabled="true"]': {
    backgroundColor: theme.palette.brand.grey50, // 1
    color: theme.palette.brand.grey700, // 1
    cursor: 'not-allowed', // 1
  },
}));

export type AriaDisabledButtonProps = ButtonProps;

export const AriaDisabledButton = forwardRef<HTMLButtonElement, AriaDisabledButtonProps>(
  function AriaDisabledButton(props, ref) {
    return <StyledButton aria-disabled={true} ref={ref} {...props} />;
  }
);
