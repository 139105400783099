import type { FC } from 'react';

import { GridItem } from '@cofenster/web-components';

import type { Project, Team } from '../../api/hooks/project/useLatestProjects';
import { ProjectRow } from '../../components/team/Projects/ProjectRow';
import { ProjectThumbnail } from '../../components/team/Projects/ProjectThumbnail';
import { useListViewType } from '../../contexts/listViewType/useListViewType';

import { HomeProjectContext } from './HomeProjectContext';

export const HomeProject: FC<{ project: Project; team: Team; refetchQueries?: string[] }> = ({
  project,
  team,
  refetchQueries,
}) => {
  const { type } = useListViewType();

  return (
    <HomeProjectContext project={project} team={team}>
      {type === 'GRID' ? (
        <GridItem xs={12} md={6} lg={4}>
          <ProjectThumbnail
            project={project}
            projectFolderId={project.projectFolders[0]?.id}
            withOwner
            withBulkSelection={false}
            refetchQueries={refetchQueries}
          />
        </GridItem>
      ) : (
        <GridItem xs={12}>
          <ProjectRow
            project={project}
            projectFolderId={project.projectFolders[0]?.id}
            withOwner
            withBulkSelection={false}
            refetchQueries={refetchQueries}
          />
        </GridItem>
      )}
    </HomeProjectContext>
  );
};
