import { type FC, useCallback } from 'react';

import { Button, GridContainer, GridItem } from '@cofenster/web-components';
import { useSaveAudioOverlay } from './useSave';

export const AudioOverlayPanelFooter: FC<{
  closePanel: VoidFunction;
  cancel: VoidFunction;
  isCanceling: boolean;
}> = ({ closePanel, cancel, isCanceling }) => {
  const [save, isSaving] = useSaveAudioOverlay();
  const handleSave = useCallback(async () => {
    await save();
    closePanel();
  }, [save, closePanel]);

  return (
    <GridContainer>
      <GridItem xs={5}>
        <Button fullWidth onClick={cancel} loading={isCanceling} variant="tertiary">
          i18n.global.cancel
        </Button>
      </GridItem>
      <GridItem xs={7}>
        <Button data-testid="scene-audio-panel-save" onClick={handleSave} fullWidth loading={isSaving}>
          i18n.global.save
        </Button>
      </GridItem>
    </GridContainer>
  );
};
