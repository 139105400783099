import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useVerifyToken } from '../../api/hooks/user/useVerifyToken';
import { Redirect } from '../../components/common/Redirect';
import { PageHeadline, PageSubline, UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';
import type { ResetPasswordRouteParams } from '../../routes';

import { ResetPasswordFrom } from './ResetPasswordFrom';

export const ResetPassword: FC = () => {
  const { token } = useParams() as ResetPasswordRouteParams;
  const { status } = useVerifyToken(token);

  if (status && status !== 'valid') {
    return <Redirect to="forgotPassword" state={{ error: status }} />;
  }

  if (!token) return <Redirect to="signin" />;

  return (
    <UnauthenticatedPage title="i18n.reset.title">
      <PageHeadline variant="h1">i18n.reset.headline</PageHeadline>
      <PageSubline variant="xl" component="p">
        i18n.reset.subline
      </PageSubline>
      <ResetPasswordFrom />
    </UnauthenticatedPage>
  );
};
