import { type I18nContext, useI18n as useI18nHook } from '@cofenster/web-components';

import type { TRANSLATIONS } from '.';

export type I18n = I18nContext<TRANSLATIONS>;

export const useI18n = () => {
  const context = useI18nHook();
  if (!context) throw new Error('I18n provider missing in tree');
  return context as I18nContext<TRANSLATIONS>;
};
