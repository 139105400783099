import { useCallback } from 'react';

import { type AddTeamMembersMutationVariables, useAddTeamMembersMutation } from '../../generated';

export const useAddTeamMembers = () => {
  const [addTeamMembers] = useAddTeamMembersMutation({ refetchQueries: ['Team', 'ProjectFolder'] });
  return useCallback(
    async (id: AddTeamMembersMutationVariables['id'], input: AddTeamMembersMutationVariables['input']) =>
      addTeamMembers({ variables: { id, input } }),
    [addTeamMembers]
  );
};
