import { styled } from '@mui/material';
import type { ComponentProps } from 'react';

import { BlankButton } from '../../../controls/Button/BlankButton';

import { BaseItem } from './BaseItem';

export type BaseButtonProps = ComponentProps<typeof BaseButton>;

export const BaseButton = styled(BaseItem.withComponent(BlankButton))(({ theme }) => ({
  transition: '150ms',

  '&:hover, &:focus-visible, &:active': {
    outline: 0,
    color: theme.palette.brand.white,
  },

  '&:hover, &:focus-visible': {
    backgroundColor: theme.palette.brand.grey900,
  },

  '&:active': {
    backgroundColor: theme.palette.brand.grey700,
    borderColor: theme.palette.brand.grey600,
  },
}));
