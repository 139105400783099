import type { FC } from 'react';

import { EmptyState, Icon } from '@cofenster/web-components';

import { SendRequestButton } from '../SendRequestButton';

export const ContributionRecordingsEmptyState: FC<{
  goToProjectContributionsPage?: () => void;
}> = ({ goToProjectContributionsPage }) => {
  return (
    <EmptyState
      backgroundColor="transparent"
      title="i18n.projectContributionConfiguration.recordings.emptyState.title"
      description="i18n.projectContributionConfiguration.recordings.emptyState.description"
      icon={<Icon type="ProhibitInsetIcon" size="l" background={{ size: 'xxl', color: 'linen50' }} />}
      cta={<SendRequestButton onClick={goToProjectContributionsPage} />}
    />
  );
};
