import type { FC } from 'react';

import { BlankButton, Headline, VideoAssetPreview, preventForwardProps, styled } from '@cofenster/web-components';

import { useI18n } from '../../../../i18n/useI18n';

import type { Template } from './';

const Card = styled('div')(() => ({
  position: 'relative',
}));

const PreviewContainer = styled(
  'div',
  preventForwardProps(['isSelected'])
)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  aspectRatio: 1,
  borderRadius: theme.shape['borderRadius-l'],
  overflow: 'hidden',
  border: `3px solid ${isSelected ? theme.palette.brand.blue : 'transparent'}`,
  cursor: 'pointer',
}));

const Button = styled(BlankButton)(({ theme }) => ({
  margin: theme.spacing(1, 1, 0),

  '&:focus': { outline: 0 },
  '&:focus-visible': theme.mixins.focusRing,
}));

type Props = {
  template: Template;
  selected: boolean;
  onClick: () => unknown;
};

export const TemplateCard: FC<Props> = ({ selected, onClick, template }) => {
  const { locale } = useI18n();

  return (
    <Card>
      <PreviewContainer
        isSelected={selected}
        // We cannot spread a pseudo-element from the button to increase the
        // hitbox to the whole card because the video needs mouse events.
        onClick={onClick}
      >
        {template.previewVideoAsset?.videoUrl && (
          <VideoAssetPreview
            src={template.previewVideoAsset.videoUrl}
            poster={template.previewImageAsset?.imageUrl ?? template.previewVideoAsset.thumbnailUrl ?? undefined}
          />
        )}
      </PreviewContainer>

      <Button type="button" onClick={onClick} aria-pressed={selected}>
        <Headline variant="h5" component="span">
          {locale === 'DE' ? template.name : template.nameEN}
        </Headline>
      </Button>
    </Card>
  );
};
