import type { ContributionRequestPreset } from './types';

export const contributionRequestPresets: { DE: ContributionRequestPreset[]; EN: ContributionRequestPreset[] } = {
  EN: [
    {
      id: 'colleague-introduction',
      title: 'Colleague Introduction',
      videoUrl: '/assets/videos/colleague-intro-EN.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/colleague-intro-EN.png',
      requests: [
        {
          title: 'Introduction',
          instruction:
            'Tell us a little bit about yourself: Start with your name and job title. Where did you previously work?',
          type: 'VIDEO',
        },
        {
          title: 'Why us',
          instruction:
            'Why did you want to join our team specifically? What convinced you during the interviewing process?',
          type: 'VIDEO',
        },
        {
          title: 'Your secret power',
          instruction:
            'What is a secret power of yours? What can we talk to you about professionally and on a personal level?',
          type: 'VIDEO',
        },
      ],
    },
    {
      id: 'birthday',
      title: 'Birthday Video',
      videoUrl: '/assets/videos/birthday-EN.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/birthday-EN.png',
      requests: [
        {
          title: 'Congratulate',
          instruction:
            'Congratulate your colleague for their birthday. You can sing a song, or simply wish a good birthday.',
          type: 'VIDEO',
        },
        {
          title: 'Birthday Kudos',
          instruction:
            'As a little gift, please share something nice about your colleague. It can be something they helped you with, a nice conversation you had over lunch or a quality they bring to the team that you enjoy.',
          type: 'VIDEO',
        },
      ],
    },
    {
      id: 'three-questions',
      title: '3 questions for…',
      videoUrl: '/assets/videos/3-questions-for-EN.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/3-questions-for-EN.png',
      requests: [
        {
          title: 'Your favorite topics for conversation',
          instruction:
            'What are topics we can talk to you about? From gardening to football, what do you love ranting about?',
          type: 'VIDEO',
        },
        {
          title: 'Workplace pet peeve',
          instruction: 'What is something that really grinds your gears at the workplace?',
          type: 'VIDEO',
        },
        {
          title: 'Most productive time',
          instruction: 'When is your peak productive time during which we should best not interrupt your work?',
          type: 'VIDEO',
        },
      ],
    },
  ],
  DE: [
    {
      id: 'colleague-introduction',
      title: 'Kolleg*innen Vorstellung',
      videoUrl: '/assets/videos/colleague-intro-DE.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/colleague-intro-DE.png',
      requests: [
        {
          title: 'Vorstellung',
          instruction:
            'Erzähl uns ein wenig über dich. Beginne mit deinem Namen und Jobtitel. Wo hast du vor uns schon gearbeitet?',
          type: 'VIDEO',
        },
        {
          title: 'Warum wir?',
          instruction: 'Warum wolltest du in unser Team? Was hat dich im Bewerbungsprozess überzeugt?',
          type: 'VIDEO',
        },
        {
          title: 'Deine geheime Superkraft',
          instruction:
            'Was ist eine geheime Superkraft von dir? Wobei können wir dich beruflich besonders gut konsultieren?',
          type: 'VIDEO',
        },
      ],
    },
    {
      id: 'birthday',
      title: 'Geburtstags Kudos',
      videoUrl: '/assets/videos/birthday-DE.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/birthday-DE.png',
      requests: [
        {
          title: 'Glückwünsche',
          instruction:
            'Beglückwünsche den/die Kolleg*in zum Geburtstag. Du kannst etwas singen oder einfach nur alles Gute wünschen.',
          type: 'VIDEO',
        },
        {
          title: 'Geburtstags Kudos',
          instruction:
            'Sage als kleines Geburtstagsgeschenk etwas Nettes über deinen/deine Kolleg*in. Es kann etwas sein womit dir geholfen wurde, ein nettes gemeinsames Mittagessen oder ein Danke für das, was der/die Kolleg*in ins Team mit einbringt.',
          type: 'VIDEO',
        },
      ],
    },
    {
      id: 'three-questions',
      title: '3 Fragen an…',
      videoUrl: '/assets/videos/3-questions-for-DE.mp4',
      thumbnailUrl: '/assets/images/contributionRequestPresets/3-questions-for-DE.png',
      requests: [
        {
          title: 'Deine Lieblingsthemen',
          instruction:
            'Was sind Themen, über die du dich gerne außerhalb der Arbeit unterhältst? Worauf können wir dich an der Kaffeemaschine ansprechen?',
          type: 'VIDEO',
        },
        {
          title: 'Arbeitsplatz No-Go',
          instruction:
            'Was ist etwas, das dich während der Arbeit auf die Palme bringt? Was sollten Kollegen in deinem Umfeld vermeiden?',
          type: 'VIDEO',
        },
        {
          title: 'Deine produktivste Zeit',
          instruction: 'Wann am Tag ist deine produktivste Phase? Wann sollten wir dich am Besten nicht unterbrechen?',
          type: 'VIDEO',
        },
      ],
    },
  ],
};
