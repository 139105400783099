import { useMemo } from 'react';

import {
  type PaginatedProjects,
  type ProjectsQuery,
  type ProjectsQueryVariables,
  useProjectsQuery,
} from '../../generated';

export type Project = ProjectsQuery['projects']['items'][number];

export type ProjectsFilter = NonNullable<ProjectsQueryVariables['filter']>;

type FetchPolicy = NonNullable<Parameters<typeof useProjectsQuery>[0]>['fetchPolicy'];

export const useProjects = (
  folderId: ProjectsQueryVariables['folderId'],
  filter?: ProjectsFilter,
  fetchPolicy: FetchPolicy = 'cache-and-network'
) => {
  const { error, loading, data } = useProjectsQuery({
    variables: { filter, folderId },
    fetchPolicy,
  });
  const paginatedProjects = data?.projects as PaginatedProjects;
  return useMemo(() => ({ paginatedProjects, loading, error }), [paginatedProjects, loading, error]);
};
