import { TextLink, Typography, styled } from '@cofenster/web-components';

import { PexelsIcon } from './PexelsIcon';

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledTextLink = styled(TextLink)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const PexelsMention = () => (
  <StyledTypography
    component="p"
    i18nParams={{
      link: (chunks) => (
        <StyledTextLink href="https://pexels.com" target="_blank" rel="noopener noreferrer" underline>
          <PexelsIcon />
          {chunks}
        </StyledTextLink>
      ),
    }}
  >
    i18n.dialogs.StockLibraryDialog.pexels
  </StyledTypography>
);
