import type { FC, ReactNode } from 'react';

import { type ImageAsset, useImageAsset } from '../../../../../api/hooks/imageAsset/useImageAsset';
import { usePollingImageAsset } from '../../../../../api/hooks/imageAsset/usePollingImageAsset';

type Props = {
  imageAssetId?: string;
  children: (imageAsset: ImageAsset | null | undefined) => ReactNode;
};

export const PollingImageAsset: FC<Props> = ({ children, imageAssetId }) => {
  const { imageAsset } = useImageAsset(imageAssetId);
  const polledImageAsset = usePollingImageAsset(imageAsset?.id);

  return children(imageAssetId ? polledImageAsset : null);
};
