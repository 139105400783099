import { type FC, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import { AssetDropzone, DropzoneStatus, useUploadHint } from '@cofenster/web-components';

import type { SceneVideo } from '../../../api/hooks/scene/useScenes';
import { useProject } from '../../../contexts/project/useProject';
import { useScenes } from '../../../contexts/scenes/useScenes';
import { OpenSceneAssetLifecyclePageButton } from '../OpenSceneAssetLifecyclePage';
import type { SceneUploadingAssetProps } from '../SceneUploadingAssetProps';
import { useAssetState } from '../useSceneAssetState';

export type SceneVideoUploadProps = {
  sceneVideo?: SceneVideo;
} & SceneUploadingAssetProps;

export const SceneVideoUpload: FC<SceneVideoUploadProps> = ({
  sceneVideo,
  startUpload,
  uploadProgress,
  cancelUpload,
  cancelProcessing,
  deleteSceneAsset,
  disabled,
}) => {
  const { project } = useProject();
  const hintText = useUploadHint('video', project?.videoFormat);
  const { currentScene } = useScenes();
  const videoAsset = sceneVideo?.videoAsset;
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const assetState = useAssetState(videoAsset ?? undefined, uploadProgress);

  const isScreenRecording = currentScene?.type === 'screenRecording';

  if (assetState === 'READY') return null;

  if (assetState === 'ERROR') {
    return (
      <DropzoneStatus
        size="medium"
        status="error"
        text="i18n.sceneVideo.error"
        onCancel={deleteSceneAsset}
        backgroundColor="linen50"
        data-testid="scene-video-upload-error"
      />
    );
  }

  if (assetState === 'UPLOADING') {
    return (
      <DropzoneStatus
        size="medium"
        backgroundColor="linen50"
        status="uploading"
        text={fileName}
        hintText="i18n.sceneVideo.uploading"
        onCancel={cancelUpload}
        progress={uploadProgress || 0}
      />
    );
  }

  if (assetState === 'PROCESSING') {
    return (
      <DropzoneStatus
        size="medium"
        backgroundColor="linen50"
        status="processing"
        text={fileName}
        hintText="i18n.sceneVideo.processing"
        onCancel={cancelProcessing}
      />
    );
  }

  return (
    <AssetDropzone
      onFile={(file: File) => {
        setFileName(file.name);
        startUpload(file, {
          videoFit: 'Fit',
        });
      }}
      mime={MimeTypes.video}
      size="medium"
      text="i18n.sceneVideo.dropzone.text"
      hintText={hintText}
      data-testid="scene-video-upload"
      icon={isScreenRecording ? 'ScreenShareIcon' : 'MovieIcon'}
      backgroundColor="linen50"
      disabled={disabled}
    >
      {project && currentScene && (
        <OpenSceneAssetLifecyclePageButton
          projectId={project.id}
          scene={currentScene}
          type={isScreenRecording ? 'screen' : 'video'}
          disabled={disabled}
          videoFormat={project.videoFormat}
        />
      )}
    </AssetDropzone>
  );
};
