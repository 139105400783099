import type { FC, MouseEventHandler } from 'react';

import type { IconSize } from '../../assets/icons/Icon';
import { IconButton } from '../../controls/Button/IconButton';

export const PlayPause: FC<{
  isPlaying: boolean;
  onPlayPause: MouseEventHandler;
  size?: IconSize;
  disabled?: boolean;
}> = ({ isPlaying, onPlayPause, size, disabled }) => (
  <IconButton
    icon={isPlaying ? 'PauseIcon' : 'PlayIcon'}
    iconSize={size}
    iconColor="white"
    iconWeight="fill"
    hoverColor="grey300"
    hoverBackgroundColor="transparent"
    onClick={onPlayPause}
    label={isPlaying ? 'i18n.global.pause' : 'i18n.global.play'}
    disabled={disabled}
  />
);
