import type { FC } from 'react';

import { formatDuration, styled } from '@cofenster/web-components';

import { usePublishedVideoAnalytics } from '../../../../api/hooks/videoAnalytics/usePublishedVideoAnalytics';
import { AnalyticsDetail } from './AnalyticsDetail';

const StyledHR = styled('hr')(({ theme }) => ({
  flex: '0 0 1px',
  width: '100%',
  border: 0,
  backgroundColor: theme.palette.brand.grey100,
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

type VideoAnalyticsProps = {
  projectId: string;
  loading: boolean;
};

export const VideoAnalytics: FC<VideoAnalyticsProps> = ({ projectId, loading }) => {
  const { publishedVideoAnalytics } = usePublishedVideoAnalytics(projectId);

  return (
    <Container>
      <AnalyticsDetail
        title="i18n.videoAnalytics.totalViews.title"
        value={publishedVideoAnalytics?.totalViews ?? 0}
        description="i18n.videoAnalytics.totalViews.description"
        iconName="EyeIcon"
        loading={loading}
      />

      <StyledHR />

      <AnalyticsDetail
        title="i18n.videoAnalytics.uniqueViews.title"
        value={publishedVideoAnalytics?.uniqueViews ?? 0}
        description="i18n.videoAnalytics.uniqueViews.description"
        iconName="UserIcon"
        loading={loading}
      />

      <StyledHR />

      <AnalyticsDetail
        title="i18n.videoAnalytics.watchTime.title"
        value={formatDuration(publishedVideoAnalytics?.watchTime ?? 0, false)}
        description="i18n.videoAnalytics.watchTime.description"
        iconName="ClockClockwiseIcon"
        loading={loading}
      />
    </Container>
  );
};
