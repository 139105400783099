import { styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

import { GridContainer, GridItem } from '../Grid';

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(0, 3),
  backgroundColor: theme.palette.brand.white,

  '--sub-navigation-color': theme.palette.brand.white,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2, 2, 0),
    gap: theme.spacing(2),
  },
}));

const Left = styled('div')(() => ({}));

// 1. Prevent the sub-navigation from bursting out of this container on mobile.
// 2. Prevent long headlines from breaking out of their container.
// 3. The `Left` is hidden on mobile, but the flex gap between `Left` and
//    `Center` remains, so this negates it so the navigation inside `Center` is
//    properly anchored at the bottom of the header.
const Center = styled('div')(({ theme }) => ({
  maxWidth: '100%', // 1
  minWidth: 0, // 2

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(-2), // 3
  },
}));

const Right = styled('div')(({ theme }) => ({
  marginLeft: 'auto',

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
  },
}));

export type ProjectHeaderProps = {
  left?: ReactNode;
  right?: ReactNode;
  topLeft?: ReactNode;
  bottomLeft?: ReactNode;
};

export const ProjectHeader: FC<ProjectHeaderProps> = ({ left, right, topLeft, bottomLeft }) => {
  return (
    <Wrapper>
      {left && <Left>{left}</Left>}

      <Center>
        <GridContainer flexDirection="column" justifyContent="space-between" spacing={0} height="100%">
          {topLeft && <GridItem xs={12}>{topLeft}</GridItem>}
          {bottomLeft && (
            <GridItem xs={12} sm="auto">
              {bottomLeft}
            </GridItem>
          )}
        </GridContainer>
      </Center>

      {right && <Right>{right}</Right>}
    </Wrapper>
  );
};
