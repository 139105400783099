import type { FC } from 'react';

import { styled } from '@cofenster/web-components';

import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { ExistingContributionRequestListChipSection } from './ExistingContributionRequestListChipSection';

const ChipsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export const ContributionRequestListChipSection: FC<{ item: ContributionRequest }> = ({ item }) => {
  return (
    <ChipsContainer>
      {item.contributionRequestLists.map((list) => (
        <ExistingContributionRequestListChipSection key={list.id} list={list} contributionRequestId={item.id} />
      ))}
    </ChipsContainer>
  );
};
