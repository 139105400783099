import { type FC, useCallback, useEffect } from 'react';

import { MimeTypes } from '@cofenster/constants';
import { AssetDropzone, DropzoneStatus, useUpload, useUploadHint } from '@cofenster/web-components';

import { useCreateThemeVideoAsset } from '../../../../api/hooks/theme/useCreateThemeVideoAsset';
import type { ThemeVideoType } from '../../../../api/hooks/user/useMe';
import { usePollingVideoAsset } from '../../../../api/hooks/videoAsset/usePollingVideoAsset';
import { useAssetState } from '../../../../components/sceneAsset/useSceneAssetState';
import { type Project, useProject } from '../../../../contexts/project/useProject';
import { useProjectRenderDescription } from '../../../../contexts/projectRenderDescription/useProjectRenderDescription';
import { useFindVideoAssetOnTheme } from '../../../../hooks/useFindVideoOnTheme';

export const SceneIntroOutroNoAsset: FC<{ project: Project; disabled?: boolean; themeVideoType: ThemeVideoType }> = ({
  project,
  disabled,
  themeVideoType,
}) => {
  const { refetch: refetchProject } = useProject();
  const { refetch: refetchProjectRenderDescription } = useProjectRenderDescription();
  const { videoFormat, theme } = project;

  const createThemeVideoAsset = useCreateThemeVideoAsset();
  const themeVideoAsset = useFindVideoAssetOnTheme(theme, themeVideoType, videoFormat);
  const videoAsset = usePollingVideoAsset(themeVideoAsset?.videoAsset?.id);

  const { startUpload, getUpload, cancelAndClearUpload } = useUpload();
  const upload = getUpload('video', themeVideoType);
  const progress = upload?.progress;
  const fileName = upload?.file?.name;

  useEffect(() => {
    // After having created the theme video asset, we need to poll for it until
    // the file is ready, at which point refetch both the project and its render
    // description cause the whole component gets unmounded because the preview
    // has loaded.
    if (videoAsset?.videoUrl) {
      refetchProject();
      refetchProjectRenderDescription();
    }
  }, [videoAsset?.videoUrl, refetchProject, refetchProjectRenderDescription]);

  const onFile = useCallback(
    async (file: File) => {
      const videoAssetId = await startUpload('video', themeVideoType, file, {
        videoFormat,
        videoFit: 'Fit',
      });

      if (theme && videoAssetId) {
        await createThemeVideoAsset({
          themeId: theme.id,
          videoAssetId,
          videoFormat,
          videoType: themeVideoType,
        });
      }
    },
    [startUpload, themeVideoType, videoFormat, theme, createThemeVideoAsset]
  );

  const onCancel = useCallback(() => {
    cancelAndClearUpload('video', themeVideoType);
  }, [cancelAndClearUpload, themeVideoType]);

  const assetState = useAssetState(videoAsset ?? undefined, progress);
  const hintText = useUploadHint('video', videoFormat);

  if (assetState === 'ERROR') {
    return (
      <DropzoneStatus
        size="medium"
        status="error"
        text="i18n.sceneVideo.error"
        onCancel={onCancel}
        backgroundColor="linen50"
        data-testid="scene-video-upload-error"
      />
    );
  }

  if (assetState === 'UPLOADING') {
    return (
      <DropzoneStatus
        size="medium"
        backgroundColor="linen50"
        status="uploading"
        text={fileName}
        hintText="i18n.sceneVideo.uploading"
        onCancel={onCancel}
        progress={progress ?? 0}
      />
    );
  }

  if (assetState === 'PROCESSING') {
    return (
      <DropzoneStatus
        size="medium"
        backgroundColor="linen50"
        status="processing"
        text={fileName}
        hintText="i18n.sceneVideo.processing"
        onCancel={onCancel}
      />
    );
  }

  return (
    <AssetDropzone
      onFile={onFile}
      mime={MimeTypes.video}
      text="i18n.sceneVideo.dropzone.text"
      hintText={hintText}
      size="medium"
      icon="MovieIcon"
      data-testid="scene-video-upload"
      backgroundColor="linen50"
      disabled={disabled}
    />
  );
};
