import { type FC, useCallback, useMemo } from 'react';

import { Dialog, DialogContent, LoadingSpinner } from '@cofenster/web-components';

import { useContributionRequestListByProject } from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import type { Project } from '../../../api/hooks/project/useProject';
import { ProjectContext } from '../../../contexts/project/ProjectContext';

import { ContributionRequestListDialogContent } from './ContributionRequestListDialogContent';

export type ContributionRequestListDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  project: Project;
  refetchProject: () => unknown;
  trackCloseDialog: () => unknown;
};

export const ContributionRequestListDialog: FC<ContributionRequestListDialogProps> = ({
  isOpen,
  closeDialog,
  project,
  refetchProject,
  trackCloseDialog,
}) => {
  const { contributionRequestLists, loading } = useContributionRequestListByProject(project.id);

  const projectContextValue = useMemo(
    () => ({ project, loading: false, refetch: refetchProject }),
    [project, refetchProject]
  );

  const closeDialogCallback = useCallback(() => {
    closeDialog();
    trackCloseDialog();
  }, [closeDialog, trackCloseDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialogCallback}
      size="l"
      title="i18n.dialogs.ContributionRequestListDialog.headline"
    >
      <DialogContent>
        {loading || !contributionRequestLists?.length ? (
          <LoadingSpinner />
        ) : (
          <ProjectContext.Provider value={projectContextValue}>
            <ContributionRequestListDialogContent lists={contributionRequestLists} closeDialog={closeDialog} />
          </ProjectContext.Provider>
        )}
      </DialogContent>
    </Dialog>
  );
};
