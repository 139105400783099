import type { FC } from 'react';

import { TextLink, Typography } from '@cofenster/web-components';

import type { StockImage } from '../../../api/hooks/stockFootage/useSearchStockImages';
import type { StockVideo } from '../../../api/hooks/stockFootage/useSearchStockVideos';

export const Attribution: FC<{ asset: StockImage | StockVideo }> = ({ asset }) => {
  if (!asset.attributor) return null;

  return (
    <Typography>
      &copy;&nbsp;
      {asset.attributor.url ? (
        <TextLink href={asset.attributor.url} target="_blank" rel="noopener noreferrer" underline>
          {asset.attributor.name}
        </TextLink>
      ) : (
        asset.attributor.name
      )}
    </Typography>
  );
};
