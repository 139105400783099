import type { FC, PropsWithChildren } from 'react';

import {
  GridContainer,
  GridItem,
  Headline,
  LegalNavigation,
  LogoWide,
  Text,
  styled,
  useTitle,
} from '@cofenster/web-components';

export const PageHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const PageSubline = styled(Text)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(8),
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.brand.linen,
  minHeight: '100%',
}));

const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(5),
  margin: theme.spacing('auto', 2),
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    margin: 0,
    padding: theme.spacing(17.5, 15, 10, 17.5),
  },
}));

const PhonePicture = styled('img')(() => ({
  display: 'block',
  height: 648,
  marginTop: -10,
  marginBottom: -10,
}));

const ContentWrapper = styled('div')(() => ({
  maxWidth: 348,
}));

export const UnauthenticatedPage: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  useTitle(title);

  return (
    <Container>
      <GridContainer
        justifyContent="space-between"
        flexDirection="column"
        alignItems="stretch"
        flex={1}
        pl={{ md: 6 }}
        pr={{ md: 6 }}
      >
        <GridItem mt={{ md: 3 }} component="header">
          <LogoWide size={82} />
        </GridItem>
        <GridItem component="main">
          <GridContainer justifyContent="center" spacing={4}>
            <GridItem display={{ xs: 'none', md: 'none', lg: 'flex' }} component="picture">
              <source srcSet="/assets/images/phone.avif" type="image/avif" />
              <source srcSet="/assets/images/phone.webp" type="image/webp" />
              <PhonePicture src="/assets/images/phone.png" alt="" />
            </GridItem>
            <GridItem>
              <Content>
                <GridContainer height="100%">
                  <GridItem>
                    <ContentWrapper>{children}</ContentWrapper>
                  </GridItem>
                </GridContainer>
              </Content>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem mt={4} mb={3} component="footer">
          <LegalNavigation />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
