import { FocusTrap } from '@mui/base/FocusTrap';
import { type FormikValues, useField, useFormikContext } from 'formik';
import { type FC, type FocusEventHandler, type KeyboardEvent, useCallback, useEffect } from 'react';

import { type CSSObject, Card, Form, IconButton, Typography, styled, useI18n } from '@cofenster/web-components';

import { useUpdateScenes } from '../../../../api/hooks/scene/useUpdateScenes';
import { useScenes } from '../../../../contexts/scenes/useScenes';

import { useSceneEditorNotesState } from './SceneEditorNotesStateContext';

const PositionedCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    inset: theme.spacing(2),
    left: 'auto',
    width: '360px',
    zIndex: theme.zIndex.above,
    boxShadow: theme.shadows[1],
  },

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
  },
}));

const CardInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: '100%',
}));

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.brand.grey50}`,
}));

const FullHeightForm = styled(Form)({
  height: '100%',
});

const FullHeightTextArea = styled('textarea')(({ theme }) => ({
  width: '100%',
  height: '100%',
  minHeight: '20vh',
  resize: 'none',

  border: 0,
  borderRadius: theme.shape.borderRadius,

  font: 'inherit',
  ...(theme.typography.m as CSSObject),
  color: theme.palette.brand.carbon,

  padding: theme.spacing(1),

  transition: 'background-color 250ms',
  '&:hover:not(:focus)': {
    backgroundColor: theme.palette.brand.grey50,
  },

  '&:focus': {
    outline: `1px solid ${theme.palette.brand.blue}`,
  },
}));

const SceneEditorNotesTextArea = () => {
  const { translate } = useI18n();
  const [field] = useField('editorNotes');
  const { handleBlur, dirty, submitForm } = useFormikContext();

  const onBlur: FocusEventHandler = useCallback(
    (event) => {
      handleBlur(event);
      if (dirty) submitForm();
    },
    [dirty, submitForm, handleBlur]
  );

  useEffect(() => {
    return () => {
      if (dirty) submitForm();
    };
  }, [dirty, submitForm]);

  return (
    <FullHeightTextArea
      autoFocus
      autoCorrect="on"
      placeholder={translate('projectEdit.SceneEditorNotes.overlay.placeholder')}
      aria-label={translate('projectEdit.SceneEditorNotes.overlay.header')}
      id="editorNotes"
      {...field}
      onBlur={onBlur}
    />
  );
};
const SceneEditorNotesForm: FC<{ id: string; editorNotes?: string | null }> = ({ id, editorNotes }) => {
  const updateScene = useUpdateScenes();
  const onSubmit = ({ id, editorNotes }: FormikValues) => updateScene([{ sceneId: id, editorNotes }]);

  return (
    <FullHeightForm initialValues={{ id, editorNotes }} onSubmit={onSubmit}>
      <SceneEditorNotesTextArea />
    </FullHeightForm>
  );
};

export const SceneEditorNotesOverlay = () => {
  const { currentScene } = useScenes();
  const [notesOpen, setNotesOpen] = useSceneEditorNotesState();
  const { translate } = useI18n();

  if (!currentScene) return null;
  if (!notesOpen) return null;

  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') setNotesOpen(false);
  };

  return (
    <PositionedCard
      onKeyDown={onEscape}
      role="dialog"
      aria-modal="false"
      aria-label={translate('projectEdit.SceneEditorNotes.overlay.header')}
    >
      <FocusTrap open disableEnforceFocus>
        <CardInner>
          <CardHeader>
            <Typography component="h2" variant="h4">
              i18n.projectEdit.SceneEditorNotes.overlay.header
            </Typography>
            <IconButton icon="CloseIcon" label="i18n.global.close" onClick={() => setNotesOpen(false)} />
          </CardHeader>
          <SceneEditorNotesForm key={currentScene.id} id={currentScene.id} editorNotes={currentScene.editorNotes} />
        </CardInner>
      </FocusTrap>
    </PositionedCard>
  );
};
