import { type FC, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { useSnackbars } from '@cofenster/web-components';

import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { useGoToCollectSceneAssetPage } from '../../hooks/navigation/useGoToCollectSceneAssetPage';
import { useCreateProjectWithScene } from '../../hooks/quickActions/useCreateProjectWithScene';

import { QuickActionButton } from './QuickActionButton';

export const RecordYourself: FC<{ folderId: string }> = ({ folderId }) => {
  const gotoCollectSceneAssetPage = useGoToCollectSceneAssetPage();
  const { openDialog } = useDialogs();
  const createProjectWithScene = useCreateProjectWithScene();
  const { openSnackbar } = useSnackbars();

  const onCreateProject = useCallback(
    async (videoFormat: VideoFormat) => {
      const { projectId, sceneId, error } = await createProjectWithScene({
        folderId,
        videoFormat,
        sceneType: 'video',
        source: 'webcam record button',
      });
      if (error) {
        return openSnackbar({
          variant: 'error',
          children: 'i18n.global.error.generic.unknown',
        });
      }

      gotoCollectSceneAssetPage(projectId, sceneId, { source: 'quickAction', parentFolderId: folderId });
    },
    [folderId, createProjectWithScene, gotoCollectSceneAssetPage, openSnackbar]
  );

  const onClick = useCallback(() => {
    openDialog('RecordYourselfDialog', { onVideoFormatSelected: onCreateProject });
  }, [onCreateProject, openDialog]);

  return (
    <QuickActionButton onClick={onClick} iconType="WebcamIcon" variant="secondary" data-testid="record-yourself-button">
      i18n.quickActions.recordYourself.button
    </QuickActionButton>
  );
};
