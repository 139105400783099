import type { CSSProperties, FC } from 'react';

import { styled } from '@cofenster/web-components';

import type { AccountTheme } from '../../api/hooks/user/useMe';

const isWhite = (color?: string | null) => color?.toLowerCase() === '#ffffff';

const Bubbles = styled('div')(() => ({
  display: 'flex',
}));

const Bubble = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: '50%',
  backgroundColor: 'var(--color)',
  border: `calc(var(--border, 0) * 1px) solid ${theme.palette.brand.grey200}`,

  '&:first-of-type': {
    position: 'relative',
    zIndex: theme.zIndex.above,
  },

  '&:last-of-type': {
    marginLeft: theme.spacing(-1),
  },
}));

export const ThemeBubbles: FC<{ theme: AccountTheme }> = ({ theme }) => {
  return (
    <Bubbles>
      <Bubble
        style={
          {
            '--color': theme.colorPrimary,
            '--border': Number(isWhite(theme.colorPrimary)),
          } as CSSProperties
        }
        data-testid="theme-bubble"
        data-color={theme.colorPrimary}
      />
      <Bubble
        style={
          {
            '--color': theme.colorSecondary,
            '--border': Number(isWhite(theme.colorSecondary)),
          } as CSSProperties
        }
        data-testid="theme-bubble"
        data-color={theme.colorSecondary}
      />
    </Bubbles>
  );
};
