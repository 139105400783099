import type { FC } from 'react';

import { Button, Icon, withPopoverMenu } from '@cofenster/web-components';

import { MemberPopover, type MemberPopoverProps } from './MemberPopover';

export type MemberPopoverButtonProps = MemberPopoverProps;

const translations = {
  Admin: 'i18n.team.role.Admin',
  Contributor: 'i18n.team.role.Contributor',
  Editor: 'i18n.team.role.Editor',
  Publisher: 'i18n.team.role.Publisher',
  Translator: 'i18n.team.role.Translator',
  Viewer: 'i18n.team.role.Viewer',
};

export const MemberPopoverButton: FC<MemberPopoverButtonProps> = (props) => {
  const PopoverButton = withPopoverMenu(Button, { children: <MemberPopover {...props} /> });

  const roleName = props.currentRole?.name;

  return (
    <PopoverButton
      variant="tertiary"
      endIcon={<Icon type="CaretDownIcon" size="s" />}
      data-testid="member-role-menu-button"
    >
      {roleName ? translations[roleName] : 'unknown'}
    </PopoverButton>
  );
};
