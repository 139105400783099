import { styled } from '@mui/material';
import type { CSSProperties, FC } from 'react';
import type { SliderItem } from 'react-compound-slider';

import { VisuallyHidden } from '../../../utilities/VisuallyHidden';

const StyledHandle = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: 'calc(var(--percent) * 1%)',
  zIndex: theme.zIndex.above,
  height: theme.spacing(2),
  width: theme.spacing(2),
  backgroundColor: theme.palette.brand.white,
  borderRadius: '50%',
  cursor: 'pointer',

  '&[aria-disabled="true"]': {
    backgroundColor: theme.palette.brand.grey300,
  },

  '&:not([aria-disabled="true"]):hover': {
    backgroundColor: theme.palette.brand.grey300,
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

type HandleProps = {
  label: string;
  handle: SliderItem;
  domain: [number, number];
  disabled?: boolean;
};

export const Handle: FC<HandleProps> = ({ label, handle: { percent, value }, domain, disabled, ...rest }) => (
  <StyledHandle
    style={{ '--percent': percent } as CSSProperties}
    data-testid="playback-slider-handle"
    role="slider"
    tabIndex={0}
    aria-valuemin={domain[0]}
    aria-valuenow={value}
    aria-valuemax={domain[1]}
    aria-disabled={disabled}
    {...rest}
  >
    <VisuallyHidden>{label}</VisuallyHidden>
  </StyledHandle>
);
