import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

export type DeleteProjectFoldersDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  deleteProjectFolders: () => Promise<void>;
};

export const DeleteProjectFolderDialog: FC<DeleteProjectFoldersDialogProps> = ({
  isOpen,
  closeDialog,
  deleteProjectFolders,
}) => {
  const { openSnackbar } = useSnackbars();
  const onAction = useCallback(async () => {
    await deleteProjectFolders();
    closeDialog();
    openSnackbar({ children: 'i18n.snackbars.folderDeletedSnackbar.copy' });
  }, [deleteProjectFolders, closeDialog, openSnackbar]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="folder-delete-dialog"
      title="i18n.dialogs.deleteProjectFolderDialog.headline"
    >
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.deleteProjectFolderDialog.description
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button variant="destructive" fullWidth onClick={onAction} data-testid="folder-delete-dialog-button">
          i18n.dialogs.deleteProjectFolderDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
