import { type FC, useMemo } from 'react';

import { PopoverMenuTriggerIcon, Responsive, withPopoverMenu } from '@cofenster/web-components';

import type { ImageSceneAsset, Scene } from '../../../../../api/hooks/scene/useScenes';
import { CollectAssetButton } from '../../../../../components/sceneAsset/CollectAssetButton';
import { PickSceneAssetFromMediaLibraryButton } from '../../../../../components/sceneAsset/PickSceneAssetFromMediaLibraryButton';
import { StockAssetButton } from '../../../../../components/sceneAsset/StockAssetButton';
import type { Project } from '../../../../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../../../../contexts/user/TeamPermissionRestriction';
import { AudioOverlay } from '../../../components/AudioOverlay';
import { SceneCropButton } from '../../../components/EditingBar/SceneCropButton';
import { SceneDurationButton } from '../../../components/EditingBar/SceneDurationButton';
import { SceneOverlaysButton } from '../../../components/EditingBar/SceneOverlaysButton';
import { SceneSubtitlesButton } from '../../../components/EditingBar/SceneSubtitlesButton';
import { useUpdateDurationImage } from '../../../hooks';
import { useSceneImageActionsPopoverContent } from '../../EditorSceneAssetThumbs/hooks/useSceneImageActionsPopoverContent';
import { SceneEditorNotesButton } from '../../SceneEditorNotes/SceneEditorNotesButton';

export const SceneImageControls: FC<{
  project: Project;
  scene: Scene;
  imageSceneAsset?: ImageSceneAsset;
  isEmpty?: boolean;
  withDisabledControls?: boolean;
}> = ({ project, scene, imageSceneAsset, isEmpty, withDisabledControls }) => {
  const duration = imageSceneAsset?.asset?.duration ?? 0;
  const onDurationUpdate = useUpdateDurationImage(scene, imageSceneAsset?.asset ?? undefined);
  const actions = useSceneImageActionsPopoverContent({ scene, imageSceneAsset });

  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: actions,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [actions]
  );

  if (isEmpty) {
    return (
      <TeamPermissionRestriction has="SceneUpload">
        <CollectAssetButton
          variant="tertiary"
          assetType="image"
          projectId={project.id}
          sceneId={scene.id}
          disabled={withDisabledControls}
        />

        <StockAssetButton
          variant="tertiary"
          assetType="image"
          projectId={project.id}
          scene={scene}
          videoFormat={project.videoFormat}
          disabled={withDisabledControls}
        />

        <PickSceneAssetFromMediaLibraryButton
          assetType="IMAGE"
          sceneId={scene.id}
          disabled={withDisabledControls}
          variant="tertiary"
          videoFormat={project.videoFormat}
        />

        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    );
  }

  return (
    <>
      <TeamPermissionRestriction has="ProjectUpdate">
        <SceneDurationButton duration={duration} onChange={onDurationUpdate} disabled={withDisabledControls} />

        <SceneCropButton projectId={project.id} sceneId={scene.id} disabled={withDisabledControls} />

        <SceneOverlaysButton
          projectId={project.id}
          sceneId={scene.id}
          text="i18n.ProjectSceneOverlays.buttons.addOverlays"
          icon="AddMediaOverlay"
          disabled={withDisabledControls}
        />

        <AudioOverlay disabled={withDisabledControls} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="SubtitlesCreate">
        <SceneSubtitlesButton disabled={withDisabledControls} scene={scene} project={project} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="ProjectUpdate">
        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    </>
  );
};
