import type { FC } from 'react';

import { Button, Icon, styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const ShowMore: FC<{ onClick: VoidFunction; isExpanded: boolean }> = ({ onClick, isExpanded }) => {
  return (
    <Container>
      <Button
        onClick={onClick}
        type="button"
        aria-controls="folders-list"
        aria-expanded={isExpanded}
        variant="tertiary"
        endIcon={<Icon type={isExpanded ? 'CaretUpIcon' : 'CaretDownIcon'} size="s" />}
        data-testid="folders-list-toggle"
      >
        {isExpanded ? 'i18n.projectsFolderList.showLess' : 'i18n.projectsFolderList.showMore'}
      </Button>
    </Container>
  );
};
