import { useCallback } from 'react';

import { usePlayerScenes } from '../../../../../contexts/editorPlayer/usePlayerScenes';
import { useScenes } from '../../../../../contexts/scenes/useScenes';
import { getActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';
import { useSelectedScenes } from '../../../../../contexts/selectedScenes/useSelectedScenes';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import { usePreviewDetails } from '../../../hooks/usePreviewDetails';

const capitalize = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

export const useTrackSceneSelected = () => {
  const { scenes } = useScenes();
  const { selectedItems } = useSelectedScenes();

  const { sceneTimings } = usePlayerScenes(true);
  const tracking = useWebManagerTracking();
  const getPreviewDetails = usePreviewDetails();

  return useCallback(
    (sceneId: string) => {
      const isIntroOrOutro = ['intro', 'outro'].includes(sceneId);
      const sceneTiming = sceneTimings.find((sceneTiming) => sceneTiming.id === sceneId);
      const capitalizedType = isIntroOrOutro ? capitalize(sceneId) : 'Scene';
      const sceneIds = getActionSceneIds(sceneId, scenes, selectedItems);

      tracking.trackEvent({
        event: `${capitalizedType}ThumbnailSelected`,
        details: {
          ...getPreviewDetails({ sceneId, videoPlayerTime: sceneTiming?.start }),
          sceneIds,
        },
      });
    },
    [getPreviewDetails, sceneTimings, tracking, scenes, selectedItems]
  );
};
