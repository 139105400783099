import { type FC, useCallback } from 'react';

import {
  Icon,
  Select,
  type SelectChangeEvent,
  SelectOption,
  type SelectProps,
  Text,
  styled,
} from '@cofenster/web-components';

import { type AudioOverlayStatus, useAudioOverlayContext } from './Context';

const SelectOptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const AudioOverlayStatusSelect: FC<Partial<SelectProps>> = (props) => {
  const { videoSceneAsset, localStatus, setLocalStatus } = useAudioOverlayContext();
  const handleLocalStatusChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setLocalStatus(event.target.value as AudioOverlayStatus),
    [setLocalStatus]
  );

  const videoAsset = videoSceneAsset?.asset?.videoAsset;
  const videoAssetId = videoAsset?.id;

  // Only render the status select if we are dealing with a video scene with a
  // video asset, as this is the only case where we have original audio
  if (!videoAssetId) return null;

  return (
    <Select
      {...props}
      value={localStatus}
      onChange={handleLocalStatusChange}
      name="scene-audio-status-select"
      label="i18n.projectEdit.SceneAudioButton.sceneAudio"
    >
      <SelectOption value="SCENE_AUDIO">
        <SelectOptionContainer>
          <Icon type="WaveformIcon" size="m" />
          <Text>i18n.projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio</Text>
        </SelectOptionContainer>
      </SelectOption>
      <SelectOption value="UPLOADED_AUDIO">
        <SelectOptionContainer>
          <Icon type="UploadIcon" size="m" />
          <Text>i18n.projectEdit.SceneAudioPanelContent.sceneAudioType.uploadedAudio</Text>
        </SelectOptionContainer>
      </SelectOption>
    </Select>
  );
};
