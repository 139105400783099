import { type FC, type PropsWithChildren, useCallback } from 'react';

import { IconButton, type IconButtonProps, styled } from '@cofenster/web-components';

import type { Permission } from '../../api/generated';
import { useRequestRoleWithPermissions } from '../../api/hooks/roleRequest/useRequestRoleWithPermissions';
import { useUserRoleRequest } from '../../api/hooks/roleRequest/useUserRoleRequest';
import { useCurrentTeam } from '../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import type { User } from '../../contexts/user/useUser';

type RequestPermissionButtonProps = {
  user: User;
  permission: Permission;
} & Omit<IconButtonProps, 'onClick' | 'loading' | 'label' | 'icon'>;

export const RequestPermissionIconButton: FC<PropsWithChildren<RequestPermissionButtonProps>> = ({
  user,
  permission,
  children,
  ...props
}) => {
  const { openDialog } = useDialogs();
  const { team } = useCurrentTeam();
  const { userRoleRequest, loading } = useUserRoleRequest(user.id, team && !team.public ? team.id : null);
  const requestRoleWithPermissions = useRequestRoleWithPermissions();

  const onClick = useCallback(() => {
    openDialog('RequestPermissionDialog', {
      user,
      team,
      requestRole: () => requestRoleWithPermissions([permission], team && !team.public ? team.id : null),
    });
  }, [openDialog, user, team, requestRoleWithPermissions, permission]);

  if (userRoleRequest) {
    return <HighlightIconButton {...props} disabled icon="HourglassIcon" label="i18n.roleRequest.pending.text" />;
  }

  return (
    <IconButton
      {...props}
      loading={loading}
      onClick={onClick}
      label="i18n.roleRequest.button.request"
      icon="LockIcon"
    />
  );
};

const HighlightIconButton = styled(IconButton)(({ theme }) => ({
  '@keyframes highlight': {
    'from, to': { borderColor: theme.palette.brand.transparent },
    '25%': { borderColor: theme.palette.brand.positive },
  },
  animation: 'highlight 1s ease-in-out',
}));
