import type { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text } from '@cofenster/web-components';

export type ChangeAnimationStyleDialogDialogProps = {
  isOpen: boolean;
  textElementsCount: number;
  onAction: () => unknown;
  closeDialog: () => unknown;
};

export const ChangeAnimationStyleDialog: FC<ChangeAnimationStyleDialogDialogProps> = ({
  isOpen,
  closeDialog,
  onAction,
  textElementsCount,
}) => {
  const handleAction = () => {
    closeDialog();
    onAction();
  };
  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.changeAnimationStyleDialog.headline">
      <DialogContent>
        <Text variant="l" color="negative" i18nParams={{ count: textElementsCount }} component="p">
          i18n.dialogs.changeAnimationStyleDialog.descriptionLine1
        </Text>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.changeAnimationStyleDialog.descriptionLine2
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.dialogs.changeAnimationStyleDialog.cancel
        </Button>
        <Button variant="primary" fullWidth onClick={handleAction}>
          i18n.dialogs.changeAnimationStyleDialog.confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
