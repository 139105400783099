import { type FC, type PropsWithChildren, useMemo } from 'react';

import { useBooleanState } from '@cofenster/web-components';

import { PanelContext } from './PanelContext';

export const PanelProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const [isPanelOpen, openPanel, closePanel, _, togglePanel] = useBooleanState(false);

  const context = useMemo(
    () => ({
      isPanelOpen,
      openPanel,
      closePanel,
      togglePanel,
    }),
    [isPanelOpen, openPanel, closePanel, togglePanel]
  );

  return <PanelContext.Provider value={context}>{children}</PanelContext.Provider>;
};
