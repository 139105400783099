import { type ReactNode, useCallback } from 'react';

import { PopoverMenuDivider } from '@cofenster/web-components';

export const useAddPopoverDividers = () => {
  return useCallback(
    (groups: ReactNode[][]) =>
      groups
        .filter((group) => group.filter(Boolean).length > 0)
        .reduce<ReactNode[]>(
          (array, group, index) => [
            ...array,
            array.length > 0 && <PopoverMenuDivider key={`divider-${index}`} />,
            ...group.filter(Boolean),
          ],
          []
        ),
    []
  );
};
