import { styled } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { Icon, type IconType } from '../../../assets/icons/Icon';
import { Typography } from '../../../typography/Typography';

import { BaseItem, type BaseItemProps } from './BaseItem';

const StyledBaseItem = styled(BaseItem)(({ theme }) => ({
  padding: theme.spacing(1.5, 1),
  marginBottom: theme.spacing(0.5),
}));

export const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: `0 0 ${theme.spacing(3)}`,
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.brand.grey600,
  borderRadius: theme.spacing(0.5),
}));

export type SectionHeaderProps = BaseItemProps & {
  children: string;
  startIcon?: IconType;
};

export const SectionHeader = ({ children, startIcon, ...rest }: PropsWithChildren<SectionHeaderProps>) => (
  <StyledBaseItem {...rest}>
    {startIcon && (
      <IconContainer>
        <Icon type={startIcon} size="s" />
      </IconContainer>
    )}
    <Typography variant="h6" component="span">
      {children}
    </Typography>
  </StyledBaseItem>
);
