import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { FromProjectTeamProvider } from '../../contexts/currentTeam/FromProjectTeamProvider';
import { PanelProvider } from '../../contexts/panel/PanelProvider';
import { ProjectProvider } from '../../contexts/project/ProjectProvider';
import { ProjectRenderDescriptionProvider } from '../../contexts/projectRenderDescription/ProjectRenderDescriptionProvider';
import { ScenesProvider } from '../../contexts/scenes/ScenesProvider';
import type { ProjectEditorRouteParams } from '../../routes';

import { SelectedScenesProvider } from '../../contexts/selectedScenes/SelectedScenesProvider';
import { ProjectEditorContent } from './ProjectEditorContent';
import { SceneEditorNotesStateProvider } from './ProjectEditorContent/SceneEditorNotes/SceneEditorNotesStateContext';

export const ProjectEditor: FC = () => {
  const { projectId, sceneId } = useParams() as ProjectEditorRouteParams;

  return (
    <ProjectProvider projectId={projectId}>
      <CurrentTeamProvider>
        <FromProjectTeamProvider>
          <PanelProvider>
            <ScenesProvider projectId={projectId} sceneId={sceneId} pollingInterval={5000}>
              <SelectedScenesProvider>
                <SceneEditorNotesStateProvider projectId={projectId}>
                  <ProjectRenderDescriptionProvider projectId={projectId}>
                    <ProjectEditorContent />
                  </ProjectRenderDescriptionProvider>
                </SceneEditorNotesStateProvider>
              </SelectedScenesProvider>
            </ScenesProvider>
          </PanelProvider>
        </FromProjectTeamProvider>
      </CurrentTeamProvider>
    </ProjectProvider>
  );
};
