import { useMemo } from 'react';

import {
  type CreateSceneSubtitleForAllScenesInLanguageInput,
  useCreateSceneSubtitleForAllScenesInLanguageMutation,
} from '../../generated';

export const useCreateSceneSubtitleForAllScenesInLanguage = () => {
  const [createSceneSubtitleForAllScenesInLanguage, { data, loading, error }] =
    useCreateSceneSubtitleForAllScenesInLanguageMutation({
      refetchQueries: ['ScenesWithSubtitles'],
      awaitRefetchQueries: true,
    });

  return useMemo(
    () => ({
      createSceneSubtitleForAllScenesInLanguage: async (input: CreateSceneSubtitleForAllScenesInLanguageInput) =>
        createSceneSubtitleForAllScenesInLanguage({ variables: { input } }),
      data,
      loading,
      error,
    }),
    [createSceneSubtitleForAllScenesInLanguage, data, loading, error]
  );
};
