import type { FC, PropsWithChildren } from 'react';

import type { VisuallyHiddenProps } from '../../utilities/VisuallyHidden';

import { SearchEmpty } from './Empty';
import { SearchHeader } from './Header';

export const SearchResults: FC<
  PropsWithChildren<{ id: string; search?: string; count: number; headerAs?: VisuallyHiddenProps['as'] }>
> = ({ children, id, search, count, headerAs }) => (
  <>
    <SearchHeader id={`search-results-${id}`} search={search ?? undefined} count={count} as={headerAs} />
    {children}
    <SearchEmpty search={search} count={count} />
  </>
);
