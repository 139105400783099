import type { FC, PropsWithChildren } from 'react';

import { usePersistedState } from '@cofenster/web-components';

import { ProjectSpaceContext } from './ProjectSpaceContext';

export const ProjectSpaceProvider: FC<PropsWithChildren> = ({ children }) => {
  const projectSpaceState = usePersistedState<string | undefined>('projectSpace', undefined);

  return <ProjectSpaceContext.Provider value={projectSpaceState}>{children}</ProjectSpaceContext.Provider>;
};
