import { type FC, useCallback } from 'react';

import { Select, type SelectChangeEvent, SelectOption, Text } from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';

export const ContributionRequestListSelect: FC<{
  lists: ContributionRequestList[];
  setSelectedList: (list: ContributionRequestList) => unknown;
  selectedList: ContributionRequestList;
}> = ({ lists, selectedList, setSelectedList }) => {
  const onChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newList = lists.find((list) => list.name === event.target.value);
      if (newList) setSelectedList(newList);
    },
    [setSelectedList, lists]
  );

  return (
    <Select
      autoFocus
      value={selectedList.id}
      name="selectedList"
      label="i18n.dialogs.ContributionRequestListDialog.contributionRequestListSelect.label"
      onChange={onChange}
      renderValue={() => (
        <Text>
          {selectedList.default
            ? 'i18n.dialogs.ContributionRequestListDialog.contributionRequestListSelect.default'
            : selectedList.name}
        </Text>
      )}
      pb={3}
    >
      {lists.map((list) => (
        <SelectOption value={list.name} key={list.id}>
          {list.default
            ? 'i18n.dialogs.ContributionRequestListDialog.contributionRequestListSelect.default'
            : list.name}
        </SelectOption>
      ))}
    </Select>
  );
};
