import { useCallback } from 'react';

import { type ResetPasswordMutationVariables, useResetPasswordMutation } from '../../generated';

export const useResetPassword = () => {
  const [resetPassword] = useResetPasswordMutation({ refetchQueries: ['Me'] });
  return useCallback(
    (token: ResetPasswordMutationVariables['token'], password: ResetPasswordMutationVariables['password']) => {
      return resetPassword({
        variables: {
          token,
          password,
        },
      });
    },
    [resetPassword]
  );
};
