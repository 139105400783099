import { useMemo } from 'react';

import { type ImageAssetQueryResult, type ImageAssetQueryVariables, useImageAssetQuery } from '../../generated';

export type ImageAsset = NonNullable<ImageAssetQueryResult['data']>['imageAsset'];

export const useImageAsset = (id?: ImageAssetQueryVariables['id']) => {
  const { loading, error, data } = useImageAssetQuery({
    variables: id ? { id } : undefined,
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });
  const imageAsset = data?.imageAsset || undefined;
  return useMemo(
    () => ({
      imageAsset,
      loading,
      error,
    }),
    [imageAsset, loading, error]
  );
};
