import { useCallback } from 'react';

import { type DeleteProjectFoldersMutationVariables, useDeleteProjectFoldersMutation } from '../../generated';

export const useDeleteProjectFolders = () => {
  const [deleteProjectFolder] = useDeleteProjectFoldersMutation({ refetchQueries: ['ProjectFolders'] });

  return useCallback(
    (ids: DeleteProjectFoldersMutationVariables['ids']) => deleteProjectFolder({ variables: { ids } }),
    [deleteProjectFolder]
  );
};
