import { useMemo } from 'react';

import { type TeamQueryResult, type TeamQueryVariables, useTeamQuery } from '../../generated';

export type Team = NonNullable<TeamQueryResult['data']>['team'];
export type Member = Team['members'][number];
export type RootProjectFolder = Team['rootProjectFolder'];

export const useTeam = (id: TeamQueryVariables['id']) => {
  const { loading, error, data } = useTeamQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const team = data?.team || undefined;
  return useMemo(() => ({ team, loading, error }), [team, loading, error]);
};
