import type { FC } from 'react';

import type { Scene } from '../../../../api/hooks/scene/useScenes';
import { SceneVideoUpload } from '../../../../components/sceneAsset/SceneVideoUpload';
import { isMainSceneAsset, isVideoSceneAsset } from '../../../../helpers/sceneAssets/is';
import { useDeleteVideoSceneAssetWithTracking } from '../../../../hooks/sceneAsset/video/useDeleteVideoSceneAssetWithTracking';
import { useUploadVideoSceneAssetWithTracking } from '../../../../hooks/sceneAsset/video/useUploadVideoSceneAssetWithTracking';

export const SceneVideoNoAsset: FC<{
  scene: Scene;
  disabled?: boolean;
}> = ({ scene, disabled }) => {
  const videoSceneAsset = scene.sceneAssets.filter(isMainSceneAsset).find(isVideoSceneAsset);
  const sceneVideo = videoSceneAsset?.asset ?? undefined;
  const { videoUploadProgress, uploadVideo, cancelVideoUpload } = useUploadVideoSceneAssetWithTracking(scene.id);
  const onDelete = useDeleteVideoSceneAssetWithTracking(scene.id);

  return (
    <SceneVideoUpload
      uploadProgress={videoUploadProgress}
      startUpload={uploadVideo}
      cancelUpload={cancelVideoUpload}
      cancelProcessing={onDelete}
      sceneVideo={sceneVideo}
      deleteSceneAsset={onDelete}
      disabled={disabled}
    />
  );
};
