import { useCallback, useEffect, useMemo, useRef } from 'react';

export const usePlayVideoOnHover = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const playPromise = useRef<Promise<unknown> | undefined>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const onMouseEnter = useCallback(() => {
    // Add a small delay to only start playing the video on assumed intent
    // instead of during random hovering or scrolling
    timeout.current = setTimeout(() => {
      if (videoRef.current) playPromise.current = videoRef.current.play();
    }, 500);
  }, []);

  const onMouseLeave = useCallback(() => {
    // See: https://developer.chrome.com/blog/play-request-was-interrupted/
    if (typeof playPromise.current !== 'undefined') {
      playPromise.current
        .then(() => {
          if (videoRef.current) videoRef.current.load();
        })
        .catch((error) => console.error(error));
    }
    clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return useMemo(() => ({ ref: videoRef, onMouseEnter, onMouseLeave }), [onMouseEnter, onMouseLeave]);
};
