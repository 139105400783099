import { type FC, useMemo } from 'react';

import {
  Button,
  Icon,
  PopoverMenuTriggerIcon,
  Responsive,
  withPopover,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { ColorSceneAsset, Scene, SceneColor } from '../../../../../api/hooks/scene/useScenes';
import type { Project } from '../../../../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../../../../contexts/user/TeamPermissionRestriction';
import { AudioOverlay } from '../../../components/AudioOverlay';
import { SceneDurationButton } from '../../../components/EditingBar/SceneDurationButton';
import { SceneOverlaysButton } from '../../../components/EditingBar/SceneOverlaysButton';
import { SceneSubtitlesButton } from '../../../components/EditingBar/SceneSubtitlesButton';
import { useUpdateColor, useUpdateDurationColor } from '../../../hooks';
import { useSceneColorActionsPopoverContent } from '../../EditorSceneAssetThumbs/hooks/useSceneColorActionsPopoverContent';
import { ColorPicker } from '../../EditorUpload/ColorPicker';
import { SceneEditorNotesButton } from '../../SceneEditorNotes/SceneEditorNotesButton';

export const SceneColorControls: FC<{
  project: Project;
  scene: Scene;
  colorSceneAsset?: ColorSceneAsset;
  withDisabledControls?: boolean;
}> = ({ project, scene, colorSceneAsset, withDisabledControls }) => {
  const sceneColor = colorSceneAsset?.asset as SceneColor;
  const duration = sceneColor?.duration ?? 0;
  const onDurationUpdate = useUpdateDurationColor(scene, sceneColor);
  const color = sceneColor?.colorAsset?.color;
  const isEmpty = !color;
  const actions = useSceneColorActionsPopoverContent({ scene, colorSceneAsset });

  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: actions,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [actions]
  );

  const primary = project?.theme?.colorPrimary ?? '#000000';
  const secondary = project?.theme?.colorSecondary ?? '#ffffff';

  const onColorUpdate = useUpdateColor(scene.id, colorSceneAsset);
  const ColorPickerToggle = useMemo(
    () =>
      withPopover(Button, {
        anchorOriginX: 'center',
        anchorOriginY: 'top',
        transformOriginX: 'center',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: <ColorPicker color={color} onChange={onColorUpdate} primary={primary} secondary={secondary} />,
      }),
    [color, onColorUpdate, primary, secondary]
  );

  if (isEmpty) {
    return (
      <TeamPermissionRestriction has="ProjectUpdate">
        <ColorPickerToggle
          disabled={withDisabledControls}
          startIcon={<Icon type="EyedropperIcon" size="s" />}
          variant="tertiary"
          i18nParams={{ color: '' }}
          data-testid="color-button"
        >
          i18n.projectEdit.colorPicker.pickButton
        </ColorPickerToggle>

        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    );
  }

  return (
    <>
      <TeamPermissionRestriction has="ProjectUpdate">
        <ColorPickerToggle
          disabled={withDisabledControls}
          startIcon={<Icon type="EyedropperIcon" size="s" />}
          variant="tertiary"
        >
          {color}
        </ColorPickerToggle>

        <SceneDurationButton duration={duration} onChange={onDurationUpdate} disabled={withDisabledControls} />

        <Responsive up="md">
          <SceneOverlaysButton
            projectId={project.id}
            sceneId={scene.id}
            text="i18n.ProjectSceneOverlays.buttons.addOverlays"
            icon="AddMediaOverlay"
            disabled={withDisabledControls}
          />
        </Responsive>

        <AudioOverlay disabled={withDisabledControls} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="SubtitlesCreate">
        <SceneSubtitlesButton disabled={withDisabledControls} scene={scene} project={project} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="ProjectUpdate">
        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    </>
  );
};
