import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Dialog, DialogContent, Form, LoadingSpinner, Spacing, Text, styled } from '@cofenster/web-components';
import { useFormikContext } from 'formik';

import type { SocialMediaSafeZone } from '../../../api/generated';
import { useProject } from '../../../api/hooks/project/useProject';
import { useUpdateSubtitleSocialMediaSafeZone } from '../../../api/hooks/sceneSubtitle/useUpdateSubtitleSocialMediaSafeZone';
import { DefaultSafeZoneSvg } from '../../../pages/ProjectSceneSubtitleEdit/ProjectSceneSubtitleEditDialog/SubtitleSafeZoneButton/DefaultSafeZoneSvg';
import { InstagramSafeZoneSvg } from '../../../pages/ProjectSceneSubtitleEdit/ProjectSceneSubtitleEditDialog/SubtitleSafeZoneButton/InstagramSafeZoneSvg';
import { TikTokSafeZoneSvg } from '../../../pages/ProjectSceneSubtitleEdit/ProjectSceneSubtitleEditDialog/SubtitleSafeZoneButton/TikTokSafeZoneSvg';
import { SubtitleSafeZoneBoxInput } from './SubtitleSafeZoneBoxInput';

type Values = {
  socialMediaSafeZone: SocialMediaSafeZone | 'Default';
};

const validationSchema = Yup.object().shape({
  socialMediaSafeZone: Yup.mixed<SocialMediaSafeZone | 'Default'>().oneOf(['TikTok', 'Instagram', 'Default']),
});

const useInitialValues = (socialMediaSafeZone: SocialMediaSafeZone | null | undefined) => {
  return useMemo(
    () => ({
      socialMediaSafeZone: socialMediaSafeZone ?? ('Default' as const),
    }),
    [socialMediaSafeZone]
  );
};

const useSubmit = (closeDialog: VoidFunction, projectId: string) => {
  const [updateSocialMediaSafeZone] = useUpdateSubtitleSocialMediaSafeZone({
    refetchQueries: ['Project', 'SceneRenderDescription'],
  });

  return useCallback(
    async (values: Values) => {
      await updateSocialMediaSafeZone(projectId, {
        socialMediaSafeZone: values.socialMediaSafeZone === 'Default' ? null : values.socialMediaSafeZone,
      });
      closeDialog();
    },
    [updateSocialMediaSafeZone, projectId, closeDialog]
  );
};

export type SubtitleSafeZoneDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectId: string;
};

export const SubtitleSafeZoneDialog: FC<SubtitleSafeZoneDialogProps> = ({ isOpen, closeDialog, projectId }) => {
  const { project } = useProject(projectId);
  const onSubmit = useSubmit(closeDialog, projectId);
  const initialValues = useInitialValues(project?.socialMediaSafeZone);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.sceneSubtitle.socialMediaSafeZone.dialog.title" size="l">
      <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} autoSubmit>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.sceneSubtitle.socialMediaSafeZone.dialog.subtitle
            </Text>
          </Spacing>
          <SubtitleSafeZonePositionInput />
        </DialogContent>
      </Form>
    </Dialog>
  );
};

const FlexRow = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const AbsoluteContainer = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SubtitleSafeZonePositionInput: FC = () => {
  const { isSubmitting, values } = useFormikContext<Values>();
  return (
    <FlexRow>
      <SubtitleSafeZoneBoxInput
        name="socialMediaSafeZone"
        id="default"
        data-testid="default-subtitle-safe-zone-input"
        value="Default"
        title="i18n.sceneSubtitle.socialMediaSafeZone.type.Default"
        disabled={isSubmitting}
      >
        {values.socialMediaSafeZone === 'Default' && isSubmitting && (
          <AbsoluteContainer>
            <LoadingSpinner />
          </AbsoluteContainer>
        )}
        <DefaultSafeZoneSvg />
      </SubtitleSafeZoneBoxInput>
      <SubtitleSafeZoneBoxInput
        name="socialMediaSafeZone"
        id="instragram"
        data-testid="instagram-subtitle-safe-zone-input"
        value="Instagram"
        title="i18n.sceneSubtitle.socialMediaSafeZone.type.Instagram"
        disabled={isSubmitting}
      >
        {values.socialMediaSafeZone === 'Instagram' && isSubmitting && (
          <AbsoluteContainer>
            <LoadingSpinner />
          </AbsoluteContainer>
        )}
        <InstagramSafeZoneSvg />
      </SubtitleSafeZoneBoxInput>
      <SubtitleSafeZoneBoxInput
        name="socialMediaSafeZone"
        id="tiktok"
        data-testid="tiktok-subtitle-safe-zone-input"
        value="TikTok"
        title="i18n.sceneSubtitle.socialMediaSafeZone.type.TikTok"
        disabled={isSubmitting}
      >
        {values.socialMediaSafeZone === 'TikTok' && isSubmitting && (
          <AbsoluteContainer>
            <LoadingSpinner />
          </AbsoluteContainer>
        )}
        <TikTokSafeZoneSvg />
      </SubtitleSafeZoneBoxInput>
    </FlexRow>
  );
};
