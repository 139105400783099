import { LAYER_INDEXES } from '@cofenster/constants';

import type {
  AudioSceneAsset,
  ColorSceneAsset,
  ImageSceneAsset,
  SceneAsset,
  SceneAssetAsset,
  SceneAudio,
  SceneColor,
  SceneImage,
  SceneVideo,
  VideoAsset,
  VideoSceneAsset,
} from './types';

export type { SceneAudio, SceneColor, SceneImage, SceneVideo } from './types';

export const isSceneAudio = (asset: SceneAssetAsset): asset is SceneAudio => asset.__typename === 'SceneAudio';
export const isSceneColor = (asset: SceneAssetAsset): asset is SceneColor => asset.__typename === 'SceneColor';
export const isSceneImage = (asset: SceneAssetAsset): asset is SceneImage => asset.__typename === 'SceneImage';
export const isSceneVideo = (asset: SceneAssetAsset): asset is SceneVideo => asset.__typename === 'SceneVideo';

export const isAudioSceneAsset = (sceneAsset: SceneAsset): sceneAsset is AudioSceneAsset =>
  sceneAsset.__typename === 'AudioSceneAsset';
export const isColorSceneAsset = (sceneAsset: SceneAsset): sceneAsset is ColorSceneAsset =>
  sceneAsset.__typename === 'ColorSceneAsset';
export const isImageSceneAsset = (sceneAsset: SceneAsset): sceneAsset is ImageSceneAsset =>
  sceneAsset.__typename === 'ImageSceneAsset';
export const isVideoSceneAsset = (sceneAsset: SceneAsset): sceneAsset is VideoSceneAsset =>
  sceneAsset.__typename === 'VideoSceneAsset';

export const isMainSceneAsset = (sceneAsset: SceneAsset) => sceneAsset.layerIndex === LAYER_INDEXES.main;
export const isOverlaySceneAsset = (sceneAsset: SceneAsset) => sceneAsset.layerIndex === LAYER_INDEXES.overlay;

export const isVideoAssetProcessing = ({ videoUrl, status }: VideoAsset) => !!videoUrl && status !== 'Ready';
