import { useCallback, useMemo } from 'react';

import { useAuthProviderByEmailQuery } from '../../generated';

export const useAuthProviderByEmail = (email?: string) => {
  const { loading, error, data, refetch } = useAuthProviderByEmailQuery({
    variables: email ? { email } : undefined,
    fetchPolicy: 'no-cache',
    skip: !email,
  });

  const getAuthProvider = useCallback(
    async (email: string) => {
      const response = await refetch({ email });
      return response.data?.authProviderByEmail ?? undefined;
    },
    [refetch]
  );

  const authProvider = data?.authProviderByEmail ?? undefined;

  return useMemo(
    () => ({ authProvider, loading, error, getAuthProvider }),
    [authProvider, loading, error, getAuthProvider]
  );
};
