import type { FC } from 'react';

import { Icon, Responsive } from '@cofenster/web-components';

import { RouterButton, type RouterButtonProps } from '../../../../components/button/RouterButton';

export const SceneCropButton: FC<Partial<RouterButtonProps> & { projectId: string; sceneId: string }> = ({
  projectId,
  sceneId,
  ...rest
}) => (
  <Responsive up="md">
    <RouterButton
      {...rest}
      to="projectManipulateAsset"
      params={{ projectId, sceneId }}
      variant="tertiary"
      startIcon={<Icon type="CropIcon" size="s" />}
      data-testid="scene-manipulate-asset-button"
    >
      i18n.projectEdit.manipulateAsset.crop
    </RouterButton>
  </Responsive>
);
