import { useContext } from 'react';

import { RemotionPlayerRefContext } from './RemotionPlayerRefContext';

export const useRemotionPlayerRef = (failOnMissingContext = true) => {
  const context = useContext(RemotionPlayerRefContext);

  if (!context && failOnMissingContext) {
    throw new Error('RemotionPlayerRefProvider missing in tree');
  }

  return context;
};
