import type { FC } from 'react';

import { Icon, Text, styled } from '@cofenster/web-components';

const MusicContainer = styled('p')(({ theme }) => ({
  position: 'absolute',
  top: `calc(100% + ${theme.spacing(1)})`,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  margin: 0,
  whiteSpace: 'nowrap',
}));

export const EditorMusicHint: FC = () => (
  <MusicContainer>
    <Icon type="MusicNoteIcon" size="s" color="carbon" />
    <Text color="carbon">i18n.projectEditor.hint.music.short</Text>
  </MusicContainer>
);
