import { type KeyboardEventHandler, useCallback, useState } from 'react';

export type TablistProps = { role: string };

export type TabProps = {
  role: string;
  tabIndex?: number;
  id: string;
  'aria-controls': string;
  'aria-selected': boolean;
  type: string;
  onClick: () => unknown;
  onKeyDown: KeyboardEventHandler<HTMLElement>;
};

export type TabSectionProps = {
  id: string;
  hidden: boolean;
};

export const useTabNavigation = (
  tabs: string[],
  initialTab: string
): {
  currentTab: string;
  getTablistProps: () => TablistProps;
  getTabProps: (tabName: string) => TabProps;
  getTabSectionProps: (tabName: string) => TabSectionProps;
} => {
  const [currentTab, setCurrentTab] = useState(initialTab);
  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const count = tabs.length;
      const index = tabs.findIndex((tab: string) => tab === currentTab);
      const first = tabs[0];
      const last = tabs[count - 1];

      if (!first || !last) return;

      if (event.which === 37) {
        const prev = tabs[index - 1];
        if (index === 0) setCurrentTab(last);
        else if (prev) setCurrentTab(prev);
      }
      if (event.which === 39) {
        const next = tabs[index + 1];
        if (index === count - 1) setCurrentTab(first);
        else if (next) setCurrentTab(next);
      }
      if (event.which === 36) setCurrentTab(first);
      if (event.which === 35) setCurrentTab(last);
    },
    [currentTab, tabs]
  );

  const getTablistProps = () => ({ role: 'tablist' });
  const getTabProps = (name: string) => ({
    role: 'tab',
    tabIndex: name === currentTab ? undefined : -1,
    id: name,
    'aria-controls': `${name}-tab`,
    'aria-selected': name === currentTab,
    type: 'button',
    onClick: () => setCurrentTab(name),
    onKeyDown: handleKeyDown,
  });
  const getTabSectionProps = (name: string) => ({
    id: `${name}-tab`,
    hidden: name !== currentTab,
  });

  return { currentTab, getTablistProps, getTabProps, getTabSectionProps };
};
