import { type FC, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import { AssetDropzone, DropzoneStatus, useUploadHint } from '@cofenster/web-components';

import type { SceneImage } from '../../../api/hooks/scene/useScenes';
import { useProject } from '../../../contexts/project/useProject';
import { useScenes } from '../../../contexts/scenes/useScenes';
import { OpenSceneAssetLifecyclePageButton } from '../OpenSceneAssetLifecyclePage';
import type { SceneUploadingAssetProps } from '../SceneUploadingAssetProps';
import { useAssetState } from '../useSceneAssetState';

export type SceneImageUploadProps = {
  sceneImage?: SceneImage;
} & SceneUploadingAssetProps;

export const SceneImageUpload: FC<SceneImageUploadProps> = ({
  sceneImage,
  startUpload,
  uploadProgress,
  cancelUpload,
  cancelProcessing,
  deleteSceneAsset,
  disabled,
}) => {
  const imageAsset = sceneImage?.imageAsset;
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const assetState = useAssetState(imageAsset ?? undefined, uploadProgress);

  const { project } = useProject();
  const { currentScene } = useScenes();
  const hintText = useUploadHint('image', project?.videoFormat);

  if (assetState === 'ERROR') {
    return (
      <DropzoneStatus
        size="medium"
        status="error"
        text="i18n.sceneImage.error"
        onCancel={deleteSceneAsset}
        backgroundColor="linen50"
      />
    );
  }

  if (assetState === 'UPLOADING') {
    return (
      <DropzoneStatus
        size="medium"
        status="uploading"
        text={fileName}
        hintText="i18n.sceneImage.uploading"
        onCancel={cancelUpload}
        progress={uploadProgress || 0}
        backgroundColor="linen50"
      />
    );
  }

  if (assetState === 'PROCESSING') {
    return (
      <DropzoneStatus
        size="medium"
        backgroundColor="linen50"
        status="processing"
        text={fileName}
        hintText="i18n.sceneImage.processing"
        onCancel={cancelProcessing}
      />
    );
  }

  return (
    <AssetDropzone
      onFile={(file: File) => {
        startUpload(file);
        setFileName(file.name);
      }}
      mime={MimeTypes.image}
      text="i18n.sceneImage.dropzone.text"
      hintText={hintText}
      size="medium"
      icon="ImageIcon"
      data-testid="scene-image-upload"
      backgroundColor="linen50"
      disabled={disabled}
    >
      {project && currentScene && (
        <OpenSceneAssetLifecyclePageButton
          projectId={project.id}
          scene={currentScene}
          type="image"
          disabled={disabled}
          videoFormat={project.videoFormat}
        />
      )}
    </AssetDropzone>
  );
};
