import { Card, GridContainer, GridItem, IconButton, Typography, styled } from '@cofenster/web-components';

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  '&:disabled': { backgroundColor: theme.palette.brand.carbon_alpha10 },
}));

export const EmptyMusicCard = () => (
  <StyledCard>
    <GridContainer alignItems="center">
      <GridItem>
        <StyledIconButton label="i18n.global.play" iconSize="l" iconWeight="fill" icon="PlayIcon" disabled />
      </GridItem>
      <GridItem xs textAlign="center">
        <Typography component="p" variant="l">
          i18n.projectMusic.music.hint
        </Typography>
      </GridItem>
    </GridContainer>
  </StyledCard>
);
