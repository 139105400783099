import { useMemo } from 'react';

import { type RoleRequestsQuery, useRoleRequestsQuery } from '../../generated';

export type RoleRequest = NonNullable<RoleRequestsQuery>['roleRequests'][number];
export const useRoleRequests = (teamId: string | null = null) => {
  const { data, loading, error } = useRoleRequestsQuery({ variables: { teamId } });
  return useMemo(() => {
    return { roleRequests: data?.roleRequests, loading, error };
  }, [data, loading, error]);
};
