import type { FC, ReactNode } from 'react';

import { EmptyState } from '@cofenster/web-components';

export const RenderJobFailed: FC<{ cta?: ReactNode }> = ({ cta }) => (
  <EmptyState
    iconType="WarningCircleIcon"
    title="i18n.projectVideo.renderJobFailed.title"
    description="i18n.projectVideo.renderJobFailed.subtitle"
    cta={cta}
  />
);
