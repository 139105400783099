import type { FC } from 'react';

import { Icon, type IconType, Responsive } from '@cofenster/web-components';

import { RouterButton } from '../../../../components/button/RouterButton';

export const SceneOverlaysButton: FC<{
  projectId: string;
  sceneId: string;
  text: string;
  icon: IconType;
  disabled?: boolean;
}> = ({ projectId, sceneId, text, icon, disabled }) => {
  return (
    <Responsive up="md">
      <RouterButton
        to="projectSceneOverlays"
        params={{ projectId, sceneId }}
        variant="tertiary"
        startIcon={<Icon type={icon} size="s" />}
        data-testid="scene-overlays"
        disabled={disabled}
      >
        {text}
      </RouterButton>
    </Responsive>
  );
};
