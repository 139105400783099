import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import { type ScenesQuery, type ScenesQueryVariables, useScenesQuery } from '../../generated';

export type Scene = NonNullable<ScenesQuery>['scenes'][number];
export type SceneText = NonNullable<Scene['sceneTexts'][number]>;

// A scene has some ‘scene assets’
export type SceneAsset = Scene['sceneAssets'][number];

// These scene assets can be of 4 different types
export type VideoSceneAsset = Extract<SceneAsset, { __typename?: 'VideoSceneAsset' }>;
export type ImageSceneAsset = Extract<SceneAsset, { __typename?: 'ImageSceneAsset' }>;
export type ColorSceneAsset = Extract<SceneAsset, { __typename?: 'ColorSceneAsset' }>;
export type AudioSceneAsset = Extract<SceneAsset, { __typename?: 'AudioSceneAsset' }>;

// Each of these scene assets have an ‘asset’
export type SceneVideo = NonNullable<VideoSceneAsset['asset']>;
export type SceneAudio = NonNullable<AudioSceneAsset['asset']>;
export type SceneImage = NonNullable<ImageSceneAsset['asset']>;
export type SceneColor = NonNullable<ColorSceneAsset['asset']>;

// These assets have a ‘typed asset’ (e.g. ‘imageAsset’ for an image scene)
export type ImageAsset = NonNullable<SceneImage['imageAsset']>;
export type VideoAsset = NonNullable<SceneVideo['videoAsset']>;
export type ColorAsset = NonNullable<SceneColor['colorAsset']>;
export type AudioAsset = NonNullable<SceneAudio['audioAsset']>;

// A video scene can have ‘video sections’
export type VideoSection = NonNullable<SceneVideo['videoSections']>[number];

export const useScenes = (projectId?: ScenesQueryVariables['projectId']) => {
  const { loading, error, data, startPolling, stopPolling, refetch } = useScenesQuery({
    variables: projectId ? { projectId } : undefined,
    skip: !projectId,
    skipPollAttempt: () => document.hidden,
  });
  const scenes = data?.scenes;

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({
      scenes: scenes || [],
      loading,
      error,
      startPolling,
      stopPolling,
      refetch,
    }),
    [scenes, loading, error, startPolling, stopPolling, refetch]
  );
};
