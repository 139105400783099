import { styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

import type { TextVariants } from '../../../../theming/theme';
import type { IconSize } from '../../../assets/icons/Icon';
import { Spacing } from '../../../layout/Spacing';
import { Text } from '../../../typography/Text';
import { Typography } from '../../../typography/Typography';

export type AssetDropzoneSize = 'small' | 'medium';
export type FontWeight = 'normal' | 'bold';

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: theme.spacing(2),
}));

const MainText = styled(Typography)<{ fontWeight: FontWeight }>(({ fontWeight }) => ({
  fontWeight,
  fontFamily: fontWeight === 'bold' ? 'Beatrice' : undefined,
  wordBreak: 'break-word',
}));

export type DropzoneContentProps = {
  icon: ReactNode;
  text?: string;
  hintText?: ReactNode;
  size?: AssetDropzoneSize;
  isActive?: boolean;
  hintFontWeight?: FontWeight;
};

export const DropzoneContent: FC<DropzoneContentProps> = ({
  icon,
  text,
  hintText,
  size = 'small',
  isActive = false,
  hintFontWeight,
}) => {
  const sizing: {
    [key in AssetDropzoneSize]: {
      icon: IconSize;
      text: TextVariants;
      hintText: TextVariants;
      errorText: TextVariants;
      fontWeight: FontWeight;
    };
  } = {
    small: {
      icon: 'm',
      text: 'l',
      fontWeight: 'normal',
      hintText: 's',
      errorText: 'l',
    },
    medium: {
      icon: 'xxl',
      fontWeight: 'bold',
      text: 'l',
      hintText: 'm',
      errorText: 'xl',
    },
  };
  const selectedSize = sizing[size];

  return (
    <Content>
      {icon}
      {text && (
        <Spacing top={1.5}>
          <MainText
            fontWeight={hintFontWeight ?? selectedSize.fontWeight}
            variant={selectedSize.text}
            color={isActive ? 'blue' : 'carbon'}
            component="p"
          >
            {text}
          </MainText>
        </Spacing>
      )}
      {!!hintText && (
        <Spacing top={0.5}>
          <Text variant={selectedSize.hintText} color={isActive ? 'blue' : 'grey700'} component="div">
            {hintText}
          </Text>
        </Spacing>
      )}
    </Content>
  );
};
