import { type FC, memo } from 'react';

export const TikTokSafeZoneSvg: FC = memo(() => (
  <svg width="88" height="168" viewBox="0 0 88 168" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="85" height="165" rx="10.5" fill="#223A41" stroke="#0A242C" strokeWidth="3" />
    <rect x="8" y="127" width="20" height="4" rx="2" fill="#627378" />
    <rect x="16" y="151" width="20" height="4" rx="2" fill="#627378" />
    <rect x="8" y="135" width="48" height="12" rx="2.60494" fill="#627378" />
    <path
      d="M12.9903 150.603C12.968 150.585 12.9418 150.572 12.9139 150.567C12.886 150.561 12.8572 150.561 12.8295 150.568L9.82953 151.318C9.78901 151.328 9.75303 151.352 9.7273 151.385C9.70156 151.418 9.68756 151.458 9.6875 151.5V154.08C9.53107 153.976 9.34419 153.927 9.15657 153.941C8.96895 153.954 8.79134 154.031 8.65197 154.157C8.5126 154.283 8.41947 154.453 8.38737 154.638C8.35527 154.823 8.38605 155.014 8.47482 155.18C8.56358 155.346 8.70524 155.477 8.87728 155.554C9.04932 155.63 9.24187 155.646 9.42434 155.6C9.6068 155.554 9.76871 155.449 9.88434 155.301C9.99997 155.152 10.0627 154.969 10.0625 154.781V152.584L12.6875 151.928V153.33C12.5311 153.226 12.3442 153.177 12.1566 153.191C11.9689 153.204 11.7913 153.281 11.652 153.407C11.5126 153.533 11.4195 153.703 11.3874 153.888C11.3553 154.073 11.3861 154.264 11.4748 154.43C11.5636 154.596 11.7052 154.727 11.8773 154.804C12.0493 154.88 12.2419 154.896 12.4243 154.85C12.6068 154.804 12.7687 154.699 12.8843 154.551C13 154.402 13.0627 154.219 13.0625 154.031V150.75C13.0624 150.721 13.0559 150.693 13.0434 150.668C13.0309 150.642 13.0127 150.62 12.9903 150.603Z"
      fill="#627378"
    />
    <rect
      x="9"
      y="27"
      width="56"
      height="91"
      rx="5"
      fill="white"
      fillOpacity="0.2"
      stroke="white"
      strokeWidth="2"
      strokeDasharray="10 4"
    />
    <path
      d="M79.0625 61C79.0625 61.8035 78.8242 62.5889 78.3778 63.257C77.9315 63.9251 77.297 64.4458 76.5547 64.7533C75.8123 65.0607 74.9955 65.1412 74.2074 64.9844C73.4194 64.8277 72.6955 64.4408 72.1274 63.8726C71.5592 63.3045 71.1723 62.5806 71.0156 61.7926C70.8588 61.0045 70.9393 60.1877 71.2467 59.4453C71.5542 58.703 72.0749 58.0685 72.743 57.6222C73.4111 57.1758 74.1965 56.9375 75 56.9375C76.077 56.9388 77.1096 57.3673 77.8711 58.1289C78.6327 58.8904 79.0612 59.923 79.0625 61Z"
      fill="#627378"
    />
    <path
      d="M75 62.375C74.6786 62.375 74.3644 62.4703 74.0972 62.6489C73.83 62.8274 73.6217 63.0812 73.4987 63.3781C73.3757 63.6751 73.3435 64.0018 73.4062 64.317C73.4689 64.6322 73.6237 64.9218 73.851 65.149C74.0782 65.3763 74.3678 65.5311 74.683 65.5938C74.9982 65.6565 75.3249 65.6243 75.6219 65.5013C75.9188 65.3783 76.1726 65.17 76.3511 64.9028C76.5297 64.6356 76.625 64.3214 76.625 64C76.6245 63.5692 76.4531 63.1562 76.1485 62.8515C75.8438 62.5469 75.4308 62.3755 75 62.375ZM75.625 64.125H75.125V64.625C75.125 64.6582 75.1118 64.6899 75.0884 64.7134C75.0649 64.7368 75.0332 64.75 75 64.75C74.9668 64.75 74.9351 64.7368 74.9116 64.7134C74.8882 64.6899 74.875 64.6582 74.875 64.625V64.125H74.375C74.3418 64.125 74.3101 64.1118 74.2866 64.0884C74.2632 64.0649 74.25 64.0332 74.25 64C74.25 63.9668 74.2632 63.9351 74.2866 63.9116C74.3101 63.8882 74.3418 63.875 74.375 63.875H74.875V63.375C74.875 63.3418 74.8882 63.3101 74.9116 63.2866C74.9351 63.2632 74.9668 63.25 75 63.25C75.0332 63.25 75.0649 63.2632 75.0884 63.2866C75.1118 63.3101 75.125 63.3418 75.125 63.375V63.875H75.625C75.6582 63.875 75.6899 63.8882 75.7134 63.9116C75.7368 63.9351 75.75 63.9668 75.75 64C75.75 64.0332 75.7368 64.0649 75.7134 64.0884C75.6899 64.1118 75.6582 64.125 75.625 64.125Z"
      fill="#B5BDC0"
    />
    <path
      d="M79.375 77.6719C79.375 80.4062 75.3207 82.6195 75.148 82.7109C75.1025 82.7354 75.0517 82.7482 75 82.7482C74.9483 82.7482 74.8975 82.7354 74.852 82.7109C74.6793 82.6195 70.625 80.4062 70.625 77.6719C70.6257 77.0298 70.8811 76.4142 71.3352 75.9602C71.7892 75.5061 72.4048 75.2507 73.0469 75.25C73.8535 75.25 74.5598 75.5969 75 76.1832C75.4402 75.5969 76.1465 75.25 76.9531 75.25C77.5952 75.2507 78.2108 75.5061 78.6648 75.9602C79.1189 76.4142 79.3743 77.0298 79.375 77.6719Z"
      fill="#627378"
    />
    <path
      d="M79.0625 96.9983C79.0627 97.6997 78.8812 98.3892 78.5358 98.9996C78.1905 99.6101 77.6929 100.121 77.0917 100.482C76.4904 100.843 75.8059 101.042 75.1047 101.06C74.4036 101.078 73.7097 100.915 73.0906 100.585L71.7605 101.028C71.6504 101.065 71.5322 101.07 71.4193 101.044C71.3063 101.017 71.203 100.96 71.1209 100.877C71.0388 100.795 70.9812 100.692 70.9545 100.579C70.9279 100.466 70.9332 100.348 70.9699 100.238L71.4133 98.9077C71.1235 98.3629 70.9616 97.7592 70.94 97.1425C70.9184 96.5257 71.0375 95.9122 71.2884 95.3484C71.5393 94.7846 71.9153 94.2853 72.3879 93.8886C72.8606 93.4918 73.4174 93.2079 74.0161 93.0584C74.6149 92.909 75.2398 92.8979 75.8434 93.026C76.4471 93.1541 77.0136 93.4181 77.5 93.7979C77.9864 94.1776 78.38 94.6632 78.6507 95.2177C78.9214 95.7723 79.0622 96.3812 79.0625 96.9983Z"
      fill="#627378"
    />
    <path
      d="M77.1875 111.25H72.8125C72.6467 111.25 72.4878 111.316 72.3706 111.433C72.2533 111.55 72.1875 111.709 72.1875 111.875V118.75C72.1875 118.806 72.2025 118.861 72.2308 118.909C72.2591 118.957 72.2998 118.996 72.3486 119.023C72.3974 119.05 72.4525 119.064 72.5082 119.062C72.564 119.061 72.6183 119.044 72.6656 119.015L75 117.556L77.3348 119.015C77.3821 119.044 77.4364 119.061 77.492 119.062C77.5477 119.063 77.6028 119.05 77.6515 119.023C77.7002 118.996 77.7409 118.956 77.7692 118.908C77.7975 118.86 77.8124 118.806 77.8125 118.75V111.875C77.8125 111.709 77.7467 111.55 77.6294 111.433C77.5122 111.316 77.3533 111.25 77.1875 111.25Z"
      fill="#627378"
    />
    <path
      d="M79.2836 132.596L76.1586 135.721C76.1149 135.765 76.0592 135.795 75.9985 135.807C75.9379 135.819 75.875 135.813 75.8179 135.789C75.7607 135.766 75.7119 135.725 75.6776 135.674C75.6432 135.623 75.6249 135.562 75.625 135.5V133.947C73.3945 134.073 71.8652 135.52 71.4359 135.978C71.3685 136.05 71.2801 136.099 71.1833 136.118C71.0865 136.137 70.9862 136.124 70.8967 136.083C70.8072 136.041 70.7331 135.973 70.6849 135.887C70.6366 135.801 70.6168 135.702 70.6281 135.604C70.773 134.344 71.4633 133.131 72.5719 132.191C73.4926 131.41 74.6117 130.914 75.625 130.827V129.25C75.6249 129.188 75.6432 129.128 75.6776 129.077C75.7119 129.025 75.7607 128.985 75.8179 128.961C75.875 128.938 75.9379 128.931 75.9985 128.944C76.0592 128.956 76.1149 128.985 76.1586 129.029L79.2836 132.154C79.3126 132.183 79.3357 132.218 79.3514 132.256C79.3671 132.294 79.3752 132.334 79.3752 132.375C79.3752 132.416 79.3671 132.457 79.3514 132.495C79.3357 132.533 79.3126 132.567 79.2836 132.596Z"
      fill="#627378"
    />
    <circle cx="75" cy="151" r="5" fill="#627378" />
    <path
      d="M72.3269 147.402C72.312 147.39 72.2946 147.382 72.276 147.378C72.2574 147.374 72.2381 147.374 72.2197 147.379L70.2197 147.879C70.1927 147.886 70.1687 147.901 70.1515 147.923C70.1344 147.945 70.125 147.972 70.125 148V149.72C70.0207 149.651 69.8961 149.618 69.771 149.627C69.646 149.636 69.5276 149.687 69.4346 149.771C69.3417 149.856 69.2796 149.968 69.2582 150.092C69.2368 150.216 69.2574 150.343 69.3165 150.453C69.3757 150.564 69.4702 150.652 69.5849 150.702C69.6995 150.753 69.8279 150.764 69.9496 150.734C70.0712 150.703 70.1791 150.633 70.2562 150.534C70.3333 150.435 70.3751 150.313 70.375 150.187V148.723L72.125 148.285V149.22C72.0207 149.151 71.8961 149.118 71.771 149.127C71.646 149.136 71.5276 149.187 71.4346 149.271C71.3417 149.356 71.2796 149.468 71.2582 149.592C71.2368 149.716 71.2574 149.843 71.3165 149.953C71.3757 150.064 71.4702 150.152 71.5849 150.202C71.6995 150.253 71.8279 150.264 71.9496 150.234C72.0712 150.203 72.1791 150.133 72.2562 150.034C72.3333 149.935 72.3751 149.813 72.375 149.687V147.5C72.375 147.481 72.3706 147.462 72.3623 147.445C72.3539 147.428 72.3418 147.413 72.3269 147.402Z"
      fill="#B5BDC0"
    />
    <path
      d="M78.4952 154.301C78.484 154.292 78.4709 154.286 78.457 154.283C78.443 154.28 78.4286 154.281 78.4148 154.284L76.9148 154.659C76.8945 154.664 76.8765 154.676 76.8636 154.692C76.8508 154.709 76.8438 154.729 76.8438 154.75V156.04C76.7655 155.988 76.6721 155.963 76.5783 155.97C76.4845 155.977 76.3957 156.015 76.326 156.078C76.2563 156.142 76.2097 156.226 76.1937 156.319C76.1776 156.412 76.193 156.507 76.2374 156.59C76.2818 156.673 76.3526 156.739 76.4386 156.777C76.5247 156.815 76.6209 156.823 76.7122 156.8C76.8034 156.777 76.8844 156.724 76.9422 156.65C77 156.576 77.0313 156.485 77.0313 156.391V155.292L78.3438 154.964V155.665C78.2655 155.613 78.1721 155.588 78.0783 155.595C77.9845 155.602 77.8957 155.64 77.826 155.703C77.7563 155.767 77.7097 155.851 77.6937 155.944C77.6776 156.037 77.693 156.132 77.7374 156.215C77.7818 156.298 77.8526 156.364 77.9386 156.402C78.0247 156.44 78.1209 156.448 78.2122 156.425C78.3034 156.402 78.3844 156.349 78.4422 156.275C78.5 156.201 78.5313 156.11 78.5313 156.016V154.375C78.5312 154.361 78.528 154.347 78.5217 154.334C78.5154 154.321 78.5064 154.31 78.4952 154.301Z"
      fill="#B5BDC0"
    />
    <path
      d="M80.1586 145.502C80.14 145.487 80.1182 145.477 80.0949 145.472C80.0717 145.467 80.0476 145.468 80.0246 145.474L77.5246 146.099C77.4908 146.107 77.4609 146.126 77.4394 146.154C77.418 146.181 77.4063 146.215 77.4063 146.25V148.4C77.2759 148.313 77.1202 148.272 76.9638 148.284C76.8075 148.295 76.6594 148.359 76.5433 148.464C76.4272 148.569 76.3496 148.711 76.3228 148.865C76.2961 149.019 76.3217 149.178 76.3957 149.317C76.4697 149.455 76.5877 149.564 76.7311 149.628C76.8744 149.691 77.0349 149.705 77.1869 149.667C77.339 149.629 77.4739 149.541 77.5703 149.417C77.6666 149.293 77.7189 149.141 77.7188 148.984V147.153L79.9063 146.606V147.775C79.7759 147.688 79.6202 147.647 79.4638 147.659C79.3075 147.67 79.1594 147.734 79.0433 147.839C78.9272 147.944 78.8496 148.086 78.8228 148.24C78.7961 148.394 78.8217 148.553 78.8957 148.692C78.9697 148.83 79.0877 148.939 79.2311 149.003C79.3744 149.066 79.5349 149.08 79.6869 149.042C79.839 149.004 79.9739 148.916 80.0703 148.792C80.1666 148.668 80.2189 148.516 80.2188 148.359V145.625C80.2187 145.601 80.2133 145.578 80.2028 145.557C80.1924 145.535 80.1773 145.517 80.1586 145.502Z"
      fill="#B5BDC0"
    />
    <path
      d="M79.0817 20.4184L77.2266 18.5626C77.7828 17.8378 78.0425 16.9285 77.953 16.0193C77.8635 15.11 77.4314 14.2689 76.7445 13.6665C76.0576 13.0641 75.1673 12.7455 74.2542 12.7754C73.341 12.8053 72.4734 13.1814 71.8274 13.8274C71.1814 14.4734 70.8053 15.341 70.7754 16.2542C70.7455 17.1673 71.0641 18.0576 71.6665 18.7445C72.2689 19.4314 73.11 19.8635 74.0193 19.953C74.9285 20.0425 75.8378 19.7828 76.5626 19.2266L78.4192 21.0837C78.4628 21.1273 78.5146 21.1619 78.5715 21.1855C78.6285 21.209 78.6896 21.2212 78.7512 21.2212C78.8129 21.2212 78.874 21.209 78.9309 21.1855C78.9879 21.1619 79.0397 21.1273 79.0833 21.0837C79.1269 21.0401 79.1615 20.9883 79.1851 20.9313C79.2087 20.8744 79.2208 20.8133 79.2208 20.7516C79.2208 20.69 79.2087 20.6289 79.1851 20.5719C79.1615 20.515 79.1269 20.4632 79.0833 20.4196L79.0817 20.4184ZM71.7188 16.3751C71.7188 15.8497 71.8746 15.3362 72.1665 14.8993C72.4583 14.4625 72.8732 14.1221 73.3586 13.921C73.8439 13.72 74.378 13.6674 74.8933 13.7699C75.4085 13.8723 75.8818 14.1253 76.2533 14.4968C76.6248 14.8683 76.8778 15.3416 76.9803 15.8569C77.0828 16.3721 77.0302 16.9062 76.8291 17.3916C76.6281 17.8769 76.2876 18.2918 75.8508 18.5837C75.414 18.8755 74.9004 19.0313 74.3751 19.0313C73.6708 19.0306 72.9956 18.7505 72.4976 18.2525C71.9996 17.7545 71.7195 17.0793 71.7188 16.3751Z"
      fill="#627378"
    />
    <rect x="26" y="15" width="16" height="4" rx="2" fill="#627378" />
    <rect x="46" y="15" width="16" height="4" rx="2" fill="#627378" />
    <path d="M19 3H69V3C69 5.20914 67.2091 7 65 7H23C20.7909 7 19 5.20914 19 3V3Z" fill="#0A242C" />
  </svg>
));
