import { useField } from 'formik';
import { type FC, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import { AbsoluteDeleteIcon, AssetDropzone, Box, DropzoneContainer, Typography } from '@cofenster/web-components';

export const FromSRTSubForm: FC = () => {
  const [{ value }, , { setValue }] = useField('srtContent');
  const [file, setFile] = useState<File | null>(null);

  const onFile = async (file: File) => {
    const srtContent = await file.text();
    setFile(file);
    return setValue(srtContent);
  };

  const onDelete = () => {
    setFile(null);
    return setValue(undefined);
  };

  if (value) {
    return (
      <DropzoneContainer size="small">
        <Box fullHeight backgroundColor="grey50" padding={3}>
          {file?.name ? (
            <Typography align="center" component="span">
              {file.name}
            </Typography>
          ) : (
            <Typography>i18n.sceneSubtitle.dropzone.selected.noNamePlaceholder</Typography>
          )}
          <AbsoluteDeleteIcon onClick={onDelete} />
        </Box>
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="small" data-testid="srt-upload-dropzone-container">
      <AssetDropzone
        backgroundColor="white"
        mime={MimeTypes.subtitle}
        maxSize={100 * 1024 * 1024}
        onFile={onFile}
        icon="SubtitlesIcon"
        text="i18n.sceneSubtitle.dropzone.text"
        hintText="i18n.sceneSubtitle.dropzone.hintText"
      />
    </DropzoneContainer>
  );
};
