import { useCallback } from 'react';

import { type MoveProjectFolderMutationVariables, useMoveProjectFolderMutation } from '../../generated';

export const useMoveProjectFolder = () => {
  const [moveProjectFolder] = useMoveProjectFolderMutation({
    refetchQueries: ['ProjectFolder', 'ProjectFolders'],
  });
  return useCallback(
    (id: MoveProjectFolderMutationVariables['id'], input: MoveProjectFolderMutationVariables['input']) => {
      return moveProjectFolder({
        variables: {
          id,
          input,
        },
      });
    },
    [moveProjectFolder]
  );
};
