import { useContext, useEffect } from 'react';

import { ProjectSpaceContext } from './ProjectSpaceContext';

export const useProjectSpace = () => {
  const context = useContext(ProjectSpaceContext);

  if (!context) {
    throw new Error('ProjectSpaceProvider missing in tree');
  }

  return context;
};

export const useSetProjectSpace = (teamId?: string) => {
  const [, setProjectSpace] = useProjectSpace();

  useEffect(() => {
    setProjectSpace(teamId ?? 'private');
  }, [setProjectSpace, teamId]);
};
