import { useCallback } from 'react';

import { type DeleteSceneAssetMutationVariables, useDeleteSceneAssetMutation } from '../../generated';

export const useDeleteSceneAsset = () => {
  const [deleteSceneAsset] = useDeleteSceneAssetMutation({ refetchQueries: ['Scenes', 'SceneRenderDescription'] });
  return useCallback(
    (id: DeleteSceneAssetMutationVariables['id']) => deleteSceneAsset({ variables: { id } }),
    [deleteSceneAsset]
  );
};
