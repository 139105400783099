import { useCallback, useMemo } from 'react';

import type { MutationHookOptions } from '@cofenster/api-client-apollo';

import { type UpdateProjectMutationVariables, useUpdateProjectMutation } from '../../generated';

export type ProjectId = UpdateProjectMutationVariables['id'];

export const useUpdateProject = (options?: Omit<MutationHookOptions, 'variables'>) => {
  const [update, metadata] = useUpdateProjectMutation(options);

  const updateProject = useCallback(
    (id: UpdateProjectMutationVariables['id'], input: UpdateProjectMutationVariables['input']) => {
      return update({ variables: { id, input } });
    },
    [update]
  );

  return useMemo(() => [updateProject, metadata] as const, [updateProject, metadata]);
};
