import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { styled } from '@cofenster/web-components';

import { ErrorState } from '@cofenster/web-remotion-player';
import { RemotionProjectPreview } from '../../../../components/remotionPreview/RemotionProjectPreview';
import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';
import { useRemotionPlayerRef } from '../../../../contexts/editorPlayer/useRemotionPlayerRef';
import type { ProjectRouteParams } from '../../../../routes';

const PreviewContainer = styled('div')(({ theme }) => ({
  height: '100%',
  borderRadius: 'inherit',
  overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },
}));

export const EditorPreview: FC<{ bundleUrl: string; templateIdentifier: string }> = ({
  bundleUrl,
  templateIdentifier,
}) => {
  const { projectId } = useParams() as ProjectRouteParams;
  const { setPlayerRef } = useRemotionPlayerRef();
  const {
    pause,
    play,
    paused,
    setIsVideoBuffering,
    onPlayerReady,
    synchronizeCurrentSceneWithTime,
    hasPreviewLoadingError,
    setHasPreviewLoadingError,
  } = useEditorPlayer();

  const onError = useCallback(() => setHasPreviewLoadingError(true), [setHasPreviewLoadingError]);

  const handlePlayPause = useCallback(() => {
    paused ? play() : pause();
  }, [paused, play, pause]);

  if (hasPreviewLoadingError) return <ErrorState />;

  return (
    <PreviewContainer onClick={handlePlayPause}>
      <RemotionProjectPreview
        projectId={projectId}
        allowFullscreen={true}
        bundleUrl={bundleUrl}
        templateIdentifier={templateIdentifier}
        moveToBeginningWhenEnded={false}
        onEnd={pause}
        onError={onError}
        onLoadingStateChanged={setIsVideoBuffering}
        onPlayerReady={onPlayerReady}
        onProgress={synchronizeCurrentSceneWithTime}
        ref={setPlayerRef}
        spaceKeyToPlayOrPause
      />
    </PreviewContainer>
  );
};
