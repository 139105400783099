import { useField } from 'formik';
import { type ChangeEventHandler, type FC, type PropsWithChildren, useCallback } from 'react';

import { Switch, type SwitchProps } from '../../controls/Switch';

export type FormToggleSwitchProps = SwitchProps & { name: string };

export const FormToggleSwitch: FC<PropsWithChildren<FormToggleSwitchProps>> = ({
  name,
  onChange,
  children,
  ...rest
}) => {
  const [field] = useField({ name, type: 'checkbox' });
  const { onChange: fieldOnChange } = field;

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      fieldOnChange(event);
      onChange?.(event);
    },
    [fieldOnChange, onChange]
  );

  return (
    <Switch {...field} {...rest} onChange={handleChange}>
      {children}
    </Switch>
  );
};
