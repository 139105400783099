import { type FC, useCallback, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Spacing, Text } from '@cofenster/web-components';

import { useAvailableTeamsForPermission } from '../../../hooks/useAvailableTeamsForPermission';
import { RootFolderSelect } from '../../form/RootFolderSelect';

export type CreateProjectDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  personalFolderId: string;
  goToProjectCreation: (folderId: string) => void;
};

export const CreateProjectDialog: FC<CreateProjectDialogProps> = ({
  isOpen,
  closeDialog,
  personalFolderId,
  goToProjectCreation,
}) => {
  const availableTeams = useAvailableTeamsForPermission('ProjectCreate');
  const [selectedRootFolderId, setSelectedRootFolderId] = useState(
    availableTeams[0]?.rootProjectFolder.id ?? personalFolderId
  );

  const handleConfirm = useCallback(() => {
    goToProjectCreation(selectedRootFolderId);
    closeDialog();
  }, [goToProjectCreation, selectedRootFolderId, closeDialog]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="create-project-dialog"
      title="i18n.home.createProjectDialog.headline"
    >
      <DialogContent>
        <Spacing bottom={3}>
          <Text variant="l" color="grey600" component="p">
            i18n.home.createProjectDialog.description
          </Text>
        </Spacing>

        <RootFolderSelect
          label="i18n.home.createProjectDialog.teamSelect.label"
          name="destinationTeam"
          teams={availableTeams}
          privateRootFolderId={personalFolderId}
          value={selectedRootFolderId}
          onChange={setSelectedRootFolderId}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button onClick={handleConfirm} fullWidth data-testid="create-project-dialog-confirm">
          i18n.home.createProjectDialog.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
