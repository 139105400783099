import { styled } from '@mui/material';
import type { ComponentProps, FC, MouseEventHandler, PropsWithChildren } from 'react';

import { IconButton } from '../../controls/Button/IconButton';
import { ProfileCircle, type ProfileCircleProps } from '../../display/ProfileCircle';
import { Text } from '../../typography/Text';

const Container = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: 40,
  maxWidth: '100%',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.brand.carbon,
  borderRadius: theme.shape.borderRadius,
}));

const Ellipsis = styled(Text)(() => ({
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export type UserChipProps = {
  children: string;
  onDelete?: {
    handler: MouseEventHandler<HTMLButtonElement>;
    label: string;
    'aria-labelledby'?: string;
  };
  size?: ProfileCircleProps['size'];
} & ComponentProps<'div'>;

export const UserChip: FC<PropsWithChildren<UserChipProps>> = ({ children, onDelete, size = 'small', ...rest }) => {
  return (
    <Container {...rest}>
      <ProfileCircle size={size} color="light">
        {children}
      </ProfileCircle>

      <Ellipsis variant="l" color="white">
        {children}
      </Ellipsis>

      {!!onDelete && (
        <IconButton
          icon="CloseIcon"
          iconSize="s"
          iconColor="white"
          hoverColor="white"
          backgroundColor="carbon"
          hoverBackgroundColor="grey900"
          onClick={onDelete.handler}
          label={onDelete.label}
        />
      )}
    </Container>
  );
};
