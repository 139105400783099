import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoProjectVideo = (projectId?: string) => {
  const goto = useGoto();

  return useCallback(() => goto(routes.projectVideo, { projectId }), [goto, projectId]);
};
