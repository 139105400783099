const loadIntercomScript = (appId: string) => {
  setTimeout(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${appId}`;
    const x = document.getElementsByTagName('script')[0];
    x?.parentNode?.insertBefore(script, x);
  }, 0);
};

// See: https://github.com/devrnt/react-use-intercom/blob/main/packages/react-use-intercom/src/initialize.ts#L10
// See: https://developers.intercom.com/installing-intercom/web/installation/#basic-javascript
export const initialize = (appId: string) => {
  const load = () => loadIntercomScript(appId);

  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator');
    window.Intercom('update', window.intercomSettings);
  } else {
    const intercom = (...args: unknown[]) => {
      intercom.c(args);
    };

    intercom.q = [] as unknown[];
    intercom.c = (args: unknown[]) => {
      intercom.q.push(args);
    };

    // @ts-expect-error
    window.Intercom = intercom;

    if (document.readyState === 'complete') {
      load();
    } else if ('attachEvent' in window && typeof window.attachEvent === 'function') {
      window.attachEvent('onload', load);
    } else {
      window.addEventListener('load', load, false);
    }
  }
};
