import { useEffect, useRef } from 'react';

type RefLike<T> = { readonly current: T };

export function useRefOfState<T>(state: T): RefLike<T> {
  const ref = useRef(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
}
