import { useCallback, useEffect, useRef, useState } from 'react';

export const STATES = {
  entering: 'ENTERING',
  entered: 'ENTERED',
  exiting: 'EXITING',
  exited: 'EXITED',
};

export type AnimountStatus = (typeof STATES)[keyof typeof STATES];

export const useAnimount = (isVisible: boolean, duration = 300) => {
  const [status, setStatus] = useState(isVisible ? STATES.entered : STATES.exited);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const didMount = useRef(false);

  const updateStatus = useCallback(
    (newStatus: AnimountStatus, nextStatus: AnimountStatus) => {
      clearTimeout(timeout.current);
      setStatus(newStatus);
      timeout.current = setTimeout(() => setStatus(nextStatus), duration);
    },
    [duration]
  );

  useEffect(() => {
    if (didMount.current) {
      if (isVisible) updateStatus(STATES.entering, STATES.entered);
      else updateStatus(STATES.exiting, STATES.exited);
    } else didMount.current = true;
  }, [isVisible, updateStatus]);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  return status;
};
