import { useCallback } from 'react';

import { type CloneProjectMutationVariables, useCloneProjectMutation } from '../../generated';

export const useCloneProject = (refetchQueries?: string[]) => {
  const [cloneProject] = useCloneProjectMutation({ refetchQueries: [...(refetchQueries ?? []), 'Projects'] });

  return useCallback(
    (id: CloneProjectMutationVariables['id'], options?: CloneProjectMutationVariables['options']) =>
      cloneProject({ variables: { id, options } }),
    [cloneProject]
  );
};
