import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGoToCollectSceneAssetPage = (projectId?: string, sceneId?: string) => {
  const goto = useGoto();
  return useCallback(
    (_projectId?: string, _sceneId?: string, search?: { [key: string]: string }) => {
      const resolvedProjectId = _projectId ?? projectId;
      const resolvedSceneId = _sceneId ?? sceneId;
      goto(routes.projectCaptureSceneAsset, { projectId: resolvedProjectId, sceneId: resolvedSceneId }, { search });
    },
    [goto, projectId, sceneId]
  );
};
