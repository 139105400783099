import { isApolloError } from '@cofenster/api-client-apollo';
import { useSnackbars } from '@cofenster/web-components';
import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { useCopyScenesToProject } from '../../../api/hooks/scene/useCopyScenesToProject';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { COPY_SCENE_EVENT_NAME } from './useCopySceneToClipboard';

export const usePasteSceneFromClipboard = (projectId?: string) => {
  const { openSnackbar } = useSnackbars();
  const openDialog = useConfirmDialog(
    useMemo(
      () => ({
        title: 'i18n.dialogs.copyPasteDeniedDialog.title',
        content: 'i18n.dialogs.copyPasteDeniedDialog.content',
        confirm: 'i18n.global.okay',
        cancel: null,
      }),
      []
    )
  );

  const copySceneToProject = useCopyScenesToProject();
  const tracking = useWebManagerTracking();

  const pasteScene = useCallback(
    async (index?: number) => {
      if (!projectId) return;

      const sceneIds = await ClipboardSceneObserver.readScenesFromClipboard();
      if (sceneIds === null) {
        openDialog();
        return;
      }
      if (!sceneIds?.length) return;

      try {
        tracking.trackEvent({
          event: 'scenesPasted',
          details: {
            sceneIds,
          },
        });
        return await copySceneToProject(sceneIds, projectId, index);
      } catch (error) {
        if (error instanceof Error && isApolloError(error)) {
          openSnackbar({ children: 'i18n.projectEdit.copySceneToProject.error', variant: 'error' });
          return;
        }

        openSnackbar({ children: 'i18n.global.error.generic.unknown', variant: 'error' });
      }
    },
    [projectId, copySceneToProject, openSnackbar, openDialog, tracking]
  );

  const hasContent = useSyncExternalStore(ClipboardSceneObserver.subscribe, ClipboardSceneObserver.getSnapshot);

  return useMemo(() => ({ pasteScene, hasContent }), [pasteScene, hasContent]);
};

class ClipboardSceneObserver extends EventTarget {
  private static readonly instance = new ClipboardSceneObserver();

  private constructor() {
    super();

    this.initialize();
  }

  private _state: PermissionState = 'prompt';

  get state() {
    return this._state;
  }

  protected set state(value: PermissionState) {
    if (this._state === value) return;
    this._state = value;
    this.dispatchEvent(new Event('permissionchange'));
  }

  private _hasContent = false;

  get hasContent() {
    return this._hasContent;
  }

  protected set hasContent(value: boolean) {
    if (this._hasContent === value) return;
    this._hasContent = value;
    this.dispatchEvent(new Event('contentchange'));
  }

  static subscribe(onStoreChange: VoidFunction) {
    return ClipboardSceneObserver.instance.subscribe(onStoreChange);
  }

  static getSnapshot() {
    return ClipboardSceneObserver.instance.hasContent;
  }

  /**
   * @returns Scene ids, undefined if no scene is in the clipboard, null if the clipboard content is not accessible
   */
  static readScenesFromClipboard() {
    return ClipboardSceneObserver.instance.readScenesFromClipboard();
  }

  private readonly readScenesFromClipboard = async () => {
    if (this.state === 'denied') return null;

    try {
      if (!document.hasFocus()) document.body.focus();
      const text = await window.navigator.clipboard.readText();
      this.state = 'granted';
      try {
        const { type, ids } = JSON.parse(atob(text));
        if (type !== 'scenes') return undefined;
        if (!Array.isArray(ids)) return undefined;
        const verifiedIds = ids.filter(Boolean).filter((id) => typeof id === 'string');
        if (verifiedIds.length === 0) return undefined;
        return verifiedIds;
      } catch {
        // ignore, not a scene
      }
    } catch {
      console.warn('Clipboard content is not accessible');
      this.state = 'denied';
      return null;
    }
  };

  private readonly subscriptions = new Set<() => void>();

  public subscribe(onStoreChange: VoidFunction) {
    this.subscriptions.add(onStoreChange);
    ClipboardSceneObserver.instance.addEventListener('contentchange', onStoreChange);
    this.dispatchEvent(new Event('subscriptionchange'));
    return () => {
      this.subscriptions.delete(onStoreChange);
      ClipboardSceneObserver.instance.removeEventListener('contentchange', onStoreChange);
      this.dispatchEvent(new Event('subscriptionchange'));
    };
  }

  private async initialize() {
    try {
      const permission = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName });
      this.state = permission.state;
      permission.addEventListener('change', () => {
        this.state = permission.state;
      });
    } catch {
      // ignore
    }

    const checkContent = async () => {
      if (document.visibilityState === 'visible') {
        const content = await this.readScenesFromClipboard();
        this.hasContent = Array.isArray(content) && content.length > 0;
      } else {
        this.hasContent = false;
      }
    };

    const handleListeners = () => {
      if (this.subscriptions.size > 0) {
        document.addEventListener(COPY_SCENE_EVENT_NAME, checkContent);
        if (this.state === 'granted') {
          document.addEventListener('copy', checkContent);
          document.addEventListener('cut', checkContent);
          document.addEventListener('visibilitychange', checkContent);
        }
      } else {
        document.removeEventListener(COPY_SCENE_EVENT_NAME, checkContent);
        document.removeEventListener('copy', checkContent);
        document.removeEventListener('cut', checkContent);
        document.removeEventListener('visibilitychange', checkContent);
      }
    };

    this.addEventListener('subscriptionchange', handleListeners);
    this.addEventListener('permissionchange', handleListeners);
    handleListeners();
  }
}
