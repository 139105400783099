import { useMemo } from 'react';

import { type ContributionRequestsByProjectQuery, useContributionRequestsByProjectQuery } from '../../generated';

export type ContributionRequest = ContributionRequestsByProjectQuery['contributionRequestsByProject'][0];

export const useContributionRequestsByProject = (projectId: string, pollingInterval?: number) => {
  const { data, loading, error, refetch } = useContributionRequestsByProjectQuery({
    variables: { projectId },
    pollInterval: pollingInterval,
  });

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refetch,
    }),
    [data, loading, error, refetch]
  );
};
