import { type FC, useCallback } from 'react';

import { GridContainer, GridItem, IconButton, styled } from '@cofenster/web-components';

// 1. We cannot apply this style with the `backgroundColor` prop of the
//    `IconButton` since by default, when disabled, a transparent background
//    color is applied.
const CircleIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  borderRadius: '50%',

  '&:disabled': { backgroundColor: theme.palette.brand.grey400 }, // 1
  '&:disabled > svg': { fill: theme.palette.brand.grey200 },
}));

const PlayPauseIconButton = styled(CircleIconButton)(() => ({
  width: 'var(--project-editor-player-actions-height)',
  height: 'var(--project-editor-player-actions-height)',
}));

const SceneChangeIconButton = styled(CircleIconButton)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

type Props = {
  gotoNextScene?: VoidFunction;
  gotoPreviousScene?: VoidFunction;
  play: VoidFunction;
  pause: VoidFunction;
  paused: boolean;
  disabled: boolean;
};

export const PlayerActions: FC<Props> = ({ gotoNextScene, gotoPreviousScene, play, pause, paused, disabled }) => {
  const playOrPause = useCallback(() => {
    if (paused) {
      play();
    } else {
      pause();
    }
  }, [play, pause, paused]);

  return (
    <GridContainer alignItems="center" spacing={0} gap={1}>
      <GridItem>
        <SceneChangeIconButton
          icon="SkipBackIcon"
          backgroundColor="carbon"
          label="i18n.projectEditor.playerActions.gotoPreviousScene"
          onClick={gotoPreviousScene}
          iconColor={disabled ? 'grey500' : 'white'}
          iconWeight="fill"
          data-testid="player-actions-previous-scene"
          hoverColor="white"
          hoverBackgroundColor="grey900"
          disabled={!gotoPreviousScene || disabled}
        />
      </GridItem>
      <GridItem>
        <PlayPauseIconButton
          icon={paused ? 'PlayIcon' : 'PauseIcon'}
          backgroundColor="carbon"
          label={paused ? 'i18n.global.play' : 'i18n.global.pause'}
          onClick={playOrPause}
          iconColor={disabled ? 'grey500' : 'white'}
          iconWeight="fill"
          data-testid="player-actions-play-or-pause"
          hoverColor="white"
          hoverBackgroundColor="grey900"
          disabled={disabled}
        />
      </GridItem>
      <GridItem>
        <SceneChangeIconButton
          icon="SkipForwardIcon"
          backgroundColor="carbon"
          label="i18n.projectEditor.playerActions.gotoNextScene"
          onClick={gotoNextScene}
          iconColor={disabled ? 'grey500' : 'white'}
          iconWeight="fill"
          data-testid="player-actions-next-scene"
          hoverColor="white"
          hoverBackgroundColor="grey900"
          disabled={!gotoNextScene || disabled}
        />
      </GridItem>
    </GridContainer>
  );
};
