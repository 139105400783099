import type { FC, PropsWithChildren } from 'react';

import { Icon, SpinningIcon, useUpload } from '@cofenster/web-components';

import type { SceneVideo } from '../../../../../../api/hooks/scene/useScenes';
import { usePollingVideoAsset } from '../../../../../../api/hooks/videoAsset/usePollingVideoAsset';
import type { Scene } from '../../../../../../contexts/scenes/useScenes';
import { useIsLastIncludedScene } from '../../hooks/useIsLastIncludedScene';

import { useVideoAssetTumbnail } from '../../../../../../hooks/useVideoAssetTumbnail';
import { SceneTypeIcon, Thumbnail } from './Thumbnail';

type VideoThumbnailProps = PropsWithChildren<{
  scene: Scene;
  sceneVideo: SceneVideo | undefined;
  isRecording: boolean;
}>;

export const VideoThumbnail: FC<VideoThumbnailProps> = ({ children, scene, sceneVideo, isRecording }) => {
  const videoAsset = usePollingVideoAsset(sceneVideo?.videoAsset?.id || undefined) ?? sceneVideo?.videoAsset;
  const thumbnailUrl = useVideoAssetTumbnail(videoAsset);

  const { getUpload } = useUpload();
  const uploadProgress = getUpload('video', scene.id)?.progress;
  const textElementsCount = scene.sceneTexts.length;
  const isLastIncludedScene = useIsLastIncludedScene()(scene.id);

  if (!videoAsset?.status && typeof uploadProgress === 'number') {
    return (
      <Thumbnail
        hint={<Icon type="CloudUploadIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  if (videoAsset?.status === 'Waiting') {
    return (
      <Thumbnail
        hint={<SpinningIcon type="GearIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  if (videoAsset?.status === 'Error') {
    return (
      <Thumbnail
        hint={<Icon type="CloudErrorIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  return (
    <Thumbnail
      hint={
        <SceneTypeIcon
          title={isRecording ? 'i18n.sceneTypes.screenRecording' : 'i18n.sceneTypes.video'}
          iconType={isRecording ? 'ScreenShareIcon' : 'MovieIcon'}
        />
      }
      hasTransition={scene.withTransition}
      isLastIncludedScene={isLastIncludedScene}
      textElementsCount={textElementsCount}
      image={thumbnailUrl}
      isExcludedScene={scene.excluded}
      data-scene-type="video"
    >
      {children}
    </Thumbnail>
  );
};
