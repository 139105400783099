import { type NodeCue, parseSync, stringifySync } from 'subtitle';

import type { SubtitlePosition } from '@cofenster/constants';
import type { SubtitleNode } from '@cofenster/render-description';

export const srtToSubtitles = (srt: string, position: SubtitlePosition): SubtitleNode[] =>
  parseSync(srt)
    .filter((node): node is NodeCue => node.type === 'cue')
    .map((node) => ({
      start: node.data.start / 1000,
      end: node.data.end / 1000,
      text: node.data.text,
      position,
    }));

export const subtitlesToSrt = (nodes: SubtitleNode[]) =>
  stringifySync(
    nodes.map((node) => ({
      type: 'cue',
      data: {
        start: node.start * 1000,
        end: node.end * 1000,
        text: node.text,
      },
    })),
    { format: 'SRT' }
  );
