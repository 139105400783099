import { useMemo } from 'react';

import { type SearchStockImagesQuery, useSearchStockImagesLazyQuery } from '../../generated';

export type StockImage = NonNullable<SearchStockImagesQuery['searchStockImages']>['items'][number];
export type StockImageResponse = NonNullable<SearchStockImagesQuery['searchStockImages']>;
export type Attributor = NonNullable<StockImage['attributor']>;

export const useSearchStockImages = () => {
  const [searchStockImages, metadata] = useSearchStockImagesLazyQuery();

  return useMemo(() => [searchStockImages, metadata] as const, [searchStockImages, metadata]);
};
