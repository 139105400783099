import type { AudioAsset, ImageAsset, VideoAsset } from '../../api/hooks/scene/useScenes';

type SceneAssetState = 'ERROR' | 'UPLOADING' | 'PROCESSING' | 'PRISTINE' | 'READY';

export const useAssetState = (
  asset: VideoAsset | ImageAsset | AudioAsset | undefined,
  uploadProgress: number | undefined
): SceneAssetState => {
  if (typeof uploadProgress === 'number') return 'UPLOADING';
  if (!asset) return 'PRISTINE';
  if (asset.status === 'Ready') return 'READY';
  if (asset.status === 'Error') return 'ERROR';
  return 'PROCESSING';
};
