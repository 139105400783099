import { useContext } from 'react';
import { SelectedScenesContext } from './SelectedScenesContext';

export const useSelectedScenes = () => {
  const context = useContext(SelectedScenesContext);
  if (!context) {
    throw new Error('useSelectedScenes must be used within a SelectedScenesProvider');
  }
  return context;
};
