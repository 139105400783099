import { useMemo } from 'react';

import { type ContributionRequestListByProjectQuery, useContributionRequestListByProjectQuery } from '../../generated';

export type ContributionRequestList = ContributionRequestListByProjectQuery['contributionRequestListByProject'][0];

export const useContributionRequestListByProject = (projectId: string | undefined) => {
  const { data, loading, error, refetch } = useContributionRequestListByProjectQuery({
    variables: projectId ? { projectId } : undefined,
    skip: !projectId,
  });

  const contributionRequestLists = data?.contributionRequestListByProject;

  return useMemo(
    () => ({
      contributionRequestLists: contributionRequestLists ?? [],
      loading,
      error,
      refetch,
    }),
    [contributionRequestLists, loading, error, refetch]
  );
};
