import { type CSSProperties, type FC, useCallback, useState } from 'react';

import {
  BlankButton,
  Button,
  GridContainer,
  GridItem,
  SidePanel,
  Translatable,
  Typography,
  styled,
  useSnackbars,
} from '@cofenster/web-components';

import { useCopyFromTheme } from '../../../../api/hooks/theme/useCopyFromTheme';
import type { AccountTheme } from '../../../../api/hooks/user/useMe';
import { ThemeCard } from '../../../../components/branding/ThemeCard';
import type { Project } from '../../../../contexts/project/useProject';
import { useUser } from '../../../../contexts/user/useUser';

const useSubmit = (selectedTheme: AccountTheme | undefined, project: Project, close: Props['close']) => {
  const copyFromTheme = useCopyFromTheme();
  const { openSnackbar } = useSnackbars();
  const themeId = project.theme?.id;

  return useCallback(async () => {
    try {
      if (selectedTheme && themeId) await copyFromTheme(themeId, selectedTheme.id);
    } catch {
      openSnackbar({
        variant: 'error',
        children: 'i18n.global.error.generic.unknown',
      });
    } finally {
      close();
    }
  }, [selectedTheme, themeId, close, copyFromTheme, openSnackbar]);
};

type Props = {
  isOpen: boolean;
  project: Project;
  close: () => unknown;
};

const ExpandedButton = styled(BlankButton)(({ theme }) => ({
  textAlign: 'start',

  '&:focus': {
    outline: 0,
  },

  '&:focus-visible::before': theme.mixins.focusRing,

  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    zIndex: theme.zIndex.base,
    cursor: 'pointer',
    borderRadius: theme.shape['borderRadius-l'],
  },
}));

export const ThemesPanel: FC<Props> = ({ isOpen, project, close }) => {
  const { user } = useUser();
  const [themeState, setThemeState] = useState<AccountTheme['id'] | undefined>();
  const selectedTheme = user?.account.themes.find((theme) => theme.id === themeState);
  const onSubmit = useSubmit(selectedTheme, project, close);

  if (!user?.account.themes) return null;

  return (
    <SidePanel
      data-testid="project-theme-panel"
      isOpen={isOpen}
      close={close}
      headline="i18n.projectDesign.themeForm.panel.headline"
      footer={
        <Button fullWidth onClick={onSubmit} data-testid="project-theme-save-button">
          i18n.global.save
        </Button>
      }
      style={{ '--drawer-top-offset': 'var(--project-header-height, 88px)' } as CSSProperties}
    >
      <GridContainer>
        {user.account.themes && user.account.themes.length > 0 ? (
          user.account.themes.map((theme) => (
            <GridItem key={theme.id} xs={12}>
              <ThemeCard
                theme={theme}
                aria-current={theme.id === themeState}
                renderName={(theme) => (
                  <ExpandedButton
                    type="button"
                    onClick={() => setThemeState(theme.id)}
                    aria-pressed={theme.id === themeState}
                  >
                    {theme.name ?? <Translatable>i18n.projectDesign.themeForm.panel.unnamedTheme</Translatable>}
                  </ExpandedButton>
                )}
              />
            </GridItem>
          ))
        ) : (
          <GridItem>
            <Typography variant="l" component="p" data-testid="project-theme-empty">
              i18n.projectDesign.themeForm.panel.noThemes
            </Typography>
          </GridItem>
        )}
      </GridContainer>
    </SidePanel>
  );
};
