import type { MaybeSubtitlesLanguage } from '../../api/hooks/renderDescription/types';
import { type RenderJob, useRenderJobByProject } from '../../api/hooks/renderJob/useRenderJobByProject';
import type { Project } from '../../contexts/project/useProject';

export const useProjectExportStatus = (project: Project, subtitlesLanguage: MaybeSubtitlesLanguage) => {
  const { renderJob, loading } = useRenderJobByProject(project.id, subtitlesLanguage);

  const renderJobStatus = getRenderJobStatus(renderJob);
  if (loading) return 'UNKNOWN';
  return renderJobStatus;
};

export type RenderJobStatus = 'RENDERING' | 'RENDERED' | 'ERROR' | 'NOT_RENDERED';

export const getRenderJobStatus = (
  renderJob: RenderJob | null | undefined,
  requestedRender = false
): RenderJobStatus => {
  if (!renderJob) return 'NOT_RENDERED';

  const isWaiting = renderJob.status === 'Waiting';
  const isInProgress = renderJob.status === 'InProgress';
  const isRendering = isWaiting || isInProgress || requestedRender;
  const hasError = renderJob.status === 'Failed' || renderJob.videoAsset?.status === 'Error';
  const hasVideo = !!renderJob.videoAsset?.videoUrl;

  if (isRendering) return 'RENDERING';
  if (hasError) return 'ERROR';
  if (hasVideo) return 'RENDERED';
  return 'NOT_RENDERED';
};
