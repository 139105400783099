import { useCallback, useState } from 'react';

import type { AudioSceneAsset } from '../../../api/hooks/scene/useScenes';
import { useDeleteSceneAsset } from '../../../api/hooks/sceneAsset/useDeleteSceneAsset';
import { useConfirmDialog } from '../../useConfirmDialog';

export const useDeleteAudioSceneAsset = (audioSceneAsset?: AudioSceneAsset) => {
  const [busy, setBusy] = useState(false);
  const deleteAudioSceneAsset = useDeleteSceneAsset();
  const confirmDeleteAudioSceneAsset = useConfirmDialog({
    title: 'i18n.dialogs.DeleteAssetDialog.headline',
    content: 'i18n.dialogs.DeleteAssetDialog.text',
    confirm: 'i18n.dialogs.DeleteAssetDialog.button',
  });

  return useCallback(async () => {
    if (!audioSceneAsset || busy) return;
    try {
      setBusy(true);

      if (await confirmDeleteAudioSceneAsset()) {
        await deleteAudioSceneAsset(audioSceneAsset.id);
      }
    } finally {
      setBusy(false);
    }
  }, [confirmDeleteAudioSceneAsset, deleteAudioSceneAsset, audioSceneAsset, busy]);
};
