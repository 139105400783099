import { useCallback, useEffect } from 'react';

import { useDialogs } from '../contexts/dialogs/useDialogs';
import { useUser } from '../contexts/user/useUser';

export const useSessionExpiredDialog = () => {
  const { hasSessionExpired, setHasSessionExpired } = useUser();
  const { openDialog } = useDialogs();
  const onClose = useCallback(() => setHasSessionExpired(false), [setHasSessionExpired]);

  useEffect(() => {
    if (hasSessionExpired) openDialog('SessionExpiredDialog', { onClose });
  }, [hasSessionExpired, openDialog, onClose]);
};
