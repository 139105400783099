import { styled } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

import type { I18nParams } from '../../../services/i18n';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Icon, type IconType } from '../../assets/icons/Icon';
import { BaseLink, type BaseLinkProps } from '../../controls/BaseLink';
import { BlankButton, type BlankButtonProps } from '../../controls/Button/BlankButton';
import { Typography } from '../../typography/Typography';

const Component = (props: SubNavigationItemProps) => {
  if ('href' in props) {
    return <BaseLink {...props} />;
  }

  return <BlankButton {...(props as ButtonProps)} />;
};

const StyledLink = styled(
  Component,
  preventForwardProps(['active'])
)<{ active: boolean }>(({ active, theme, disabled }) => ({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'inline-flex',
  padding: theme.spacing(0, 2),
  height: 48,
  color: disabled ? theme.palette.brand.grey300 : theme.palette.brand.carbon,
  pointerEvents: disabled ? 'none' : 'all',
  cursor: 'pointer',
  textShadow: active ? '0 0 .65px' : undefined,
  whiteSpace: 'nowrap',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    // Fake the font bolding with a text-shadow to avoid a layout shift due to
    // the bold variant being wider than the normal one.
    textShadow: '0 0 .65px',
    outline: 0,
    color: theme.palette.brand.blue,
  },

  '&:focus-visible': theme.mixins.focusRing,

  '& > svg:first-of-type': {
    marginRight: theme.spacing(1),
  },

  '&::before': {
    content: active ? '""' : undefined,
    bottom: 0,
    left: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
    borderRadius: 1.5,
    height: 3,
    backgroundColor: 'currentColor',
  },
}));

type BaseProps = PropsWithChildren<{
  active: boolean;
  disabled?: boolean;
  iconType?: IconType;
  i18nParams?: I18nParams;
}>;

type ButtonProps = BaseProps & BlankButtonProps;
type LinkProps = BaseProps & Pick<BaseLinkProps, 'onClick' | 'href'>;

export type SubNavigationItemProps = ButtonProps | LinkProps;

export const SubNavigationItem: FC<PropsWithChildren<SubNavigationItemProps>> = ({
  children,
  iconType,
  i18nParams,
  ...rest
}) => {
  return (
    <StyledLink {...rest}>
      {iconType && <Icon type={iconType} size="m" />}
      <Typography variant="button-m" i18nParams={i18nParams}>
        {children}
      </Typography>
    </StyledLink>
  );
};
