import { type FC, useCallback } from 'react';

import { Icon, Select, SelectMenuProps, type SelectProps, Translatable, Typography } from '@cofenster/web-components';

import type { ContributionRequest } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';

import { SelectSelectedValue } from './SelectSelectedValue';
import {
  CheckIconContainer,
  LimitedWidthTypography,
  OptionContainer,
  ResetFilterButton,
  SelectedOptionContainer,
  StyledMenuItem,
} from './SelectedOptionContainer';

export const ContributionRequestsSelect: FC<
  Omit<SelectProps, 'label' | 'name'> & {
    name?: string;
    contributionRequests: ContributionRequest[];
  }
> = ({ id = 'contributionRequests', name = 'contributionRequests', contributionRequests, ...rest }) => {
  const isSelected = useCallback(
    (request: ContributionRequest) => {
      return Array.isArray(rest.value) && (rest.value as string[])?.includes(request.id);
    },
    [rest.value]
  );

  return (
    <Select
      id={id}
      name={name}
      label="i18n.projectContributionConfiguration.filter.byRequests.label"
      displayEmpty
      labelProps={{
        shrink: true,
      }}
      renderValue={(value) => {
        const arrayValue = value as string[];
        if (arrayValue.length === 0) {
          return (
            <SelectSelectedValue iconType="ClipboardIcon">
              i18n.projectContributionConfiguration.filter.byRequests.empty
            </SelectSelectedValue>
          );
        }

        return (
          <SelectedOptionContainer>
            <Translatable i18nParams={{ count: arrayValue.length }}>
              i18n.projectContributionConfiguration.filter.byRequests.notEmpty
            </Translatable>
          </SelectedOptionContainer>
        );
      }}
      MenuProps={{
        ...SelectMenuProps,
        PaperProps: {
          ...SelectMenuProps.PaperProps,
          sx: {
            ...SelectMenuProps.PaperProps?.sx,
            padding: 1,
            paddingBottom: 0,
          },
        },
      }}
      multiple
      pb={0}
      {...rest}
    >
      {contributionRequests.map((contributionRequest, index) => (
        <StyledMenuItem p={1} key={contributionRequest.id} value={contributionRequest.id}>
          <OptionContainer>
            {contributionRequest.title ? (
              <LimitedWidthTypography>{contributionRequest.title}</LimitedWidthTypography>
            ) : (
              <Typography i18nParams={{ index: index + 1 }}>
                i18n.projectContributionConfiguration.filter.byRequests.emptyCRTitle
              </Typography>
            )}
          </OptionContainer>
          <CheckIconContainer selected={isSelected(contributionRequest)}>
            <Icon type="CheckIcon" />
          </CheckIconContainer>
        </StyledMenuItem>
      ))}
      <ResetFilterButton fullWidth variant="tertiary" value="reset">
        i18n.projectContributionConfiguration.filter.reset
      </ResetFilterButton>
    </Select>
  );
};
