import { useCallback, useEffect, useMemo, useState } from 'react';

import type { RemotionPlayerRef } from './Player';
import { type UseRemotionPlayerCurrentTimeOptions, useRemotionPlayerCurrentTime } from './usePlayerCurrentTime';
import { usePlayerPausedState } from './usePlayerPausedState';

export const useRemotionPlayerControls = (
  remotionPlayer: RemotionPlayerRef | null,
  options: UseRemotionPlayerCurrentTimeOptions
) => {
  const { paused, play, pause, setPlayerControls } = usePlayerPausedState(remotionPlayer);
  const [isDraggingControls, setIsDraggingControls] = useState(false);
  const currentTime = useRemotionPlayerCurrentTime(remotionPlayer, options);

  const setCurrentTime = useCallback(
    (currentTime: number) => {
      if (remotionPlayer) remotionPlayer.currentTime = currentTime;
    },
    [remotionPlayer]
  );

  useEffect(() => {
    if (!remotionPlayer) return;

    setPlayerControls({
      ...remotionPlayer,
      setCurrentTime,
    });
  }, [remotionPlayer, setCurrentTime, setPlayerControls]);

  return useMemo(
    () => ({ paused, currentTime, play, pause, setCurrentTime, isDraggingControls, setIsDraggingControls }),
    [play, pause, setCurrentTime, paused, currentTime, isDraggingControls]
  );
};
