import type { FC } from 'react';

import {
  GridContainer,
  GridItem,
  Icon,
  PopoverMenuItem,
  ProfileCircle,
  Typography,
  styled,
} from '@cofenster/web-components';

import { RouterLink } from '../../../../../../button/RouterLink';

const StyledPopoverMenuItem = styled(PopoverMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const SettingsLink: FC<{ 'data-testid'?: string }> = (props) => <RouterLink to="settingsPersonal" {...props} />;

export type PopoverProfileLinkProps = {
  name: string;
  accountName?: string;
  onClick: VoidFunction;
};

export const PopoverProfileLink: FC<PopoverProfileLinkProps> = ({ name, accountName }) => {
  return (
    <StyledPopoverMenuItem data-testid="main-profile-link" component={SettingsLink}>
      <GridContainer spacing={1} alignItems="center">
        <GridItem>
          <ProfileCircle size="large" color="dark">
            {name}
          </ProfileCircle>
        </GridItem>
        <GridItem xs textAlign="left">
          <Typography variant="h6" component="div" color="carbon">
            {name}
          </Typography>
          {accountName && (
            <Typography variant="s" component="div" color="grey600">
              {accountName}
            </Typography>
          )}
        </GridItem>
        <GridItem display="flex">
          <Icon type="CaretRightIcon" size="s" />
        </GridItem>
      </GridContainer>
    </StyledPopoverMenuItem>
  );
};
