import type { FC } from 'react';

import { BlankButton, type BlankButtonProps, Icon, Typography, styled } from '@cofenster/web-components';

const Container = styled(BlankButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.grey900,

  '&:hover, &:focus-visible': {
    outline: 0,
    backgroundColor: theme.palette.brand.grey800,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const AddTeam: FC<BlankButtonProps> = ({ onClick }) => (
  <Container onClick={onClick} type="button">
    <Icon type="PlusCircleIcon" />
    <Title variant="h6" color="white">
      i18n.team.addTeam.create
    </Title>
    <Typography>i18n.team.addTeam.decription</Typography>
  </Container>
);
