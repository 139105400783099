import { useMemo } from 'react';

import { type MeQueryResult, useMeQuery } from '../../generated';

export type User = NonNullable<NonNullable<MeQueryResult['data']>['me']>;
export type Account = NonNullable<User['account']>;
export type FeatureFlag = NonNullable<Account['featureFlags']>[number];
export type AccountTheme = NonNullable<NonNullable<MeQueryResult['data']>['me']>['account']['themes'][number];
export type ThemeVideoType = AccountTheme['themeVideoAssets'][number]['videoType'];

export const useMe = () => {
  const { loading, error, data } = useMeQuery();
  const user = data?.me ?? undefined;
  return useMemo(() => ({ user, loading, error }), [user, loading, error]);
};
