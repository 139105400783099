import { useCallback } from 'react';

import { type SendUserInviteEmailMutationVariables, useSendUserInviteEmailMutation } from '../../generated';

export const useSendUserInviteEmail = () => {
  const [sendUserInviteEmail] = useSendUserInviteEmailMutation();
  return useCallback(
    (id: SendUserInviteEmailMutationVariables['id']) => sendUserInviteEmail({ variables: { id } }),
    [sendUserInviteEmail]
  );
};
