import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import {
  type RenderJobsByProjectQueryResult,
  type RenderJobsByProjectQueryVariables,
  useRenderJobsByProjectQuery,
} from '../../generated';
import type { MaybeSubtitlesLanguage } from '../renderDescription/types';

export type RenderJob = NonNullable<NonNullable<RenderJobsByProjectQueryResult['data']>['renderJobsByProject'][number]>;

export const useRenderJobByProject = (
  projectId: RenderJobsByProjectQueryVariables['projectId'],
  subtitlesLanguage: MaybeSubtitlesLanguage = null,
  refetchOnMount = false
) => {
  const { loading, error, data, startPolling, stopPolling } = useRenderJobsByProjectQuery({
    variables: { projectId },
    fetchPolicy: refetchOnMount ? 'cache-and-network' : undefined,
    skipPollAttempt: () => document.hidden,
  });
  const renderJob = data?.renderJobsByProject?.find((renderJob) => renderJob.subtitlesLanguage === subtitlesLanguage);

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({ renderJob, loading, error, startPolling, stopPolling }),
    [renderJob, loading, error, startPolling, stopPolling]
  );
};
