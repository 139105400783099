import { styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

import { Container } from '../Container/Container';

const AppHeaderContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.linen50,
  padding: theme.spacing(4, 0, 0),
  '--sub-navigation-color': theme.palette.brand.linen50,
}));

const AppHeaderContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const AppHeaderRow = styled('div')(() => ({
  display: 'flex',
}));

const AppHeaderTopRow = styled(AppHeaderRow)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 72,
  },
}));

const AppHeaderBottomRow = styled(AppHeaderRow)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
}));

const Right = styled('div')(({ theme }) => ({
  flexShrink: 0,

  [theme.breakpoints.up('md')]: {
    marginLeft: 'auto',
  },
}));

export type AppHeaderProps = {
  topLeft?: ReactNode;
  topRight?: ReactNode;
  bottomLeft?: ReactNode;
  bottomRight?: ReactNode;
};

export const AppHeader: FC<AppHeaderProps> = ({ topLeft, topRight, bottomLeft, bottomRight }) => {
  return (
    <AppHeaderContainer>
      <AppHeaderContent>
        {(topLeft || topRight) && (
          <AppHeaderTopRow>
            {topLeft}
            {topRight && <Right>{topRight}</Right>}
          </AppHeaderTopRow>
        )}

        {(bottomLeft || bottomRight) && (
          <AppHeaderBottomRow>
            {bottomLeft}
            {bottomRight && <Right>{bottomRight}</Right>}
          </AppHeaderBottomRow>
        )}
      </AppHeaderContent>
    </AppHeaderContainer>
  );
};
