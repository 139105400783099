import { Typography, useFormatTimeDistance } from '@cofenster/web-components';
import type { FC } from 'react';
import { useI18n } from '../../../i18n';
import type { Project } from '../ProjectVideoContent';

export const UpdatedAtInfo: FC<{ videoAsset: NonNullable<Project['videoAsset']>; hasPendingChanges: boolean }> = ({
  videoAsset,
  hasPendingChanges,
}) => {
  const { translate } = useI18n();
  const formatTimeDistance = useFormatTimeDistance();

  return (
    <Typography variant="s" component="span" color="grey700">
      {[
        videoAsset.fileSize,
        translate('projectVideo.renderedVideo.lastExported', {
          timeAgo: formatTimeDistance(new Date(videoAsset.updatedAt)),
        }),
        hasPendingChanges && translate('projectVideo.renderedVideo.pendingChanges'),
      ]
        .filter(Boolean)
        .join(' • ')}
    </Typography>
  );
};
