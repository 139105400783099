import { type ComponentProps, useCallback } from 'react';

import type { ChoiceDialog } from '@cofenster/web-components';

import { useDialogs } from '../contexts/dialogs/useDialogs';

export const useChoiceDialog = <L, R>({
  title,
  titleI18nParams,
  content,
  left,
  leftValue,
  leftProps,
  right,
  rightValue,
  rightProps,
}: { leftValue: L; rightValue: R } & Omit<
  ComponentProps<typeof ChoiceDialog>,
  'isOpen' | 'closeDialog' | 'onLeft' | 'onRight' | 'onClose'
>) => {
  const { openDialog } = useDialogs();

  return useCallback(
    () =>
      new Promise<L | R | undefined>((resolve) => {
        openDialog('ChoiceDialog', {
          title,
          titleI18nParams,
          content,
          left,
          leftProps,
          onLeft: () => resolve(leftValue),
          right,
          rightProps,
          onRight: () => resolve(rightValue),
          onClose: () => resolve(undefined),
        });
      }),
    [openDialog, title, titleI18nParams, content, left, leftProps, leftValue, right, rightProps, rightValue]
  );
};
