import { type FC, useCallback, useState } from 'react';

import { preventForwardProps, styled } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProjectFolder } from '../../../contexts/projectFolder/useProjectFolder';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { NewButton } from '../NewButton';

import { FolderRow } from './FolderRow';
import { ShowMore } from './ShowMore';

const Container = styled(
  'div',
  preventForwardProps(['isExpanded', 'maxHeight'])
)<{ isExpanded: boolean; maxHeight: number }>(({ theme, isExpanded, maxHeight }) => ({
  position: 'relative',
  margin: theme.spacing(0, -1),
  padding: theme.spacing(1),
  maxHeight: isExpanded ? maxHeight : 320,
  overflow: 'hidden',
  transition: 'max-height .25s ease-in-out',
}));

type Props = {
  projectFolders: ProjectFolder[];
  isArchive?: boolean;
};

const SHOW_LIMIT = 3;

export const FoldersListView: FC<Props> = ({ projectFolders, isArchive }) => {
  const { projectFolder } = useProjectFolder();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { openDialog } = useDialogs();

  const onFolderCreated = useCallback(
    ({ id }: { id: string }) => {
      if (projectFolders.length >= SHOW_LIMIT) setIsExpanded(true);

      // The `setTimeout` is necessary as the component has not re-rendered with
      // the new DOM node when calling the `onFolderCreated` handler.
      setTimeout(() => {
        document.querySelector(`#folder-${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    },
    [projectFolders.length]
  );

  const openCreateFolderDialog = useCallback(() => {
    openDialog('CreateProjectFolderDialog', {
      projectFolderId: projectFolder.id,
      onFolderCreated,
      teamId: projectFolder?.team?.id,
    });
  }, [openDialog, onFolderCreated, projectFolder]);

  const toggleIsExanded = useCallback(() => setIsExpanded((isExpanded) => !isExpanded), []);

  return (
    <>
      <Container isExpanded={isExpanded} maxHeight={(projectFolders.length + 1) * 72} id="folders-list">
        {!isArchive && (
          <TeamPermissionRestriction has="FolderCreate">
            <NewButton
              type="button"
              onClick={openCreateFolderDialog}
              data-testid="create-folder-button"
              variant="compact"
            >
              i18n.projects.buttons.folder
            </NewButton>
          </TeamPermissionRestriction>
        )}

        {projectFolders.slice(0, isExpanded ? Number.POSITIVE_INFINITY : SHOW_LIMIT).map((projectFolder) => (
          <FolderRow key={projectFolder.id} projectFolder={projectFolder} />
        ))}
      </Container>

      {projectFolders.length > SHOW_LIMIT && <ShowMore onClick={toggleIsExanded} isExpanded={isExpanded} />}
    </>
  );
};
