import { useTheme } from '@mui/material';
import type { FC } from 'react';

import { Svg, type SvgProps } from '../../Svg';

export type LogoIconProps = {
  size?: SvgProps['size'];
  alt?: string;
} & Omit<SvgProps, 'size' | 'viewBox' | 'children' | 'titleAccess'>;

export const LogoIcon: FC<LogoIconProps> = ({ size = 75, color, ...rest }) => {
  const theme = useTheme();
  return (
    <Svg size={size} color={color} viewBox="0 0 100 75" titleAccess="cofenster logo" {...rest}>
      <path
        d="M62.3219 23.5C56.5999 23.5931 52.6602 26.4993 50.4061 29.8634C50.1256 30.2836 49.8043 30.8699 49.5417 31.3404C49.0776 32.1657 46.6704 37.7743 46.446 38.3178C44.8855 42.1247 42.6568 45.3303 38.248 45.5543H37.6794C35.5132 45.5543 33.4358 44.7052 31.9041 43.1938C30.3724 41.6824 29.5119 39.6324 29.5119 37.495C29.5119 35.3575 30.3724 33.3076 31.9041 31.7962C33.4358 30.2848 35.5132 29.4357 37.6794 29.4357C39.2993 29.4317 40.8832 29.9077 42.2259 30.8019L45.449 25.7847C43.6011 24.5914 41.4963 23.8404 39.3023 23.5915C37.1083 23.3427 34.8857 23.6029 32.8116 24.3513C30.7375 25.0998 28.8691 26.3159 27.3554 27.9027C25.8418 29.4895 24.7245 31.4032 24.0927 33.4914C23.461 35.5795 23.3321 37.7844 23.7164 39.9304C24.1007 42.0763 24.9876 44.1041 26.3064 45.8521C27.6251 47.6001 29.3394 49.0201 31.3126 49.9989C33.2857 50.9777 35.4632 51.4882 37.6717 51.4899H38.1817C38.3041 51.4899 38.4367 51.4899 38.5438 51.4899C40.8969 51.3548 43.1786 50.6425 45.1822 49.4176C47.1859 48.1926 48.8482 46.4937 50.0185 44.4748C51.2591 46.6063 53.0467 48.3778 55.2014 49.6111C57.3562 50.8443 59.8021 51.4958 62.2931 51.5C64.7841 51.5042 67.2322 50.8609 69.3912 49.6349C71.5503 48.4089 73.344 46.6435 74.5919 44.5162C75.8398 42.3889 76.4979 39.9747 76.5 37.5167C76.5021 35.0587 75.8481 32.6436 74.6038 30.5142C73.3595 28.3848 71.5687 26.6164 69.4118 25.3868C67.2549 24.1573 64.8078 23.51 62.3168 23.5101L62.3219 23.5ZM62.3219 45.5543C60.7081 45.5543 59.1305 45.0821 57.7886 44.1973C56.4467 43.3126 55.4009 42.0551 54.7833 40.5838C54.1657 39.1125 54.0041 37.4936 54.3189 35.9317C54.6338 34.3698 55.4109 32.9351 56.5521 31.8091C57.6933 30.683 59.1472 29.9161 60.73 29.6055C62.3129 29.2948 63.9535 29.4542 65.4445 30.0637C66.9356 30.6731 68.2099 31.7051 69.1066 33.0292C70.0032 34.3533 70.4817 35.91 70.4817 37.5025C70.4757 39.6354 69.6138 41.6792 68.0846 43.1867C66.5555 44.6942 64.4835 45.5427 62.3219 45.5467V45.5543Z"
        fill={color ?? theme.palette.brand.carbon}
      />
    </Svg>
  );
};
