import { useMemo } from 'react';

import {
  type UpdateContributionRequestInput as Input,
  type UpdateContributionRequestMutationVariables,
  useUpdateContributionRequestMutation,
} from '../../generated';

export type UpdateContributionRequestInput = Input;

export const useUpdateContributionRequest = () => {
  const [updateContributionRequest, { loading }] = useUpdateContributionRequestMutation({
    refetchQueries: ['ContributionRequestsByProject'],
    awaitRefetchQueries: true,
  });

  return useMemo(
    () => ({
      updateContributionRequest: async (
        id: UpdateContributionRequestMutationVariables['id'],
        input: UpdateContributionRequestMutationVariables['input']
      ) => updateContributionRequest({ variables: { id, input } }),
      loading,
    }),
    [loading, updateContributionRequest]
  );
};
