import { Typography, useRenderJobProgress, useRenderJobProgressIconAndDescription } from '@cofenster/web-components';
import type { FC } from 'react';
import type { RenderJob } from '../../../api/hooks/renderJob/useRenderJobByProject';

export const RenderJobProgressTextInformation: FC<{ renderJob: RenderJob }> = ({ renderJob }) => {
  const { step, status, stepProgress } = renderJob;

  const hasFailed = status === 'Failed';
  const progress = useRenderJobProgress(status, step, stepProgress ?? 0);
  const { description } = useRenderJobProgressIconAndDescription(progress, hasFailed);

  return (
    <Typography variant="s" color="grey700" component="p">
      {description}
    </Typography>
  );
};
