import { useMemo } from 'react';

import { type ProjectSubtitlesLanguagesQueryVariables, useProjectSubtitlesLanguagesQuery } from '../../generated';

export const useProjectSubtitlesLanguages = (id: ProjectSubtitlesLanguagesQueryVariables['id']) => {
  const { loading, error, data } = useProjectSubtitlesLanguagesQuery({
    variables: { id },
    // We need to clear the cache each time a new subtitles language is added. Instead of doing
    // it in each place where we add a new language, we can just use cache-and-network policy.
    // This query is executed only when the user navigates to the render page (it's not refetched
    // when a language is changed in the tab navigation - no extra fetches).
    fetchPolicy: 'cache-and-network',
  });
  const languages = data?.projectSubtitlesLanguages ?? undefined;
  return useMemo(() => ({ languages, loading, error }), [languages, loading, error]);
};
