import { useCallback } from 'react';

import { type CloneScenesMutationVariables, useCloneScenesMutation } from '../../generated';

export const useCloneScenes = ({ awaitRefetchQueries = false, refetchRenderDescription = false } = {}) => {
  const [cloneScenes] = useCloneScenesMutation({
    refetchQueries: refetchRenderDescription ? ['Scenes', 'ProjectRenderDescription'] : ['Scenes'],
    awaitRefetchQueries,
    update: (cache) => {
      /**
       * @see useUpdateSceneVideo.ts
       */
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
  });
  return useCallback(
    (ids: CloneScenesMutationVariables['ids']) => {
      return cloneScenes({ variables: { ids } });
    },
    [cloneScenes]
  );
};
