import type { FC } from 'react';

import { BaseLayout, type BaseLayoutProps } from '@cofenster/web-components';

import { useAutomaticTracking } from '../../../hooks/useAutomaticTracking';
import { UserNavigation } from '../Sidebar/MainNavigation/UserNavigation';

export const AppLayout: FC<BaseLayoutProps> = (props) => {
  useAutomaticTracking();

  return <BaseLayout navigation={UserNavigation} {...props} />;
};
