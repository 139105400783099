import type { FC } from 'react';

import { type IconType, PopoverMenuItem, type PopoverMenuItemProps, Text, styled } from '@cofenster/web-components';

import type { RoleName } from '../../../../api/generated';

type RoleWithOwner = RoleName | 'Owner';

const roleIcon: Record<RoleWithOwner, IconType> = {
  Owner: 'UserIcon',
  Admin: 'ShieldStarIcon',
  Publisher: 'UploadIcon',
  Editor: 'PencilIcon',
  Contributor: 'VideoCameraIcon',
  Translator: 'TranslateIcon',
};

type RolePopoverMenuItemProps = PopoverMenuItemProps & {
  roleName: RoleWithOwner;
  roleId: string;
  isActive?: boolean;
  canTransfer?: boolean;
};

const Flex = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

const RoleNameText = styled(Text)(() => ({ fontWeight: 'bold' }));

const descriptionTranslations = {
  Admin: 'i18n.team.role.Admin.description',
  Contributor: 'i18n.team.role.Contributor.description',
  Editor: 'i18n.team.role.Editor.description',
  Publisher: 'i18n.team.role.Publisher.description',
  Translator: 'i18n.team.role.Translator.description',
  Viewer: 'i18n.team.role.Viewer.description',
  Owner: 'i18n.team.role.Owner.description',
};

const roleTranslations = {
  Admin: 'i18n.team.role.Admin',
  Contributor: 'i18n.team.role.Contributor',
  Editor: 'i18n.team.role.Editor',
  Publisher: 'i18n.team.role.Publisher',
  Translator: 'i18n.team.role.Translator',
  Viewer: 'i18n.team.role.Viewer',
  Owner: 'i18n.team.role.Owner',
};

export const RolePopoverMenuItem: FC<RolePopoverMenuItemProps> = ({
  roleName,
  isActive,
  canTransfer,
  roleId,
  ...rest
}) => {
  const transferOwnershipDescription = 'i18n.team.role.Owner.description';
  const description = descriptionTranslations[roleName];
  const roleDescription = canTransfer ? transferOwnershipDescription : description;

  return (
    <PopoverMenuItem
      {...rest}
      icon={roleIcon[roleName]}
      data-testid={`member-role-button-${roleId}`}
      aria-current={isActive}
    >
      <Flex>
        <RoleNameText component="p">{roleTranslations[roleName]}</RoleNameText>
        <Text component="p">{roleDescription}</Text>
      </Flex>
    </PopoverMenuItem>
  );
};
