import { useField } from 'formik';
import type { FC } from 'react';

import { Text } from '../../typography/Text';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

type RemainingCharacterCounterProps = {
  id: string;
  name: string;
  maxChars: number;
};

export const RemainingCharacterCounter: FC<RemainingCharacterCounterProps> = ({ name, maxChars, ...rest }) => {
  const [, { value }] = useField(name);
  if (typeof value !== 'string') return null;
  const remainingChars = maxChars - value.length;
  return (
    <>
      <Text variant="s" color={remainingChars >= 0 ? 'grey600' : 'negative'} aria-hidden="true" {...rest}>
        {remainingChars}
      </Text>
      <VisuallyHidden i18nParams={{ remaining: remainingChars, max: maxChars }}>
        i18n.global.characterCounter.remaining
      </VisuallyHidden>
    </>
  );
};
