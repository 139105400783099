import { type FC, type PropsWithChildren, useMemo } from 'react';

import type { Project, Team } from '../../api/hooks/project/useLatestProjects';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { ProjectContext } from '../../contexts/project/ProjectContext';
import type { ProjectFolderContext } from '../../contexts/projectFolder/ProjectFolderContext';
import { ProjectFolderProvider } from '../../contexts/projectFolder/ProjectFolderProvider';

const noop = () => undefined;

export const HomeProjectContext: FC<PropsWithChildren<{ project: Project; team: Team }>> = ({
  children,
  project,
  team,
}) => {
  const context = useMemo(() => ({ project, loading: false, refetch: noop }), [project]);

  return (
    <ProjectContext.Provider value={context}>
      <CurrentTeamProvider team={team ?? undefined}>
        <ProjectFolderProvider projectFolder={project.projectFolders[0] as ProjectFolderContext['projectFolder']}>
          {children}
        </ProjectFolderProvider>
      </CurrentTeamProvider>
    </ProjectContext.Provider>
  );
};
