import { useMemo } from 'react';

import {
  type CreateContributionRequestMutationVariables,
  type CreateContributionRequestInput as Input,
  useCreateContributionRequestMutation,
} from '../../generated';

export type CreateContributionRequestInput = Input;

export const useCreateContributionRequest = (refetchQueries: string[] = ['ContributionRequestsByProject']) => {
  const [createContributionRequest, { loading }] = useCreateContributionRequestMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  });

  return useMemo(
    () => ({
      createContributionRequest: async (
        projectId: CreateContributionRequestMutationVariables['projectId'],
        inputs: CreateContributionRequestMutationVariables['inputs']
      ) => createContributionRequest({ variables: { projectId, inputs } }),
      loading,
    }),
    [createContributionRequest, loading]
  );
};
