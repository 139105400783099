import { useEffect, useState } from 'react';

import { useSortScenes } from '../../api/hooks/scene/useSortScenes';
import { type Scene, useScenes } from '../../contexts/scenes/useScenes';

export const useSortableScenes = () => {
  const { scenes, currentScene } = useScenes();
  // We cache the scenes on the client to improve the drag’n’drop experience. In
  // theory this can be achieved with Apollo optimistic responses but I wasn’t
  // able to make it work. So we update the order on the client immediately upon
  // drag end, and perform the actual mutation in the background, which
  // eventually refetches the scenes.
  const [cachedScenes, setCachedScenes] = useState(scenes);
  const sortScenes = useSortScenes({ refetchRenderDescription: true });

  useEffect(() => setCachedScenes(scenes), [scenes]);

  return {
    currentScene,
    scenes: cachedScenes,
    sortScenes: (newScenes: Scene[]) => {
      setCachedScenes(newScenes);
      sortScenes(newScenes.map((scene) => scene.id));
    },
  };
};
