import type { FC } from 'react';

import { VisuallyHidden, type VisuallyHiddenProps } from '../../utilities/VisuallyHidden';

export const SearchHeader: FC<{ search?: string; count: number; id: string; as?: VisuallyHiddenProps['as'] }> = ({
  search,
  count,
  id,
  as = 'h2',
}) =>
  search ? (
    <VisuallyHidden tabIndex={-1} as={as} id={id} i18nParams={{ search, count }}>
      i18n.global.search.results.header
    </VisuallyHidden>
  ) : null;
