import { useField } from 'formik';
import { type FC, Fragment, useCallback, useMemo } from 'react';

import {
  Autocomplete,
  CustomPaper,
  Icon,
  LoadingSpinner,
  SelectHeader,
  SelectOption,
  Spacing,
  TextField,
  styled,
} from '@cofenster/web-components';

import { useI18n } from '../../../../i18n';

import { LanguageDisplay } from './LanguageDisplay';

const useTranslateLanguageCodes = () => {
  const { languageNames } = useI18n();
  return useCallback(
    (codes: string[]) =>
      codes
        .map((code) => ({ code, label: languageNames.of(code) ?? code }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [languageNames]
  );
};

const useOptionGroups = (languageGroups: LanguageSelectProps['languageGroups']) => {
  const translateLanguages = useTranslateLanguageCodes();
  return useMemo(() => {
    if (!languageGroups) return undefined;

    return languageGroups.flatMap(({ label, languages }) => {
      const translatedLanguages = translateLanguages(languages);
      return translatedLanguages.map((language) => ({ ...language, group: label }));
    });
  }, [languageGroups, translateLanguages]);
};

const useOptions = (languages: LanguageSelectProps['languages']) => {
  const translateLanguages = useTranslateLanguageCodes();
  return useMemo(() => translateLanguages(languages ?? []), [languages, translateLanguages]);
};

export type BaseProps = {
  name: string;
  label?: string;
  placeholder?: string;
  getAdditionalText?: (language: string) => string | undefined;
  getLoadingState?: (language: string) => boolean;
};

export type LanguageGroup = { label: string; languages: string[] };

export type LanguageSelectProps =
  | (BaseProps & {
      languages: string[];
      languageGroups?: never;
    })
  | (BaseProps & {
      languages?: never;
      languageGroups: LanguageGroup[];
    });

type OptionType = {
  code: string;
  label: string;
  group: string;
};

const StyledCustomPaper = styled(CustomPaper)(({ theme }) => ({
  '.MuiAutocomplete-listbox': {
    padding: theme.spacing(0),
  },
}));

export const LanguageSelect: FC<LanguageSelectProps> = ({
  languages,
  languageGroups,
  label,
  placeholder,
  getAdditionalText,
  getLoadingState,
  name,
}) => {
  const [{ value, onBlur }, { error }, { setValue }] = useField(name);
  const optionGroups = useOptionGroups(languageGroups);
  const options = useOptions(languages);
  const selectedValue = useMemo(() => {
    if (optionGroups) {
      return optionGroups?.find((option) => option.code === value);
    }
    return options.find((option) => option.code === value);
  }, [optionGroups, options, value]);
  const noOptions = !languages?.length && !languageGroups?.length;

  return (
    <Autocomplete<OptionType | Omit<OptionType, 'group'>, false, true>
      // Let's completely rerender the component when the options change to avoid
      // issue with the component changing from controlled to uncontrolled state.
      key={noOptions ? 'no-options' : 'options'}
      onBlurCapture={onBlur}
      options={optionGroups ?? options}
      value={selectedValue}
      disableClearable
      data-testid="language-select-autocomplete"
      groupBy={optionGroups ? (option) => ('group' in option ? option.group : '') : undefined}
      onChange={(_, value) => setValue(value?.code ?? '')}
      renderInput={(props) => (
        <TextField
          {...props}
          inputProps={{
            ...props.inputProps,
            id: `language-select-${name}`,
          }}
          InputProps={{
            ...props.InputProps,
            startAdornment: (
              <Spacing right={0.5} left={0.5} display="flex">
                {getLoadingState?.(value) ? (
                  <LoadingSpinner size={24} spacing={0} />
                ) : (
                  <Icon type="SubtitlesIcon" size={24} />
                )}
              </Spacing>
            ),
          }}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          error={!!error}
          helperText={error}
        />
      )}
      renderOption={({ className, ...rest }, { code, label }) => (
        <SelectOption data-testid={`option-${code}`} selected={code === value} value={code} {...rest} key={code}>
          <LanguageDisplay
            language={label}
            additionalText={getAdditionalText?.(code)}
            isLoading={getLoadingState?.(code)}
          />
        </SelectOption>
      )}
      renderGroup={
        optionGroups
          ? ({ group, children }) => (
              <Fragment key={group}>
                <SelectHeader key={group} label={group} />
                {children}
              </Fragment>
            )
          : undefined
      }
      PaperComponent={StyledCustomPaper}
    />
  );
};
