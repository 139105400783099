import { type FC, type PropsWithChildren, useState } from 'react';
import { type AssetPreloadingCache, AssetPreloadingContext } from './AssetPreloadingContext';

// This component is used in the CoManager project editor, in order to expose a
// high-level cache map which is persisted across mountings and unmountings of
// the Remotion player.
export const AssetPreloadingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [map] = useState<AssetPreloadingCache>(() => new Map());

  return <AssetPreloadingContext.Provider value={map}>{children}</AssetPreloadingContext.Provider>;
};
