import type { Permission } from '../../api/generated';

import type { PermissionSelection } from './PermissionSelection';

export const checkPermission = (selection: PermissionSelection, permissions: Permission[] | undefined) => {
  if (!permissions) return false;
  const { has, not, and, or } = selection;

  if (has) {
    return permissions.includes(has);
  }

  if (not) {
    return !permissions.includes(not);
  }

  if (and) {
    return and.every((permission) => permissions.includes(permission));
  }

  if (or) {
    return or.some((permission) => permissions.includes(permission));
  }

  return false;
};
