import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useTeam } from '../../api/hooks/team/useTeam';
import { AppLayout } from '../../components/page/Layout';
import { ProjectSpaceSubNavigation } from '../../components/project/ProjectSpaceBar/ProjectSpaceSubNavigation';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import { useSetProjectSpace } from '../../contexts/projectSpace/useProjectSpace';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import type { TeamRouteParams } from '../../routes';
import { NoAccessLayout } from '../NoAccess';

import { TeamSettingsContent } from './TeamSettingsContent';

export const TeamSettings: FC = () => {
  const { teamId } = useParams() as TeamRouteParams;
  const { team, loading } = useTeam(teamId);

  useSetProjectSpace(team?.id);

  if (!team && !loading) return <NoAccessLayout resource="Team" />;

  return (
    <CurrentTeamProvider team={team}>
      <TeamPermissionRestriction
        has="TeamUpdate"
        fallback={<NoAccessLayout resource="Team" />}
        loader={<LoadingSpinner />}
      >
        <AppLayout
          documentTitle={team?.name ?? 'i18n.team.settings'}
          header={{
            topLeft: <PageHeadline mb={0} title={team?.name} isPrivate={!team?.public} />,
            bottomLeft: team && <ProjectSpaceSubNavigation team={team} folderId={team.rootProjectFolder.id} />,
          }}
        >
          {team ? <TeamSettingsContent team={team} /> : <LoadingSpinner />}
        </AppLayout>
      </TeamPermissionRestriction>
    </CurrentTeamProvider>
  );
};
