import type { Permission, RoleName } from '../../api/generated';
import type { Role } from '../../api/hooks/user/useUser';

import type { PermissionSelection } from './PermissionSelection';

const permissionsDisallowedInPersonalSpace: Permission[] = [
  'SharingLinkCreate',
  'SharingLinkUpdate',
  'SharingLinkDelete',
  'VideoDownload',
];

const rolesWithFullAccessInPersonalSpace: RoleName[] = ['Admin', 'Publisher'];

export const checkPersonalSpacePermissions = (params: PermissionSelection, accountRole: Role | undefined) => {
  if (accountRole?.name && rolesWithFullAccessInPersonalSpace.includes(accountRole.name)) {
    // Admins and publishers can do anything in personal spaces.
    if (params.not) return false;
    return true;
  }

  if (params.has) {
    return !permissionsDisallowedInPersonalSpace.includes(params.has);
  }

  if (params.not) {
    return permissionsDisallowedInPersonalSpace.includes(params.not);
  }

  if (params.and) {
    return !params.and.some((permission) => permissionsDisallowedInPersonalSpace.includes(permission));
  }

  if (params.or) {
    return params.or.some((permission) => !permissionsDisallowedInPersonalSpace.includes(permission));
  }

  throw new Error('Invalid permission selection');
};
