import { styled } from '@mui/material';
import type { CSSProperties, FC } from 'react';
import type { SliderItem } from 'react-compound-slider';

// 1. Make it possible to apply scene timings markers with a CSS gradient by
//    defining it as a CSS Custom Property up the tree.
const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 'calc(var(--from) * 1%)',
  width: 'calc((var(--to) - var(--from)) * 1%)',
  height: 'var(--preview-track-height, 4px)',
  zIndex: theme.zIndex.base,
  background: `var(--preview-track-markers, ${theme.palette.brand.white})`, // 1
  borderRadius: theme.spacing(0.25),
}));

type Props = {
  from: SliderItem;
  to: SliderItem;
};

export const Track: FC<Props> = ({ from: { percent: fromPercent }, to: { percent: toPercent } }) => {
  return <Container style={{ '--from': fromPercent, '--to': toPercent } as CSSProperties} />;
};
