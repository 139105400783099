import { useCallback, useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import { useSearchStockImages } from '../../../api/hooks/stockFootage/useSearchStockImages';
import { useSearchStockVideos } from '../../../api/hooks/stockFootage/useSearchStockVideos';

export const useSearch = (type: 'image' | 'video', videoFormat: VideoFormat) => {
  const [searchStockImages, metadataImages] = useSearchStockImages();
  const [searchStockVideos, metadataVideos] = useSearchStockVideos();
  const searchFn = type === 'image' ? searchStockImages : searchStockVideos;
  const search = useCallback(
    (searchTerm: string, page = 1) => {
      if (!searchTerm) return;
      searchFn({
        variables: {
          filter: {
            query: searchTerm,
            videoFormat,
            page,
            perPage: 30,
          },
        },
      });
    },
    [searchFn, videoFormat]
  );

  const loading = type === 'image' ? metadataImages.loading : metadataVideos.loading;
  const error = type === 'image' ? metadataImages.error : metadataVideos.error;
  const data = type === 'image' ? metadataImages.data?.searchStockImages : metadataVideos.data?.searchStockVideos;
  const metadata = useMemo(() => ({ loading, error, data }), [data, error, loading]);

  return useMemo(() => [search, metadata] as const, [search, metadata]);
};
