import type { FC } from 'react';

import { Headline, VideoAssetPreview, styled } from '@cofenster/web-components';

import type { Project } from '../../../contexts/project/useProject';
import { useI18n } from '../../../i18n/useI18n';

const Container = styled('figure')(() => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TemplateCard = styled('div')(({ theme }) => ({
  height: 200,
  width: 200,
  border: `5px solid ${'transparent'}`,
  borderRadius: 3,
  '&.isActive': {
    borderColor: theme.palette.brand.positive,
  },
}));

type Props = {
  project: Project;
};

export const TemplatePreview: FC<Props> = ({ project }) => {
  const { locale } = useI18n();
  const template = project.template;

  if (!template) return null;

  return (
    <Container>
      {template.previewVideoAsset?.videoUrl && (
        <TemplateCard>
          <VideoAssetPreview
            src={template.previewVideoAsset.videoUrl}
            poster={template.previewImageAsset?.imageUrl ?? template.previewVideoAsset.thumbnailUrl ?? undefined}
          />
        </TemplateCard>
      )}
      <Headline variant="h5" component="figcaption">
        {locale === 'DE' ? template.name : template.nameEN}
      </Headline>
    </Container>
  );
};
