import { createContext } from 'react';

import type { Scene } from '../../api/hooks/scene/useScenes';

export type * from '../../api/hooks/scene/useScenes';

export type ScenesContext = {
  scenes: Scene[];
  currentScene?: Scene;
  loading: boolean;
};

export const ScenesContext = createContext<ScenesContext>({
  scenes: [],
  loading: true,
});
