import { type FC, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Button, Dialog, DialogActions, DialogContent } from '@cofenster/web-components';

import { VideoFormatSelect } from '../../form/VideoFormatSelect';

export type RecordYourselfDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  onVideoFormatSelected: (videoFormat: VideoFormat) => unknown;
};

export const RecordYourselfDialog: FC<RecordYourselfDialogProps> = ({ isOpen, closeDialog, onVideoFormatSelected }) => {
  const [videoFormat, setVideoFormat] = useState<VideoFormat>('Horizontal');

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="record-yourself-dialog"
      title="i18n.dialogs.recordYourself.headline"
    >
      <DialogContent>
        <VideoFormatSelect
          videoFormat={videoFormat}
          setVideoFormat={setVideoFormat}
          name="videoFormat"
          label="i18n.dialogs.recordYourself.videoFormat"
        />
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button
          onClick={() => {
            onVideoFormatSelected(videoFormat);
            closeDialog();
          }}
          fullWidth
          data-testid="record-yourself-dialog-confirm"
        >
          i18n.dialogs.recordYourself.button
        </Button>
      </DialogActions>
    </Dialog>
  );
};
