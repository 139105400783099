import { type PaginationItemProps as MuiPaginationItemProps, styled } from '@mui/material';
import type { FC, ReactEventHandler } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { BlankButton } from '../../controls/Button/BlankButton';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

type ButtonProps = Pick<PaginationItemProps, 'selected' | 'disabled'>;

const Button = styled(BlankButton)<ButtonProps>(({ theme, selected, disabled }) => ({
  ...theme.typography['button-m'],
  display: 'flex',
  width: theme.spacing(6),
  height: theme.spacing(6),
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0, 1),
  border: selected ? `1px solid ${disabled ? theme.palette.brand.grey300 : theme.palette.brand.carbon}` : undefined,
  color: disabled ? theme.palette.brand.grey300 : theme.palette.brand.carbon,
  borderRadius: '50%',
  transition: '150ms',

  '&:not(:disabled):hover': {
    color: theme.palette.brand.white,
    backgroundColor: theme.palette.brand.carbon,
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

export type PaginationItemProps = MuiPaginationItemProps & {
  onClick: ReactEventHandler<Element>;
};

const EllipsisFakeButton = styled(Button)(() => ({
  '&:not(:disabled):hover': { backgroundColor: 'transparent', color: 'inherit', cursor: 'default' },
}));

export const PaginationItem: FC<PaginationItemProps> = ({ type, page, onClick, selected, disabled }) => {
  const buttonProps = { onClick, selected, disabled };

  if (type === 'page') return <Button {...buttonProps}>{page}</Button>;
  if (type === 'next')
    return (
      <Button {...buttonProps}>
        <Icon size="s" type="CaretRightIcon" />
        <VisuallyHidden>i18n.global.next</VisuallyHidden>
      </Button>
    );
  if (type === 'previous')
    return (
      <Button {...buttonProps}>
        <Icon size="s" type="CaretLeftIcon" />
        <VisuallyHidden>i18n.global.previous</VisuallyHidden>
      </Button>
    );
  if (type === 'end-ellipsis' || type === 'start-ellipsis') return <EllipsisFakeButton as="span">…</EllipsisFakeButton>;

  return null;
};
