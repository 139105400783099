export type TimeFormat = 'mm:ss.S' | 'mm:ss' | 'hh:mm:ss' | 'hh:mm:ss.S';

export const formatTime = (secondsInput: number, format: TimeFormat) => {
  const hours = Math.floor(secondsInput / 3600);
  const minutes = Math.floor((secondsInput % 3600) / 60);
  const seconds = Math.floor(secondsInput % 60);
  const fractionalSeconds = Math.floor(((secondsInput * 1000) % 1000) / 100); // one decimal place for S

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  let result = format.replace('hh', formattedHours).replace('mm', formattedMinutes).replace('ss', formattedSeconds);

  if (format.includes('S')) {
    result = result.replace('S', fractionalSeconds.toString());
  }

  return result;
};
