import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { type BulkSelection, BulkSelectionContext } from './BulkSelectionContext';

export const BulkSelectionProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const [selectables, setSelectables] = useState<BulkSelection>({
    projects: [],
    folders: [],
  });

  const [selection, setSelection] = useState<BulkSelection>({
    projects: [],
    folders: [],
  });

  useEffect(() => {
    setSelection((selection) => ({
      projects: selection.projects.filter((id) => selectables.projects.includes(id)),
      folders: selection.folders.filter((id) => selectables.folders.includes(id)),
    }));
  }, [selectables]);

  const setSelectablesByType = useCallback((type: keyof BulkSelection, ids: string[]) => {
    setSelectables((selectables) => ({
      ...selectables,
      [type]: ids,
    }));
  }, []);

  const selectByType = useCallback((type: keyof BulkSelection, ids: string[]) => {
    setSelection((selection) => ({
      ...selection,
      [type]: ids,
    }));
  }, []);

  const deselectAll = useCallback(() => {
    setSelection({ projects: [], folders: [] });
  }, []);

  const setSelectableProjects = useCallback(
    (ids: string[]) => {
      setSelectablesByType('projects', ids);
    },
    [setSelectablesByType]
  );

  const selectProjects = useCallback(
    (ids: string[]) => {
      selectByType('projects', ids);
    },
    [selectByType]
  );

  const toggleProject = useCallback(
    (id: string) => {
      if (selection.projects?.includes(id)) {
        selectProjects(selection.projects.filter((projectId) => projectId !== id));
      } else {
        selectProjects([...selection.projects, id]);
      }
    },
    [selection.projects, selectProjects]
  );

  const setSelectableFolders = useCallback(
    (ids: string[]) => {
      setSelectablesByType('folders', ids);
    },
    [setSelectablesByType]
  );

  const selectFolders = useCallback(
    (ids: string[]) => {
      selectByType('folders', ids);
    },
    [selectByType]
  );

  const toggleFolder = useCallback(
    (id: string) => {
      if (selection.folders?.includes(id)) {
        selectFolders(selection.folders.filter((projectId) => projectId !== id));
      } else {
        selectFolders([...selection.folders, id]);
      }
    },
    [selection.folders, selectFolders]
  );

  const context = useMemo(
    () => ({
      setSelectables,
      selection,
      setSelection,
      deselectAll,
      setSelectableProjects,
      selectProjects,
      toggleProject,
      setSelectableFolders,
      selectFolders,
      toggleFolder,
    }),
    [
      selection,
      deselectAll,
      setSelectableProjects,
      selectProjects,
      toggleProject,
      setSelectableFolders,
      selectFolders,
      toggleFolder,
    ]
  );

  return <BulkSelectionContext.Provider value={context}>{children}</BulkSelectionContext.Provider>;
};
