import { useCallback } from 'react';

import { type UpdateSceneColorMutationVariables, useUpdateSceneColorMutation } from '../../generated';

export const useUpdateSceneColor = () => {
  const [updateSceneColor] = useUpdateSceneColorMutation({
    refetchQueries: ['Scenes', 'ProjectRenderDescription', 'SceneRenderDescription'],
  });
  return useCallback(
    (id: UpdateSceneColorMutationVariables['id'], input: UpdateSceneColorMutationVariables['input']) =>
      updateSceneColor({ variables: { id, input } }),
    [updateSceneColor]
  );
};
