import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';

import { useSendUserInviteEmail } from '../../../api/hooks/user/useSendUserInviteEmail';
import type { User } from '../../../api/hooks/user/useUser';

export type SendUserInviteEmailDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const SendUserInviteEmailDialog: FC<SendUserInviteEmailDialogProps> = ({ isOpen, closeDialog, user }) => {
  const { openSnackbar } = useSnackbars();
  const sendUserInviteEmail = useSendUserInviteEmail();

  const onAction = useCallback(async () => {
    await sendUserInviteEmail(user.id);
    openSnackbar({
      children: 'i18n.snackbars.UserReinvitedSnackbar.copy',
      i18nParams: { email: user.email },
    });
    closeDialog();
  }, [user, sendUserInviteEmail, openSnackbar, closeDialog]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.SendUserInviteEmailDialog.headline">
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          i18n.dialogs.SendUserInviteEmailDialog.text
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" onClick={closeDialog} fullWidth>
          i18n.global.cancel
        </Button>
        <Button variant="primary" onClick={onAction} fullWidth>
          i18n.common.resendInvite
        </Button>
      </DialogActions>
    </Dialog>
  );
};
