import { useMemo } from 'react';

import { type RenderProjectMutationVariables, useRenderProjectMutation } from '../../generated';

export const useRenderProject = (
  projectId: RenderProjectMutationVariables['id'],
  options?: RenderProjectMutationVariables['options']
) => {
  const [renderProject, metadata] = useRenderProjectMutation({
    refetchQueries: ['Project', 'RenderJobsByProject'],
    awaitRefetchQueries: true,
    variables: { id: projectId, options },
  });

  return useMemo(() => ({ renderProject, ...metadata }), [renderProject, metadata]);
};
