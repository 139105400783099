import { type MutableRefObject, useCallback, useMemo, useState } from 'react';

import { useInterval } from '@cofenster/web-components';

import type { RemotionPlayerRef } from './Player';

export const useReactiveRemotionPlayerControls = (
  playerRef: MutableRefObject<RemotionPlayerRef | null>,
  fps: number
) => {
  const [playerControls, setPlayerControls] = useState<RemotionPlayerRef | null>(null);
  const player = playerRef.current;

  const setCurrentTime = useCallback(
    (currentTime: number) => {
      if (player) player.currentTime = currentTime;
    },
    [player]
  );

  const updatePlayerControls = useCallback(() => {
    if (!playerRef.current) return;
    const player = playerRef.current;
    setPlayerControls({
      play: player.play,
      pause: player.pause,
      paused: player.paused,
      duration: player.duration,
      currentTime: player.currentTime,
      setCurrentTime: setCurrentTime,
    });
  }, [setCurrentTime, playerRef]);

  // sets the state on interval
  useInterval(updatePlayerControls, 1000 / fps);

  // creates a new object ONLY when either of the playerRef keys change
  return useMemo(() => {
    if (
      playerControls?.play === undefined ||
      playerControls?.pause === undefined ||
      playerControls?.paused === undefined ||
      playerControls?.duration === undefined ||
      playerControls?.currentTime === undefined ||
      playerControls?.setCurrentTime === undefined
    ) {
      return;
    }
    return {
      play: playerControls.play,
      pause: playerControls.pause,
      paused: playerControls.paused,
      duration: playerControls.duration,
      currentTime: playerControls.currentTime,
      setCurrentTime: playerControls.setCurrentTime,
    };
  }, [
    playerControls?.play,
    playerControls?.pause,
    playerControls?.paused,
    playerControls?.duration,
    playerControls?.currentTime,
    playerControls?.setCurrentTime,
  ]);
};
