import { type CSSProperties, type FC, useCallback, useState } from 'react';

import { GridContainer, GridItem, styled } from '@cofenster/web-components';

import type { ProjectFolder } from '../../../api/hooks/projectFolder/useProjectFolders';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useProjectFolder } from '../../../contexts/projectFolder/useProjectFolder';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { NewButton } from '../NewButton';

import { FolderCard } from './FolderCard';
import { ShowMore } from './ShowMore';

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(0, -1),
  padding: theme.spacing(1),
  maxHeight: 'calc(var(--max-height) * 1px)',
  overflow: 'hidden',
  transition: 'max-height 250ms ease-in-out',
}));

type Props = {
  projectFolders: ProjectFolder[];
  isArchive?: boolean;
};

const SHOW_LIMIT = 5;

export const FoldersGridView: FC<Props> = ({ projectFolders, isArchive }) => {
  const { projectFolder } = useProjectFolder();
  const [isExanded, setIsExpanded] = useState<boolean>(false);
  const { openDialog } = useDialogs();

  const onFolderCreated = useCallback(
    ({ id }: { id: string }) => {
      if (projectFolders.length >= SHOW_LIMIT) setIsExpanded(true);

      // The `setTimeout` is necessary as the component has not re-rendered with
      // the new DOM node when calling the `onFolderCreated` handler.
      setTimeout(() => {
        document.querySelector(`#folder-${id}`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    },
    [projectFolders.length]
  );

  const openCreateFolderDialog = useCallback(() => {
    openDialog('CreateProjectFolderDialog', {
      projectFolderId: projectFolder.id,
      onFolderCreated,
      teamId: projectFolder.team?.id,
    });
  }, [openDialog, onFolderCreated, projectFolder]);

  const toggleIsExanded = useCallback(() => setIsExpanded((isExpanded) => !isExpanded), []);

  const totalHeight = Math.ceil(projectFolders.length + 1 / 3) * 120;
  const cappedHeight = 250;

  return (
    <>
      <Container
        style={
          {
            '--max-height': isExanded ? totalHeight : cappedHeight,
          } as CSSProperties
        }
      >
        <GridContainer pt={1} spacing={2} id="folders-list" aria-expanded={isExanded}>
          {!isArchive && (
            <TeamPermissionRestriction has="FolderCreate">
              <GridItem xs={12} sm={6} md={4}>
                <NewButton type="button" onClick={openCreateFolderDialog} data-testid="create-folder-button">
                  i18n.projects.buttons.folder
                </NewButton>
              </GridItem>
            </TeamPermissionRestriction>
          )}
          {projectFolders.slice(0, isExanded ? Number.POSITIVE_INFINITY : SHOW_LIMIT).map((projectFolder) => (
            <GridItem key={projectFolder.id} xs={12} sm={6} md={4}>
              <FolderCard projectFolder={projectFolder} />
            </GridItem>
          ))}
        </GridContainer>
      </Container>
      {projectFolders.length > SHOW_LIMIT && <ShowMore onClick={toggleIsExanded} isExpanded={isExanded} />}
    </>
  );
};
