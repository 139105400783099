import { useCallback, useMemo } from 'react';

import { type CreateSceneMutationVariables, useCreateSceneMutation } from '../../generated';
export { type CreateSceneMutationResult } from '../../generated';

export type SceneType = CreateSceneMutationVariables['input']['type'];

export const useCreateScene = ({ awaitRefetchQueries = false } = {}) => {
  const [createScene, metadata] = useCreateSceneMutation({
    refetchQueries: ['Scenes'],
    awaitRefetchQueries,
    update: (cache) => {
      cache.evict({ fieldName: 'scenesSubtitles' });
    },
  });

  const create = useCallback(
    (projectId: CreateSceneMutationVariables['projectId'], input: CreateSceneMutationVariables['input']) =>
      createScene({ variables: { projectId, input } }),
    [createScene]
  );

  return useMemo(() => [create, metadata] as const, [create, metadata]);
};
