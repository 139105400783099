import type { FC, MouseEventHandler } from 'react';

import { IconButton } from '../../controls/Button/IconButton';
import { GridContainer, GridItem } from '../../layout/Grid';
import { Headline } from '../../typography/Headline';

import type { DrawerProps } from './';

export const SidePanelHeaderContent: FC<{ close: DrawerProps['close']; headline: string }> = ({ close, headline }) => {
  return (
    <GridContainer justifyContent="space-between" alignItems="center">
      <GridItem xs>
        <Headline variant="h3" component="h2">
          {headline}
        </Headline>
      </GridItem>
      <GridItem xs="auto">
        <IconButton
          icon="CloseIcon"
          onClick={close as MouseEventHandler<HTMLButtonElement>}
          label="i18n.global.close"
          data-testid="side-panel-header-close-button"
        />
      </GridItem>
    </GridContainer>
  );
};
