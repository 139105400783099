import { type FC, useMemo } from 'react';

import { Icon, PopoverMenuTriggerIcon, Responsive, withPopoverMenu } from '@cofenster/web-components';

import type { Scene } from '../../../../../api/hooks/scene/useScenes';
import { RouterButton } from '../../../../../components/button/RouterButton';
import { CollectAssetButton } from '../../../../../components/sceneAsset/CollectAssetButton';
import { PickSceneAssetFromMediaLibraryButton } from '../../../../../components/sceneAsset/PickSceneAssetFromMediaLibraryButton';
import { StockAssetButton } from '../../../../../components/sceneAsset/StockAssetButton';
import type { Project } from '../../../../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../../../../contexts/user/TeamPermissionRestriction';
import { isMainSceneAsset, isVideoSceneAsset } from '../../../../../helpers/sceneAssets/is';
import { AudioOverlay } from '../../../components/AudioOverlay';
import { SceneCropButton } from '../../../components/EditingBar/SceneCropButton';
import { SceneOverlaysButton } from '../../../components/EditingBar/SceneOverlaysButton';
import { SceneSubtitlesButton } from '../../../components/EditingBar/SceneSubtitlesButton';
import { useSceneVideoActionsPopoverContent } from '../../EditorSceneAssetThumbs/hooks/useSceneVideoActionsPopoverContent';
import { SceneEditorNotesButton } from '../../SceneEditorNotes/SceneEditorNotesButton';

export const SceneVideoControls: FC<{
  project: Project;
  scene: Scene;
  isEmpty?: boolean;
  withDisabledControls?: boolean;
}> = ({ project, scene, isEmpty, withDisabledControls }) => {
  const videoSceneAsset = scene.sceneAssets.filter(isMainSceneAsset).find(isVideoSceneAsset);
  const actions = useSceneVideoActionsPopoverContent({ scene, videoSceneAsset });
  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: actions,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [actions]
  );

  if (isEmpty) {
    return (
      <TeamPermissionRestriction has="SceneUpload">
        <CollectAssetButton
          variant="tertiary"
          assetType="video"
          projectId={project.id}
          sceneId={scene.id}
          disabled={withDisabledControls}
        />

        <StockAssetButton
          variant="tertiary"
          assetType="video"
          projectId={project.id}
          scene={scene}
          videoFormat={project.videoFormat}
          disabled={withDisabledControls}
        />

        <PickSceneAssetFromMediaLibraryButton
          assetType="VIDEO"
          sceneId={scene.id}
          disabled={withDisabledControls}
          variant="tertiary"
          videoFormat={project.videoFormat}
        />

        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    );
  }

  return (
    <>
      <TeamPermissionRestriction has="ProjectUpdate">
        <Responsive up="md">
          <RouterButton
            to="projectCutScene"
            params={{ projectId: project.id, sceneId: scene.id }}
            variant="tertiary"
            startIcon={<Icon type="ScissorsIcon" size="s" />}
            data-testid="scene-cut-button"
            disabled={withDisabledControls}
          >
            i18n.projectEdit.trimming.cut
          </RouterButton>
        </Responsive>

        <SceneCropButton projectId={project.id} sceneId={scene.id} disabled={withDisabledControls} />

        <SceneOverlaysButton
          projectId={project.id}
          sceneId={scene.id}
          text="i18n.ProjectSceneOverlays.buttons.addOverlays"
          icon="AddMediaOverlay"
          disabled={withDisabledControls}
        />

        <AudioOverlay disabled={withDisabledControls} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="SubtitlesCreate">
        <SceneSubtitlesButton disabled={withDisabledControls} scene={scene} project={project} />
      </TeamPermissionRestriction>

      <TeamPermissionRestriction has="ProjectUpdate">
        <SceneEditorNotesButton />

        {actions.length > 0 && (
          <Responsive down="sm">
            <ActionsPopoverTrigger />
          </Responsive>
        )}
      </TeamPermissionRestriction>
    </>
  );
};
