import { type FC, useCallback } from 'react';

import { Icon, SearchField, VisuallyHidden, styled } from '@cofenster/web-components';

import { useMediaLibraryFilterAndPagination } from './MediaLibrarySearchWithPaginationContext';

const Container = styled('div')(() => ({
  width: '100%',
  maxWidth: 480,
  marginLeft: 'auto',
}));

export const MediaLibrarySearchField: FC = () => {
  const { filter, setFilter } = useMediaLibraryFilterAndPagination();
  const onSearch = useCallback((value: string) => setFilter('search', value), [setFilter]);

  return (
    <Container>
      <VisuallyHidden as="label" htmlFor="search-field-search-project-templates">
        i18n.mediaLibrary.search.label
      </VisuallyHidden>
      <SearchField
        id="search-field-media-library"
        name="search"
        label=""
        placeholder="i18n.mediaLibrary.search.label"
        search={filter.search || ''}
        onSearch={onSearch}
        alwaysExtended
        fullWidth
        autoFocus
        InputProps={{
          startAdornment: <Icon type="SearchIcon" size="m" color="grey600" />,
        }}
        data-testid="search-field-media-library"
      />
    </Container>
  );
};
