import { Text } from '@cofenster/web-components';
import { useCallback, useMemo, useState } from 'react';
import { useDeleteMainAssetFromScenes } from '../../../api/hooks/scene/useDeleteMainAssetFromScenes';
import { useActionSceneIds } from '../../../contexts/selectedScenes/useActionSceneIds';
import { useConfirmDialog } from '../../useConfirmDialog';

export const useDeleteColorSceneAsset = (sceneId: string) => {
  const [busy, setBusy] = useState(false);
  const deleteMainAssetFromScenes = useDeleteMainAssetFromScenes();
  const actionSceneIds = useActionSceneIds(sceneId);

  const confirmDeleteColorSceneAsset = useConfirmDialog({
    title: 'i18n.dialogs.DeleteAssetDialog.multiple.headline',
    titleI18nParams: { count: actionSceneIds.length },
    content: (
      <Text i18nParams={{ count: actionSceneIds.length }} variant="l" color="grey600">
        i18n.dialogs.DeleteAssetDialog.multiple.text
      </Text>
    ),
    confirm: 'i18n.dialogs.DeleteAssetDialog.button',
  });

  const onClick = useCallback(async () => {
    if (busy) return;

    setBusy(true);

    try {
      if (actionSceneIds.length === 1 || (actionSceneIds.length > 1 && (await confirmDeleteColorSceneAsset()))) {
        await deleteMainAssetFromScenes(actionSceneIds);
      }
    } finally {
      setBusy(false);
    }
  }, [busy, deleteMainAssetFromScenes, actionSceneIds, confirmDeleteColorSceneAsset]);

  return useMemo<[typeof onClick, boolean]>(() => [onClick, busy], [onClick, busy]);
};
