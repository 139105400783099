import { type FC, useCallback, useMemo } from 'react';

import { GridItem, LoadingSpinner, Text, styled } from '@cofenster/web-components';

import { useRoles } from '../../../../api/hooks/role/useRoles';
import { useChangeTeamMemberRole } from '../../../../api/hooks/team/useChangeTeamMemberRole';
import { useLeaveTeam } from '../../../../api/hooks/team/useLeaveTeam';
import { useRemoveTeamMembers } from '../../../../api/hooks/team/useRemoveTeamMembers';
import type { Member } from '../../../../api/hooks/team/useTeam';
import type { CurrentTeamContext } from '../../../../contexts/currentTeam/CurrentTeamContext';
import { useCurrentTeam } from '../../../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';
import { useTeamPermissionStatus } from '../../../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../../../contexts/user/useUser';
import { useGotoHomeHandler } from '../../../../hooks/navigation/useGotoHomeHandler';
import { useConfirmDialog } from '../../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

import { MemberPopoverButton } from './MemberPopoverButton';

const useRemoveMember = (member: Member, team?: CurrentTeamContext['team']) => {
  const removeMember = useRemoveTeamMembers();
  const tracking = useWebManagerTracking();
  const teamId = team?.id ?? '';

  const trackTeamSpaceMembersRemoved = useCallback(
    (data: { id: string; name: string; public: boolean }) =>
      tracking.trackEvent({
        event: 'teamSpaceMembersRemoved',
        details: {
          teamId: data.id,
          teamName: data.name,
          teamType: data.public ? 'public' : 'private',
          userIds: [member.id],
        },
      }),
    [tracking, member.id]
  );

  return useCallback(async () => {
    const roleId = member.roles.team.find(({ teamId }) => teamId === team?.id)?.role.roleId;
    if (!team || !roleId) return;

    const result = await removeMember(teamId, { userIds: [member.id], roleId });

    if (result.data?.removeTeamMembers) {
      trackTeamSpaceMembersRemoved(result.data.removeTeamMembers);
    }
  }, [removeMember, trackTeamSpaceMembersRemoved, team, member, teamId]);
};

const useLeaveTeamWithPrompt = (member: Member, team?: CurrentTeamContext['team']) => {
  const teamName = team?.name ?? '';
  const tracking = useWebManagerTracking();
  const goHome = useGotoHomeHandler();

  const confirmLeaving = useConfirmDialog({
    title: 'i18n.dialogs.team.leave.confirm.headline',
    titleI18nParams: { team: teamName },
    content: 'i18n.dialogs.team.leave.confirm.subline',
    confirm: 'i18n.team.leave',
    variant: 'destructive',
  });

  const trackTeamSpaceMembersRemoved = useCallback(
    (data: { id: string; name: string; public: boolean }) =>
      tracking.trackEvent({
        event: 'teamSpaceMembersRemoved',
        details: {
          teamId: data.id,
          teamName: data.name,
          teamType: data.public ? 'public' : 'private',
          userIds: [member.id],
        },
      }),
    [tracking, member.id]
  );

  const leaveTeam = useLeaveTeam();

  return useCallback(async () => {
    if (!team) return;
    if (await confirmLeaving()) {
      await leaveTeam(team.id);
      trackTeamSpaceMembersRemoved(team);
      goHome();
    }
  }, [goHome, confirmLeaving, leaveTeam, team, trackTeamSpaceMembersRemoved]);
};

const useTransferOwnership = (newOwnerName: string, newOwnerId: string, team: CurrentTeamContext['team']) => {
  const { openDialog } = useDialogs();

  return useCallback(async () => {
    if (!team) return;
    openDialog('TransferTeamOwnershipDialog', {
      teamId: team.id,
      creatorId: newOwnerId,
      newOwnerName,
    });
  }, [openDialog, team, newOwnerId, newOwnerName]);
};

const TextWithPadding = styled(Text)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

export const RoleAndMembership: FC<{ member: Member }> = ({ member }) => {
  const { user } = useUser();
  const { team } = useCurrentTeam();

  const { roles, loading: loadingRoles } = useRoles();
  const { changeTeamMembersRole, loading } = useChangeTeamMemberRole();

  const leaveTeam = useLeaveTeamWithPrompt(member, team);
  const removeMember = useRemoveMember(member, team);
  const transferOwnership = useTransferOwnership(member.name, member.id, team);

  const roleInThatTeam = useMemo(() => {
    if (!team) return null;
    const foundTeam = member.roles.team?.filter((role) => role.teamId === team.id);
    return foundTeam?.[0]?.role.name;
  }, [member, team]);

  const changeRole = useCallback(
    (roleId: string) => {
      if (!team) return;
      changeTeamMembersRole(team.id, {
        userIds: [member.id],
        roleId: roleId,
      });
    },
    [team, changeTeamMembersRole, member.id]
  );

  const canManage = useTeamPermissionStatus({ has: 'MemberUpdate' }) === 'ALLOWED';
  const isAllowedToRemove = useTeamPermissionStatus({ has: 'MemberDelete' }) === 'ALLOWED';

  if (!team) return null;

  const currentRole = member.roles.team.find(({ teamId }) => teamId === team.id)?.role;
  const currentUserIsOwner = !!user && team.creator?.id === user.id;
  const currentUserIsMember = member.id === user?.id;
  const memberIsOwner = team.creator?.id === member.id;

  const canLeave = !currentUserIsOwner && currentUserIsMember;
  const canRemove = isAllowedToRemove && !memberIsOwner;
  const canTransfer = currentUserIsOwner && !memberIsOwner;

  if (loadingRoles || loading)
    return (
      <GridItem data-testid="team-member-membership">
        <LoadingSpinner size={24} />
      </GridItem>
    );

  if (!currentRole) return <></>;

  if (memberIsOwner) {
    return (
      <GridItem data-testid="team-member-membership">
        <TextWithPadding variant="l">i18n.team.owner</TextWithPadding>
      </GridItem>
    );
  }

  return (
    <GridItem data-testid="team-member-membership">
      {!canLeave && !canRemove && !canTransfer && !canManage ? (
        <TextWithPadding variant="l">
          {memberIsOwner ? 'i18n.team.owner' : `i18n.team.role.${roleInThatTeam}`}
        </TextWithPadding>
      ) : (
        <MemberPopoverButton
          roles={roles}
          canLeave={canLeave}
          canRemove={canRemove}
          canTransfer={canTransfer}
          changeRole={changeRole}
          onRemove={canLeave ? leaveTeam : removeMember}
          transferOwnership={transferOwnership}
          currentRole={currentRole}
          canManage={canManage}
        />
      )}
    </GridItem>
  );
};
