import { styled } from '@mui/material';
import { type FC, useCallback, useRef } from 'react';
import { Slider, Tracks } from 'react-compound-slider';

import { Rail } from './Rail';
import { Track } from './Track';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  height: 18,
  width: '100%',
});

const sliderRootStyle = {
  position: 'relative',
  height: 'var(--preview-track-height, 4px)',
  width: '100%',
} as const;

const initialDomain = [0, 1];

type Range = Readonly<[number, number]>;

type SliderCallback = (values: Range, index: number) => void;

const useSliderCallback = (sliderCallback: SliderCallback | undefined, index?: number) => {
  const fromRef = useRef<number | undefined>(undefined);
  const toRef = useRef<number | undefined>(undefined);

  const callback = useCallback(
    ([from, to]: Readonly<number[]>) => {
      if (fromRef.current === from && toRef.current === to) {
        return;
      }

      fromRef.current = from;
      toRef.current = to;

      if (!sliderCallback) {
        return;
      }

      sliderCallback([from, to] as Range, index ?? 0);
    },
    [sliderCallback, index]
  );

  return callback;
};

type Props = {
  range: Range;
  index: number;
  onUpdate?: SliderCallback;
  onChange?: SliderCallback;
  onDragStart?: SliderCallback;
  onDragEnd?: SliderCallback;
};

export const RangeSlider: FC<Props> = ({ range, index, ...props }) => {
  const onUpdate = useSliderCallback(props.onUpdate, index);
  const onChange = useSliderCallback(props.onChange);
  const onDragStart = useSliderCallback(props.onDragStart);
  const onDragEnd = useSliderCallback(props.onDragEnd);

  return (
    <Container>
      <Slider
        mode={2}
        step={1 / 1000}
        domain={initialDomain}
        rootStyle={sliderRootStyle}
        values={range}
        onUpdate={onUpdate}
        onChange={onChange}
        onSlideStart={onDragStart}
        onSlideEnd={onDragEnd}
      >
        <Rail />
        <Tracks left={false} right={false}>
          {({ tracks }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <Track key={id} from={source} to={target} />
              ))}
            </>
          )}
        </Tracks>
      </Slider>
    </Container>
  );
};
