import { type Dispatch, type SetStateAction, createContext } from 'react';

import type { SceneTimings } from './usePlayerScenes';

export type EditorPlayerContext = {
  currentTime: number;
  setCurrentTime: (time: number) => void;
  setCurrentScene: (sceneId: string) => void;
  gotoNextScene?: VoidFunction;
  gotoPreviousScene?: VoidFunction;
  play: VoidFunction;
  pause: VoidFunction;
  paused: boolean;
  duration: number;
  setIsDraggingControls: Dispatch<SetStateAction<boolean>>;
  isDraggingControls: boolean;
  isVideoBuffering: boolean;
  setIsVideoBuffering: Dispatch<SetStateAction<boolean>>;
  sceneTimings: SceneTimings[];
  onPlayerReady: VoidFunction;
  synchronizeCurrentSceneWithTime: ({ currentTime }: { currentTime: number }) => void;
  setHasPreviewLoadingError: Dispatch<SetStateAction<boolean>>;
  hasPreviewLoadingError: boolean;
};

export const EditorPlayerContext = createContext<EditorPlayerContext | undefined>(undefined);
