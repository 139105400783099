import type { FC } from 'react';

import { GridContainer, GridItem, Text } from '@cofenster/web-components';

import { RouterLink } from '../../components/button/RouterLink';
import { PageHeadline, PageSubline, UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';
import { useI18n } from '../../i18n/useI18n';

export const ResetSent: FC = () => {
  const { translate } = useI18n();

  return (
    <UnauthenticatedPage title="i18n.resetSent.title">
      <PageHeadline variant="h1">i18n.resetSent.headline</PageHeadline>
      <PageSubline variant="xl" component="p">
        i18n.resetSent.subline
      </PageSubline>
      <GridContainer>
        <GridItem>
          {translate('reset.again', {
            link: (chunks) => (
              <RouterLink to="forgotPassword" data-testid="password-reset-link">
                <Text color="blue">{chunks}</Text>
              </RouterLink>
            ),
          })}
        </GridItem>
      </GridContainer>
    </UnauthenticatedPage>
  );
};
