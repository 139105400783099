export const USER_AUTH_COOKIE_NAME = 'cuid';
export const STAFF_USER_AUTH_COOKIE_NAME = 'aid';
export const IMPERSONATION_COOKIE_NAME = 'iuid';

export const AUTH_COOKIE_SETTINGS = {
  // The authentication cookies should never be served over HTTP, only HTTPS.
  secure: true,

  // The authentication cookies do not need (and probably should not) be sent
  // to third-party services as they only have meaning within our system, so
  // they can safely by defined a strict.
  sameSite: true,

  // The authentication cookies should not be available to client-side
  // JavaScript for security reasons and are therefore set and readable only
  // on the server.
  httpOnly: true,
};
