import { useField } from 'formik';
import type { FC, PropsWithChildren } from 'react';

import { Translatable } from '../../../services/i18n';
import { Select, SelectOption, type SelectOptionProps, type SelectProps } from '../../controls/Select';

export type BasicOption<V extends string | number = string> = PropsWithChildren<{
  value: V;
  disabled?: SelectOptionProps['disabled'];
}>;

export const renderBasicOptions = (options: BasicOption[]) =>
  options.map(({ children, ...rest }) => (
    <SelectOption key={rest.value} value={rest.value}>
      <Translatable>{children}</Translatable>
    </SelectOption>
  ));

export type FormSelectProps = Omit<SelectProps, 'error'> & {
  name: string;
};

export const FormSelect: FC<FormSelectProps> = ({ name, fullWidth = true, ...rest }) => {
  const [field, meta] = useField(name);
  const hasError = meta.touched && !!meta.error;

  return <Select {...rest} {...field} name={name} fullWidth={fullWidth} error={hasError} />;
};
