import { Headline, styled } from '@cofenster/web-components';

export const Name = styled(Headline)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxWidth: 260,
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
}));
