import { useMemo } from 'react';

import {
  type PublishedVideoAnalyticsQueryResult,
  type PublishedVideoAnalyticsQueryVariables,
  usePublishedVideoAnalyticsQuery,
} from '../../generated';

export type PublishedVideoAnalytics = NonNullable<PublishedVideoAnalyticsQueryResult['data']>;

export const usePublishedVideoAnalytics = (projectId: PublishedVideoAnalyticsQueryVariables['projectId']) => {
  const { loading, error, data } = usePublishedVideoAnalyticsQuery({
    variables: { projectId },
  });
  const publishedVideoAnalytics = data?.publishedVideoAnalytics ?? undefined;
  return useMemo(() => ({ publishedVideoAnalytics, loading, error }), [publishedVideoAnalytics, loading, error]);
};
