import { useCallback } from 'react';

import { type DeleteTeamMutationVariables, useDeleteTeamMutation } from '../../generated';

export const useDeleteTeam = () => {
  const [deleteTeam] = useDeleteTeamMutation({ refetchQueries: ['Teams'] });
  return useCallback(
    (id: DeleteTeamMutationVariables['id']) => {
      return deleteTeam({ variables: { id } });
    },
    [deleteTeam]
  );
};
