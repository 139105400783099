import type { FC, PropsWithChildren } from 'react';

import { Button, type ButtonProps, styled } from '@cofenster/web-components';

import { INTERCOM_TOGGLE_CLASS_NAME } from './Intercom';
import { useHasIntercomMounted } from './useHasIntercomMounted';

const NoWrapButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'nowrap',
  },
}));

export const IntercomButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...rest }) => {
  const hasIntercomMounted = useHasIntercomMounted();

  if (!hasIntercomMounted) return null;

  return (
    <NoWrapButton {...rest} className={INTERCOM_TOGGLE_CLASS_NAME}>
      {children}
    </NoWrapButton>
  );
};
