import { useMemo } from 'react';

import { type SearchStockVideosQuery, useSearchStockVideosLazyQuery } from '../../generated';

export type StockVideo = NonNullable<SearchStockVideosQuery['searchStockVideos']>['items'][number];
export type StockVideoResponse = NonNullable<SearchStockVideosQuery['searchStockVideos']>;
export type Attributor = NonNullable<StockVideo['attributor']>;

export const useSearchStockVideos = () => {
  const [searchStockVideos, metadata] = useSearchStockVideosLazyQuery();

  return useMemo(() => [searchStockVideos, metadata] as const, [searchStockVideos, metadata]);
};
