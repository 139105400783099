import { useCallback } from 'react';

import { type SigninMutationResult, type SigninMutationVariables, useSigninMutation } from '../../generated';

export type SigninResult = NonNullable<SigninMutationResult['data']>['signin'];

export const useSignin = () => {
  const [signin] = useSigninMutation({ refetchQueries: ['Me'], awaitRefetchQueries: true });
  return useCallback(
    (email: SigninMutationVariables['email'], password: SigninMutationVariables['password']) =>
      signin({ variables: { email, password } }),
    [signin]
  );
};
