import { useCallback } from 'react';

import { type CopyFromOtherThemeMutationVariables, useCopyFromOtherThemeMutation } from '../../generated';

export const useCopyFromTheme = () => {
  const [copyFromTheme] = useCopyFromOtherThemeMutation({
    refetchQueries: ['Me', 'Project'],
  });

  return useCallback(
    (
      themeId: CopyFromOtherThemeMutationVariables['id'],
      otherThemeId: CopyFromOtherThemeMutationVariables['otherThemeId']
    ) => copyFromTheme({ variables: { id: themeId, otherThemeId } }),
    [copyFromTheme]
  );
};
