import { useCallback } from 'react';

import type { AudioSceneAsset } from '../../../api/hooks/scene/useScenes';
import { useWebManagerTracking } from '../../useWebManagerTracking';
import { useDeleteAudioSceneAsset } from './useDeleteAudioSceneAsset';

export const useDeleteAudioSceneAssetWithTracking = (
  sceneId: string,
  audioSceneAsset?: AudioSceneAsset,
  additionalTrackingDetails?: Record<string, string | number>
) => {
  const deleteAudioSceneAsset = useDeleteAudioSceneAsset(audioSceneAsset);
  const tracking = useWebManagerTracking();
  const trackSceneAssetDeleted = useCallback(
    (audioSceneAsset: AudioSceneAsset) =>
      tracking.trackEvent({
        event: 'SceneAssetDeleted',
        details: {
          sceneId: sceneId,
          assetType: 'audio',
          location: 'projectEdit',
          assetName: audioSceneAsset?.asset?.audioAsset?.audioUrl,
          ...additionalTrackingDetails,
        },
      }),
    [tracking, sceneId, additionalTrackingDetails]
  );

  return useCallback(async () => {
    if (!audioSceneAsset) return;
    await deleteAudioSceneAsset();
    trackSceneAssetDeleted(audioSceneAsset);
  }, [deleteAudioSceneAsset, audioSceneAsset, trackSceneAssetDeleted]);
};
