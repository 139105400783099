import { useMemo } from 'react';

import {
  type TemplatesByProjectQueryResult,
  type TemplatesByProjectQueryVariables,
  useTemplatesByProjectQuery,
} from '../../generated';

export type Template = NonNullable<TemplatesByProjectQueryResult['data']>['templatesByProject'][number];

export const useTemplatesByProject = (projectId: TemplatesByProjectQueryVariables['projectId']) => {
  const { loading, error, data } = useTemplatesByProjectQuery({
    variables: { projectId },
    fetchPolicy: 'cache-and-network',
  });
  const templatesByProject = data?.templatesByProject;
  return useMemo(
    () => ({
      templatesByProject: templatesByProject || [],
      loading,
      error,
    }),
    [templatesByProject, loading, error]
  );
};
