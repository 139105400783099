import type { FC } from 'react';

import type { ImageSceneAsset, Scene } from '../../../../../api/hooks/scene/useScenes';
import { useEditorPlayer } from '../../../../../contexts/editorPlayer/useEditorPlayer';
import type { Project } from '../../../../../contexts/project/useProject';

import { SceneImageControls } from './SceneImageControls';

export const EditorToolsSceneImage: FC<{
  project: Project;
  scene: Scene;
  imageSceneAsset?: ImageSceneAsset;
}> = ({ project, scene, imageSceneAsset }) => {
  const { hasPreviewLoadingError } = useEditorPlayer();

  const { imageUrl, status } = imageSceneAsset?.asset?.imageAsset ?? {};
  const withDisabledControls = status === 'Waiting' || hasPreviewLoadingError || Boolean(project.archivedAt);

  return (
    <SceneImageControls
      project={project}
      scene={scene}
      imageSceneAsset={imageSceneAsset}
      isEmpty={!imageUrl}
      withDisabledControls={withDisabledControls}
    />
  );
};
