import { type CSSProperties, type FC, useEffect, useState } from 'react';

import {
  BlankButton,
  Button,
  LazyLoadedColorInput,
  VisuallyHidden,
  isHexColor,
  styled,
} from '@cofenster/web-components';

const RowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 2),
  gap: theme.spacing(1),
}));

const ColContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const ColorDotButton = styled(BlankButton)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: 'var(--color)',
  width: theme.spacing(5.5),
  height: theme.spacing(5.5),
  border: `2px solid ${theme.palette.brand.linen}`,

  '&:hover': { outline: `2px solid ${theme.palette.brand.grey200}` },
  '&:focus-visible': theme.mixins.focusRing,
}));

export const ColorPicker: FC<{
  color?: string;
  primary?: string;
  secondary?: string;
  onChange: (value: string) => void;
}> = ({ color, primary, secondary, onChange }) => {
  const [uncommitedColor, setUncommitedColor] = useState(color);

  useEffect(() => setUncommitedColor(color), [color]);

  return (
    <PopoverContainer data-testid="scene-color-picker">
      <ColContainer>
        <RowContainer>
          {primary && (
            <ColorDotButton
              onClick={() => setUncommitedColor(primary)}
              style={{ '--color': primary } as CSSProperties}
              type="button"
              data-testid="scene-color-picker-primary-button"
            >
              <VisuallyHidden i18nParams={{ color: primary }}>i18n.projectEdit.colorPicker.pickButton</VisuallyHidden>
            </ColorDotButton>
          )}
          {secondary && (
            <ColorDotButton
              onClick={() => setUncommitedColor(secondary)}
              style={{ '--color': secondary } as CSSProperties}
              type="button"
              data-testid="scene-color-picker-secondary-button"
            >
              <VisuallyHidden i18nParams={{ color: secondary }}>i18n.projectEdit.colorPicker.pickButton</VisuallyHidden>
            </ColorDotButton>
          )}
        </RowContainer>
        <LazyLoadedColorInput
          value={isHexColor(uncommitedColor) ? uncommitedColor : '#000'}
          onChange={setUncommitedColor}
        />
        <RowContainer>
          <Button
            variant="primary"
            disabled={color === uncommitedColor}
            onClick={() => uncommitedColor && onChange(uncommitedColor)}
            data-testid="scene-color-picker-save-button"
            fullWidth
          >
            i18n.projectEdit.colorPicker.setButton
          </Button>
        </RowContainer>
      </ColContainer>
    </PopoverContainer>
  );
};
