import { styled } from '@mui/material';
import { useField } from 'formik';
import type { FC, ReactNode } from 'react';

import { Checkbox, type CheckboxProps } from '../../controls/Checkbox';
import { Typography } from '../../typography/Typography';

import type { TextProps } from '@cofenster/web-components';
import { FieldLabel } from './FieldLabel';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const Label = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
}));

export type FormCheckboxProps = CheckboxProps & {
  name: string;
  id: string;
  value?: string | undefined;
  label: ReactNode;
  labelProps?: TextProps;
};

export const FormCheckbox: FC<FormCheckboxProps> = ({ name, value, label, labelProps, ...rest }) => {
  const [field, meta] = useField({ name, value, type: 'checkbox' });
  const hasError = meta.touched && !!meta.error;

  return (
    <Container>
      <Label htmlFor={rest.id}>
        <Checkbox
          {...rest}
          {...field}
          inputProps={{ ...rest.inputProps, 'aria-describedby': `${rest.id}-helper-text` }}
        />
        <FieldLabel {...labelProps}>{label}</FieldLabel>
      </Label>
      {hasError ? (
        <Typography id={`${rest.id}-helper-text`} color="negative">
          {meta.error}
        </Typography>
      ) : null}
    </Container>
  );
};
