import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRemotionPlayerControls } from '@cofenster/web-remotion-player';

import type { ProjectEditorRouteParams } from '../../routes';

import { EditorPlayerContext } from './EditorPlayerContext';
import { usePlayerScenes } from './usePlayerScenes';
import { useRemotionPlayerRef } from './useRemotionPlayerRef';

export type RootEditorPlayerProviderProps = PropsWithChildren;

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

export const EditorPlayerProvider: FC<RootEditorPlayerProviderProps> = ({ children }) => {
  const { sceneId } = useParams() as ProjectEditorRouteParams;

  // The Remotion player can go into buffering when the required asset is not
  // ready and is loaded from the network. We need to keep track of it to
  // disable controls and make the effects more stable where needed.
  const [isVideoBuffering, setIsVideoBuffering] = useState(false);
  const [hasPreviewLoadingError, setHasPreviewLoadingError] = useState(false);
  // biome-ignore lint/correctness/useExhaustiveDependencies: reset the error state when the scene changes
  useEffect(() => setHasPreviewLoadingError(false), [sceneId]);

  const { playerRef } = useRemotionPlayerRef();
  const { currentTime, setCurrentTime, pause, paused, play, isDraggingControls, setIsDraggingControls } =
    useRemotionPlayerControls(playerRef, { eventType: 'timeupdate' });
  const { sceneTimings, setCurrentScene, gotoNextScene, gotoPreviousScene } = usePlayerScenes(isDraggingControls);

  const playerTotalDuration = sceneTimings[sceneTimings.length - 1]?.end ?? 0;

  const setCurrentTimeAndMoveToScene = useCallback(
    (time: number) => {
      const sceneId = sceneTimings.find((scene) => {
        return (
          scene.hasAsset &&
          roundToTwoDecimals(time) >= roundToTwoDecimals(scene.start) &&
          time <= roundToTwoDecimals(scene.end)
        );
      })?.id;

      if (sceneId) {
        setCurrentTime(time);
        setCurrentScene(sceneId);
      }
    },
    [sceneTimings, setCurrentTime, setCurrentScene]
  );

  const setCurrentSceneAndMoveToTime = useCallback(
    (sceneId: string) => {
      const timing = sceneTimings.find((scene) => scene.id === sceneId);
      if (!timing?.hasAsset) pause();
      if (timing) {
        setCurrentTime(timing.start);
      }
      setCurrentScene(sceneId);
    },
    [sceneTimings, pause, setCurrentScene, setCurrentTime]
  );
  const gotoNextSceneAndMoveTimeOnPlayer = useCallback(() => {
    if (!gotoNextScene) return;
    const nextSceneTiming = gotoNextScene();
    if (nextSceneTiming) {
      setCurrentTime(nextSceneTiming);
    }
  }, [gotoNextScene, setCurrentTime]);

  const gotoPreviousSceneAndMoveTimeOnPlayer = useCallback(() => {
    if (!gotoPreviousScene) return;
    const previousSceneTiming = gotoPreviousScene();
    if (previousSceneTiming || previousSceneTiming === 0) {
      setCurrentTime(previousSceneTiming);
    }
  }, [gotoPreviousScene, setCurrentTime]);

  // As per COF-2490, we need to make sure that when the player is at the end of
  // the video and the user presses “play”, the player will start from the
  // beginning. Rounding is needed because the player currentTime is a float.
  // See: https://www.notion.so/cofenster/Change-Player-behavior-on-end-e224f8b7a3bb403d95c9ef6efeb4c6eb
  const onPlay = useCallback(() => {
    if (playerTotalDuration !== undefined && Math.round(currentTime) === Math.round(playerTotalDuration))
      setCurrentTimeAndMoveToScene(0);
    play();
  }, [play, setCurrentTimeAndMoveToScene, currentTime, playerTotalDuration]);

  const onPlayerReady = useCallback(() => {
    if (sceneId) {
      setCurrentSceneAndMoveToTime(sceneId);
    }
  }, [sceneId, setCurrentSceneAndMoveToTime]);

  const synchronizeCurrentSceneWithTime = useCallback(
    ({ currentTime }: { currentTime: number }) => {
      const sceneAtCurrentTime = sceneTimings.find(
        (scene) =>
          scene.hasAsset &&
          roundToTwoDecimals(scene.start) < roundToTwoDecimals(currentTime) &&
          roundToTwoDecimals(scene.end) > roundToTwoDecimals(currentTime)
      );

      if (sceneAtCurrentTime && sceneAtCurrentTime.id !== sceneId) {
        setCurrentScene(sceneAtCurrentTime.id);
      }
    },
    [sceneTimings, sceneId, setCurrentScene]
  );

  const context = useMemo(
    () => ({
      currentTime,
      setCurrentTime: setCurrentTimeAndMoveToScene,
      setCurrentScene: setCurrentSceneAndMoveToTime,
      gotoNextScene: gotoNextScene ? gotoNextSceneAndMoveTimeOnPlayer : undefined,
      gotoPreviousScene: gotoPreviousScene ? gotoPreviousSceneAndMoveTimeOnPlayer : undefined,
      pause,
      paused,
      play: onPlay,
      duration: playerTotalDuration,
      isDraggingControls,
      setIsDraggingControls,
      isVideoBuffering,
      setIsVideoBuffering,
      sceneTimings,
      onPlayerReady,
      synchronizeCurrentSceneWithTime,
      hasPreviewLoadingError,
      setHasPreviewLoadingError,
    }),
    [
      currentTime,
      setCurrentTimeAndMoveToScene,
      setCurrentSceneAndMoveToTime,
      gotoNextScene,
      gotoNextSceneAndMoveTimeOnPlayer,
      gotoPreviousScene,
      gotoPreviousSceneAndMoveTimeOnPlayer,
      pause,
      paused,
      onPlay,
      playerTotalDuration,
      isDraggingControls,
      setIsDraggingControls,
      isVideoBuffering,
      sceneTimings,
      onPlayerReady,
      synchronizeCurrentSceneWithTime,
      hasPreviewLoadingError,
    ]
  );

  return <EditorPlayerContext.Provider value={context}>{children}</EditorPlayerContext.Provider>;
};
