import { useCallback } from 'react';

import { type UpdateScenesMutationVariables, useUpdateScenesMutation } from '../../generated';

export const useUpdateScenes = (refetchQueries = ['Scenes']) => {
  const [updateScene] = useUpdateScenesMutation({ refetchQueries, awaitRefetchQueries: true });
  return useCallback(
    (inputs: UpdateScenesMutationVariables['inputs']) => updateScene({ variables: { inputs } }),
    [updateScene]
  );
};
