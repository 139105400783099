import type { ServerError } from '@apollo/client';
import type { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import type { ErrorResponse } from '@apollo/client/link/error';

const isUnauthorizedCode = (error: GraphQLErrors[number]) => {
  return error.extensions.code === 'UNAUTHORIZED';
};

const hasUnauthorizedError = (result: ServerError['result']) => {
  if (typeof result === 'string') return false;
  if (!('errors' in result)) return false;
  return (result.errors as GraphQLErrors).some(isUnauthorizedCode);
};

const hasErrorResults = (error?: NetworkError): error is ServerError => {
  return error ? 'result' in error : false;
};

type BatchedServerError = Omit<ServerError, 'result'> & { result: ServerError['result'][] };

const hasBatchedResults = (error: ServerError): error is BatchedServerError => {
  return Array.isArray(error.result);
};

export const isUnauthenticated = ({ networkError }: Omit<ErrorResponse, 'operation' | 'forward'>) => {
  if (!hasErrorResults(networkError)) return false;
  if (networkError.statusCode !== 401) return false;
  if (!hasBatchedResults(networkError)) return hasUnauthorizedError(networkError.result);
  return networkError.result.some(hasUnauthorizedError);
};
