import { type FC, memo } from 'react';

export const InstagramSafeZoneSvg: FC = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width={88} height={168} fill="none">
    <rect width={85} height={165} x={1.5} y={1.5} fill="#223A41" stroke="#0A242C" strokeWidth={3} rx={10.5} />
    <path fill="#0A242C" d="M19 3h50a4 4 0 0 1-4 4H23a4 4 0 0 1-4-4Z" />
    <path
      fill="#627378"
      d="M78.125 14.188h-1.083l-.532-.799a.313.313 0 0 0-.26-.139h-2.5a.312.312 0 0 0-.26.14l-.533.797h-1.082a.938.938 0 0 0-.938.938V19.5a.938.938 0 0 0 .938.938h6.25a.938.938 0 0 0 .938-.938v-4.375a.938.938 0 0 0-.938-.938Zm-1.719 2.968a1.406 1.406 0 1 1-2.812 0 1.406 1.406 0 0 1 2.812 0Z"
    />
    <rect width={28} height={4} x={8} y={15} fill="#627378" rx={2} />
    <rect
      width={56}
      height={102}
      x={9}
      y={27}
      fill="#fff"
      fillOpacity={0.2}
      stroke="#fff"
      strokeDasharray="10 4"
      strokeWidth={2}
      rx={5}
    />
    <circle cx={13} cy={143} r={5} fill="#627378" />
    <rect width={24} height={4} x={22} y={141} fill="#627378" rx={2} />
    <rect width={48} height={4} x={8} y={152} fill="#627378" rx={2} />
    <path
      fill="#627378"
      d="M79.375 77.672c0 2.734-4.054 4.948-4.227 5.039a.312.312 0 0 1-.296 0c-.173-.091-4.227-2.305-4.227-5.04a2.424 2.424 0 0 1 2.422-2.421c.806 0 1.513.347 1.953.933.44-.586 1.147-.933 1.953-.933a2.424 2.424 0 0 1 2.422 2.422ZM79.063 96.998a4.061 4.061 0 0 1-5.972 3.587l-1.33.443a.629.629 0 0 1-.64-.151.624.624 0 0 1-.151-.639l.443-1.33a4.062 4.062 0 1 1 7.65-1.91ZM79.04 111.73v.006l-2.274 7.498a.617.617 0 0 1-.547.449.618.618 0 0 1-.617-.355l-1.395-2.945a.154.154 0 0 1 .031-.177l2.237-2.237a.313.313 0 0 0-.442-.442l-2.24 2.237a.15.15 0 0 1-.084.043.15.15 0 0 1-.094-.012l-2.93-1.388a.64.64 0 0 1-.372-.606.62.62 0 0 1 .452-.567l7.498-2.273h.005a.625.625 0 0 1 .772.769ZM75.469 133a.467.467 0 0 1-.29.433.465.465 0 0 1-.51-.102.466.466 0 0 1-.102-.51.471.471 0 0 1 .433-.29.47.47 0 0 1 .469.469Zm2.187-.469a.473.473 0 0 0-.433.29.466.466 0 0 0 .102.51.466.466 0 0 0 .721-.071.467.467 0 0 0-.058-.591.469.469 0 0 0-.332-.138Zm-5.312 0a.473.473 0 0 0-.433.29.466.466 0 0 0 .101.51.466.466 0 0 0 .722-.071.467.467 0 0 0-.059-.591.469.469 0 0 0-.331-.138Z"
    />
    <circle cx={75} cy={151} r={5} fill="#627378" />
    <path
      fill="#0A242C"
      d="M76.99 148.603a.185.185 0 0 0-.16-.035l-3 .75a.187.187 0 0 0-.142.182v2.58a.845.845 0 0 0-1.213 1.1.843.843 0 0 0 1.588-.399v-2.197l2.624-.656v1.402a.845.845 0 0 0-1.212 1.1.843.843 0 0 0 1.588-.399v-3.281a.184.184 0 0 0-.073-.147Z"
    />
  </svg>
));
