import { useCallback } from 'react';

import type { MutationHookOptions } from '@cofenster/api-client-apollo';

import {
  type InitProjectVideoAssetDownloadMutationVariables,
  useInitProjectVideoAssetDownloadMutation,
} from '../../generated';

export const useInitProjectVideoAssetDownload = (options?: Omit<MutationHookOptions, 'variables'>) => {
  const [initProjectVideoAssetDownload] = useInitProjectVideoAssetDownloadMutation(options);

  return useCallback(
    (
      id: InitProjectVideoAssetDownloadMutationVariables['id'],
      language: InitProjectVideoAssetDownloadMutationVariables['language']
    ) => initProjectVideoAssetDownload({ variables: { id, language } }),
    [initProjectVideoAssetDownload]
  );
};
