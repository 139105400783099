import type { FormikValues } from 'formik';
import { useCallback } from 'react';

import { useUpdateScenes } from '../../../../../api/hooks/scene/useUpdateScenes';

type ToggleSceneVisibilityValues = {
  excluded: boolean;
  sceneId: string;
};

export const useToggleSceneVisibility = () => {
  const updateScene = useUpdateScenes();

  return useCallback(
    (values: FormikValues, sceneId: string) => {
      const { excluded } = values as ToggleSceneVisibilityValues;
      return updateScene([{ sceneId, excluded }]);
    },
    [updateScene]
  );
};
