import { useCallback } from 'react';

import { useInitProjectVideoAssetDownload } from '../../api/hooks/project/useInitProjectVideoAssetDownload';
import type { Project as UseProjectsProject } from '../../api/hooks/project/useProjects';
import type { Project as UseProjectProject } from '../../contexts/project/useProject';
import { useDownloadUrl } from '../download/useDownloadUrl';
import { useWebManagerTracking } from '../useWebManagerTracking';

import { useProjectDetails } from './useProjectDetails';

type Project = UseProjectProject | UseProjectsProject;

export const useDownloadExportedVideo = (project: Project, downloadSource: string, language: string | null = null) => {
  const tracking = useWebManagerTracking();
  const details = useProjectDetails(project);
  const initProjectVideoAssetDownload = useInitProjectVideoAssetDownload();
  const downloadFile = useDownloadUrl();

  return useCallback(async () => {
    const result = await initProjectVideoAssetDownload(project.id, language);
    const downloadUrl = result?.data?.initProjectVideoAssetDownload;
    if (!downloadUrl) return;
    downloadFile(downloadUrl);
    tracking.trackEvent({ event: 'projectDownload', details: { ...details, source: downloadSource } });
  }, [project.id, initProjectVideoAssetDownload, downloadFile, tracking, details, downloadSource, language]);
};
