import { useCallback, useMemo, useState } from 'react';

import { LAYER_INDEXES } from '@cofenster/constants';
import { useUpload } from '@cofenster/web-components';

import { useUpdateAudioAsset } from '../../../api/hooks/audioAsset/useUpdateAudioAsset';
import { useCreateSceneAsset } from '../../../api/hooks/sceneAsset/useCreateSceneAsset';

export const useUploadSceneAudioAsset = (sceneId: string) => {
  const { startUpload, getUpload, cancelAndClearUpload } = useUpload();
  const [isProcessing, setIsProcessing] = useState(false);

  const updateAudioAsset = useUpdateAudioAsset();
  const createSceneAsset = useCreateSceneAsset();
  const uploadProgress: number | undefined = getUpload('audio', sceneId)?.progress;

  const cancelUpload = useCallback(() => {
    cancelAndClearUpload('audio', sceneId);
  }, [cancelAndClearUpload, sceneId]);

  const upload = useCallback(
    async (file: File) => {
      try {
        setIsProcessing(true);

        const audioAssetId = await startUpload('audio', sceneId, file);

        // Uploading file failed or was cancelled - there is not sens to create/update sceneAsset
        // without asset itself. By creating we will have InProgress status forever.
        if (!audioAssetId) return;

        await updateAudioAsset(audioAssetId, { status: 'Processing' });
        await createSceneAsset({
          sceneId,
          assetType: 'Audio',
          assetId: audioAssetId,
          layerIndex: LAYER_INDEXES.audio,
          startTime: 0,
        });

        return audioAssetId;
      } finally {
        setIsProcessing(false);
        cancelUpload();
      }
    },
    [sceneId, startUpload, cancelUpload, updateAudioAsset, createSceneAsset]
  );

  return useMemo(
    () => [uploadProgress, upload, cancelUpload, isProcessing] as const,
    [uploadProgress, upload, cancelUpload, isProcessing]
  );
};
