import type { RoleName } from '../../api/generated';
import { useCurrentTeamSafe } from '../currentTeam/useCurrentTeam';

import { useIsPersonalSpace } from './TeamPermissionRestriction';
import { type User, useUser } from './useUser';

type UseRoleReturn = {
  accountRole: User['roles']['account'];
  teamRoles: User['roles']['team'];
};

export const useRole = (): UseRoleReturn => {
  const { user } = useUser();
  if (!user) {
    throw new Error("Can't read roles, there's no User");
  }

  return { accountRole: user?.roles.account, teamRoles: user?.roles?.team ?? [] };
};

export const useRoleSafe = (): UseRoleReturn | undefined => {
  const { user } = useUser();
  return user ? { accountRole: user?.roles.account, teamRoles: user?.roles?.team ?? [] } : undefined;
};

type UserContextRoleInformation =
  | {
      context: 'personalSpace';
    }
  | {
      context: 'account';
      contextId: string;
      contextRole: RoleName;
    }
  | {
      context: 'team';
      contextId: string;
      contextRole: RoleName | undefined;
    };

export const useUserContextRole = (): UserContextRoleInformation | undefined => {
  const { user } = useUser();
  const { team } = useCurrentTeamSafe();
  const isPersonalSpace = useIsPersonalSpace();

  if (!user) return undefined;

  if (team) {
    const teamRole = user.roles.team.find((it) => it.teamId === team.id);
    return {
      context: 'team',
      contextId: team.id,
      contextRole: teamRole?.role.name,
    };
  }

  if (isPersonalSpace) {
    return {
      context: 'personalSpace',
    };
  }

  return {
    context: 'account',
    contextId: user.account.id,
    contextRole: user.roles.account.name,
  };
};
