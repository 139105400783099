import { BlankButton, styled } from '@cofenster/web-components';

// 1. Enable overlapping the thumbnail button and the thumbnail content without
//    resorting to absolute positioning.
export const DraggableEditorThumbnailButton = styled(BlankButton)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape['borderRadius-l'],
  gridArea: 'content', // 1
  '&:focus-visible': theme.mixins.focusRing,
}));
