import { styled } from '@mui/material';
import type { FC, ReactNode } from 'react';

import type { AnimountStatus } from '../../../hooks/state/useAnimount';

// 1. Keep the banner in the flow at the bottom of the page, but allow it to
//    float on top of the scenes while scrolling.
// 2. Give some breathing room between the banner and the add scene button, and
//    extend the banner past the horizontal edges.
const Container = styled('div')<{ status?: AnimountStatus }>(({ theme, status }) => ({
  position: 'sticky', // 1
  bottom: theme.spacing(3), // 1
  width: `calc(100% + ${theme.spacing(4)})`,
  margin: theme.spacing(3, -2, 0), // 2
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.brand.carbon,
  borderRadius: theme.shape['borderRadius-l'],
  color: theme.palette.brand.white,
  zIndex: theme.zIndex.above,
  animationDuration: 'calc(var(--duration, 300) * 1ms)',
  animationFillMode: 'forwards',
  animationName: status === 'ENTERING' || status === 'EXITING' ? 'slide-up' : undefined,
  animationDirection: status === 'EXITING' ? 'reverse' : undefined,

  '@keyframes slide-up': {
    from: { transform: 'translateY(100%)', opacity: 0 },
    to: { transform: 'translateY(0)', opacity: 1 },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    margin: theme.spacing(3, 0, 0),
  },
}));

type Props = {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  status?: AnimountStatus;
};

export const BulkSelector: FC<Props> = ({ left, center, right, status }) => (
  <Container status={status}>
    <div>{left}</div>
    <div>{center}</div>
    <div>{right}</div>
  </Container>
);
