import type { MuiCancellableEvent } from '@mui/base/utils/MuiCancellableEvent';
import { useField } from 'formik';
import {
  type ClipboardEventHandler,
  type FC,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEventHandler,
  type SyntheticEvent,
  useCallback,
} from 'react';
import * as Yup from 'yup';

import { Autocomplete, FormTextField, TextField, UserChip, styled } from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useTrackContributionRequestList } from '../../../../pages/ProjectContributionConfiguration/hooks/useTrackContributionRequestList';

const UserChipsContainer = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export const ContributionRequestListEmailSelect: FC<{
  lists: ContributionRequestList[];
  selectedList: ContributionRequestList;
}> = ({ lists, selectedList }) => {
  const [{ value: emails }, { error }, { setValue, setError }] = useField('emails');
  const track = useTrackContributionRequestList();

  const onChange = useCallback((_event: SyntheticEvent, values: string[]) => setValue(values), [setValue]);
  const onInputChange = useCallback(() => setError(undefined), [setError]);

  const onBlurCapture = useCallback((event: FocusEvent<HTMLInputElement>) => {
    // Validate email on focus lost
    event.target.dispatchEvent(new window.KeyboardEvent('keydown', { key: 'Enter', bubbles: true }));
  }, []);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement> & MuiCancellableEvent) => {
      if (event.key !== 'Enter') return;

      const emailCandidate = event.currentTarget.value;
      const isValidEmail = Yup.string().email().isValidSync(emailCandidate);
      const isDuplicatedEmail = emails.includes(emailCandidate);

      if (!isValidEmail) {
        event.preventDefault();
        event.defaultMuiPrevented = true;
        setError('i18n.dialogs.ContributionRequestListDialog.emailInput.validation.invalidEmail');
      }

      if (isDuplicatedEmail) {
        event.preventDefault();
        event.defaultMuiPrevented = true;
        setError('i18n.dialogs.ContributionRequestListDialog.emailInput.validation.duplicateEmail');
      }

      if (isValidEmail && !isDuplicatedEmail) {
        track('requestListEmailAdded', selectedList);
      }
    },
    [emails, selectedList, setError, track]
  );

  const onInputPaste: ClipboardEventHandler = useCallback(
    async (event) => {
      event.preventDefault();

      const text = await navigator.clipboard.readText();
      const emailCandidates: string[] = text.split(/[ ,]+/g).filter(Boolean);

      setValue(
        emails.concat(
          emailCandidates
            .filter((candidate) => Yup.string().email().isValidSync(candidate))
            .filter((candidate) => !emails.includes(candidate))
        )
      );
    },
    [emails, setValue]
  );

  return (
    <>
      <Autocomplete
        onChange={onChange}
        onBlurCapture={onBlurCapture}
        renderInput={(props) => {
          return (
            <TextField
              {...props}
              inputProps={{
                ...props.inputProps,
                autoComplete: 'email',
                inputMode: 'email',
                onPaste: onInputPaste,
                onKeyDown,
              }}
              onChange={onInputChange}
              variant="outlined"
              label="i18n.dialogs.ContributionRequestListDialog.emailInput.label"
              autoFocus={lists.length === 1}
              error={!!error}
              helperText={error}
            />
          );
        }}
        multiple
        value={emails}
        freeSolo
        filterOptions={(x) => x}
        options={[]}
        renderTags={(value, getTagProps) => {
          return (
            <UserChipsContainer>
              {value.map((option, index) => {
                const { onDelete, ...tagProps } = getTagProps({ index });
                const onDeleteCallback: MouseEventHandler<HTMLButtonElement> = (event) => {
                  onDelete(event);
                  track('requestListEmailRemoved', selectedList);
                };
                return (
                  <UserChip
                    {...tagProps}
                    key={option}
                    onDelete={{ handler: onDeleteCallback, label: 'i18n.common.remove' }}
                  >
                    {option}
                  </UserChip>
                );
              })}
            </UserChipsContainer>
          );
        }}
      />
      <FormTextField
        id="email-message"
        name="message"
        label="i18n.dialogs.ContributionRequestListDialog.emailMessageInput.label"
        multiline
        rows={5}
      />
    </>
  );
};
