import { useCallback } from 'react';
import { type PathParam, generatePath, matchPath, useLocation } from 'react-router-dom';
import { type RouteName, type Routes, routes } from '../../routes';

export const useIsRouteActive = () => {
  const { pathname } = useLocation();
  return useCallback(
    <T extends RouteName>(route: T, params: { [key in PathParam<Routes[T]>]: string | null }) =>
      !!matchPath(generatePath(routes[route], params), pathname),
    [pathname]
  );
};
