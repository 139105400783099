import {
  Chip,
  EmptyState,
  Headline,
  Spacing,
  Typography,
  styled,
  useLocalizedWebsiteUrl,
  useTabNavigation,
} from '@cofenster/web-components';
import type { FC } from 'react';

import { RouterButton } from '../../components/button/RouterButton';
import { FeatureFlagRestriction } from '../../components/featureFlag/FeatureFlagRestriction';
import { UpsellBanner } from '../../components/featureFlag/UpsellBanner';
import type { Project } from '../../contexts/project/useProject';
import { AccountPermissionRestriction } from '../../contexts/user/AccountPermissionRestriction';

import { EmptyMusicCard } from './EmptyMusicCard';
import { MusicCard } from './MusicCard';
import { MusicList } from './MusicList';
import { LazyLoadedMusicPlayback } from './MusicPlayback';

import { useMusics, usePlayer, useProjectMusicDetails } from './hooks';

const StyledHeadline = styled(Headline)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const MusicUpsell = () => {
  const learnMoreUrl = useLocalizedWebsiteUrl('KNOWLEDGE_BASE');

  return (
    <UpsellBanner
      title="i18n.upsellBanner.CUSTOM_MUSICS.title"
      learnMoreUrl={learnMoreUrl}
      featureRelation="CUSTOM_MUSICS"
    >
      i18n.upsellBanner.CUSTOM_MUSICS.content
    </UpsellBanner>
  );
};

const Navigation = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

export const ProjectMusicForm: FC<{ project: Project }> = ({ project }) => {
  const { privateMusics, publicMusics, allMusics } = useMusics();
  const { volume } = useProjectMusicDetails(project);
  const player = usePlayer();
  const { playback, music: playedMusic, onPlaying, onFinishedPlaying } = player;
  const audioUrl = playedMusic?.musicAsset?.audioUrl;

  const { currentTab, getTablistProps, getTabProps, getTabSectionProps } = useTabNavigation(
    ['ALL', 'PRIVATE', 'PUBLIC', 'PUBLIC_DEPRECATED'],
    'ALL'
  );
  const { type: _typeAll, ...tabAllProps } = getTabProps('ALL');
  const { type: _typePrivate, ...tabPrivateProps } = getTabProps('PRIVATE');
  const { type: _typePublic, ...tabPublicProps } = getTabProps('PUBLIC');

  return (
    <>
      {audioUrl && (
        <LazyLoadedMusicPlayback
          volume={volume}
          url={audioUrl}
          playStatus={playback.status}
          position={playback.position}
          // @ts-expect-error: the `onPlaying` prop is incorrectly typed via the
          // `@types/react-sound` package, saying it does not receive any
          // argument when it clearly does, as confirmed in the documentation
          // See: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/64828b61751341bfdb2bd5ba88ef61c1f1f77cb8/types/react-sound/index.d.ts#L15
          // See: https://github.com/leoasis/react-sound?tab=readme-ov-file#props
          onPlaying={onPlaying}
          onFinishedPlaying={onFinishedPlaying}
        />
      )}

      <Spacing bottom={4}>
        <StyledHeadline variant="h4" component="h2">
          i18n.projectMusic.music.headline.current
        </StyledHeadline>
        <Typography component="p" variant="l">
          i18n.projectMusic.music.subline
        </Typography>

        {project.music ? <MusicCard project={project} music={project.music} player={player} /> : <EmptyMusicCard />}
      </Spacing>

      <StyledHeadline variant="h4" component="h2">
        i18n.projectMusic.music.headline.public
      </StyledHeadline>

      <Spacing bottom={2}>
        <Navigation {...getTablistProps()}>
          <Chip clickable color={currentTab === 'ALL' ? 'dark' : 'light'} {...tabAllProps} data-testid="tab-ALL">
            i18n.projectMusic.music.navigation.all
          </Chip>
          <Chip
            clickable
            color={currentTab === 'PRIVATE' ? 'dark' : 'light'}
            {...tabPrivateProps}
            data-testid="tab-PRIVATE"
          >
            i18n.projectMusic.music.navigation.private
          </Chip>
          {publicMusics.length > 0 && (
            <Chip
              clickable
              color={currentTab === 'PUBLIC' ? 'dark' : 'light'}
              {...tabPublicProps}
              data-testid="tab-PUBLIC"
            >
              i18n.projectMusic.music.navigation.public
            </Chip>
          )}
        </Navigation>
      </Spacing>

      <div {...getTabSectionProps('ALL')}>
        <MusicList musics={allMusics} project={project} player={player} />
      </div>

      <div {...getTabSectionProps('PRIVATE')}>
        <FeatureFlagRestriction not="CUSTOM_MUSICS">
          <Spacing top={2}>
            <MusicUpsell />
          </Spacing>
        </FeatureFlagRestriction>

        {
          // We always want to render the private musics if there are any,
          // whether or not the feature flag is enabled. It is possible the
          // account *had* the flag enabled, uploaded musics, then downgraded
          // and they should still be able to access their music.
          privateMusics.length > 0 ? (
            <MusicList musics={privateMusics} project={project} player={player} />
          ) : (
            <FeatureFlagRestriction has="CUSTOM_MUSICS">
              <EmptyState
                iconType="SearchIcon"
                title="i18n.branding.musics.empty.title"
                description="i18n.branding.musics.empty.description"
                data-testid="no-musics"
                cta={
                  <AccountPermissionRestriction has="MusicCreate">
                    <RouterButton to="brandingMusicCreate">i18n.branding.musics.empty.cta</RouterButton>
                  </AccountPermissionRestriction>
                }
              />
            </FeatureFlagRestriction>
          )
        }
      </div>

      {publicMusics.length > 0 && (
        <div {...getTabSectionProps('PUBLIC')}>
          <MusicList musics={publicMusics} project={project} player={player} />
        </div>
      )}
    </>
  );
};
