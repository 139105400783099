import { useDebounceCallback } from '@react-hook/debounce';
import {
  type ChangeEventHandler,
  type FC,
  type FormEventHandler,
  type KeyboardEventHandler,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { styled } from '@mui/material';
import { useBooleanState } from '../../../hooks/state/useBooleanState';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { IconButton } from '../../controls/Button/IconButton';
import { TextField, type TextFieldProps } from '../../controls/TextField';
import { SearchInfo } from './Info';

const STAGE = process.env.STAGE as string;

const IconContainer = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: 2,
}));

const StyledTextField = styled(
  TextField,
  preventForwardProps(['fullWidth'])
)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  paddingBottom: 0,
  width: fullWidth ? '100%' : 220,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.brand.white,
}));

const StyledInput = styled(
  'input',
  preventForwardProps(['isExtended', 'fullWidth'])
)<{ isExtended?: boolean; fullWidth?: boolean }>(({ theme, isExtended, fullWidth }) => ({
  ...theme.typography.l,
  color: theme.palette.brand.carbon,
  height: 44,
  width: fullWidth ? '100%' : isExtended ? 220 : 0,
  paddingLeft: isExtended ? theme.spacing(1.5) : 0,
  paddingRight: 44,
  backgroundColor: isExtended ? theme.palette.brand.white : 'rgb(255 255 255 / 0)',
  borderRadius: theme.shape.borderRadius,
  border: 0,
  outline: 0,
  transition: '250ms ease-in-out',

  '&:focus': {
    outline: `1px solid ${theme.palette.brand.blue}`,
  },
}));

type SearchFieldProps = Omit<TextFieldProps, 'label'> & {
  label: string;
  search: string;
  onSearch: (value: string) => unknown;
  autoFocus?: boolean;
  alwaysExtended?: boolean;
  minLength?: number;
  fullWidth?: boolean;
  'data-testid'?: string;
  onSubmit?: FormEventHandler;
  debounceTime?: number;
  component?: 'div' | 'form';
};

export const SearchField: FC<PropsWithChildren<SearchFieldProps>> = ({
  id,
  label,
  placeholder = label,
  name,
  search,
  onSearch,
  minLength,
  alwaysExtended = false,
  autoFocus = false,
  fullWidth = false,
  InputProps,
  'data-testid': dataTestId,
  onSubmit = (event) => event.preventDefault(),
  children,
  debounceTime = 500,
  component = 'form',
}) => {
  const Container = useMemo(
    () =>
      styled(
        component,
        preventForwardProps(['fullWidth'])
      )<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
        display: 'flex',
        position: 'relative',
        gap: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        width: fullWidth ? '100%' : undefined,
        flex: 1,
      })),
    [component]
  );

  const handleSearch = useCallback((value: string) => onSearch(value), [onSearch]);
  const throttledHandleSearch = useDebounceCallback(handleSearch, debounceTime, false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, onFocus, onBlur] = useBooleanState(autoFocus);
  const [value, setValue] = useState(search || '');
  const isExtended = alwaysExtended || isFocused || !!value;

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const isNotEmpty = (event.target as HTMLInputElement).value.length > 0;
      if (event.key === 'Enter' && isNotEmpty) {
        const heading = document.querySelector(`#search-results-${id}`) as HTMLElement;

        if (heading) {
          heading.focus();
        } else if (STAGE !== 'production') {
          console.warn(
            `The \`SearchField\` component with \`id="${id}"\` could not find a \`SearchResults\` component with \`id="${id}"\`. This is necessary for an accessible searching experience so please make sure to render that component with the right identifier.`
          );
        }
      }
    },
    [id]
  );

  const focusField = useCallback(() => inputRef.current?.focus(), []);

  const clearField = useCallback(() => {
    setValue('');
    handleSearch('');
  }, [handleSearch]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setValue(event.target.value);
      if (minLength && event.target.value.length < minLength) {
        throttledHandleSearch('');
        return;
      }
      throttledHandleSearch(event.target.value);
    },
    [throttledHandleSearch, minLength]
  );

  const FieldComponent = alwaysExtended ? StyledTextField : StyledInput;
  const dynamicProps = alwaysExtended
    ? {
        inputRef,
        label,
        InputProps: {
          ...InputProps,
          endAdornment: value ? (
            <IconButton icon="CloseIcon" iconColor="grey700" onClick={clearField} label="i18n.global.search.clear" />
          ) : null,
        },
      }
    : { ref: inputRef, 'aria-label': label, isExtended };

  return (
    <Container role="search" onSubmit={component === 'form' ? onSubmit : undefined} fullWidth={fullWidth}>
      <FieldComponent
        {...dynamicProps}
        placeholder={placeholder}
        type={component === 'form' ? 'text' : 'search'}
        id={`search-field-${id}`}
        name={name}
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        autoComplete="off"
        aria-describedby={`search-info-${id}`}
        fullWidth={fullWidth}
        data-testid={dataTestId}
      />

      {!alwaysExtended ? (
        <IconContainer>
          <IconButton
            icon={isExtended ? 'CloseIcon' : 'SearchIcon'}
            iconSize="m"
            iconColor="grey700"
            onClick={isExtended ? clearField : focusField}
            label={
              isExtended ? (value ? 'i18n.global.search.clear' : 'i18n.global.search.close') : 'i18n.global.search.open'
            }
          />
        </IconContainer>
      ) : null}

      <SearchInfo id={`search-info-${id}`} />

      {children}
    </Container>
  );
};
