import { type FC, useEffect } from 'react';

import { LoadingSpinner, styled } from '@cofenster/web-components';

const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80%',
}));

export type LoadingProps = {
  onMount?: VoidFunction;
};

export const Loading: FC<LoadingProps> = ({ onMount }) => {
  useEffect(() => {
    onMount?.();
  }, [onMount]);
  return (
    <Container>
      <LoadingSpinner />
    </Container>
  );
};
