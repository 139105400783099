import { useContext } from 'react';

export type { AccountTheme } from '../../api/hooks/user/useMe';
export type { User } from './UserContext';

import { type User, UserContext } from './UserContext';

export type AccountMember = User['account']['users'][number];

export const useUser = () => useContext(UserContext);
