import { useMemo } from 'react';

import { useUpdateContributionRequestList } from '../../../api/hooks/contributionRequestList/useUpdateContributionRequestList';

export const useDeleteVideoBriefing = (contributionRequestListId: string) => {
  const { updateContributionRequestList, loading, error } = useUpdateContributionRequestList();
  return useMemo(
    () => ({
      deleteVideoBriefing: () =>
        updateContributionRequestList(contributionRequestListId, {
          briefingVideoAssetId: null,
        }),
      loading,
      error,
    }),
    [contributionRequestListId, updateContributionRequestList, loading, error]
  );
};
