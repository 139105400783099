import { useField } from 'formik';
import type { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react';

import { HiddenInput, Typography, styled } from '@cofenster/web-components';

type SelectableBoxInputProps = PropsWithChildren<
  Omit<ComponentProps<'input'>, 'title' | 'type' | 'checked'> & {
    title: string | ReactElement;
    name: string;
  }
>;

const Label = styled('label')(() => ({
  display: 'block',
  position: 'relative',
}));

const ContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey200}`,
  transition: '250ms border-color',
}));

export const SubtitleSafeZoneBoxInput: FC<PropsWithChildren<SelectableBoxInputProps>> = ({
  id,
  name,
  value,
  title,
  children,
  ...rest
}) => {
  const [field] = useField({ name, value, type: 'radio' });

  return (
    <Label htmlFor={id}>
      <HiddenInput id={id} {...field} {...rest} type="radio" />
      <ContentContainer>
        {children}
        <Typography>{title}</Typography>
      </ContentContainer>
    </Label>
  );
};
