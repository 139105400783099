import type { FC, PropsWithChildren } from 'react';

import { Icon, SpinningIcon, useUpload } from '@cofenster/web-components';

import { usePollingImageAsset } from '../../../../../../api/hooks/imageAsset/usePollingImageAsset';
import type { SceneImage } from '../../../../../../api/hooks/scene/useScenes';
import type { Scene } from '../../../../../../contexts/scenes/useScenes';
import { useIsLastIncludedScene } from '../../hooks/useIsLastIncludedScene';

import { SceneTypeIcon, Thumbnail } from './Thumbnail';

type ImageThumbnailProps = PropsWithChildren<{
  scene: Scene;
  sceneImage?: SceneImage | null;
}>;

export const ImageThumbnail: FC<ImageThumbnailProps> = ({ children, scene, sceneImage }) => {
  const imageAsset = usePollingImageAsset(sceneImage?.imageAsset?.id || undefined) ?? sceneImage?.imageAsset;

  const { getUpload } = useUpload();
  const uploadProgress = getUpload('image', scene.id)?.progress;
  const textElementsCount = scene.sceneTexts.length;
  const isLastIncludedScene = useIsLastIncludedScene()(scene.id);

  if (!imageAsset?.status && typeof uploadProgress === 'number') {
    return (
      <Thumbnail
        hint={<Icon type="CloudUploadIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  if (imageAsset?.status === 'Waiting') {
    return (
      <Thumbnail
        hint={<SpinningIcon type="GearIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  if (imageAsset?.status === 'Error') {
    return (
      <Thumbnail
        hint={<Icon type="CloudErrorIcon" size="ms" />}
        hasTransition={scene.withTransition}
        isLastIncludedScene={isLastIncludedScene}
        textElementsCount={textElementsCount}
      >
        {children}
      </Thumbnail>
    );
  }

  return (
    <Thumbnail
      hint={<SceneTypeIcon title="i18n.sceneTypes.image" iconType="ImageIcon" />}
      hasTransition={scene.withTransition}
      isLastIncludedScene={isLastIncludedScene}
      textElementsCount={textElementsCount}
      image={imageAsset?.imageUrl}
      isExcludedScene={scene.excluded}
      data-scene-type="image"
    >
      {children}
    </Thumbnail>
  );
};
