import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const useGotoHomeHandler = () => {
  const goto = useGoto();
  return useCallback(() => {
    goto(routes.home);
  }, [goto]);
};
