import { useCallback } from 'react';

import { type UpdateSceneImageMutationVariables, useUpdateSceneImageMutation } from '../../generated';

export const useUpdateSceneImage = () => {
  const [updateSceneImage] = useUpdateSceneImageMutation({
    refetchQueries: ['Scenes', 'ProjectRenderDescription', 'SceneRenderDescription'],
  });
  return useCallback(
    (id: UpdateSceneImageMutationVariables['id'], input: UpdateSceneImageMutationVariables['input']) =>
      updateSceneImage({ variables: { id, input } }),
    [updateSceneImage]
  );
};
