import { type FC, type PropsWithChildren, createContext, useContext, useMemo } from 'react';

import type { MaybeSubtitlesLanguage } from '../../api/hooks/renderDescription/types';

type SubtitlesLanguageContextType = {
  subtitlesLanguage: MaybeSubtitlesLanguage;
};

const SubtitlesLanguageContext = createContext<SubtitlesLanguageContextType>({
  subtitlesLanguage: null,
});

export const SubtitlesContextProvider: FC<PropsWithChildren<{ subtitlesLanguage: string | undefined }>> = ({
  children,
  subtitlesLanguage,
}) => {
  const value = useMemo(() => ({ subtitlesLanguage: subtitlesLanguage ?? null }), [subtitlesLanguage]);

  return <SubtitlesLanguageContext.Provider value={value}>{children}</SubtitlesLanguageContext.Provider>;
};

export const useSubtitlesLanguage = () => {
  const context = useContext(SubtitlesLanguageContext);
  if (context === undefined) {
    throw new Error('useSubtitlesLanguage must be used within a SubtitleContextProvider');
  }
  return context;
};
