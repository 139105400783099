import { useCallback } from 'react';
import { parseSync, stringifySync } from 'subtitle';

import { useProjectRenderDescriptionLazyQuery } from '../../api/generated';
import { subtitlesToSrt } from '../../pages/ProjectSceneSubtitleEdit/ProjectSceneSubtitleEditDialog/SRTEditor/utils';
import { useWebManagerTracking } from '../useWebManagerTracking';

import { useSubtitleDetails } from './useSubtitleDetails';

const downloadFileFromText = (file: string, name: string) => {
  const blob = new Blob([file], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const useDownloadSubtitle = (subtitle: {
  id: string;
  srt?: string | undefined | null;
  subtitleType: 'project' | 'scene' | 'Transcription' | 'Translation';
  sceneId?: string;
}) => {
  const tracking = useWebManagerTracking();
  const subtitleDetails = useSubtitleDetails(subtitle);

  return useCallback(
    (format: 'SRT' | 'VTT') => {
      tracking.trackEvent({
        event: 'subtitleDownload',
        details: {
          fileType: format,
          ...subtitleDetails,
        },
      });

      if (format === 'SRT') {
        return downloadFileFromText(subtitle.srt ?? '', `${subtitle.id}.srt`);
      }
      const vtt = stringifySync(parseSync(subtitle.srt ?? ''), { format: 'WebVTT' });
      downloadFileFromText(vtt, `${subtitle.id}.vtt`);
    },
    [subtitle, tracking, subtitleDetails]
  );
};

export const useDownloadSceneSubtitleForTheProject = (projectId: string, language: string, name: string) => {
  const tracking = useWebManagerTracking();
  const [getProjectRenderDescription] = useProjectRenderDescriptionLazyQuery({
    variables: { projectId, subtitlesLanguage: language },
  });

  return useCallback(
    async (format: 'SRT' | 'VTT') => {
      const { data } = await getProjectRenderDescription();
      const subtitleNodes = data?.projectRenderDescription?.subtitles;
      if (!subtitleNodes) return;

      const srt = subtitlesToSrt(subtitleNodes);
      if (!srt) return;

      tracking.trackEvent({
        event: 'subtitleDownload',
        details: {
          projectId,
          language,
          subtitleType: 'project',
          subtitleId: name,
          fileType: format,
        },
      });

      if (format === 'SRT') {
        return downloadFileFromText(srt, `${name}.srt`);
      }
      const vtt = stringifySync(parseSync(srt), { format: 'WebVTT' });
      downloadFileFromText(vtt, `${name}.vtt`);
    },
    [getProjectRenderDescription, tracking, projectId, language, name]
  );
};
