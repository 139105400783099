import type { FC } from 'react';

import { Icon, VisuallyHidden, preventForwardProps, styled } from '@cofenster/web-components';

// 1. Uses half the height of the *thumbnail* instead of `50%`, as the parent
//    could be taller in the storyboard view, depending on the content of the
//    card.
const Handle = styled(
  'div',
  preventForwardProps(['centerVertically'])
)<{ centerVertically?: boolean }>(({ theme, centerVertically }) => ({
  cursor: 'move',
  position: 'absolute',
  zIndex: theme.zIndex.above,
  top: centerVertically ? '50%' : 80, // 1
  right: '100%',
  marginRight: theme.spacing(1),
  transform: 'translateY(-50%)',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 250ms',

  svg: { display: 'block' },
  '&:hover': { backgroundColor: theme.palette.brand.linen50 },
  '&:focus-visible': theme.mixins.focusRing,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },

  [theme.breakpoints.down(1480)]: {
    backgroundColor: theme.palette.brand.linen,
    border: `1px solid ${theme.palette.brand.linen50}`,
    marginRight: 0,
    left: 0,
    right: 'auto',
    transform: 'translate(-50%, -50%)',
  },
}));

type CardDragHandleProps = {
  centerVertically?: boolean;
};

export const CardDragHandle: FC<CardDragHandleProps> = (props) => (
  <Handle {...props} data-testid="scene-drag-handle" tabIndex={0} role="button">
    <Icon type="DragHandleIcon" />
    <VisuallyHidden>i18n.global.drag.hint</VisuallyHidden>
  </Handle>
);
