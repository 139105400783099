import { type FC, useMemo } from 'react';

import { RenderingProgress } from '@cofenster/web-components';

import type { Project, RenderJob } from '../../../contexts/project/useProject';
import type { RenderJobStatus } from '../../../hooks/project/useProjectExportStatus';

import { useSubtitlesLanguage } from '../SubtitlesLanguageContext';
import { NotYetRendered } from './NotYetRendered';
import { RenderJobFailed } from './RenderJobFailed';
import { RenderedVideo } from './RenderedVideo';
import { StartRenderButton } from './StartRenderButton';

export const RENDER_JOB_POLLING_INTERVAL = 5000;

export const RenderProgress: FC<{
  project: Project;
  renderJob: RenderJob | undefined;
  status: RenderJobStatus;
}> = ({ project, renderJob, status }) => {
  const { subtitlesLanguage } = useSubtitlesLanguage();

  const cta = useMemo(
    () => (
      <StartRenderButton projectId={project.id} subtitlesLanguage={subtitlesLanguage} variant="secondary">
        {status === 'ERROR' ? 'i18n.global.renderingProgress.restart' : 'i18n.global.renderingProgress.start'}
      </StartRenderButton>
    ),
    [project.id, subtitlesLanguage, status]
  );

  if (status === 'NOT_RENDERED' || !renderJob) return <NotYetRendered cta={cta} />;
  if (status === 'ERROR') return <RenderJobFailed cta={cta} />;
  if (status === 'RENDERING')
    return <RenderingProgress status={renderJob.status} step={renderJob.step} stepProgress={renderJob.stepProgress} />;
  if (status === 'RENDERED') return <RenderedVideo project={project} renderJob={renderJob} />;
  return null;
};
