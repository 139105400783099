import type { FC } from 'react';

import { VisuallyHidden } from '../../utilities/VisuallyHidden';

export const SearchEmpty: FC<{ search?: string; count: number }> = ({ search, count }) => (
  <div aria-live="assertive" aria-atomic="true">
    {count === 0 && search ? (
      <VisuallyHidden i18nParams={{ search }}>i18n.global.search.results.empty</VisuallyHidden>
    ) : null}
  </div>
);
