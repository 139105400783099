export const useSubtitleDetails = (
  subtitle: {
    id: string;
    subtitleType: 'project' | 'scene' | 'Transcription' | 'Translation';
    sceneId?: string;
  } & Record<string, unknown>
) => {
  return {
    subtitleId: subtitle.id,
    subtitleType: subtitle.subtitleType,
    sceneId: subtitle.sceneId,
  };
};
