import { useMemo } from 'react';

import type { Project as UseProjectsProject } from '../../api/hooks/project/useProjects';
import { type Project as UseProjectProject, useProject } from '../../contexts/project/useProject';
import { useScenes } from '../../contexts/scenes/useScenes';
import {
  isAudioSceneAsset,
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../helpers/sceneAssets/is';

type Project = UseProjectProject | UseProjectsProject;

export const useProjectDetails = (_project?: Project) => {
  const { scenes, loading: scenesLoading } = useScenes();
  const { project } = useProject();
  const trackingProject = _project || project;

  return useMemo(() => {
    if (!trackingProject) return {};

    const sceneCount = !scenesLoading
      ? scenes.reduce(
          (acc, scene) => {
            if (scene.excluded) {
              acc.excluded += 1;
              return acc;
            }

            acc.textElementsCount += scene.sceneTexts.length;

            if (scene.withTransition) {
              acc.transition += 1;
            }

            const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);
            if (mainSceneAsset) {
              if (isVideoSceneAsset(mainSceneAsset)) {
                if (scene.type === 'screenRecording') {
                  acc.screenRecording += 1;
                } else {
                  acc.video += 1;
                }
                if (mainSceneAsset.asset?.volume === 0) acc.muted === 1;
              }
              if (isImageSceneAsset(mainSceneAsset)) acc.image += 1;
              if (isColorSceneAsset(mainSceneAsset)) acc.color += 1;
            }

            const audioSceneAsset = scene.sceneAssets.find(isAudioSceneAsset);
            if (audioSceneAsset) acc.image += 1;

            return acc;
          },
          {
            video: 0,
            image: 0,
            color: 0,
            screenRecording: 0,
            textElementsCount: 0,
            muted: 0,
            audio: 0,
            excluded: 0,
            transition: 0,
          }
        )
      : undefined;

    const isDetailedProject = (project: UseProjectProject | UseProjectsProject): project is UseProjectProject => {
      return 'theme' in project;
    };

    const detailedProperties = isDetailedProject(trackingProject)
      ? {
          withIntro:
            trackingProject.withIntro &&
            trackingProject.theme?.themeVideoAssets?.some((it) => it.videoType === 'Intro'),
          withOutro:
            trackingProject.withOutro &&
            trackingProject.theme?.themeVideoAssets?.some((it) => it.videoType === 'Outro'),
          withLogo: trackingProject.withLogo && !!trackingProject.theme?.logoAsset?.imageUrl,
          withMusic: !!trackingProject.music,
          musicTitle: trackingProject.music?.name,
          designBackground: trackingProject.theme?.background,
          videoFormat: trackingProject.videoFormat,
          teamId: trackingProject.projectFolders[0]?.team?.id,
          teamName: trackingProject.projectFolders[0]?.team?.name,
          teamType: trackingProject.projectFolders[0]?.team?.public ? 'public' : 'private',
          folderName: trackingProject.projectFolders?.[0]?.name,
          folderType: trackingProject.projectFolders?.[0]?.team ? 'team' : 'private',
        }
      : {};

    return {
      projectId: trackingProject.id,
      projectName: trackingProject.name,
      loudness: trackingProject.musicLoudness,
      sceneCount: trackingProject.sceneCount,
      folderId: trackingProject.projectFolders[0]?.id,

      excludedScenesCount: sceneCount?.excluded,
      imageScenesCount: sceneCount?.image,
      videoScenesCount: sceneCount?.video,
      colorScenesCount: sceneCount?.color,
      screenRecordingScenesCount: sceneCount?.screenRecording,
      mutedSceneCount: sceneCount?.muted,
      textElementsCount: sceneCount?.textElementsCount,
      transitionsCount: sceneCount?.transition,

      ...detailedProperties,
    };
  }, [trackingProject, scenes, scenesLoading]);
};
