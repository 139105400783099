import { ListSubheader, type ListSubheaderProps, styled } from '@mui/material';
import type { FC } from 'react';

import { type I18nParams, Translatable } from '../../../services/i18n';

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.brand.grey100,
  ...theme.typography.h6,
  color: theme.palette.brand.carbon,
  userSelect: 'none',
}));

export type SelectHeaderProps = Omit<ListSubheaderProps, 'children'> & {
  label: string;
  i18nParams?: I18nParams;
};

export const SelectHeader: FC<SelectHeaderProps> & { muiSkipListHighlight: boolean } = ({
  i18nParams,
  label,
  ...rest
}) => {
  return (
    <StyledListSubheader {...rest}>
      <Translatable i18nParams={i18nParams}>{label}</Translatable>
    </StyledListSubheader>
  );
};

// https://mui.com/material-ui/react-select/#grouping
SelectHeader.muiSkipListHighlight = true;
