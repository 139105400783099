import { type FC, useMemo } from 'react';

import { BlankButton, Box, Circle, DropzoneContent, Icon, styled, withPopover } from '@cofenster/web-components';

import type { Scene } from '../../../../api/hooks/scene/useScenes';
import { useProject } from '../../../../contexts/project/useProject';
import { useUpdateColor } from '../../hooks';

import { isColorSceneAsset, isMainSceneAsset } from '../../../../helpers/sceneAssets/is';
import { ColorPicker } from './ColorPicker';

const StyledCircle = styled(Circle)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.brand.carbon_alpha50 : theme.palette.brand.blue,
  transition: 'transform 150ms ease-out',
  marginBottom: theme.spacing(1.5),
  backgroundColor: theme.palette.toRgba(disabled ? theme.palette.brand.grey400 : theme.palette.brand.blue400, 0.2),
}));

const InteractiveBox = styled(Box.withComponent(BlankButton))(({ theme }) => ({
  width: '100%',
  color: theme.palette.brand.grey600,
  border: '1px solid transparent',
  transition: 'border-color 150ms ease-in-out',

  '&:disabled p': {
    color: theme.palette.brand.grey600,
  },

  '&:not(:disabled):hover': {
    borderColor: theme.palette.brand.carbon,
  },

  '&:focus-visible': theme.mixins.focusRing,

  '&:not(:disabled):hover .Circle, &:focus .Circle': {
    transform: 'scale(1.1)',
  },
}));

export const SceneColorNoAsset: FC<{ scene: Scene; disabled?: boolean }> = ({ scene, disabled }) => {
  const { project } = useProject();
  const primary = project?.theme?.colorPrimary ?? '#000000';
  const secondary = project?.theme?.colorSecondary ?? '#ffffff';

  const colorSceneAsset = scene.sceneAssets.filter(isMainSceneAsset).find(isColorSceneAsset);

  const onColorUpdate = useUpdateColor(scene.id, colorSceneAsset);
  const ColorPickerToggle = useMemo(
    () =>
      withPopover(InteractiveBox, {
        anchorOriginX: 'center',
        anchorOriginY: 'center',
        transformOriginX: 'center',
        transformOriginY: 'center',
        children: <ColorPicker color={undefined} onChange={onColorUpdate} primary={primary} secondary={secondary} />,
      }),
    [onColorUpdate, primary, secondary]
  );

  return (
    <ColorPickerToggle disabled={disabled} fullHeight data-testid="scene-color-upload">
      <DropzoneContent
        icon={
          <StyledCircle disabled={disabled} className="Circle" size="xxl" color={disabled ? 'carbon' : 'blue'}>
            <Icon size="l" type="EyedropperIcon" />
          </StyledCircle>
        }
        text="i18n.projectEdit.SceneColorNoAsset.text"
        size="medium"
      />
    </ColorPickerToggle>
  );
};
