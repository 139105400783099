import type { FC } from 'react';

import type { ThemeVideoType } from '../../../../api/hooks/user/useMe';
import type { Project } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { useMoveSceneKeyboardHandler } from '../../hooks/useMoveSceneKeyboardHandler';
import { toThemeVideoType } from '../EditorTools';

import { EmptyState } from '@cofenster/web-components';
import { useTeamPermissionStatus } from '../../../../contexts/user/TeamPermissionRestriction';
import { SceneColorNoAsset } from './SceneColorNoAsset';
import { SceneImageNoAsset } from './SceneImageNoAsset';
import { SceneIntroOutroNoAsset } from './SceneIntroOutroNoAsset';
import { SceneVideoNoAsset } from './SceneVideoNoAsset';

export const EditorUpload: FC<{ project: Project; themeVideoType?: Lowercase<ThemeVideoType> }> = ({
  project,
  themeVideoType,
}) => {
  const { currentScene } = useScenes();

  useMoveSceneKeyboardHandler();

  const SceneUpload = () => {
    const isAllowedToUpload = useTeamPermissionStatus({ has: 'SceneUpload' }) === 'ALLOWED';
    const disabled = Boolean(project.archivedAt);

    if (!isAllowedToUpload) {
      return (
        <EmptyState iconType="NoOptionIcon" title="i18n.sceneVideo.empty" description="i18n.sceneVideo.empty.subline" />
      );
    }

    if (themeVideoType) {
      return (
        <SceneIntroOutroNoAsset
          project={project}
          disabled={Boolean(project.archivedAt)}
          themeVideoType={toThemeVideoType(themeVideoType)}
        />
      );
    }

    switch (currentScene?.type) {
      case 'video':
        return <SceneVideoNoAsset scene={currentScene} disabled={disabled} />;
      case 'screenRecording':
        return <SceneVideoNoAsset scene={currentScene} disabled={disabled} />;
      case 'image':
        return <SceneImageNoAsset scene={currentScene} disabled={disabled} />;
      case 'color':
        return <SceneColorNoAsset scene={currentScene} disabled={disabled} />;
      default:
        return null;
    }
  };

  return (
    <ResponsiveContainer project={project} enforceAspectRatioOnSmallScreens={false}>
      <SceneUpload />
    </ResponsiveContainer>
  );
};
