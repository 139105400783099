import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text } from '@cofenster/web-components';

import { useUpdateTeam } from '../../../api/hooks/team/useUpdateTeam';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

const useSubmit = (closeDialog: () => unknown, creatorId: string, id: string) => {
  const updateTeam = useUpdateTeam();
  const tracking = useWebManagerTracking();
  const trackTeamSpaceOwnershipTransfered = useCallback(
    (data: { id: string; name: string; public: boolean }) =>
      tracking.trackEvent({
        event: 'teamSpaceOwnershipTransfered',
        details: {
          teamId: data.id,
          teamName: data.name,
          teamType: data.public ? 'public' : 'private',
          newOwnerId: creatorId,
        },
      }),
    [tracking, creatorId]
  );
  const update = useCallback(() => updateTeam({ creatorId }, id), [updateTeam, creatorId, id]);
  return useCallback(async () => {
    const result = await update();

    if (result.data?.updateTeam.__typename === 'Team') {
      trackTeamSpaceOwnershipTransfered(result.data.updateTeam);
    }

    closeDialog();
  }, [update, trackTeamSpaceOwnershipTransfered, closeDialog]);
};

export type TransferTeamOwnershipProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  creatorId: string;
  teamId: string;
  newOwnerName: string;
};

export const TransferTeamOwnershipDialog: FC<TransferTeamOwnershipProps> = ({
  isOpen,
  closeDialog,
  creatorId,
  teamId,
  newOwnerName,
}) => {
  const onSubmit = useSubmit(closeDialog, creatorId, teamId);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.dialogs.team.owner.title">
      <DialogContent>
        <Text variant="l" color="grey600" i18nParams={{ newOwner: <strong>{newOwnerName}</strong> }} component="p">
          i18n.dialogs.team.owner.description
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          i18n.global.cancel
        </Button>
        <Button fullWidth onClick={onSubmit}>
          i18n.dialogs.team.owner.confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
