import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import { routes } from '../../routes';
import { SubNavigationItem } from '../button/SubNavigationItem';

type Props = {
  projectId: string;
};

export const ProjectEditSubNavigation: FC<Props> = ({ projectId }) => {
  const editActive = !!useMatch(routes.projectEditScene);
  const rootEditActive = !!useMatch(routes.projectEdit);
  const designActive = !!useMatch(routes.projectDesign);
  const musicActive = !!useMatch(routes.projectMusic);

  return (
    <SubNavigation>
      <TeamPermissionRestriction has="SceneUpload">
        <SubNavigationItem
          to="projectEdit"
          params={{ projectId }}
          active={editActive || rootEditActive}
          iconType="MovieIcon"
          data-testid="subnav-project-edit"
        >
          i18n.subNavigation.edit
        </SubNavigationItem>
      </TeamPermissionRestriction>
      <TeamPermissionRestriction has="ProjectUpdate">
        <SubNavigationItem to="projectDesign" params={{ projectId }} active={designActive} iconType="PaletteIcon">
          i18n.subNavigation.design
        </SubNavigationItem>
      </TeamPermissionRestriction>
      <TeamPermissionRestriction has="ProjectUpdate">
        <SubNavigationItem
          to="projectMusic"
          params={{ projectId }}
          active={musicActive}
          iconType="MusicNoteIcon"
          data-testid="subnav-project-music"
        >
          i18n.subNavigation.music
        </SubNavigationItem>
      </TeamPermissionRestriction>
    </SubNavigation>
  );
};
