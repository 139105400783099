import { useCallback, useMemo } from 'react';

import { useDeleteProjectFolders } from '../../api/hooks/projectFolder/useDeleteProjectFolders';
import type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolders';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { useWebManagerTracking } from '../useWebManagerTracking';

export const useDeleteFolderWithConfirmation = (
  projectFolder: Pick<ProjectFolder, 'projectCount' | 'team' | 'id'>,
  source: 'folderCard' | 'folderDetail',
  onDeleted?: VoidFunction
) => {
  const { openDialog } = useDialogs();
  const deleteProjectFolders = useDeleteProjectFolders();
  const tracking = useWebManagerTracking(projectFolder.team?.id);
  const folderIds = useMemo(() => [projectFolder.id], [projectFolder.id]);

  const trackDeletion = useCallback(
    () => tracking.trackEvent({ event: 'folderDeleted', details: { folderIds, source } }),
    [tracking, folderIds, source]
  );

  const handleDelete = useCallback(async () => {
    await deleteProjectFolders(folderIds);
    trackDeletion();
    onDeleted?.();
  }, [deleteProjectFolders, folderIds, trackDeletion, onDeleted]);

  return useCallback(async () => {
    if (projectFolder.projectCount === 0) {
      await handleDelete();
    } else {
      openDialog('DeleteProjectFolderDialog', { deleteProjectFolders: handleDelete });
    }
  }, [projectFolder.projectCount, handleDelete, openDialog]);
};
