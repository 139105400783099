import { type ComponentProps, type FC, type MouseEventHandler, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { MainNavigation, generatePath, useGoto } from '@cofenster/web-components';

import { routes } from '../../../../../routes';
import type { RouterLinkProps } from '../../../../button/RouterLink';

export type MainNavigationRouterLinkProps = ComponentProps<typeof MainNavigation.Link> &
  RouterLinkProps & { matchPaths?: string[] };

export const MainNavigationRouterLink: FC<MainNavigationRouterLinkProps> = ({
  to,
  params,
  matchPaths = [routes[to]],
  isSelected: isExplicitlySelected,
  ...rest
}) => {
  const { pathname } = useLocation();
  const goto = useGoto();
  const routePath = routes[to];
  const href = generatePath(routePath, params);
  const isSelected = isExplicitlySelected ?? matchPaths.filter(Boolean).some((match) => matchPath(match, pathname));

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      goto(routePath, params);
    },
    [goto, routePath, params]
  );

  return <MainNavigation.Link {...rest} href={href} isSelected={isSelected} onClick={handleClick} />;
};
