import { Drawer as MuiDrawer, type DrawerProps as MuiDrawerProps, styled } from '@mui/material';
import type { ElementType, FC, PropsWithChildren, ReactNode } from 'react';

import { SidePanelHeaderContent } from './SidePanelHeaderContent';

export type DrawerProps = Pick<MuiDrawerProps, 'anchor' | 'children' | 'hideBackdrop' | 'onClose' | 'style'> & {
  isOpen: MuiDrawerProps['open'];
  close: MuiDrawerProps['onClose'];
};

const Drawer: FC<DrawerProps> = ({ isOpen, close, ...props }) => (
  <MuiDrawer
    {...props}
    open={isOpen}
    onClose={close}
    slotProps={{ backdrop: { invisible: true } }}
    elevation={1}
    sx={(theme) => ({
      [theme.breakpoints.up('md')]: {
        '.MuiPaper-root': {
          top: `calc(var(--drawer-top-offset, 0px) + ${theme.spacing(2)})`,
          bottom: `calc(var(--drawer-bottom-offset, 0px) + ${theme.spacing(2)})`,
          right: `calc(var(--drawer-right-offset, 0px) + ${theme.spacing(2)})`,
          borderRadius: theme.spacing(2),
          height: 'auto',
        },
      },
    })}
  />
);

// 1. Arbitrary width for side panels; could be more, could be less. It needs to be kept in sync with the Intercom
//    adjusted styles in src/theming/ThemeProvider, to make sure the widget does not overlap an open panel.
// 2. Occupy the entire height of the drawer, which is a flex container.
// 3. Flex layout to make it possible for the footer to be anchored at the bottom of the panel.
// 4. Inherit the background color of the drawer, so the footer can inherit it as well, which is important as it is
//    a sticky element floating on top of the scrollable content.
const SidePanelContainer = styled('div')(() => ({
  width: 'min(480px, 100vw)', // 1
  flex: '1 1 100%', // 2
  display: 'flex', // 3
  flexDirection: 'column', // 3
  backgroundColor: 'inherit', // 4
}));

const SidePanelHeader = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.brand.grey50}`,
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

// 1. Occupy as much height as possible within the panel.
const SidePanelContent = styled('div')(({ theme }) => ({
  flexGrow: 1, // 1
  padding: theme.spacing(4),
}));

// 1. Place the footer at the bottom of the drawer when there is not enough content to fill the entire height.
// 2. Make the footer stick to the bottom of the drawer when the content is scrollable.
// 3. Make sure the content cannot be seen beneath the footer by giving it an opaque background color.
const SidePanelFooter = styled('div')(({ theme }) => ({
  marginTop: 'auto', // 1
  position: 'sticky', // 2
  zIndex: theme.zIndex.above,
  bottom: 0, // 2
  backgroundColor: 'inherit', // 3
  borderTop: `1px solid ${theme.palette.brand.grey50}`,
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export type SidePanelProps = DrawerProps & {
  headline: string;
  as?: ElementType;
  footer?: ReactNode;
  preventCloseShortcuts?: boolean;
  'data-testid'?: string;
};

export const SidePanel: FC<PropsWithChildren<SidePanelProps>> = ({
  // Container props
  as = 'div',
  // Header props
  headline,
  // Content props
  children,
  // Footer props
  footer,

  // Drawer props
  anchor = 'right',
  close,
  preventCloseShortcuts,
  ...rest
}) => {
  return (
    <Drawer anchor={anchor} close={preventCloseShortcuts ? undefined : close} {...rest}>
      <SidePanelContainer as={as}>
        <SidePanelHeader>
          <SidePanelHeaderContent headline={headline} close={close} />
        </SidePanelHeader>
        <SidePanelContent>{children}</SidePanelContent>
        {footer && <SidePanelFooter>{footer}</SidePanelFooter>}
      </SidePanelContainer>
    </Drawer>
  );
};
