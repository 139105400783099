import { useCallback } from 'react';

import { useProject } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { useUser } from '../../../../contexts/user/useUser';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

export const useSceneEditorNotesTracking = () => {
  const { currentScene } = useScenes();
  const { project } = useProject();
  const { user } = useUser();
  const { trackEvent } = useWebManagerTracking();

  return useCallback(
    (event: 'sceneNotesOpened' | 'sceneNotesClosed') => {
      trackEvent({
        event,
        details: {
          sceneId: currentScene?.id,
          projectId: project?.id,
          accountId: user?.account?.id,
        },
      });
    },
    [currentScene, project, user, trackEvent]
  );
};
