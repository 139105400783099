import { type FC, Fragment } from 'react';

import {
  Icon,
  Markdown,
  MarkdownComponents,
  type ReactMarkdownComponents,
  TextLink,
  Translatable,
  Typography,
  styled,
  useI18n,
} from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'start',
}));

const SupportList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  padding: 0,
  marginTop: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}));

const SupportItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const Code = styled('span')(({ theme }) => ({
  display: 'inline-block',
  padding: theme.spacing(0.5, 0.9),
  backgroundColor: theme.palette.brand.grey100,
  borderRadius: 4,
  lineHeight: 1,
}));

const ExampleList = styled(MarkdownComponents.Ul)(({ theme }) => ({
  display: 'inline-block',
  paddingLeft: theme.spacing(0.5),
  listStylePosition: 'inside',
}));

type Translate = (key: string) => string;
const SUPPORT_MAP = [
  (translate: Translate) => `**${translate('markdownTooltip.example.bold')}**`,
  (translate: Translate) => `_${translate('markdownTooltip.example.italics')}_`,
  (translate: Translate) => `- ${translate('markdownTooltip.example.unorderedList')}`,
  (translate: Translate) => `1. ${translate('markdownTooltip.example.orderedList')}`,
];

const Ul = () => (
  <ExampleList key="markup">
    <li>
      <Translatable>i18n.markdownTooltip.example.unorderedList</Translatable>
    </li>
  </ExampleList>
);

const Ol = () => (
  <ExampleList as="ol" key="markup">
    <li>
      <Translatable>i18n.markdownTooltip.example.orderedList</Translatable>
    </li>
  </ExampleList>
);

const MARKDOWN_COMPONENTS: ReactMarkdownComponents = {
  ol: Ol,
  ul: Ul,
  // Avoid rendering paragraph since we're just showcasing inline-markdown
  // inside a list.
  p: Fragment as ReactMarkdownComponents['p'],
};

export const MarkdownTooltip: FC = () => {
  const { translate } = useI18n();

  return (
    <Container data-testid="markdown-tooltip">
      <Typography variant="h4" component="p" paragraph>
        i18n.markdownTooltip.title
      </Typography>
      <Typography variant="m" component="p">
        i18n.markdownTooltip.text
      </Typography>
      <TextLink
        href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
        target="_blank"
        rel="noopener noreferrer"
        underline
      >
        i18n.markdownTooltip.link
      </TextLink>
      <SupportList>
        {SUPPORT_MAP.map((key) => {
          const translated = key(translate);
          return (
            <SupportItem key={translated}>
              <Code>{translated}</Code> <Icon type="ArrowRightIcon" size="xs" />{' '}
              <Markdown components={MARKDOWN_COMPONENTS}>{translated}</Markdown>
            </SupportItem>
          );
        })}
      </SupportList>
    </Container>
  );
};
