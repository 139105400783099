import { styled } from '@mui/material';
import { type FC, useCallback, useMemo } from 'react';
import {
  type GetHandleProps,
  type GetTrackProps,
  Handles,
  Rail,
  Slider,
  type SliderItem,
  Tracks,
} from 'react-compound-slider';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import { BlankButton } from '../../../controls/Button/BlankButton';

const VERTICAL_WIDTH = 5;
const VERTICAL_HEIGHT = 60;

const verticalSliderStyle = {
  position: 'absolute',
  width: VERTICAL_WIDTH,
  height: VERTICAL_HEIGHT,
  left: `calc(50% - ${VERTICAL_WIDTH / 2}px)`,
  top: 18,
};

const horizontalSliderStyle = {
  width: VERTICAL_HEIGHT,
  height: VERTICAL_WIDTH,
};

const InnerRail = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: 5,
  backgroundColor: theme.palette.brand.white,
  opacity: 0.2,
}));

const HandleContainer = styled(
  BlankButton,
  preventForwardProps(['vertical'])
)<{ vertical: boolean }>(({ theme, vertical }) => ({
  top: vertical ? 'calc(var(--percent) * 1% - 7.5px)' : -VERTICAL_WIDTH,
  left: !vertical ? 'calc(var(--percent) * 1% - 7.5px)' : -VERTICAL_WIDTH,
  position: 'absolute',
  zIndex: theme.zIndex.above,
  width: VERTICAL_WIDTH * 3,
  height: VERTICAL_WIDTH * 3,
  textAlign: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.brand.white,
}));

const TrackButton = styled(
  BlankButton,
  preventForwardProps(['vertical'])
)<{ vertical: boolean }>(({ vertical, theme }) => ({
  position: 'absolute',
  zIndex: theme.zIndex.base,
  backgroundColor: 'white',
  borderRadius: 5,
  top: vertical ? 'calc(var(--source-percent) * 1%)' : undefined,
  left: !vertical ? 0 : undefined,
  height: vertical ? 'calc((var(--target-percent) - var(--source-percent)) * 1%)' : VERTICAL_WIDTH,
  width: vertical ? VERTICAL_WIDTH : 'calc(var(--source-percent) * 1%)',
}));

const Track: FC<{
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  vertical: boolean;
}> = ({ source, target, getTrackProps, vertical }) => {
  return (
    <TrackButton
      vertical={vertical}
      style={{
        '--source-percent': source.percent,
        '--target-percent': target.percent,
      }}
      {...getTrackProps()}
    />
  );
};

const Handle: FC<{ handle: SliderItem; getHandleProps: GetHandleProps; vertical: boolean }> = ({
  handle: { id, percent },
  getHandleProps,
  vertical,
}) => {
  return <HandleContainer style={{ '--percent': percent }} vertical={vertical} {...getHandleProps(id)} />;
};

type Props = {
  value: number;
  setValue: (value: number) => void;
  domain?: [number, number];
  vertical?: boolean;
};

export const SoundSlider: FC<Props> = ({ value, setValue, domain = [0, 1], vertical = false }) => {
  const onChange = useCallback(
    (numbers: readonly number[]) => {
      const value = numbers[0];
      if (typeof value === 'number' && !Number.isNaN(value)) {
        setValue(value);
      } else {
        setValue(1);
      }
    },
    [setValue]
  );

  const values = useMemo(() => [value], [value]);

  return (
    <Slider
      reversed={vertical}
      vertical={vertical}
      rootStyle={vertical ? verticalSliderStyle : horizontalSliderStyle}
      domain={domain}
      values={values}
      onChange={onChange}
      onUpdate={onChange}
    >
      <Rail>{({ getRailProps }) => <InnerRail {...getRailProps()} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle) => (
              <Handle vertical={vertical} key={handle.id} handle={handle} getHandleProps={getHandleProps} />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track vertical={vertical} key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
};
