import { useMemo } from 'react';

import {
  type ContributionSettingsQueryResult,
  type ContributionSettingsQueryVariables,
  useContributionSettingsQuery,
} from '../../generated';

export type ContributionSettings = NonNullable<ContributionSettingsQueryResult['data']>['contributionSettings'];

export const useContributionSettings = (projectId: ContributionSettingsQueryVariables['projectId']) => {
  const { loading, error, data } = useContributionSettingsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      projectId,
    },
  });
  const contributionSettings = data?.contributionSettings || undefined;
  return useMemo(() => ({ contributionSettings, loading, error }), [contributionSettings, loading, error]);
};
