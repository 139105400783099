import { useCallback } from 'react';

import type { ContributionRequestAssetType } from '../../../api/generated';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

type ContributionRequestTrackingData = {
  id: string;
  type?: ContributionRequestAssetType;
  showOthersContributions?: boolean;
  title?: string | null;
  instruction?: string | null;
  imageAssets?: unknown[];
  videoAssets?: unknown[];
};

export const useTrackContributionRequest = (eventName: string) => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (request: ContributionRequestTrackingData, moreDetails: Record<string, unknown> = {}) =>
      tracking.trackEvent({
        event: eventName,
        details: {
          requestId: request.id,
          requestType: request.type,
          requestVisibility: request.showOthersContributions ? 'visible' : 'hidden',
          requestContributionCount: (request.imageAssets?.length ?? 0) + (request.videoAssets?.length ?? 0),
          title: request.title,
          instruction: request.instruction,
          ...moreDetails,
        },
      }),
    [tracking, eventName]
  );
};
