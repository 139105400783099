import { useCallback } from 'react';

export const useDownloadUrl = () => {
  return useCallback((url: string, name?: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name ?? new URL(url).pathname.split('/').pop() ?? 'cofenster';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);
};
