import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { useVerifyToken } from '../../api/hooks/user/useVerifyToken';
import { Redirect } from '../../components/common/Redirect';
import { PageHeadline, PageSubline, UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';
import { useCheckSso } from '../../hooks/useCheckSso';
import type { ResetPasswordRouteParams } from '../../routes';

import { SetPasswordForm } from './SetPasswordForm';

export const SetPassword: FC = () => {
  const { token } = useParams() as ResetPasswordRouteParams;
  const { status, user } = useVerifyToken(token);
  const { authProvider, loading } = useCheckSso(user?.email);

  if (loading || authProvider) {
    return <LoadingSpinner />;
  }

  if (status && status !== 'valid') {
    return <Redirect to="forgotPassword" state={{ error: status }} />;
  }

  if (!token) {
    return <Redirect to="signin" />;
  }

  if (user?.deactivatedAt) {
    return <Redirect to="deactivatedUser" />;
  }

  return (
    <UnauthenticatedPage title="i18n.set.title">
      <PageHeadline variant="h1">i18n.signin.headline</PageHeadline>
      <PageSubline variant="xl">i18n.set.subline</PageSubline>
      <SetPasswordForm />
    </UnauthenticatedPage>
  );
};
