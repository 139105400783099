import type { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react';

import { Icon, type IconType, Typography, preventForwardProps, styled } from '@cofenster/web-components';

type SelectableSelectBoxProps = PropsWithChildren<
  ComponentProps<'div'> & {
    checked?: boolean;
    checkedIcon?: IconType;
    title: string | ReactElement;
    description?: string;
    mainIcon: ReactElement;
  }
>;

const ContentContainer = styled(
  'div',
  preventForwardProps(['checked'])
)<{ checked?: boolean }>(({ theme, checked }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey200}`,
  transition: '250ms border-color',
  ...(checked && {
    color: theme.palette.brand.blue,
    backgroundColor: theme.palette.brand.blue50,
    border: `1px solid ${theme.palette.brand.blue}`,
  }),
}));

const CheckedIcon = styled(Icon)(() => ({ marginLeft: 'auto', flexShrink: 0 }));

export const FormTypeSelectBox: FC<SelectableSelectBoxProps> = ({
  checkedIcon = 'CheckIcon' as const,
  title,
  description,
  mainIcon,
  checked,
  ...rest
}) => {
  return (
    <ContentContainer checked={checked} {...rest}>
      {mainIcon}
      <div>
        <Typography variant="h6" component="p">
          {title}
        </Typography>
        <Typography component="p">{description}</Typography>
      </div>
      <CheckedIcon type={checkedIcon} style={{ visibility: checked ? 'visible' : 'hidden' }} />
    </ContentContainer>
  );
};
