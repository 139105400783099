import { type ChangeEventHandler, type FC, useMemo } from 'react';

import {
  Card,
  GridContainer,
  GridItem,
  Spacing,
  Typography,
  UpsellIcon,
  styled,
  useFeatureFlags,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import { usePublishedVideoAnalytics } from '../../../../api/hooks/videoAnalytics/usePublishedVideoAnalytics';
import { CardHeadline } from '../../../../components/card';
import { UpsellBanner } from '../../../../components/featureFlag/UpsellBanner';
import type { Project } from '../../../../contexts/project/useProject';
import { TeamPermissionRestriction } from '../../../../contexts/user/TeamPermissionRestriction';
import { EnableSharingLink } from '../EnableSharingLink';
import { SharingLinkActions } from '../SharingLinkActions';
import { SharingLinkSwitch } from '../SharingLinkSwitch';
import { useSharingLink } from '../useSharingLink';
import { VideoAnalytics } from './VideoAnalytics';

const StyledCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    containerType: 'inline-size',
    minWidth: 480,
    maxWidth: '100%',
  },
}));

const StyledCardHeadline = styled(CardHeadline)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const AnalyticsAndSharingLinkSettings: FC<{ project: Project }> = ({ project }) => {
  const { loading, publishedVideoAnalytics } = usePublishedVideoAnalytics(project.id);
  const { enableSharingLink, disableSharingLink, sharingEnabled } = useSharingLink(project);
  const withVideoAnalyticsFlag = useFeatureFlags().hasFeature('VIDEO_ANALYTICS');
  const learnMoreUrl = useLocalizedWebsiteUrl('VIDEO_ANALYTICS_INFO');

  const onSharingLinkChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.currentTarget.checked && !project.sharingLink) enableSharingLink();
    else if (!event.currentTarget.checked && project.sharingLink) disableSharingLink();
  };

  const noViews = useMemo(() => {
    const uniqueViews = publishedVideoAnalytics?.uniqueViews ?? 0;
    const totalViews = publishedVideoAnalytics?.totalViews ?? 0;
    return uniqueViews === 0 && totalViews === 0;
  }, [publishedVideoAnalytics?.uniqueViews, publishedVideoAnalytics?.totalViews]);

  return (
    <TeamPermissionRestriction has="SharingLinkRead">
      <GridContainer spacing={2}>
        <GridItem xs={12}>
          {!project.sharingLink && noViews ? (
            <EnableSharingLink onSharingLinkChange={enableSharingLink} project={project} />
          ) : (
            <StyledCard>
              <Typography variant="h4" component="h2">
                i18n.videoAnalytics.share
              </Typography>

              <Spacing top={1} bottom={2}>
                <SharingLinkSwitch checked={sharingEnabled} onChange={onSharingLinkChange} project={project} />
              </Spacing>

              {(withVideoAnalyticsFlag || !noViews) && (
                <Spacing top={2} bottom={2}>
                  <VideoAnalytics projectId={project.id} loading={loading} />
                </Spacing>
              )}

              <Spacing top={2}>
                <SharingLinkActions url={project?.sharingLink?.url} title={project.name} />
              </Spacing>
            </StyledCard>
          )}

          {!withVideoAnalyticsFlag && project.sharingLink && (
            <Spacing top={2}>
              <StyledCard>
                <StyledCardHeadline variant="h3">
                  Analytics <UpsellIcon />
                </StyledCardHeadline>
                <UpsellBanner
                  title="i18n.upsellBanner.VIDEO_ANALYTICS.title"
                  learnMoreUrl={learnMoreUrl}
                  featureRelation="VIDEO_ANALYTICS"
                />
              </StyledCard>
            </Spacing>
          )}
        </GridItem>
      </GridContainer>
    </TeamPermissionRestriction>
  );
};
