import { type FC, type MouseEventHandler, useMemo, useState } from 'react';

import { Button, DurationInput, Icon, styled, usePopover, withPopover } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';

const ColContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const useFormattedTime = (seconds = 0) => {
  return `${seconds.toFixed(1)}s`;
};

const DurationButton: FC<{ duration: number; onClick?: MouseEventHandler<HTMLButtonElement>; disabled?: boolean }> = ({
  duration,
  onClick,
  disabled,
}) => (
  <Button variant="tertiary" disabled={disabled} startIcon={<Icon type="ClockIcon" size="s" />} onClick={onClick}>
    {useFormattedTime(duration)}
  </Button>
);

const DurationPopover: FC<{ duration: number; onChange: (value: number) => void }> = ({ duration, onChange }) => {
  const [uncommitedDuration, setUncommitedDuration] = useState(duration);
  const { closePopover } = usePopover();

  return (
    <PopoverContainer>
      <ColContainer>
        <DurationInput value={uncommitedDuration} min={1} unit="s" onChange={setUncommitedDuration} />
        <Button
          variant="primary"
          disabled={duration === uncommitedDuration}
          onClick={() => {
            onChange(uncommitedDuration);
            closePopover();
          }}
        >
          i18n.projectEdit.durationInput.setButton
        </Button>
      </ColContainer>
    </PopoverContainer>
  );
};

export const SceneDurationButton: FC<{ duration: number; onChange: (value: number) => void; disabled?: boolean }> = ({
  duration,
  onChange,
  disabled,
}) => {
  const { pause } = useEditorPlayer();
  const DurationButtonWithPopover = useMemo(
    () =>
      withPopover(DurationButton, {
        anchorOriginX: 'center',
        anchorOriginY: 'top',
        transformOriginX: 'center',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: <DurationPopover duration={duration} onChange={onChange} />,
      }),
    [duration, onChange]
  );

  return <DurationButtonWithPopover duration={duration} disabled={disabled} onClick={pause} />;
};
