import { useState } from 'react';

import { useInterval } from '@cofenster/web-components';

export const useHasIntercomMounted = () => {
  const [hasIntercomMounted, setHasIntercomMounted] = useState(false);

  useInterval(
    () => {
      if ('Intercom' in window) setHasIntercomMounted(true);
    },
    hasIntercomMounted ? 0 : 1000
  );

  return hasIntercomMounted;
};
