import type { FC } from 'react';

import {
  Button,
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  Icon,
  IconButton,
  Responsive,
  Typography,
  VisuallyHidden,
  formatDuration,
  styled,
  useI18n,
} from '@cofenster/web-components';

import type { Music } from '../../../api/hooks/music/useProjectMusics';
import type { Project } from '../../../contexts/project/useProject';
import { MusicRange } from '../MusicRange';
import { useMusics, type usePlayer, useProjectMusicDetails, useSelectMusic } from '../hooks';

type ExtendedMusic = {
  music: Music;
  project: Project;
  player: ReturnType<typeof usePlayer>;
};

const HeaderCell: FC<{ column: ColumnConfig<ExtendedMusic> }> = ({ column }) => (
  <VisuallyHidden as="span">{column.name}</VisuallyHidden>
);

const MusicName = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
}));

const MusicInfoContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const columns: CSSGridTableConfig<ExtendedMusic>['columns'] = [
  {
    id: 'play',
    name: 'Play',
    cell: ({ item }) => <PlayButton {...item} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'name',
    name: 'Name',
    cell: ({ item }) => <MusicInfo {...item} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'progress',
    name: 'Progress',
    cell: ({ item }) => <MusicRange {...item} />,
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'duration',
    name: 'Duration',
    cell: ({ item }) => <>{formatDuration(item.music.musicAsset?.duration ?? 0)}</>,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'select',
    name: 'Select',
    cell: ({ item }) => <SelectButton {...item} />,
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

const PlayButton: FC<ExtendedMusic> = ({ music, player }) => {
  const { locale } = useI18n();
  const { playback, play, pause } = player;

  const isInPlayer = playback.id === music.id;
  const isPlaying = isInPlayer && playback.status === 'PLAYING';
  const musicName = locale === 'DE' ? music.name : music.nameEN;

  return (
    <IconButton
      style={{ borderRadius: '50%' }}
      type="button"
      icon={isPlaying ? 'PauseIcon' : 'PlayIcon'}
      label={isPlaying ? 'i18n.global.pause' : 'i18n.common.playItem'}
      i18nParams={{ name: musicName }}
      iconWeight="fill"
      iconColor={'carbon'}
      iconSize={'m'}
      onClick={() => (isPlaying ? pause() : play(music.id))}
      aria-pressed={isPlaying}
      data-testid="music-card-play-button"
    />
  );
};

const MusicInfo: FC<ExtendedMusic> = ({ music }) => {
  const { locale } = useI18n();
  const musicName = locale === 'DE' ? music.name : music.nameEN;
  const { privateMusics } = useMusics();
  const isPrivate = privateMusics.find((privateMusic) => privateMusic.id === music.id);

  return (
    <MusicInfoContainer>
      <MusicName variant="m" component="p">
        {musicName}
      </MusicName>
      <Typography variant="s" component="p" color="grey700">
        {isPrivate ? 'i18n.projectMusic.music.private' : 'i18n.projectMusic.music.public'}
      </Typography>
    </MusicInfoContainer>
  );
};

const SelectButton: FC<ExtendedMusic> = ({ music, project }) => {
  const { locale } = useI18n();
  const { musicId: currentMusicId } = useProjectMusicDetails(project);
  const { toggleMusic, loading } = useSelectMusic(project);
  const musicName = locale === 'DE' ? music.name : music.nameEN;

  return (
    <>
      <Responsive down="sm">
        <IconButton
          loading={loading}
          icon="PlusCircleIcon"
          onClick={() => toggleMusic(music.id)}
          label={currentMusicId === music.id ? 'i18n.projectMusic.music.remove' : 'i18n.projectMusic.music.select'}
          i18nParams={{ name: <VisuallyHidden>{musicName}</VisuallyHidden> }}
        />
      </Responsive>
      <Responsive up="sm">
        <Button
          loading={loading}
          startIcon={<Icon size="s" type={currentMusicId === music.id ? 'MinusCircleIcon' : 'PlusCircleIcon'} />}
          type="button"
          variant="tertiary"
          onClick={() => toggleMusic(music.id)}
          i18nParams={{ name: <VisuallyHidden>{musicName}</VisuallyHidden> }}
          data-testid="music-card-toggle-button"
        >
          {currentMusicId === music.id ? 'i18n.projectMusic.music.remove' : 'i18n.projectMusic.music.select'}
        </Button>
      </Responsive>
    </>
  );
};

export const MusicList: FC<{ musics: Music[]; player: ReturnType<typeof usePlayer>; project: Project }> = ({
  musics,
  player,
  project,
}) => {
  return <CSSGridTable data={musics.map((music) => ({ music, project, player }))} columns={columns} />;
};
