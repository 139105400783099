import { useMemo } from 'react';

import { useUserRoleRequestQuery } from '../../generated';

export const useUserRoleRequest = (userId: string, teamId: string | null = null) => {
  const { data, loading, error } = useUserRoleRequestQuery({ variables: { userId, teamId } });
  return useMemo(() => {
    return { userRoleRequest: data?.userRoleRequest, loading, error };
  }, [data, loading, error]);
};
