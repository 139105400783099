import { Button, styled } from '@cofenster/web-components';

export const CardButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: theme.shape['borderRadius-l'],
  border: `1px solid ${theme.palette.brand.grey100}`,
  padding: theme.spacing(4, 2),
  backgroundColor: theme.palette.brand.white,

  '&:hover': {
    borderColor: theme.palette.brand.carbon,
    backgroundColor: theme.palette.brand.white,
  },

  '&:focus-visible': theme.mixins.focusRing,

  'svg:not([data-testid="icon-LoadingIcon"])': {
    transition: 'transform 150ms ease-out',
  },

  '&:hover svg:not([data-testid="icon-LoadingIcon"]), &:focus svg:not([data-testid="icon-LoadingIcon"])': {
    transform: 'scale(1.1)',
  },
}));
