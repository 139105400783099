import { styled } from '@mui/material';
import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import { secondsToTime } from '../../../utilities/secondsToTime';
import { IconButton } from '../../controls/Button/IconButton';
import { DropzoneContent as Content } from '../../controls/Upload';
import { Box } from '../../layout/Box';
import { Text } from '../../typography/Text';

type AudioPlayerProps = {
  url: string;
  duration?: number | null;
  filename?: string | null;
};

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  border: `1px solid ${theme.palette.brand.grey100}`,
}));

export const AudioPlayer: FC<AudioPlayerProps> = ({ url, duration, filename }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  // Synchronize the player state with the local state
  useEffect(() => {
    const player = audioRef.current;
    if (!player) return;
    if (isPlaying) audioRef.current.play();
    else audioRef.current.pause();
  }, [isPlaying]);

  // Synchronize the local state with the player state once it ends
  useEffect(() => {
    const player = audioRef.current;
    if (!player) return;
    const stop = () => setIsPlaying(false);
    player.addEventListener('ended', stop);
    return () => {
      player.removeEventListener('ended', stop);
    };
  }, []);

  const handlePlayPause = useCallback(() => setIsPlaying((isPlaying) => !isPlaying), []);

  const shortFilename =
    filename && filename?.length > 20 ? `${filename.slice(0, 19)}…${filename.split('.')[1]}` : filename;

  return (
    <StyledBox backgroundColor="transparent">
      <Content
        icon={
          <IconButton
            icon={isPlaying ? 'PauseIcon' : 'PlayIcon'}
            iconWeight="fill"
            iconColor="blue"
            onClick={handlePlayPause}
            label={isPlaying ? 'i18n.global.pause' : 'i18n.global.play'}
          />
        }
        text={shortFilename ?? undefined}
        hintText={duration ? <Text tabularNums>{secondsToTime(duration)}</Text> : undefined}
        size="small"
      />
      <audio ref={audioRef} src={url} controls={false} />
    </StyledBox>
  );
};
