import { type FC, useCallback } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Icon,
  Typography,
  preventForwardProps,
  styled,
} from '@cofenster/web-components';

import { useCreateContributionRequest } from '../../../api/hooks/contributionRequest/useCreateContributionRequest';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useI18n } from '../../../i18n';
import { contributionRequestPresets } from './presets';

const LimitedWidthGridContainer = styled(GridContainer)(() => ({
  maxWidth: 996,
}));

const WithDivider = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.brand.linen50}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

const EmptyCard = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: 176,
  gap: theme.spacing(1),
  backgroundColor: theme.palette.brand.white,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.brand.white,
    borderColor: theme.palette.brand.carbon,
  },
  borderRadius: theme.shape['borderRadius-l'],
}));

const PresetCard = styled(
  EmptyCard,
  preventForwardProps(['thumbnailUrl'])
)<{ thumbnailUrl: string }>(({ theme, thumbnailUrl }) => ({
  backgroundColor: 'transparent',
  backgroundImage: `url("${thumbnailUrl}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: theme.shape['borderRadius-l'],
}));

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const CreateFirstContributionRequest: FC<{ projectId: string }> = ({ projectId }) => {
  return (
    <LimitedWidthGridContainer>
      <GridItem xs={12} alignItems="center">
        <TitleAndSubtitle />
      </GridItem>
      <GridItem xs={12}>
        <CreateYourOwn projectId={projectId} />
      </GridItem>
      <GridItem xs={12}>
        <Presets projectId={projectId} />
      </GridItem>
    </LimitedWidthGridContainer>
  );
};

const TitleAndSubtitle: FC = () => (
  <WithDivider>
    <Typography variant="h3" component="h2">
      i18n.projectContributionConfiguration.initial.title
    </Typography>
    <Typography variant="l">i18n.projectContributionConfiguration.initial.description</Typography>
  </WithDivider>
);

const CreateYourOwn: FC<{ projectId: string }> = ({ projectId }) => {
  const { createContributionRequest, loading } = useCreateContributionRequest();
  const onClick = useCallback(async () => {
    await createContributionRequest(projectId, [
      {
        index: 0,
        instruction: '',
        title: '',
        type: 'VIDEO',
      },
    ]);
  }, [projectId, createContributionRequest]);

  return (
    <GridContainer rowSpacing={1} columnSpacing={2}>
      <GridItem xs={12} mb={0}>
        <Typography variant="h5" component="h2">
          i18n.projectContributionConfiguration.initial.createYourOwn.title
        </Typography>
      </GridItem>
      <GridItem xs={12} md={4}>
        <EmptyCard
          data-testid="create-blank-contribution-request-button"
          variant="tertiary"
          onClick={onClick}
          disabled={loading}
          startIcon={<Icon type="PlusCircleIcon" color="carbon" />}
        >
          i18n.projectContributionConfiguration.initial.createYourOwn.button
        </EmptyCard>
      </GridItem>
    </GridContainer>
  );
};

const Presets: FC<{ projectId: string }> = ({ projectId }) => {
  const { openDialog } = useDialogs();
  const { locale } = useI18n();
  const contributionRequestPresetsLocalised = contributionRequestPresets[locale];

  const openPresetDialog = useCallback(
    (presetId: string) => {
      const preset = contributionRequestPresetsLocalised.find((p) => p.id === presetId);
      if (!preset) return;

      openDialog('ContributionRequestFromPresetDialog', {
        projectId,
        preset,
      });
    },
    [openDialog, projectId, contributionRequestPresetsLocalised]
  );

  if (contributionRequestPresetsLocalised.length === 0) return null;

  return (
    <GridContainer rowSpacing={1} columnSpacing={2}>
      <GridItem xs={12}>
        <Typography variant="h5" component="h2">
          i18n.projectContributionConfiguration.initial.preset.title
        </Typography>
      </GridItem>
      {contributionRequestPresetsLocalised.map((preset) => (
        <GridItem key={preset.id} xs={12} md={4}>
          <InnerContainer>
            <PresetCard thumbnailUrl={preset.thumbnailUrl} onClick={() => openPresetDialog(preset.id)} />
            <Typography variant="l">{preset.title}</Typography>
          </InnerContainer>
        </GridItem>
      ))}
    </GridContainer>
  );
};
