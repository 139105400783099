import { useCallback, useMemo, useState } from 'react';
import { useDeleteSceneAsset } from '../../../../api/hooks/sceneAsset/useDeleteSceneAsset';

import { useAudioOverlayContext } from './Context';

export const useCancelAudioOverlay = () => {
  const { setLocalStatus, savedStatus, audioSceneAsset } = useAudioOverlayContext();
  const deleteAudioSceneAsset = useDeleteSceneAsset();
  const [isCanceling, setIsCanceling] = useState(false);

  const cancel = useCallback(async () => {
    if (isCanceling) return;
    setIsCanceling(true);

    try {
      // Reset the status to its original status, since the update was canceled
      setLocalStatus(savedStatus);

      // If an audio scene asset was uploaded, and wasn’t already there before,
      // delete the audio scene asset
      // @TODO: check how to fix the case where the file was updated
      if (audioSceneAsset && savedStatus !== 'UPLOADED_AUDIO') {
        await deleteAudioSceneAsset(audioSceneAsset.id);
      }
    } finally {
      setIsCanceling(false);
    }
  }, [setLocalStatus, savedStatus, deleteAudioSceneAsset, audioSceneAsset, isCanceling]);

  return useMemo(() => [cancel, isCanceling] as const, [cancel, isCanceling]);
};
