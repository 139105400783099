import type { FC } from 'react';

import { EmptyState, GridContainer, type GridContainerProps, Spacing } from '@cofenster/web-components';

import type { Project } from '../../api/hooks/project/useLatestProjects';
import type { ExtendedProject } from '../../api/hooks/project/useSearchProjects';
import { useListViewType } from '../../contexts/listViewType/useListViewType';

import { HomeProject } from './HomeProject';

export const HomeProjects: FC<GridContainerProps & { projects: ExtendedProject[]; refetchQueries?: string[] }> = ({
  projects,
  refetchQueries,
  ...rest
}) => {
  const { type } = useListViewType();

  if (projects.length === 0) {
    return (
      <Spacing top={2}>
        <EmptyState
          iconType="SearchIcon"
          title="i18n.home.search.empty.title"
          description="i18n.home.search.empty.description"
        />
      </Spacing>
    );
  }

  return (
    <GridContainer mt={0} spacing={type === 'GRID' ? 2 : 0} {...rest}>
      {projects.map(({ project, team }) => (
        <HomeProject key={project.id} project={project as Project} team={team} refetchQueries={refetchQueries} />
      ))}
    </GridContainer>
  );
};
