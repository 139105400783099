import { type FC, useCallback } from 'react';

import {
  AspectRatioBox,
  Dialog,
  DialogContent,
  IconButton,
  NativeVideoPlayer,
  styled,
} from '@cofenster/web-components';

import type { VideoAsset } from '../../../api/hooks/videoAsset/useVideoAsset';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export type PreviewBriefingVideoDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  video: VideoAsset;
  contributionRequestListId: string;
};

const IconsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  opacity: 0,
  transition: 'opacity 200ms',
  '*:hover > &, &:focus-within': {
    opacity: 1,
  },
}));

export const PreviewBriefingVideoDialog: FC<PreviewBriefingVideoDialogProps> = ({
  isOpen,
  closeDialog,
  video,
  contributionRequestListId,
}) => {
  const tracking = useWebManagerTracking();
  const onDownloadClick = useCallback(() => {
    tracking.trackEvent({
      event: 'requestBriefingAssetDownloaded',
      details: {
        requestListId: contributionRequestListId,
      },
    });
  }, [tracking, contributionRequestListId]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      title="i18n.projectContributionConfiguration.PreviewBriefingVideoDialog.title"
    >
      <DialogContent>
        <AspectRatioBox ratio={1}>
          <NativeVideoPlayer src={video?.videoUrl ?? undefined} actions={['FULLSCREEN']} />
          {video?.videoUrl && (
            <IconsWrapper>
              <IconButton
                icon="DownloadIcon"
                target="_blank"
                href={video.videoUrl}
                download
                label="i18n.global.download"
                onClick={onDownloadClick}
                iconSize="m"
                iconColor="white"
                hoverColor="white"
                backgroundColor="carbon_alpha30"
                hoverBackgroundColor="carbon_alpha50"
              />
            </IconsWrapper>
          )}
        </AspectRatioBox>
      </DialogContent>
    </Dialog>
  );
};
