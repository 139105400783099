import { type FC, useCallback, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  EmptyState,
  GridContainer,
  GridItem,
  Icon,
  LoadingSpinner,
  Typography,
  styled,
} from '@cofenster/web-components';
import {
  type ContributionRequest,
  useContributionRequestsByProject,
} from '../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import {
  type ContributionRequestList,
  useContributionRequestListByProject,
} from '../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { ContributionRequestRow } from '../../../pages/ProjectContributionConfiguration/ContributionRequestCard/ContributionRequestListChipSection';
import { CreateContributionRequestListForm } from '../../../pages/ProjectContributionConfiguration/ContributionRequestCard/CreateContributionRequestListForm';

export type NewContributionRequestListDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  contributionRequestId: string;
  projectId: string;
};

export const NewContributionRequestListDialog: FC<NewContributionRequestListDialogProps> = ({
  isOpen,
  closeDialog,
  contributionRequestId,
  projectId,
}) => {
  const {
    contributionRequestLists,
    refetch: refetchLists,
    error: errorLists,
    loading: loadingLists,
  } = useContributionRequestListByProject(projectId);
  const { data, refetch: refetchCR, error: errorCR, loading: loadingCR } = useContributionRequestsByProject(projectId);
  const contributionRequest = useMemo(
    () => data?.contributionRequestsByProject?.find((cr) => cr.id === contributionRequestId),
    [data?.contributionRequestsByProject, contributionRequestId]
  );

  const hasError = !!errorLists || !!errorCR;
  const loading = loadingLists || loadingCR;

  const retryAll = useCallback(() => {
    return Promise.all([errorLists && refetchLists(), errorCR && refetchCR()].filter(Boolean));
  }, [refetchLists, refetchCR, errorLists, errorCR]);

  const listsWithoutDefault = useMemo(
    () => contributionRequestLists.filter((list) => !list.default),
    [contributionRequestLists]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      title="i18n.projectContribution.CreateContributionRequestListForm.addListTitle"
    >
      <DialogContent>
        <Content
          projectId={projectId}
          contributionRequest={contributionRequest}
          listsWithoutDefault={listsWithoutDefault}
          loading={loading}
          hasError={hasError}
          retryAll={retryAll}
        />
      </DialogContent>
    </Dialog>
  );
};

const Content: FC<{
  projectId: string;
  contributionRequest: ContributionRequest | undefined;
  listsWithoutDefault: ContributionRequestList[];
  loading: boolean;
  hasError: boolean;
  retryAll: VoidFunction;
}> = ({ loading, projectId, contributionRequest, listsWithoutDefault, hasError, retryAll }) => {
  if (loading) return <LoadingSpinner />;
  if (hasError)
    return (
      <EmptyState
        backgroundColor="transparent"
        title="i18n.global.error.generic.unknown"
        titleProps={{ color: 'negative', variant: 'h5' }}
        icon={<Icon type="CloudErrorIcon" color="negative" size="l" />}
        cta={
          <Button variant="tertiary" onClick={retryAll}>
            i18n.global.error.generic.unknown.retry
          </Button>
        }
      />
    );
  if (!contributionRequest) return <LoadingSpinner />;

  return (
    <GridContainer spacing={1}>
      <GridItem xs={12}>
        <CreateContributionRequestListForm projectId={projectId} contributionRequestId={contributionRequest.id} />
      </GridItem>
      {listsWithoutDefault?.length ? (
        <>
          {listsWithoutDefault.map((list) => (
            <GridItem key={list.id} xs={12}>
              <ContributionRequestRow contributionRequest={contributionRequest} contributionRequestList={list} />
            </GridItem>
          ))}
        </>
      ) : (
        <GridItem xs={12}>
          <NoContributionRequestLists />
        </GridItem>
      )}
    </GridContainer>
  );
};

const EmptyStateContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const EmptyStateText = styled(Typography)(() => ({
  textAlign: 'center',
  maxWidth: 240,
}));

const NoContributionRequestLists: FC = () => {
  return (
    <EmptyStateContainer>
      <Icon type="UserListIcon" size="l" />
      <EmptyStateText>i18n.projectContribution.CreateContributionRequestListForm.emptyStateText</EmptyStateText>
    </EmptyStateContainer>
  );
};
