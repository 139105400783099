import { styled } from '@mui/material';
import type { ComponentProps } from 'react';

export type BaseItemProps = ComponentProps<typeof BaseItem>;

export const BaseItem = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: '1px solid transparent',
  textAlign: 'start',
  color: theme.palette.brand.grey100,
  gap: theme.spacing(1),

  '> span': {
    display: '-webkit-box',
    width: '100%',
    wordBreak: 'break-word',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },

  '> svg:first-of-type': { marginLeft: theme.spacing(-1) },
  '> svg:last-child': { marginRight: theme.spacing(-1) },
}));
