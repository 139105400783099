import { PopoverMenuItem } from '@cofenster/web-components';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { Scene } from '../../../../../api/hooks/scene/useScenes';
import { useActionSceneIds } from '../../../../../contexts/selectedScenes/useActionSceneIds';
import { useSelectedScenes } from '../../../../../contexts/selectedScenes/useSelectedScenes';
import {
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../../../../helpers/sceneAssets/is';
import { useDeleteColorSceneAssetWithTracking } from '../../../../../hooks/sceneAsset/color/useDeleteColorSceneAssetWithTracking';
import { useDeleteImageSceneAssetWithTracking } from '../../../../../hooks/sceneAsset/image/useDeleteImageSceneAssetWithTracking';
import { useDeleteVideoSceneAssetWithTracking } from '../../../../../hooks/sceneAsset/video/useDeleteVideoSceneAssetWithTracking';

const useHasOnlyColorScenesSelected = (currentScene: Scene) => {
  const { selectedItems } = useSelectedScenes();
  const actionSceneIds = useActionSceneIds(currentScene.id);

  return useMemo(
    () =>
      [...selectedItems, currentScene]
        .filter((scene) => actionSceneIds.indexOf(scene.id) !== -1)
        .every((scene) => scene.type === 'color'),
    [selectedItems, currentScene, actionSceneIds]
  );
};

const useMainAssetDeleteHandler = (scene: Scene) => {
  const mainSceneAsset = scene.sceneAssets.find(isMainSceneAsset);

  const onDeleteVideo = useDeleteVideoSceneAssetWithTracking(scene.id);
  const onDeleteImage = useDeleteImageSceneAssetWithTracking(scene.id);
  const onDeleteColor = useDeleteColorSceneAssetWithTracking(scene.id);

  if (!mainSceneAsset) return undefined;
  if (isImageSceneAsset(mainSceneAsset)) return onDeleteVideo;
  if (isVideoSceneAsset(mainSceneAsset)) return onDeleteImage;
  if (isColorSceneAsset(mainSceneAsset)) return onDeleteColor;
  return undefined;
};

export const DeleteSceneAssetPopoverMenuItem: FC<{ scene: Scene }> = ({ scene }) => {
  const hasOnlyColorScenesSelected = useHasOnlyColorScenesSelected(scene);
  const onDelete = useMainAssetDeleteHandler(scene);

  return (
    <PopoverMenuItem
      key="delete-asset"
      icon="CloseIcon"
      onClick={onDelete}
      data-testid="scene-delete-asset"
      color="negative"
    >
      {hasOnlyColorScenesSelected
        ? 'i18n.projectEditor.sceneAssetThumbs.popover.actions.deleteColor'
        : 'i18n.projectEditor.sceneAssetThumbs.popover.actions.deleteAsset'}
    </PopoverMenuItem>
  );
};
