export type Range<T> = [T, T];

// this is proposed utility code organization, basic on union between Type and Namespace
// example: [ Range.Numeric.create(), Range.Numeric.create() ].sort(Range.Numeric.compare)
export namespace Range {
  export type Numeric = Range<number>;
  export namespace Numeric {
    const sign = (n: number) => (n === 0 ? 0 : n > 0 ? 1 : -1);

    export const create = (from = 0, to = 1): Numeric => [from, to];

    // biome-ignore lint/suspicious/noShadowRestrictedNames: safe to do so
    export const toString = (r: Numeric, precision = 3) =>
      `(${r[0].toPrecision(precision)}:${r[1].toPrecision(precision)})`;

    export const equals = (a: Numeric, b: Numeric) => {
      return a[0] === b[0] && a[1] === b[1];
    };

    export const compare = (a: Numeric, b: Numeric) => {
      const diff = sign(a[0] - b[0]) + sign(a[1] - b[1]);
      return diff;
    };

    export const startingAfterExclusive = ([from]: Numeric, n: number) => {
      return n < from;
    };

    export const startingAfterInclusive = ([from]: Numeric, n: number) => {
      return n <= from;
    };

    export const endingBeforeExclusive = ([, to]: Numeric, n: number) => {
      return n > to;
    };

    export const validate = (r: Numeric) => {
      console.assert(r[0] <= r[1], `Invalid Range.Numeric ${r}`);
    };

    export const normalize = (r: Numeric): Numeric => {
      return r[0] <= r[1] ? r : [r[1], r[0]];
    };

    export namespace contains {
      export const exclusive = ([from, to]: Numeric, n: number) => {
        return n > from && n < to;
      };

      export const inclusive = ([from, to]: Numeric, n: number) => {
        return n >= from && n <= to;
      };

      export const inclusiveL = ([from, to]: Numeric, n: number) => {
        return n >= from && n < to;
      };

      export const inclusiveR = ([from, to]: Numeric, n: number) => {
        return n > from && n <= to;
      };
    }

    export const overlaps = <T extends { range: Numeric }>(items: T[], target: T) => {
      return items.find((item) => Math.max(target.range[0], item.range[0]) <= Math.min(target.range[1], item.range[1]));
    };

    export const length = ([from, to]: Numeric) => {
      return to - from;
    };

    export const multiply = ([from, to]: Numeric, n: number) => {
      return create(from * n, to * n);
    };

    export const divide = ([from, to]: Numeric, n: number) => {
      return create(from / n, to / n);
    };
  }
}
