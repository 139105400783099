import type { FC } from 'react';

import { Headline, type HeadlineProps, styled } from '@cofenster/web-components';

const StyledHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const CardHeadline: FC<HeadlineProps> = ({ variant = 'h1', ...props }) => (
  <StyledHeadline {...props} variant={variant} />
);
