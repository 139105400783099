import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Headline, styled } from '@cofenster/web-components';

import { Redirect } from '../../components/common/Redirect';
import { UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';
import { useUser } from '../../contexts/user/useUser';

import { CheckEmailForm } from './CheckEmailForm';

const PageHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const SignIn: FC = () => {
  const { user } = useUser();
  const { state } = useLocation();

  if (user) {
    if (state?.redirect) return <Navigate to={state.redirect} replace />;
    return <Redirect to="home" />;
  }

  return (
    <UnauthenticatedPage title="i18n.signin.title">
      <PageHeadline variant="h1">i18n.signin.headline</PageHeadline>
      <CheckEmailForm />
    </UnauthenticatedPage>
  );
};
