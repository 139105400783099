import { styled } from '@mui/material';

export const NavList = styled('div')(({ theme }) => ({
  ul: {
    listStyleType: 'none',
    margin: 0,
    paddingLeft: 0,

    '> li + li': {
      marginTop: theme.spacing(0.5),
    },
  },
}));
