import { type Dispatch, type FC, type SetStateAction, useContext } from 'react';

import { GridContainer, GridItem } from '@cofenster/web-components';

import { type StockImage, useCuratedStockImages } from '../../../api/hooks/stockFootage/useCuratedStockImages';
import { type StockVideo, usePopularStockVideos } from '../../../api/hooks/stockFootage/usePopularStockVideos';

import { ResultPreview } from './ResultPreview';
import { SearchContext } from './SearchContext';
import { SelectAssetButton } from './SelectAssetButton';

export const DefaultState: FC<{
  setSelectedAsset: Dispatch<SetStateAction<StockImage | StockVideo | null>>;
}> = ({ setSelectedAsset }) => {
  const { type, videoFormat } = useContext(SearchContext);
  const { curatedStockImages } = useCuratedStockImages({ videoFormat, page: 1, perPage: 50 }, type !== 'image');
  const { popularStockVideos } = usePopularStockVideos({ videoFormat, page: 1, perPage: 50 }, type !== 'video');
  const result = type === 'image' ? curatedStockImages : popularStockVideos;

  return (
    <GridContainer spacing={2}>
      {result?.items.map((item) => (
        <GridItem xs={12} sm={6} md={4} key={item.id}>
          <SelectAssetButton onClick={() => setSelectedAsset(item)} type="button" data-testid="curated-asset-button">
            <ResultPreview asset={item} />
          </SelectAssetButton>
        </GridItem>
      ))}
    </GridContainer>
  );
};
