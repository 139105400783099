import { useCallback } from 'react';
import { useActionSceneIds } from '../../../contexts/selectedScenes/useActionSceneIds';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export const useCopySceneToClipboard = (id: string) => {
  const actionSceneIds = useActionSceneIds(id);
  const tracking = useWebManagerTracking();

  return useCallback(async () => {
    try {
      await navigator.clipboard.writeText(btoa(JSON.stringify({ type: 'scenes', ids: actionSceneIds })));
      document.dispatchEvent(new Event(COPY_SCENE_EVENT_NAME));
      tracking.trackEvent({
        event: 'scenesCopied',
        details: {
          sceneIds: actionSceneIds,
        },
      });
    } catch {
      console.warn('Could not write to clipboard');
    }
  }, [actionSceneIds, tracking]);
};

export const COPY_SCENE_EVENT_NAME = 'cofenster.copyScene';
