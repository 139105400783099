import type { FC } from 'react';

import { Icon, preventForwardProps, styled } from '@cofenster/web-components';

const Container = styled(
  'div',
  preventForwardProps(['small'])
)<Omit<Props, 'project'>>(({ theme, small }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: small ? 38 : 56,
  height: small ? 38 : 56,
  borderRadius: '50%',
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.linen,
  transition: 'backgroundColor 250ms ease, transform 250ms ease',
}));

type Props = {
  small?: boolean;
  open?: boolean;
};

export const FolderCircleIcon: FC<Props> = ({ small, open }) => {
  return (
    <Container small={small}>
      {open ? (
        <Icon type="FolderEmptyOpen" size={small ? 'l' : 'xl'} />
      ) : (
        <Icon type="FolderEmptyClosed" size={small ? 'l' : 'xl'} />
      )}
    </Container>
  );
};
