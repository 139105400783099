import { useScenes } from '../../../../../contexts/scenes/useScenes';

export const useSceneInsertionIndex = (selectedSceneId?: string) => {
  const { scenes } = useScenes();

  if (!selectedSceneId) return scenes.length;
  if (selectedSceneId.toLowerCase() === 'intro') return 0;
  if (selectedSceneId.toLowerCase() === 'outro') return scenes.length;

  const currentSceneIndex = scenes.findIndex((scene) => scene.id === selectedSceneId);

  return currentSceneIndex === -1 ? scenes.length : currentSceneIndex + 1;
};
