import type { FC, ReactNode } from 'react';

import { type ButtonProps, Icon, type IconType, SpinningIcon, Text, styled } from '@cofenster/web-components';

import { CardButton } from './CardButton';

export type SceneTypeProps = {
  projectId: string;
  index: number;
  onCreate?: (sceneId: string) => unknown;
};

const StyledText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.brand.blue50,
}));

const StyledSpinningIcon = styled(SpinningIcon)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.brand.blue50,
}));

export const SceneTypeCard: FC<ButtonProps & { iconType: IconType; label: ReactNode }> = ({
  children,
  iconType,
  label,
  ...rest
}) => (
  <CardButton {...rest} variant="tertiary" type="button" startIcon={null}>
    {rest.loading ? (
      <StyledSpinningIcon type="LoadingIcon" size="xl" color="grey500" speed="fast" />
    ) : (
      <StyledIcon type={iconType} size="xl" color={rest.disabled ? 'grey500' : 'blue'} />
    )}
    <StyledText variant="m">{label}</StyledText>
  </CardButton>
);
