import { type FC, type PropsWithChildren, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ADMIN_URL } from '@cofenster/constants';

import { useProject } from '../../api/hooks/project/useProject';
import { NoAccessLayout } from '../../pages/NoAccess';
import { useSetProjectSpace } from '../projectSpace/useProjectSpace';
import { useUser } from '../user/useUser';

import { ProjectContext } from './ProjectContext';

type Props = {
  projectId: string;
  pollingInterval?: number;
};

export const ProjectProvider: FC<PropsWithChildren<Props>> = ({ children, projectId, pollingInterval }) => {
  const location = useLocation();
  const { user } = useUser();

  const { loading, error, project, startPolling, stopPolling, refetch } = useProject(projectId);

  useSetProjectSpace(project?.projectFolders[0]?.team?.id);

  useEffect(() => {
    pollingInterval ? startPolling(pollingInterval) : stopPolling();
    return () => stopPolling();
  }, [pollingInterval, startPolling, stopPolling]);

  const context = useMemo(() => ({ project: project || undefined, loading, refetch }), [project, loading, refetch]);

  if (!loading && !project && user) {
    // This is a debugging utility: when opening a project link as a staff user,
    // this redirects the user to the CoAdmin page to impersonate that project
    // in order to debug it.
    const isStaffUser = user.email.endsWith('@cofenster.com');

    if ((isStaffUser || user.isImpersonated) && error?.networkError?.message.includes('403')) {
      window.location.href = `${ADMIN_URL}/project/${projectId}?to=${encodeURIComponent(location.pathname)}`;

      return null;
    }

    return <NoAccessLayout resource="Project" />;
  }

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) return <NoAccessLayout resource="Project" />;

  return <ProjectContext.Provider value={context}>{children}</ProjectContext.Provider>;
};
