import {
  type ChangeEventHandler,
  type ComponentProps,
  type FC,
  type KeyboardEventHandler,
  type ReactNode,
  useCallback,
  useRef,
} from 'react';

import { Button, type ButtonProps } from '../Button';

type FileUploadButtonProps = ButtonProps & {
  id: string;
  onFileSelect: ChangeEventHandler<HTMLInputElement>;
  fileInputProps?: Partial<ComponentProps<'input'>>;
};

export const FileUploadHandler: FC<
  Omit<ComponentProps<'input'>, 'children'> & {
    id: NonNullable<ComponentProps<'input'>['id']>;
    onChange: NonNullable<ComponentProps<'input'>['onChange']>;
    children: (props: {
      component: 'label';
      htmlFor: string;
      tabIndex: 0;
      onKeyDown: KeyboardEventHandler<HTMLElement>;
      type: null;
      role: undefined;
    }) => ReactNode;
  }
> = ({ children, id, onChange, ...rest }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!inputRef.current) return;

      onChange?.(event);
      inputRef.current.value = '';
    },
    [onChange]
  );

  const onEnter: KeyboardEventHandler<HTMLElement> = useCallback((event) => {
    if (event.key === 'Enter') inputRef.current?.click();
  }, []);

  return (
    <>
      <input ref={inputRef} id={id} type="file" onChange={handleChange} hidden {...rest} />
      {children({
        // Clicking a <label> element automatically focuses the associated field
        // and in the case of <input type=file>, it opens the native file picker
        component: 'label',
        htmlFor: id,
        // Labels are not focusable by default, so we return `tabindex=0` to put
        // the label in the tab order so it can be used with the keyboard, as
        // well as an `onKeyDown` handler to react to the `Enter` key
        tabIndex: 0,
        onKeyDown: onEnter,
        // In case the `Button` element is used as receiving node, we want to
        // make sure it does not render its default attributes of `type=button`
        // and `role=button` as they are invalid on labels
        type: null,
        role: undefined,
      })}
    </>
  );
};

export const FileUploadButton: FC<FileUploadButtonProps> = ({ id, onFileSelect, fileInputProps, ...rest }) => (
  <FileUploadHandler id={id} onChange={onFileSelect} {...fileInputProps}>
    {(props) => <Button {...rest} {...props} />}
  </FileUploadHandler>
);
