import { useCallback } from 'react';

import type { SceneColor } from '../../../api/hooks/scene/useScenes';
import { useUpdateSceneColor } from '../../../api/hooks/sceneColor/useUpdateSceneColor';
import type { Scene } from '../../../contexts/scenes/useScenes';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

export const useUpdateDurationColor = (scene: Scene, sceneColor?: SceneColor | null) => {
  const updateSceneColor = useUpdateSceneColor();
  const tracking = useWebManagerTracking();

  const trackSceneDurationUpdated = useCallback(
    (duration: number) =>
      tracking.trackEvent({
        event: 'SceneDurationUpdated',
        details: {
          sceneId: scene.id,
          textElementsCount: scene.sceneTexts.length,
          sceneType: 'colour',
          duration,
        },
      }),
    [scene.id, scene.sceneTexts.length, tracking]
  );

  return useCallback(
    async (duration: number) => {
      if (sceneColor) {
        await updateSceneColor(sceneColor.id, { duration });
        trackSceneDurationUpdated(duration);
        return true;
      }
      return false;
    },
    [sceneColor, updateSceneColor, trackSceneDurationUpdated]
  );
};
