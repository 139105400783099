import { useCallback } from 'react';

import { type UpdateTeamMutationVariables, useUpdateTeamMutation } from '../../generated';

export const useUpdateTeam = () => {
  const [updateProjectFolder] = useUpdateTeamMutation({ refetchQueries: ['Team', 'AccountTeam', 'ProjectFolder'] });
  return useCallback(
    (input: UpdateTeamMutationVariables['input'], id: UpdateTeamMutationVariables['id']) => {
      return updateProjectFolder({
        variables: {
          input,
          id,
        },
      });
    },
    [updateProjectFolder]
  );
};
