import styled from '@emotion/styled';
import type { ComponentProps, FC, ReactNode } from 'react';

import { Button, type ButtonProps } from '../../controls/Button';
import { Dialog, type DialogProps } from '../../feedback/Dialog/Dialog';
import { DialogActions } from '../../feedback/Dialog/DialogActions';
import { DialogContent } from '../../feedback/Dialog/DialogContent';
import { Text } from '../../typography/Text';

export type ChoiceDialogProps = {
  isOpen: boolean;
  closeDialog: VoidFunction;
  onLeft: () => unknown;
  onRight: () => unknown;
  onClose?: () => unknown;
  title: DialogProps['title'];
  titleI18nParams?: DialogProps['titleI18nParams'];
  content?: ReactNode;
  left: ReactNode;
  leftProps?: Omit<ButtonProps, 'onClick' | 'href' | 'children'>;
  right: ReactNode;
  rightProps?: Omit<ButtonProps, 'onClick' | 'href' | 'children'>;
};

/**
 * @see useChoiceDialog
 */
export const ChoiceDialog: FC<ChoiceDialogProps> & {
  DefaultContent: typeof DefaultContent;
} = ({
  isOpen,
  closeDialog,
  title,
  titleI18nParams,
  content,
  left,
  leftProps = { variant: 'secondary' },
  onLeft,
  right,
  rightProps = { variant: 'primary' },
  onRight,
  onClose,
}) => {
  const onLeftClick = () => {
    onLeft();
    closeDialog();
  };

  const onRightClick = () => {
    onRight();
    closeDialog();
  };
  const onCloseClick = () => {
    onClose?.();
    closeDialog();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseClick}
      data-testid="choice-dialog"
      title={title}
      titleI18nParams={titleI18nParams}
    >
      {content && (
        <DialogContent>
          {typeof content === 'string' ? <DefaultContent>{content}</DefaultContent> : content}
        </DialogContent>
      )}

      <DialogActions>
        {left && (
          <Button fullWidth onClick={onLeftClick} {...leftProps} data-testid="choice-dialog-left-action">
            {left}
          </Button>
        )}
        <PushRightButton fullWidth onClick={onRightClick} {...rightProps} data-testid="choice-dialog-right-action">
          {right}
        </PushRightButton>
      </DialogActions>
    </Dialog>
  );
};

const PushRightButton = styled(Button)(() => ({
  marginLeft: 'auto',
}));

const DefaultContent: FC<ComponentProps<typeof Text>> = ({ variant = 'l', color = 'grey600', ...rest }) => (
  <Text variant={variant} color={color} {...rest} />
);

ChoiceDialog.DefaultContent = DefaultContent;
