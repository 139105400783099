import { styled } from '@mui/material';
import { type FC, useCallback } from 'react';

import { preventForwardProps } from '../../../../utilities/preventForwardProps';
import type { WithSize } from '../types';

import { SoundControlIconButton } from './SoundControlIconButton';
import { SoundSlider } from './SoundSlider';

export type PlayerControlVolumeProps = {
  changeVolume(volume: number): void;
  volume: number;
} & WithSize;

const SliderWrapper = styled(
  'div',
  preventForwardProps(['size'])
)<WithSize>(({ theme, size }) => ({
  ...(size === 's'
    ? {
        backgroundColor: theme.palette.brand.carbon_alpha50,
        borderTopRightRadius: theme.spacing(1),
        borderTopLeftRadius: theme.spacing(1),
        display: 'flex',
        position: 'absolute',
        top: -86,
        height: 86,
        width: '200%',
        left: '-50%',

        '&:hover': { display: 'flex' },
      }
    : {
        position: 'relative',
      }),
}));

const Container = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  '&:not(:hover) .js-VideoPlayerControlsSoundSliderContainer': {
    display: 'none',
  },
}));

export const SoundControl: FC<PlayerControlVolumeProps> = ({ changeVolume, volume, size }) => {
  const isSmall = size === 's';
  const handleClick = useCallback(() => changeVolume(volume === 0 ? 1 : 0), [changeVolume, volume]);

  return (
    <Container>
      <SoundControlIconButton onClick={handleClick} size={size} volume={volume} tooltipPlacement="bottom" />

      <SliderWrapper size={size} className={isSmall ? 'js-VideoPlayerControlsSoundSliderContainer' : undefined}>
        <SoundSlider value={volume} setValue={changeVolume} vertical={isSmall} />
      </SliderWrapper>
    </Container>
  );
};
