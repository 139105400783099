import type { FC } from 'react';

import { styled } from '@cofenster/web-components';

import type { Project } from '../../../api/hooks/project/useProjects';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { NewButton } from '../NewButton';

import { ProjectRow } from './ProjectRow';

type Props = {
  projects: Project[];
  folderId: string;
  archived?: boolean;
  withOwner?: boolean;
};

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const ProjectsListView: FC<Props> = ({ projects, folderId, archived, withOwner }) => {
  return (
    <Container>
      {!archived && (
        <TeamPermissionRestriction has="ProjectCreate">
          <NewButton to="projectCreate" params={{ folderId }} variant="compact">
            i18n.projects.buttons.project
          </NewButton>
        </TeamPermissionRestriction>
      )}
      {projects.map((project) => (
        <ProjectRow
          key={project.id}
          project={project}
          projectFolderId={folderId}
          withOwner={withOwner}
          refetchQueries={['Projects']}
        />
      ))}
    </Container>
  );
};
