import type { FC } from 'react';

import {
  BlankButton,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  type IconType,
  Text,
  preventForwardProps,
  styled,
} from '@cofenster/web-components';

const Container = styled(
  BlankButton,
  preventForwardProps(['selected'])
)<{ selected: boolean }>(({ theme, selected }) => ({
  minHeight: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  color: theme.palette.brand.carbon,
  backgroundColor: theme.palette.brand.grey50,
  border: `2px solid ${selected ? theme.palette.brand.blue : 'transparent'}`,
  padding: theme.spacing(0, 1.5),
  width: '100%',

  '&:hover, &:focus': {
    color: selected ? undefined : theme.palette.brand.blue,
  },
}));

const Name = styled(Headline)(() => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'start',
}));

type DialogFolderRowProps = {
  name: string;
  selected: boolean;
  projectCount?: number;
  iconType?: IconType;
  onClick: VoidFunction;
};

export const DialogFolderRow: FC<DialogFolderRowProps> = ({
  name,
  onClick,
  selected,
  projectCount,
  iconType = 'FolderEmptyClosed',
}) => {
  return (
    <Container
      onClick={onClick}
      aria-pressed={selected}
      type="button"
      selected={selected}
      data-testid="project-move-dialog-row"
    >
      <GridContainer alignItems="center" spacing={1} wrap="nowrap">
        <GridItem flex="0 0 auto">
          <Icon
            type={selected ? 'CheckIcon' : iconType}
            color={selected ? 'white' : undefined}
            background={{ size: 'l', color: selected ? 'blue' : 'white' }}
          />
        </GridItem>

        <GridItem xs={true} zeroMinWidth>
          <Name variant="h6" component="p" color="inherit">
            {name}
          </Name>
        </GridItem>

        {typeof projectCount === 'number' && (
          <GridItem flex="0 0 auto">
            <Text variant="s" color="grey700" i18nParams={{ count: projectCount ?? 0 }}>
              i18n.projects.folderCard.videos
            </Text>
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};
