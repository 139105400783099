import {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { usePersistedState } from '@cofenster/web-components';

import { useSceneEditorNotesTracking } from './useSceneEditorNotesTracking';

const SceneEditorNotesStateContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([
  false,
  () => undefined,
]);

export const SceneEditorNotesStateProvider: FC<PropsWithChildren<{ projectId: string }>> = ({
  projectId,
  children,
}) => {
  const [notesOpen, setNotesOpen] = usePersistedState<Record<string, boolean>>('editor-notes', {}, true);
  const track = useSceneEditorNotesTracking();

  const context: [boolean, Dispatch<SetStateAction<boolean>>] = useMemo(() => {
    return [
      notesOpen[projectId] ?? false,
      (notesOpenOrFn) => {
        setNotesOpen((oldValue) => {
          const newValue =
            'function' === typeof notesOpenOrFn ? notesOpenOrFn(oldValue[projectId] ?? false) : notesOpenOrFn;
          track(newValue ? 'sceneNotesOpened' : 'sceneNotesClosed');
          return { ...oldValue, [projectId]: newValue };
        });
      },
    ];
  }, [notesOpen, setNotesOpen, track, projectId]);

  return <SceneEditorNotesStateContext.Provider value={context}>{children}</SceneEditorNotesStateContext.Provider>;
};

export const useSceneEditorNotesState = () => useContext(SceneEditorNotesStateContext);
