import { type FC, type PropsWithChildren, useMemo, useState } from 'react';

import type { RemotionPlayerRef } from '@cofenster/web-remotion-player';

import { RemotionPlayerRefContext } from './RemotionPlayerRefContext';

export const RemotionPlayerRefProvider: FC<PropsWithChildren> = ({ children }) => {
  const [playerRef, setPlayerRef] = useState<RemotionPlayerRef | null>(null);
  const context = useMemo(() => ({ playerRef, setPlayerRef }), [playerRef]);

  return <RemotionPlayerRefContext.Provider value={context}>{children}</RemotionPlayerRefContext.Provider>;
};
