import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import type { ContributionConfigurationTab } from '../../pages/ProjectContributionConfiguration/ProjectContributionConfigurationContent/types';
import { routes } from '../../routes';

export const useGoToProjectContributionsPage = (
  projectId: string,
  type: ContributionConfigurationTab = 'initial',
  replace = false
) => {
  const goto = useGoto({ replace });
  return useCallback(() => {
    return goto(routes.projectContributionsConfiguration, { projectId, type });
  }, [projectId, goto, type]);
};
