import { styled } from '@cofenster/web-components';
import type { FC, ReactNode } from 'react';

const FileName = styled('span')(() => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '10ch',
}));

const FileExtension = styled('span')(() => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));

export const AudioOverlayFileName: FC<{ name?: string; fallback: ReactNode }> = ({ name, fallback }) =>
  name && name.length > 15 ? (
    <>
      <FileName>{name}</FileName>
      <FileExtension aria-hidden="true">.{name.split('.')[1]}</FileExtension>
    </>
  ) : (
    name || fallback
  );
