import { useCallback } from 'react';

import { type DeleteScenesMutationVariables, useDeleteScenesMutation } from '../../generated';

export const useDeleteScenes = ({ awaitRefetchQueries = false } = {}) => {
  const [deleteScenes] = useDeleteScenesMutation({
    refetchQueries: ['Scenes'],
    awaitRefetchQueries,
    update: (cache) => {
      cache.evict({ fieldName: 'scenesSubtitles' });
      cache.evict({ fieldName: 'contributionRequestsByProject' });
    },
  });
  return useCallback(
    (ids: DeleteScenesMutationVariables['ids']) => deleteScenes({ variables: { ids } }),
    [deleteScenes]
  );
};
