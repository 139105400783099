import { useEffect } from 'react';

import { type AudioAssetQueryResult, useAudioAssetLazyQuery } from '../../generated';

export type AudioAsset = NonNullable<AudioAssetQueryResult['data']>['audioAsset'];

export const usePollingAudioAsset = (requestedAudioAssetId: string | null | undefined) => {
  const [getAudioAsset, { data, stopPolling }] = useAudioAssetLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (requestedAudioAssetId) getAudioAsset({ variables: { id: requestedAudioAssetId } });
  }, [requestedAudioAssetId, getAudioAsset]);

  useEffect(() => {
    if (data?.audioAsset?.status === 'Ready') stopPolling();
  }, [data?.audioAsset?.status, stopPolling]);

  return requestedAudioAssetId ? data?.audioAsset : null;
};
