import { useCallback } from 'react';

import { type DeleteThemeVideoAssetMutationVariables, useDeleteThemeVideoAssetMutation } from '../../generated';

export type ThemeVideoAssetId = DeleteThemeVideoAssetMutationVariables['id'];

export const useDeleteThemeVideoAsset = () => {
  const [deleteThemeVideoAsset] = useDeleteThemeVideoAssetMutation({
    refetchQueries: __INTEGRATED_INTRO_OUTRO_EDITOR__
      ? ['Me', 'Project', 'ProjectRenderDescription']
      : ['Me', 'Project'],
  });

  return useCallback(
    (id: DeleteThemeVideoAssetMutationVariables['id']) => {
      return deleteThemeVideoAsset({
        variables: {
          id,
        },
      });
    },
    [deleteThemeVideoAsset]
  );
};
