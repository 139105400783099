import { useMemo } from 'react';

import { type ChangeTeamMemberRoleMutationVariables, useChangeTeamMemberRoleMutation } from '../../generated';

export const useChangeTeamMemberRole = () => {
  const [changeTeamMembersRole, { loading }] = useChangeTeamMemberRoleMutation({
    refetchQueries: ['Team', 'ProjectFolder'],
    awaitRefetchQueries: true,
  });
  return useMemo(
    () => ({
      changeTeamMembersRole: async (
        id: ChangeTeamMemberRoleMutationVariables['id'],
        input: ChangeTeamMemberRoleMutationVariables['input']
      ) => changeTeamMembersRole({ variables: { id, input } }),
      loading,
    }),
    [changeTeamMembersRole, loading]
  );
};
