import type { FC } from 'react';

import { IntercomButton } from '@cofenster/intercom';
import { GridContainer, GridItem } from '@cofenster/web-components';

import { RouterButton } from '../../components/button/RouterButton';
import { PageHeadline, PageSubline, UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';

export const SignInError: FC = () => {
  return (
    <UnauthenticatedPage title="i18n.SignInError.title">
      <PageHeadline variant="h2" component="h1">
        i18n.SignInError.headline
      </PageHeadline>
      <PageSubline variant="xl" component="p">
        i18n.SignInError.subline
      </PageSubline>
      <GridContainer>
        <GridItem>
          <RouterButton to="signin" variant="tertiary">
            i18n.auth.button.backToLogin
          </RouterButton>
        </GridItem>
        <GridItem ml="auto">
          <IntercomButton fullWidth>i18n.SignInError.requestAccess</IntercomButton>
        </GridItem>
      </GridContainer>
    </UnauthenticatedPage>
  );
};
