import { type Dispatch, type FC, type SetStateAction, useContext } from 'react';

import { GridContainer, GridItem } from '@cofenster/web-components';

import type { StockImage, StockImageResponse } from '../../../api/hooks/stockFootage/useSearchStockImages';
import type { StockVideo, StockVideoResponse } from '../../../api/hooks/stockFootage/useSearchStockVideos';
import { ResultPreview } from './ResultPreview';
import { SearchContext } from './SearchContext';
import { SelectAssetButton } from './SelectAssetButton';

export const SearchResults: FC<{
  data: StockImageResponse | StockVideoResponse;
  setSelectedAsset: Dispatch<SetStateAction<StockImage | StockVideo | null>>;
}> = ({ data, setSelectedAsset }) => {
  const { videoFormat } = useContext(SearchContext);

  return (
    <GridContainer spacing={2}>
      {data.items.map((item) => (
        <GridItem xs={12} sm={6} md={videoFormat === 'Vertical' ? 3 : 4} key={item.id}>
          <SelectAssetButton onClick={() => setSelectedAsset(item)} type="button" data-testid="search-result-button">
            <ResultPreview asset={item} />
          </SelectAssetButton>
        </GridItem>
      ))}
    </GridContainer>
  );
};
