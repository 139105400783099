import { type FormikValues, useFormikContext } from 'formik';
import { type CSSProperties, type ChangeEventHandler, type FC, useCallback } from 'react';

import {
  Icon,
  SelectableBoxInput,
  Tooltip,
  Translatable,
  Typography,
  VisuallyHidden,
  styled,
} from '@cofenster/web-components';

import type { BackgroundType } from '../../contexts/project/useProject';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

const Fieldset = styled('fieldset')(() => ({
  border: 0,
  padding: 0,
  margin: 0,
}));

const BackgroundIcon = styled('span')<{ blurred?: boolean }>(({ blurred, theme }) => ({
  width: '1.75em',
  height: '1.75em',
  borderRadius: '0.35em',
  backgroundColor: `var(--color, ${theme.palette.brand.linen})`,
  flex: '0 0  auto',
  position: 'relative',
  filter: blurred ? 'blur(2px)' : undefined,
}));

// 1. Undo default styles from the <figure> element.
const TooltipContent = styled('figure')(() => ({
  margin: 0, // 1
  maxWidth: 150,
}));

// 1. Simulate a desktop screen with a forced aspect ratio (authored as a string
//    to avoid Emotion converting it to a pixel value).
const FakeImage = styled('div')<{ image?: string }>(({ theme, image }) => ({
  width: '100%', // 1
  aspectRatio: '16 / 9', // 1
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: image ? `url(${image})` : undefined,
  backgroundColor: `var(--color, ${theme.palette.brand.linen})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  margin: theme.spacing(0.5, 'auto'),
}));

const PlayIcon = styled(Icon)(({ theme }) => ({
  margin: 'auto',
  height: '100%',
  backgroundColor: theme.palette.brand.linen,
  padding: theme.spacing(0, 1.5),
  boxSizing: 'content-box',
}));

const tooltipStyles = {
  display: 'inline-block',
  verticalAlign: 'top',
  marginLeft: '0.5em',
  zIndex: 2,
  position: 'relative' as const,
};

const ExampleTooltip: FC<{ color?: string; image?: string; text: string }> = ({ color, image, text }) => (
  <Tooltip
    style={tooltipStyles}
    title={
      <TooltipContent>
        <FakeImage style={{ '--color': color } as CSSProperties} image={image}>
          <PlayIcon type="PlayIcon" weight="fill" color="carbon" />
        </FakeImage>
        <Typography variant="s" component="figcaption">
          {text}
        </Typography>
      </TooltipContent>
    }
  >
    {/* This span is necessary to serve as an anchor for the tooltip, since the
     * `Icon` component isn’t forwarding refs. Alternatively we could wrap the
     * `Icon` component with `forwardRef` but it seems rather uncommon for an
     * icon alone to have a tooltip.
     */}
    <span>
      <Icon type="InfoIcon" size="s" />
    </span>
  </Tooltip>
);

export const BackgroundSelector: FC<{ primaryColor?: string | null }> = ({ primaryColor }) => {
  const tracking = useWebManagerTracking();
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const trackDesignBackgroundUpdated = useCallback(
    (designBackground: BackgroundType) => {
      tracking.trackEvent({
        event: 'designBackgroundUpdated',
        details: { designBackground },
      });
    },
    [tracking]
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setFieldValue(event.target.name, event.target.value, true);
      trackDesignBackgroundUpdated(event.target.value as BackgroundType);
    },
    [setFieldValue, trackDesignBackgroundUpdated]
  );

  return (
    <Fieldset>
      <VisuallyHidden as="legend">
        <Translatable>i18n.projectDesign.templateForm.background</Translatable>
      </VisuallyHidden>
      <SelectableBoxInput
        mainIcon={<BackgroundIcon style={{ '--color': 'black' } as CSSProperties} />}
        type="radio"
        id="black"
        name="background"
        value="Black"
        onChange={handleChange}
        title={
          <>
            <Translatable>i18n.projectDesign.templateForm.background.black</Translatable>
            <ExampleTooltip color="black" text="i18n.projectDesign.templateForm.backgroundTooltip.black" />
          </>
        }
        description="i18n.projectDesign.templateForm.backgroundTooltip.black"
        checked={values.background === 'Black'}
        data-testid="black-background-input"
      />
      <SelectableBoxInput
        mainIcon={<BackgroundIcon style={{ '--color': primaryColor } as CSSProperties} />}
        type="radio"
        id="primary"
        name="background"
        value="Primary"
        onChange={handleChange}
        title={
          <>
            <Translatable>i18n.projectDesign.templateForm.background.primary</Translatable>
            <ExampleTooltip
              color={primaryColor || undefined}
              text="i18n.projectDesign.templateForm.backgroundTooltip.primary"
            />
          </>
        }
        description="i18n.projectDesign.templateForm.backgroundTooltip.primary"
        checked={values.background === 'Primary'}
        data-testid="primary-background-input"
      />
      <SelectableBoxInput
        mainIcon={<BackgroundIcon blurred />}
        type="radio"
        id="blurred"
        name="background"
        value="Blurred"
        onChange={handleChange}
        title={
          <>
            <Translatable>i18n.projectDesign.templateForm.background.blurred</Translatable>
            <ExampleTooltip
              image="/assets/images/blurred.jpg"
              text="i18n.projectDesign.templateForm.backgroundTooltip.blurred"
            />
          </>
        }
        description="i18n.projectDesign.templateForm.backgroundTooltip.blurred"
        checked={values.background === 'Blurred'}
        data-testid="blurred-background-input"
      />
    </Fieldset>
  );
};
