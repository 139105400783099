import type { FC } from 'react';

import { Icon, type IconType, LoadingSpinner, Typography, styled } from '@cofenster/web-components';

type VideoAnalyticsDetailProps = {
  title: string;
  value: string | number;
  description: string;
  iconName: IconType;
  loading: boolean;
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

// 1. Ensure the icon cannot be squished and remains a perfect circle
const Left = styled('div')(() => ({
  flexShrink: 0, // 1
}));

const Center = styled('div')(() => ({}));

// 1. Push the right content all the way to the right
const Right = styled('div')(() => ({
  marginLeft: 'auto', // 1
  textAlign: 'end',
}));

export const AnalyticsDetail: FC<VideoAnalyticsDetailProps> = ({ title, value, description, iconName, loading }) => {
  return (
    <Container>
      <Left>
        <Icon type={iconName} color="blue" size="m" background={{ size: 'l', color: 'blue50' }} />
      </Left>
      <Center>
        <Typography variant="h6" component="p">
          {title}
        </Typography>
        <Typography variant="m" color="grey700" component="p">
          {description}
        </Typography>
      </Center>
      <Right>
        {loading ? (
          <LoadingSpinner size={25} spacing={0} />
        ) : (
          <Typography variant="h4" component="p">
            {value}
          </Typography>
        )}
      </Right>
    </Container>
  );
};
