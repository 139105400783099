import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';

import { useFileUpload } from '@cofenster/web-components';

import { useInitVideoUpload } from './useInitVideoUpload';

export const useUploadVideoAsset = () => {
  const initVideoUpload = useInitVideoUpload();
  const uploadFile = useFileUpload();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ) => {
      const { videoFormat, videoFit, videoCaptureSource } = options ?? {};
      const result = await initVideoUpload(videoFormat, videoFit, videoCaptureSource);

      if (!result.data?.initVideoUpload) {
        throw new Error('Failed to initialize video upload');
      }

      const { uploadUrl, videoAssetId } = result.data.initVideoUpload;
      const uploadingResult = await uploadFile(uploadUrl, file, uploadId, onProgress, abortSignal);
      if (!uploadingResult) {
        // Upload failed or was aborted
        return undefined;
      }
      return videoAssetId;
    },
    [initVideoUpload, uploadFile]
  );
};
