import { useMemo } from 'react';

import { type ProjectFoldersQuery, type ProjectFoldersQueryVariables, useProjectFoldersQuery } from '../../generated';

export type ProjectFolder = ProjectFoldersQuery['projectFolders'][number];

export type ProjectFoldersFilter = NonNullable<ProjectFoldersQueryVariables['filter']>;

type FetchPolicy = NonNullable<Parameters<typeof useProjectFoldersQuery>[0]>['fetchPolicy'];

export const useProjectFolders = (
  parentFolderId: ProjectFoldersQueryVariables['parentFolderId'],
  filter?: ProjectFoldersFilter,
  fetchPolicy: FetchPolicy = 'cache-and-network'
) => {
  const { error, loading, data } = useProjectFoldersQuery({
    variables: { filter, parentFolderId },
    fetchPolicy,
  });
  const projectFolders = data?.projectFolders;
  return useMemo(() => ({ projectFolders: projectFolders || [], loading, error }), [projectFolders, loading, error]);
};
