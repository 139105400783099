import { type FC, type MouseEvent, useCallback, useMemo } from 'react';

import {
  Button,
  Icon,
  Typography,
  styled,
  useCopyToClipboard,
  useSnackbars,
  withPopover,
} from '@cofenster/web-components';

import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

import { ShareLinkPopover } from './ShareLinkPopover';

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}));

const SnackbarContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

export const SharingLinkActions: FC<{
  url?: string;
  title: string;
}> = ({ title, url }) => {
  const { copyToClipboard } = useCopyToClipboard();
  const { openSnackbar } = useSnackbars();
  const embedText = createVideoEmbedCode(url ?? '', title);
  const tracking = useWebManagerTracking();

  const trackData = useCallback(
    (event: string, destination?: string) => {
      tracking.trackEvent({ event, details: { destination } });
    },
    [tracking]
  );

  const handleCopyEmbedToClipboard = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      const { currentTarget } = event;
      const embedText = currentTarget.getAttribute('data-copy-to-clipboard-value');
      if (!embedText) return;
      copyToClipboard(embedText);
      trackData('videoEmbedCopied');
      openSnackbar({
        variant: 'info',
        children: (
          <SnackbarContent>
            <Icon type="CheckCircleIcon" />
            <Typography>i18n.snackbars.videoLink.embed</Typography>
          </SnackbarContent>
        ),
      });
    },
    [copyToClipboard, openSnackbar, trackData]
  );

  const handleCopyLinkToClipboard = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { currentTarget } = event;
      const sharingLinkUrl = currentTarget.getAttribute('data-copy-to-clipboard-value');
      if (!sharingLinkUrl) return;
      copyToClipboard(sharingLinkUrl);
      trackData('videoLinkCopied');
      openSnackbar({
        variant: 'info',
        children: (
          <SnackbarContent>
            <Icon type="CheckCircleIcon" />
            <Typography>i18n.snackbars.videoLink.copy</Typography>
          </SnackbarContent>
        ),
      });
    },
    [copyToClipboard, openSnackbar, trackData]
  );

  const SharePopoverToggle = useMemo(
    () =>
      withPopover(Button, {
        anchorOriginX: 'right',
        anchorOriginY: 'top',
        transformOriginX: 'right',
        transformOriginY: 'bottom',
        offsetY: -10,
        children: (
          <ShareLinkPopover
            embedText={embedText}
            url={url}
            copyEmbedFromClipboard={handleCopyEmbedToClipboard}
            trackData={trackData}
          />
        ),
      }),
    [embedText, handleCopyEmbedToClipboard, trackData, url]
  );

  if (!url) return null;

  return (
    <ButtonsWrapper>
      <TeamPermissionRestriction has="SharingLinkCreate">
        <SharePopoverToggle
          variant="tertiary"
          startIcon={<Icon size="s" type="ShareIcon" />}
          data-testid="share-video-button"
        >
          i18n.videoAnalytics.share
        </SharePopoverToggle>
      </TeamPermissionRestriction>

      <Button
        startIcon={<Icon size="s" type="LinkIcon" />}
        onClick={handleCopyLinkToClipboard}
        data-testid="sharing-link-copy-container"
        // We are using data attribute here because cypress does not work
        // nicely with clipboard API. This way we read the value in tests
        // and code in the same way.
        data-copy-to-clipboard-value={url}
      >
        i18n.global.shared.SharingPanel.copyLink
      </Button>
    </ButtonsWrapper>
  );
};

const createVideoEmbedCode = (src: string, title: string) =>
  `<iframe width="560" height="315" src="${src}?embed=1" frameborder="0" title="${title} — Cofenster" allowfullscreen></iframe>`;
