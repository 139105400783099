import { styled } from '@mui/material';
import { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { Handles, Slider, Rail as SliderRail } from 'react-compound-slider';

import { Handle } from './Handle';
import { Rail } from './Rail';

const Container = styled('div')({
  height: 18,
  width: '100%',
  marginLeft: 9,
  marginRight: 9,
});

const sliderRootStyle = {
  position: 'relative',
  height: 18,
  width: '100%',
} as const;

const initialDomain = [0, 1] as const;

const useSliderCallback = (callback: SliderCallback | undefined, preventRepeatedCalls = true) => {
  const valueRef = useRef<number | undefined>(undefined);

  return useCallback(
    (values: Readonly<number[]>) => {
      if (typeof callback !== 'function') return;

      const [value] = values;

      // Avoid firing the callback multiple times with the same value by caching
      // the value in a ref between calls
      if (typeof value !== 'number' || valueRef.current === value) return;

      // For the `onDragEnd` event, it is important *not* to store the value in
      // a ref, otherwise dragging more than once to the same exact value (e.g.
      // 0) will cause the callback not to be fired after the first time (since
      // the ref will be 0, and the value will be 0 as well).
      if (preventRepeatedCalls) valueRef.current = value;

      callback(value);
    },
    [callback, preventRepeatedCalls]
  );
};

type SliderCallback = (value: number) => void;

type Props = {
  label: string;
  value?: number;
  setIsDragging?: Dispatch<SetStateAction<boolean>>;
  onUpdate?: SliderCallback;
  onChange?: SliderCallback;
  onDragStart?: SliderCallback;
  onDragEnd?: SliderCallback;
  disabled?: boolean;
};

export const PlaybackSlider: FC<Props> = ({ label, value = 0, setIsDragging, disabled, ...props }) => {
  const onUpdate = useSliderCallback(props.onUpdate);
  const onChange = useSliderCallback(props.onChange);
  const onDragStart = useSliderCallback(props.onDragStart);
  const onDragEnd = useSliderCallback(props.onDragEnd, false);

  const [element, setElement] = useState<HTMLDivElement | null>(null);

  // ATTENTION: 3rd-party bug fix.
  const setAsDragging = useCallback(() => setIsDragging?.(true), [setIsDragging]);
  const setAsNotDragging = useCallback(() => setIsDragging?.(false), [setIsDragging]);

  useEffect(() => {
    const document = element?.ownerDocument;
    if (document) {
      document.addEventListener('mouseup', setAsNotDragging);
      return () => document.removeEventListener('mouseup', setAsNotDragging);
    }
  }, [element, setAsNotDragging]);

  return (
    <Container onMouseDownCapture={setAsDragging} ref={setElement}>
      <Slider
        mode={2}
        step={1 / 1000}
        domain={initialDomain}
        values={[value]}
        rootStyle={sliderRootStyle}
        onUpdate={onUpdate}
        onChange={onChange}
        onSlideStart={onDragStart}
        onSlideEnd={onDragEnd}
        disabled={disabled}
      >
        <SliderRail>{({ getRailProps }) => <Rail {...getRailProps()} />}</SliderRail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  label={label}
                  handle={handle}
                  domain={initialDomain}
                  disabled={disabled}
                  {...getHandleProps(handle.id, {
                    onKeyDown: setAsDragging,
                    onKeyUp: setAsNotDragging,
                    onBlur: setAsNotDragging,
                  })}
                />
              ))}
            </>
          )}
        </Handles>
      </Slider>
    </Container>
  );
};
