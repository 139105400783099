import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import { usePersistedState } from '@cofenster/web-components';

import { ListViewTypeContext } from './ListViewTypeContext';

type ViewTypeProviderProps = {
  id: string;
};

export const ListViewTypeProvider: FC<PropsWithChildren<ViewTypeProviderProps>> = ({ id, children }) => {
  const [type, setType] = usePersistedState<ListViewTypeContext['type']>(`view_${id}`, 'GRID');
  const toggleViewType = useCallback(() => {
    setType(type === 'GRID' ? 'LIST' : 'GRID');
  }, [type, setType]);

  const context = useMemo(
    () => ({
      type,
      toggleViewType,
    }),
    [type, toggleViewType]
  );

  return <ListViewTypeContext.Provider value={context}>{children}</ListViewTypeContext.Provider>;
};
