import { useCallback } from 'react';

import { type CreateThemeVideoAssetMutationVariables, useCreateThemeVideoAssetMutation } from '../../generated';

export type CreateThemeVideoAssetInput = CreateThemeVideoAssetMutationVariables['input'];

export const useCreateThemeVideoAsset = () => {
  const [createThemeVideoAsset] = useCreateThemeVideoAssetMutation({
    refetchQueries: __INTEGRATED_INTRO_OUTRO_EDITOR__
      ? ['Me', 'Project', 'ProjectRenderDescription']
      : ['Me', 'Project'],
  });

  return useCallback(
    (input: CreateThemeVideoAssetMutationVariables['input']) => {
      return createThemeVideoAsset({
        variables: {
          input,
        },
      });
    },
    [createThemeVideoAsset]
  );
};
