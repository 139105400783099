import { useMemo } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import type { Project } from '../../../api/hooks/project/useProjects';

export const useVideoFormatTextAndIcon = (videoFormat: VideoFormat) => {
  return useMemo(() => {
    const videoFormatsTexts = {
      Horizontal: 'i18n.common.horizontal',
      Vertical: 'i18n.common.vertical',
      Square: 'i18n.common.square',
      SocialMedia: 'i18n.common.socialMedia',
    };
    const videoFormatText = videoFormatsTexts[videoFormat];

    const videoFormatsIcons: {
      [key in Project['videoFormat']]: 'MonitorIcon' | 'MobileIcon' | 'SquareIcon' | 'SocialMediaIcon';
    } = {
      Horizontal: 'MonitorIcon',
      Vertical: 'MobileIcon',
      Square: 'SquareIcon',
      SocialMedia: 'SocialMediaIcon',
    };
    const videoFormatsIcon = videoFormatsIcons[videoFormat];

    return {
      icon: videoFormatsIcon,
      text: videoFormatText,
    };
  }, [videoFormat]);
};
