import type { FC } from 'react';

import { Icon, Typography, styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(6, 5, 6, 6),
  alignItems: 'center',
}));

const NoOptionsIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.brand.grey100,
  borderRadius: '50%',
  padding: theme.spacing(0.5),
}));

export const NoOptions: FC = () => {
  return (
    <Container>
      <NoOptionsIcon type="NoOptionIcon" size="xl" />
      <Typography component="h4" variant="h4">
        i18n.noOption.title
      </Typography>
      ¨<Typography variant="l">i18n.noOption.description</Typography>
    </Container>
  );
};
