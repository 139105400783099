import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FromProjectTeamProvider } from '../../contexts/currentTeam/FromProjectTeamProvider';
import { ProjectProvider } from '../../contexts/project/ProjectProvider';
import { ScenesProvider } from '../../contexts/scenes/ScenesProvider';
import type { ProjectRouteParams } from '../../routes';

import { LoadingSpinner } from '@cofenster/web-components';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import { NoAccessLayout } from '../NoAccess';
import { ProjectMusicContent } from './ProjectMusicContent';

export const ProjectMusic: FC = () => {
  const { projectId } = useParams() as ProjectRouteParams;

  return (
    <ProjectProvider projectId={projectId}>
      <ScenesProvider projectId={projectId} pollingInterval={60000}>
        <FromProjectTeamProvider>
          <TeamPermissionRestriction has="ProjectUpdate" fallback={<NoAccessLayout />} loader={<LoadingSpinner />}>
            <ProjectMusicContent />
          </TeamPermissionRestriction>
        </FromProjectTeamProvider>
      </ScenesProvider>
    </ProjectProvider>
  );
};
