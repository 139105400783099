import type { FC } from 'react';

import { LoadingSpinner, Typography, styled } from '@cofenster/web-components';

const Container = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

type Props = {
  language: string;
  additionalText?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export const LanguageDisplay: FC<Props> = ({ language, additionalText, isLoading }) => {
  return (
    <Container>
      <StyledSpan>
        {isLoading && <LoadingSpinner size={24} spacing={0} />}
        <Typography color={'carbon'} variant="m">
          {language}
        </Typography>
      </StyledSpan>
      <Typography color="grey500">{additionalText}</Typography>
    </Container>
  );
};
