import type { FC } from 'react';

import { Dialog, LoadingSpinner } from '@cofenster/web-components';
import { useProject } from '../../../api/hooks/project/useProject';
import { useProjectSubtitlesLanguages } from '../../../api/hooks/project/useProjectSubtitlesLanguages';
import { useRenderJobsByProject } from '../../../api/hooks/renderJob/useRenderJobsByProject';
import { SharingLinkSettingsForm } from './SharingLinkSettingsForm';

export type SharingLinkSettingsDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectId: string;
};

export const SharingLinkSettingsDialog: FC<SharingLinkSettingsDialogProps> = ({ isOpen, closeDialog, projectId }) => {
  const { project, refetch } = useProject(projectId);
  const { languages } = useProjectSubtitlesLanguages(projectId);
  const { renderJobs } = useRenderJobsByProject(projectId, refetch);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="i18n.SharingLinkSettingsDialog.title">
      {project?.sharingLink && languages && renderJobs ? (
        <SharingLinkSettingsForm
          closeDialog={closeDialog}
          sharingLink={project.sharingLink}
          languages={languages}
          renderJobs={renderJobs}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Dialog>
  );
};
