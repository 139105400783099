import type { FC } from 'react';

import { Button, type ButtonProps, Icon, SpinningIcon, Translatable } from '@cofenster/web-components';

import { useAudioOverlayContext } from './Context';
import { AudioOverlayFileName } from './FileName';

const useVideoEnhancementStatus = () => {
  const { videoSceneAsset } = useAudioOverlayContext();
  return videoSceneAsset?.asset?.videoAssetWithEnhancedAudio?.status;
};

const useAudioAssetStatus = () => {
  const { audioSceneAsset } = useAudioOverlayContext();
  return audioSceneAsset?.asset?.audioAsset?.status;
};

// The audio overlay button is *outside* of the audio overlay panel, therefore
// it shouldn’t be reactive to changes within the panel and only reflect the
// original status.
export const AudioOverlayButton: FC<
  ButtonProps & {
    isPanelOpen: boolean;
    openPanel: VoidFunction;
  }
> = ({ isPanelOpen, openPanel, ...rest }) => {
  const { savedStatus, audioSceneAsset } = useAudioOverlayContext();
  const audioStatus = useAudioAssetStatus();
  const videoEnhancementStatus = useVideoEnhancementStatus();
  const baseProps: ButtonProps = { ...rest, onClick: openPanel, variant: 'tertiary' };

  // When closing the panel before the audio upload or audio enhancement is
  // completed, we want to display the progress in the toolbar.
  if (!isPanelOpen && (audioStatus === 'Processing' || videoEnhancementStatus === 'Processing')) {
    return (
      <Button
        {...baseProps}
        startIcon={<SpinningIcon speed="fast" type="LoadingIcon" size="s" />}
        data-testid="scene-audio-button-processing"
      >
        i18n.common.uploading
      </Button>
    );
  }

  if (savedStatus === 'MUTED_AUDIO') {
    return (
      <Button
        {...baseProps}
        startIcon={<Icon type="SpeakerMutedIcon" size="s" />}
        data-testid="scene-audio-button-muted"
      >
        i18n.projectEdit.SceneAudioButton.noAudio
      </Button>
    );
  }

  if (savedStatus === 'UPLOADED_AUDIO') {
    const audioFileName = audioSceneAsset?.asset?.audioAsset?.filename ?? undefined;

    return (
      <Button
        {...baseProps}
        startIcon={<Icon type="SpeakerHighIcon" size="s" />}
        data-testid="scene-audio-button-overlay"
      >
        <AudioOverlayFileName
          name={audioFileName}
          fallback={<Translatable>i18n.projectEdit.SceneAudioPanelContent.sceneAudioType.uploadedAudio</Translatable>}
        />
      </Button>
    );
  }

  if (savedStatus === 'SCENE_AUDIO') {
    return (
      <Button {...baseProps} startIcon={<Icon type="SpeakerHighIcon" size="s" />} data-testid="scene-audio-button">
        i18n.projectEdit.SceneAudioButton.sceneAudio
      </Button>
    );
  }
};
