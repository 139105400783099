import { Button, MenuItem, Typography, preventForwardProps, styled } from '@cofenster/web-components';

export const SelectedOptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const StyledMenuItem = styled(
  MenuItem,
  preventForwardProps(['p'])
)<{ p?: number }>(({ theme, p = 0 }) => ({
  margin: theme.spacing(0, 0, 1, 0),
  padding: theme.spacing(p),
  borderRadius: theme.shape.borderRadius,
}));

export const OptionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(1),
}));

export const CheckIconContainer = styled(
  'div',
  preventForwardProps(['selected'])
)<{ selected: boolean }>(({ theme, selected }) => ({
  marginLeft: 'auto',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  visibility: selected ? 'visible' : 'hidden',
}));

export const ResetFilterButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),
}));

export const LimitedWidthTypography = styled(Typography)(() => ({
  maxWidth: 280,
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
}));
