import type { FC } from 'react';

import { type IconSize, VisuallyHidden, styled } from '@cofenster/web-components';

import type { Project } from '../../../api/hooks/project/useProjects';
import { RouterIconButton } from '../../button/RouterIconButton';

// Make sure the play button sits on top of the expanded link that links to the project
const OverlaidButton = styled(RouterIconButton)(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.above,
  borderRadius: '50%',
}));

export const ProjectWatchButton: FC<{ project: Project; size?: IconSize }> = ({ project, size }) => (
  <OverlaidButton
    icon="PlayIcon"
    iconColor="white"
    iconSize={size}
    iconWeight="fill"
    to="projectVideo"
    params={{ projectId: project.id }}
    label="i18n.projects.projectCard.watch"
    i18nParams={{ name: <VisuallyHidden>{project.name}</VisuallyHidden> }}
    hoverColor="white"
    backgroundColor="carbon"
    hoverBackgroundColor="grey600"
    withViewTransition
  />
);
