import { useCallback } from 'react';

import { type UpdateThemeMutationVariables, useUpdateThemeMutation } from '../../generated';

export type ThemeId = UpdateThemeMutationVariables['id'];
export type ThemeInput = NonNullable<UpdateThemeMutationVariables['input']>;

export const useUpdateTheme = () => {
  const [updateTheme] = useUpdateThemeMutation({
    refetchQueries: ['Me', 'Project'],
  });
  return useCallback(
    (themeId: UpdateThemeMutationVariables['id'], input: UpdateThemeMutationVariables['input']) => {
      return updateTheme({
        variables: {
          id: themeId,
          input,
        },
      });
    },
    [updateTheme]
  );
};
