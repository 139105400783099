import { preventForwardProps, styled } from '@cofenster/web-components';

export const ThumbnailContainer = styled(
  'div',
  preventForwardProps(['dragCount', 'isExcluded', 'isSelected', 'isCurrent', 'hasErrors'])
)<{
  dragCount?: number;
  isExcluded: boolean;
  isSelected: boolean;
  isCurrent: boolean;
  hasErrors: boolean;
}>(({ theme, dragCount, isExcluded, isSelected, isCurrent, hasErrors }) => ({
  position: 'relative',

  display: 'grid',
  gridTemplateAreas: '"metadata content"' as const,
  gap: theme.spacing(1),

  width: 208,
  height: 176,
  padding: theme.spacing(1),

  opacity: isExcluded ? 0.5 : undefined,
  color: isCurrent ? theme.palette.brand.blue : theme.palette.brand.grey700,

  backgroundColor: isCurrent ? theme.palette.brand.blue200 : theme.palette.brand.white,

  boxShadow: `0 0 0 2px ${hasErrors ? theme.palette.brand.negative : isSelected ? theme.palette.brand.blue : 'transparent'} inset`,

  '&:has(.editorThumbnail-button:hover)': {
    backgroundColor: isCurrent ? theme.palette.brand.blue300 : theme.palette.brand.grey100,

    boxShadow: `0 0 0 2px ${hasErrors ? theme.palette.brand.negativeDark : isSelected ? theme.palette.brand.blueDark : 'transparent'} inset`,
  },

  borderRadius: theme.shape['borderRadius-l'],

  ...(typeof dragCount === 'number' &&
    dragCount > 1 && {
      '&::before': {
        zIndex: -1,
        position: 'absolute',
        inset: 0,
        content: '""',
        backgroundColor: theme.palette.brand.white,
        boxShadow: `0 0 0 2px ${theme.palette.brand.blue} inset`,
        borderRadius: theme.shape['borderRadius-l'],
        transform: 'rotate(-2deg) translate(2px, 6px)',
      },
    }),

  ...(typeof dragCount === 'number' &&
    dragCount > 2 && {
      '&::after': {
        zIndex: -2,
        position: 'absolute',
        inset: 0,
        content: '""',
        backgroundColor: theme.palette.brand.white,
        boxShadow: `0 0 0 2px ${theme.palette.brand.blue} inset`,
        borderRadius: theme.shape['borderRadius-l'],
        transform: 'rotate(-4deg) translate(4px, 12px)',
      },
    }),
}));
