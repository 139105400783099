import { styled } from '@mui/material';

import { Text } from '../../typography/Text';

export const FieldLabel = styled(Text)(() => ({
  display: 'inline-block',
  verticalAlign: 'text-top',
  cursor: 'pointer',

  'span:has(> input:disabled) + &': {
    cursor: 'not-allowed',
  },
}));
