import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { FromProjectTeamProvider } from '../../contexts/currentTeam/FromProjectTeamProvider';
import { ProjectProvider } from '../../contexts/project/ProjectProvider';
import { ProjectRenderDescriptionProvider } from '../../contexts/projectRenderDescription/ProjectRenderDescriptionProvider';
import { ScenesProvider } from '../../contexts/scenes/ScenesProvider';
import type { ProjectRouteParams } from '../../routes';

import { ProjectVideoContent } from './ProjectVideoContent';
import { SubtitlesContextProvider } from './SubtitlesLanguageContext';

export const ProjectVideo: FC = () => {
  const { projectId, subtitlesLanguage } = useParams() as ProjectRouteParams;

  return (
    <SubtitlesContextProvider subtitlesLanguage={subtitlesLanguage}>
      <ProjectProvider projectId={projectId}>
        <ScenesProvider projectId={projectId}>
          <FromProjectTeamProvider>
            <ProjectRenderDescriptionProvider projectId={projectId} subtitlesLanguage={subtitlesLanguage}>
              <ProjectVideoContent projectId={projectId} />
            </ProjectRenderDescriptionProvider>
          </FromProjectTeamProvider>
        </ScenesProvider>
      </ProjectProvider>
    </SubtitlesContextProvider>
  );
};
