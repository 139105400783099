import { useCallback } from 'react';

import { useDeleteSharingLinkMutation } from '../../generated';

export const useDeleteSharingLink = () => {
  const [deleteSharingLink] = useDeleteSharingLinkMutation({ refetchQueries: ['Project'] });
  return useCallback(
    async (sharingLinkId: string) => deleteSharingLink({ variables: { id: sharingLinkId } }),
    [deleteSharingLink]
  );
};
