import { type ComponentProps, type FC, forwardRef, useEffect, useRef, useState } from 'react';

import { Icon, Spacing, Typography, styled } from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: theme.spacing(3),
}));

export const RescaledErrorState: FC<{ formatWidth: number }> = ({ formatWidth }) => {
  const [width, setWidth] = useState(0);
  const container = useRef<HTMLDivElement | null>(null);
  const scale = width ? `scale(${formatWidth / width})` : undefined;

  useEffect(() => {
    if (container.current) setWidth(container.current.offsetWidth);
  }, []);

  return <ErrorState ref={container} style={{ transform: scale }} />;
};

export const ErrorState = forwardRef<HTMLDivElement, ComponentProps<'div'>>(function ErrorState(props, ref) {
  return (
    <Container {...props} ref={ref}>
      <Icon type="WarningCircleIcon" size="xxl" color="negative" />
      <Spacing top={1}>
        <Typography variant="l" color="carbon" component="p">
          i18n.global.remotion.error
        </Typography>
      </Spacing>
    </Container>
  );
});
