import { type FC, useCallback } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
  useSnackbars,
} from '@cofenster/web-components';

import { useDeleteUser } from '../../../api/hooks/user/useDeleteUser';

type Values = {
  name: string;
};

const initialValues: Values = { name: '' };

const useValidationSchema = (userName: string) => {
  const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('i18n.dialogs.DeleteUserDialog.userName.error.required')
      .equals([userName], 'i18n.dialogs.DeleteUserDialog.userName.error.equal') as Yup.StringSchema<string>,
  });
  return validationSchema;
};

export type DeleteUserDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  onDone?: () => unknown;
  userName: string;
  userId: string;
};

export const DeleteUserDialog: FC<DeleteUserDialogProps> = ({ isOpen, closeDialog, onDone, userName, userId }) => {
  const { openSnackbar } = useSnackbars();
  const deleteUser = useDeleteUser();

  const validationSchema = useValidationSchema(`${userName}`);

  const onAction = useCallback(async () => {
    await deleteUser(userId);
    openSnackbar({
      children: 'i18n.snackbars.UserDeletedSnackbar.copy',
      i18nParams: { name: `${userName}` },
    });
    closeDialog();
    onDone?.();
  }, [userId, userName, deleteUser, openSnackbar, closeDialog, onDone]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="user-delete-dialog"
      title="i18n.dialogs.DeleteUserDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => onAction()}>
        <DialogContent>
          <Text
            variant="l"
            color="grey600"
            component="p"
            paragraph
            i18nParams={{
              userName: <b>{userName}</b>,
            }}
          >
            i18n.dialogs.DeleteUserDialog.text.paragraph1
          </Text>

          <Text variant="l" color="grey600" component="p" paragraph>
            i18n.dialogs.DeleteUserDialog.text.paragraph2
          </Text>

          <Text variant="l" color="grey600" component="p" paragraph>
            i18n.dialogs.DeleteUserDialog.text.paragraph3
          </Text>
          <Spacing top={2}>
            <FormTextField
              id="deleteUserName"
              name="name"
              label="i18n.dialogs.DeleteUserDialog.userName.label"
              placeholder="i18n.dialogs.DeleteUserDialog.userName.label"
              autoComplete="off"
              data-testid="user-delete-dialog-name-field"
            />
          </Spacing>
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton variant="destructive" fullWidth data-testid="user-delete-dialog-confirm-button">
            i18n.dialogs.DeleteUserDialog.button
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
