import { type FC, useMemo } from 'react';

import { Icon, type IconType } from '../../assets/icons/Icon';
import { ProgressCircle } from '../../feedback/ProgressCircle';
import { EmptyState } from '../../layout/EmptyState';

type RenderingProgressProps = {
  status: 'Waiting' | 'InProgress' | 'Done' | 'Failed';
  step?: 'Initializing' | 'Render' | 'PostProduction' | 'Upload' | null | undefined;
  stepProgress?: number | null | undefined;
};

export const useRenderJobProgress = (
  status: RenderingProgressProps['status'],
  step: RenderingProgressProps['step'],
  currentStepProgress: NonNullable<RenderingProgressProps['stepProgress']>
) => {
  return useMemo(() => {
    // The `Waiting` status lasts until the `step` becomes `InProgress` which is
    // done once the rendering environment on AWS is ready to start. That can
    // take quite a while which is why we fake progress during that time.
    // We cap the progress between 0 and 20%.
    if (status === 'Waiting') return currentStepProgress * 0.2;
    if (status === 'InProgress') {
      // The `Initializing` step lasts while we create the working directory
      // and download ffmpeg, the background music, the intro and the outro.
      // We cap the progress between 20% and 25%.
      if (step === 'Initializing') return 0.2 + currentStepProgress * 0.05;
      // The `Render` step is the actual rendering from Remotion, and should be
      // the main bulk of the rendering.
      // We cap the progress between 25% and 90%.
      if (step === 'Render') return 0.25 + currentStepProgress * 0.65;
      // The `PostProduction` step lasts while the background music is applied
      // if there is one, or instantly resolved.
      // We cap the progress between 90% and 95%.
      if (step === 'PostProduction') return 0.9 + currentStepProgress * 0.1;
      // The `Upload` step lasts while uploading the resulting video file to S3
      // and is the final step of the rendering process.
      // We cap the progress between 95% and 100%.
      if (step === 'Upload') return 0.95 + currentStepProgress * 0.05;
    }
    return 1;
  }, [status, step, currentStepProgress]);
};

export const useRenderJobProgressIconAndDescription = (progress: number, hasFailed: boolean) => {
  const iconType = PROGRESS.find(({ min, max }) => progress > min && progress <= max)?.icon ?? 'ClockCountdownIcon';
  const description = hasFailed
    ? 'i18n.global.renderingProgress.error'
    : PROGRESS.find(({ min, max }) => progress > min && progress <= max)?.text;

  return {
    iconType,
    description,
  };
};

export const RenderingProgress: FC<RenderingProgressProps> = ({ status, step, stepProgress }) => {
  const hasFailed = status === 'Failed';
  const progress = useRenderJobProgress(status, step, stepProgress ?? 0);
  const { iconType, description } = useRenderJobProgressIconAndDescription(progress, hasFailed);

  return (
    <EmptyState
      data-testid="render-in-progress"
      icon={
        <ProgressCircle
          size={80}
          color={hasFailed ? 'negative' : 'blue'}
          progress={progress}
          trackColor="white"
          strokeWidth={5}
        >
          {hasFailed ? (
            <Icon type="WarningCircleIcon" size="xxl" color="negativeDark" weight="fill" />
          ) : (
            <Icon type={iconType} size="l" />
          )}
        </ProgressCircle>
      }
      title="i18n.global.renderingProgress.title"
      description={description}
    />
  );
};

const PROGRESS: { min: number; max: number; icon: IconType; text: string }[] = [
  {
    min: Number.NEGATIVE_INFINITY,
    max: 0.05,
    icon: 'ClockCountdownIcon',
    text: 'i18n.global.renderingProgress.subtitle.0',
  },
  { min: 0.05, max: 0.15, icon: 'DownloadIcon', text: 'i18n.global.renderingProgress.subtitle.1' },
  { min: 0.15, max: 0.2, icon: 'BrandingIcon', text: 'i18n.global.renderingProgress.subtitle.2' },
  { min: 0.2, max: 0.5, icon: 'JoinIcon', text: 'i18n.global.renderingProgress.subtitle.3' },
  { min: 0.5, max: 0.6, icon: 'MusicNoteIcon', text: 'i18n.global.renderingProgress.subtitle.4' },
  { min: 0.6, max: 0.7, icon: 'FadersIcon', text: 'i18n.global.renderingProgress.subtitle.5' },
  { min: 0.7, max: 0.8, icon: 'EyedropperIcon', text: 'i18n.global.renderingProgress.subtitle.6' },
  { min: 0.8, max: 0.9, icon: 'MovieIcon', text: 'i18n.global.renderingProgress.subtitle.7' },
  {
    min: 0.9,
    max: Number.POSITIVE_INFINITY,
    icon: 'HeartIcon',
    text: 'i18n.global.renderingProgress.subtitle.8',
  },
];
