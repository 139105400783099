import type { FC } from 'react';

import { Button, type ButtonProps, Tooltip } from '@cofenster/web-components';
import type { Project } from '../../contexts/project/ProjectContext';
import { ProjectRenderDescriptionProvider } from '../../contexts/projectRenderDescription/ProjectRenderDescriptionProvider';
import { TeamPermissionRestriction } from '../../contexts/user/TeamPermissionRestriction';
import { useProjectExportStatus } from '../../hooks/project/useProjectExportStatus';
import { useStartRenderButtonProps } from '../../pages/ProjectVideo/RenderProgress/useStartRenderButtonProps';
import { RouterButton } from '../button/RouterButton';

const RenderButton: FC<ButtonProps & { project: Project; 'data-testid'?: string }> = ({
  project,
  'data-testid': dataTestId,
  ...rest
}) => {
  const { disabled, label, i18nParams, onClick, ...restRenderButtonProps } = useStartRenderButtonProps(
    project.id,
    null,
    { withNavigation: true }
  );

  const exportStatus = useProjectExportStatus(project, null);

  if (disabled) {
    return (
      <Tooltip title={label} i18nParams={i18nParams}>
        <div>
          <RouterButton
            {...restRenderButtonProps}
            data-testid={dataTestId}
            to="projectVideo"
            params={{ projectId: project.id }}
            variant="secondary"
          >
            i18n.common.exportVideo
          </RouterButton>
        </div>
      </Tooltip>
    );
  }

  if (exportStatus !== 'NOT_RENDERED') {
    return (
      <RouterButton
        {...restRenderButtonProps}
        data-testid={dataTestId}
        to="projectVideo"
        params={{ projectId: project.id }}
        variant="primary"
      >
        i18n.common.exportVideo
      </RouterButton>
    );
  }

  return (
    <Button {...rest} {...restRenderButtonProps} data-testid={dataTestId} onClick={onClick} variant="primary">
      i18n.common.exportVideo
    </Button>
  );
};

export const ExportButton: FC<{ project?: Project }> = ({ project }) =>
  project ? (
    <TeamPermissionRestriction
      has="ProjectRender"
      fallback={
        <RouterButton to="projectVideo" params={{ projectId: project.id }}>
          i18n.common.exportVideo
        </RouterButton>
      }
    >
      <ProjectRenderDescriptionProvider projectId={project.id}>
        <RenderButton project={project} data-testid="export-button" />
      </ProjectRenderDescriptionProvider>
    </TeamPermissionRestriction>
  ) : (
    <Button disabled variant="primary" data-testid="export-button">
      i18n.common.exportVideo
    </Button>
  );
