import { useCallback } from 'react';

import { type DeleteProjectsMutationVariables, useDeleteProjectsMutation } from '../../generated';

export const useDeleteProjects = (refetchQueries?: string[]) => {
  const [deleteProject] = useDeleteProjectsMutation({ refetchQueries: [...(refetchQueries ?? []), 'Projects'] });

  return useCallback(
    (ids: DeleteProjectsMutationVariables['ids']) => deleteProject({ variables: { ids } }),
    [deleteProject]
  );
};
