import { useFeatureFlags } from '@cofenster/web-components';

import { useUser } from '../../contexts/user/useUser';

const ABSOLUTE_MAX_IN_SECONDS = 90 * 60;
const RELATIVE_MAX_IN_SECONDS = 15 * 60;

export const useIsTooLongToExport = (duration: number) => {
  const { user } = useUser();
  const withLongVideoExportsFlag = useFeatureFlags().hasFeature('LONG_VIDEO_EXPORTS');
  const type = withLongVideoExportsFlag ? 'absolute' : 'relative';
  const max = type === 'absolute' ? ABSOLUTE_MAX_IN_SECONDS : RELATIVE_MAX_IN_SECONDS;
  const isTooLongToExport = duration > max;

  return {
    isTooLongToExport: user?.isImpersonated ? false : isTooLongToExport,
    duration,
    max,
    type,
  };
};
