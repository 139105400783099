import { type FC, type MouseEventHandler, useCallback } from 'react';

import { PopoverMenuItem, useI18n, usePopover } from '@cofenster/web-components';

const facebookShareLink = 'https://www.facebook.com/sharer/sharer.php';
const linkedinShareLink = 'https://www.linkedin.com/shareArticle';

type ShareLinkPopoverProps = {
  embedText: string;
  url?: string;
  copyEmbedFromClipboard: MouseEventHandler;
  trackData: (event: string, destination: string) => void;
};

export const ShareLinkPopover: FC<ShareLinkPopoverProps> = ({ embedText, url, copyEmbedFromClipboard, trackData }) => {
  const { closePopover: onClose } = usePopover();
  const { translate } = useI18n();

  const title = encodeURIComponent(translate('global.shared.SharingPanel.link.title'));

  const shareOnLinkedin = useCallback(() => {
    const shareLink = `${linkedinShareLink}?url=${url}&title=${title}`;
    trackData('videoSharedExternally', 'linkedin');
    onClose();
    window.open(shareLink, '_blank');
  }, [trackData, onClose, title, url]);

  const shareOnFacebook = useCallback(() => {
    const shareLink = `${facebookShareLink}?u=${url}&t=${title}`;
    trackData('videoSharedExternally', 'facebook');
    onClose();
    window.open(shareLink, '_blank');
  }, [trackData, onClose, title, url]);

  const shareViaEmail = useCallback(() => {
    const link = `mailto:?subject=${encodeURIComponent(
      translate('global.shared.SharingPanel.email.subject', { title })
    )}&body=${encodeURIComponent(translate('global.shared.SharingPanel.email.body', { url }))}`;
    trackData('videoSharedExternally', 'email');
    window.open(link);
    onClose();
  }, [translate, title, url, trackData, onClose]);

  const copyEmbed: MouseEventHandler = useCallback(
    (event) => {
      copyEmbedFromClipboard(event);
      onClose();
    },
    [copyEmbedFromClipboard, onClose]
  );

  return (
    <>
      <PopoverMenuItem icon="LinkedinIcon" onClick={shareOnLinkedin}>
        i18n.sharingOptions.linkedin
      </PopoverMenuItem>
      <PopoverMenuItem icon="FacebookIcon" onClick={shareOnFacebook}>
        i18n.sharingOptions.facebook
      </PopoverMenuItem>
      <PopoverMenuItem icon="EnvelopeIcon" onClick={shareViaEmail}>
        i18n.sharingOptions.email
      </PopoverMenuItem>
      <PopoverMenuItem
        icon="CodeIcon"
        onClick={copyEmbed}
        // We are using data attribute here because cypress does not work
        // nicely with clipboard API. This way we read the value in tests
        // and code in the same way.
        data-copy-to-clipboard-value={embedText}
        data-testid="sharing-embed-copy-button"
      >
        i18n.sharingOptions.copyEmbed
      </PopoverMenuItem>
    </>
  );
};
