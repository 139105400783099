import { useContext } from 'react';

export type { RenderJob } from '../../api/hooks/renderJob/useRenderJobByProject';

import { ProjectContext } from './ProjectContext';
import type { Project } from './ProjectContext';

export type { Project } from './ProjectContext';

export type ProjectTheme = Project['theme'];
export type BackgroundType = NonNullable<ProjectTheme>['background'];
export type Template = NonNullable<Project['template']>;

export const useProject = () => useContext(ProjectContext);
