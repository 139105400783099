import type { FormikValues } from 'formik';
import { type FC, type MouseEventHandler, useCallback } from 'react';

import {
  BlankButton,
  Card,
  Form,
  FormToggleSwitch,
  GridContainer,
  GridItem,
  LoadingSpinner,
  Switch,
  TextLink,
  Typography,
  styled,
  useGoto,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import { useConsentSettingsInfo } from '../../../api/hooks/consentSettings/useConsentSettingsInfo';
import { useContributionSettings } from '../../../api/hooks/contributionSettings/useContributionSettings';
import { useCreateOrUpdateContributionSettings } from '../../../api/hooks/contributionSettings/useCreateOrUpdateContributionSettings';
import { RouterIconButton } from '../../../components/button/RouterIconButton';
import { CardHeadline } from '../../../components/card';
import { FeatureFlagRestriction } from '../../../components/featureFlag/FeatureFlagRestriction';
import { UpsellBanner } from '../../../components/featureFlag/UpsellBanner';
import { useAccountPermissionStatus } from '../../../contexts/user/AccountPermissionRestriction';
import { TeamPermissionRestriction } from '../../../contexts/user/TeamPermissionRestriction';
import { useChoiceDialog } from '../../../hooks/useChoiceDialog';
import { useI18n } from '../../../i18n';
import { routes } from '../../../routes';

const useInitialValues = (projectId: string) => {
  const { contributionSettings } = useContributionSettings(projectId);
  return contributionSettings;
};

const BooleanFieldAutoSaveForm: FC<{
  id: string;
  name:
    | 'acceptResponses'
    | 'createSceneOnContribution'
    | 'collectDetails'
    | 'requireConsent'
    | 'showOthersContributions';
  title: string;
  description: string;
  initialValue: boolean;
  projectId: string;
  'data-testid'?: string;
}> = ({ id, name, title, description, initialValue, projectId, ...rest }) => {
  const { createOrUpdateContributionSettings } = useCreateOrUpdateContributionSettings();

  const onSubmit = useCallback(
    async (values: FormikValues) => {
      const newValue = values[name] as boolean;
      await createOrUpdateContributionSettings({
        projectId,
        [name]: newValue,
      });
    },
    [createOrUpdateContributionSettings, projectId, name]
  );

  return (
    <Form initialValues={{ [name]: initialValue }} onSubmit={onSubmit}>
      {({ submitForm, isSubmitting }) => (
        <FormToggleSwitch id={id} name={name} onChange={() => submitForm()} disabled={isSubmitting} {...rest}>
          <TwoLineRow>
            <Typography variant="h6">{title}</Typography>
            <Typography>{description}</Typography>
          </TwoLineRow>
        </FormToggleSwitch>
      )}
    </Form>
  );
};

const MockBooleanFieldAutoSaveForm: FC<{ id: string; title: string; description: string }> = ({
  id,
  title,
  description,
}) => {
  return (
    <Switch id={id} disabled>
      <TwoLineRow>
        <Typography variant="h6">{title}</Typography>
        <Typography>{description}</Typography>
      </TwoLineRow>
    </Switch>
  );
};

export const ContributionConfigurationSettings: FC<{ projectId: string }> = ({ projectId }) => {
  const learnMoreUrl = useLocalizedWebsiteUrl('CONSENT_INFO');

  const initialValues = useInitialValues(projectId);
  if (!initialValues) return <LoadingSpinner />;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeadline variant="h4" component="h2">
            i18n.projectContributionConfiguration.section.general.title
          </CardHeadline>
          <GridContainer>
            <GridItem xs={12}>
              <BooleanFieldAutoSaveForm
                id="createSceneOnContribution"
                name="createSceneOnContribution"
                projectId={projectId}
                title="i18n.projectContributionConfiguration.settings.createSceneOnContribution.title"
                description="i18n.projectContributionConfiguration.settings.createSceneOnContribution.description"
                initialValue={initialValues.createSceneOnContribution}
              />
            </GridItem>
            <GridItem xs={12}>
              <BooleanFieldAutoSaveForm
                id="acceptResponses"
                name="acceptResponses"
                projectId={projectId}
                title="i18n.projectContributionConfiguration.settings.acceptResponses.title"
                description="i18n.projectContributionConfiguration.settings.acceptResponses.description"
                initialValue={initialValues.acceptResponses}
                data-testid="contribution-request-config-accept-responses"
              />
            </GridItem>
            <GridItem xs={12}>
              <BooleanFieldAutoSaveForm
                id="showOthersContributions"
                name="showOthersContributions"
                projectId={projectId}
                title="i18n.projectContributionConfiguration.settings.showOthersContributions.title"
                description="i18n.projectContributionConfiguration.settings.showOthersContributions.description"
                initialValue={initialValues.showOthersContributions}
                data-testid="contribution-request-config-show-others-contributions"
              />
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeadline variant="h4" component="h2">
            i18n.projectContributionConfiguration.section.dataAndPrivacy.title
          </CardHeadline>
          <GridContainer>
            <FeatureFlagRestriction has="CONSENT">
              <ConsentRow projectId={projectId} initialValues={initialValues} />
            </FeatureFlagRestriction>
            <GridItem xs={12}>
              <BooleanFieldAutoSaveForm
                id="collectDetails"
                name="collectDetails"
                projectId={projectId}
                title="i18n.projectContributionConfiguration.settings.collectDetails.title"
                description="i18n.projectContributionConfiguration.settings.collectDetails.description"
                initialValue={initialValues.collectDetails}
                data-testid="contribution-request-config-collect-details"
              />
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
      <FeatureFlagRestriction not="CONSENT">
        <GridItem xs={12} data-testid="consent-upsell-banner">
          <UpsellBanner title="i18n.upsellBanner.CONSENT.title" learnMoreUrl={learnMoreUrl} featureRelation="CONSENT">
            i18n.upsellBanner.CONSENT.content
          </UpsellBanner>
        </GridItem>
      </FeatureFlagRestriction>
    </GridContainer>
  );
};

const ConsentRow: FC<{ projectId: string; initialValues: FormikValues }> = ({ projectId, initialValues }) => {
  const { translate } = useI18n();
  const isAllowedToConfigureConsent = useAccountPermissionStatus({ has: 'ConsentCreate' }) === 'ALLOWED';
  const { consentSettingsInfo } = useConsentSettingsInfo();
  const consentInfoUrl = useLocalizedWebsiteUrl('CONSENT_INFO');
  const navigate = useGoto();
  const openDialog = useChoiceDialog({
    title: 'i18n.projectContributionConfiguration.settings.requireConsent.notConfigured.title',
    content: (
      <Typography>
        {translate(
          isAllowedToConfigureConsent
            ? 'projectContributionConfiguration.settings.requireConsent.notConfigured.admin'
            : 'projectContributionConfiguration.settings.requireConsent.notConfigured.nonAdmin',
          {
            link: (chunk) => {
              return (
                <StyledLink href={consentInfoUrl} target="_blank" rel="noopener noreferrer">
                  {chunk}
                </StyledLink>
              );
            },
          }
        )}
      </Typography>
    ),
    left: isAllowedToConfigureConsent ? 'i18n.global.cancel' : undefined,
    right: isAllowedToConfigureConsent
      ? 'i18n.projectContributionConfiguration.settings.requireConsent.notConfigured.configureButton'
      : 'i18n.global.cancel',
    rightValue: () => {
      if (!isAllowedToConfigureConsent) return;
      navigate(routes.settingsAccountConsents);
    },
    leftValue: () => {},
    rightProps: { variant: 'primary', fullWidth: isAllowedToConfigureConsent },
  });

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    if (consentSettingsInfo?.configured) return;
    const choice = await openDialog();
    choice?.();
  }, [openDialog, consentSettingsInfo?.configured]);

  return (
    <>
      {consentSettingsInfo?.configured && !consentSettingsInfo.enabled && (
        <GridItem xs={12}>
          <Banner>
            <TwoLineRow>
              <Typography variant="h6" component="p">
                i18n.projectContributionConfiguration.settings.requireConsent.banner.title
              </Typography>
              <Typography component="p">
                i18n.projectContributionConfiguration.settings.requireConsent.banner.description
              </Typography>
            </TwoLineRow>
            <TeamPermissionRestriction has="ConsentUpdate">
              <RouterIconButton
                icon="GearIcon"
                label="i18n.projectContributionConfiguration.settings.requireConsent.banner.configureButtonLabel"
                to="settingsAccountConsents"
              />
            </TeamPermissionRestriction>
          </Banner>
        </GridItem>
      )}
      <GridItem xs={12}>
        {consentSettingsInfo?.configured ? (
          <BooleanFieldAutoSaveForm
            id="requireConsent"
            name="requireConsent"
            projectId={projectId}
            title="i18n.projectContributionConfiguration.settings.requireConsent.title"
            description="i18n.projectContributionConfiguration.settings.requireConsent.description"
            initialValue={initialValues.requireConsent}
          />
        ) : (
          <NoFocusStylesBlankButton onClick={onClick}>
            <MockBooleanFieldAutoSaveForm
              id="requireConsent"
              title="i18n.projectContributionConfiguration.settings.requireConsent.title"
              description="i18n.projectContributionConfiguration.settings.requireConsent.description"
            />
          </NoFocusStylesBlankButton>
        )}
      </GridItem>
    </>
  );
};

const NoFocusStylesBlankButton = styled(BlankButton)(({ theme }) => ({
  '&:focus': {
    outline: 'none',
  },
  // This is a workaround to force the focus ring to appear despite the switch being disabled.
  '&:focus > label > span:nth-of-type(1)': theme.mixins.focusRing,
}));

const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.brand.grey50,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.brand.grey200}`,
}));

const StyledLink = styled(TextLink)(({ theme }) => ({
  color: theme.palette.brand.blue,
  textDecoration: 'underline',
}));

const TwoLineRow = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  margin: theme.spacing(0, 1),
}));
