import { useCallback } from 'react';

import {
  type MoveProjectsToProjectFolderMutationVariables,
  useMoveProjectsToProjectFolderMutation,
} from '../../generated';

export type ProjectId = MoveProjectsToProjectFolderMutationVariables['ids'];
export type FolderId = MoveProjectsToProjectFolderMutationVariables['folderId'];

export const useMoveProjectsToProjectFolder = (refetchQueries?: string[]) => {
  const [moveProjectsToProjectFolder] = useMoveProjectsToProjectFolderMutation({
    refetchQueries: [...(refetchQueries ?? []), 'Projects', 'ProjectFolders'],
  });

  return useCallback(
    (
      ids: MoveProjectsToProjectFolderMutationVariables['ids'],
      folderId: MoveProjectsToProjectFolderMutationVariables['folderId']
    ) => {
      return moveProjectsToProjectFolder({
        variables: {
          ids,
          folderId,
        },
      });
    },
    [moveProjectsToProjectFolder]
  );
};
