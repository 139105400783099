import { type FC, type PropsWithChildren, type ReactNode, useCallback } from 'react';

import { IntercomButton } from '@cofenster/intercom';
import {
  Button,
  Circle,
  type FeatureFlagName,
  Icon,
  type IconType,
  Typography,
  styled,
} from '@cofenster/web-components';

import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),

  backgroundColor: theme.palette.brand.purple,
  color: theme.palette.brand.white,
  padding: theme.spacing(3),
  borderRadius: theme.shape['borderRadius-l'],

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`@container (width < ${theme.breakpoints.values.sm}px)`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const Content = styled('div')(() => ({}));
const Actions = styled('div')(() => ({
  marginLeft: 'auto',
  display: 'flex',
  gap: 'inherit',
}));

const StyledCircle = styled(Circle)(({ theme }) => ({
  backgroundColor: 'rgb(255 255 255 / 0.2)',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },

  [`@container (width < ${theme.breakpoints.values.sm}px)`]: {
    display: 'none',
  },
}));

const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

const NoWrapButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'nowrap',
  },
}));

export const UpsellBanner: FC<
  PropsWithChildren<{ title: ReactNode; iconType?: IconType; learnMoreUrl: string; featureRelation: FeatureFlagName }>
> = ({ children, title, iconType = 'RocketLaunchIcon', learnMoreUrl, featureRelation }) => {
  const tracking = useWebManagerTracking();
  const trackUpsellInvestigated = useCallback(
    (interaction: 'INFO' | 'CONTACT') => {
      tracking.trackEvent({
        event: 'upsellInvestigated',
        details: { feature: featureRelation, interaction },
      });
    },
    [featureRelation, tracking]
  );

  return (
    <Wrapper data-testid="upsell-banner">
      <StyledCircle size="xl">
        <Icon type={iconType} size="m" color="white" />
      </StyledCircle>
      <Content>
        {children ? (
          <Title component="p" variant="l">
            {title}
          </Title>
        ) : (
          <Typography component="p" variant="l">
            {title}
          </Typography>
        )}
        {children && (
          <Typography component="p" variant="l">
            {children}
          </Typography>
        )}
      </Content>
      <Actions>
        <NoWrapButton
          variant="tertiary"
          href={learnMoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => {
            // The upsell banner is currently used on top of the dropzone for the `STOCK_LIBRARY` feature.
            // Therefore we need to stop the propagation to avoid triggering the dropzone functionality
            event.stopPropagation();
            trackUpsellInvestigated('INFO');
          }}
        >
          i18n.upsellBanner.actions.info
        </NoWrapButton>
        <IntercomButton
          variant="secondary"
          onClick={(event) => {
            // The upsell banner is currently used on top of the dropzone for the `STOCK_LIBRARY` feature.
            // Therefore we need to stop the propagation to avoid triggering the dropzone functionality
            event.stopPropagation();
            trackUpsellInvestigated('CONTACT');
          }}
        >
          i18n.upsellBanner.actions.contact
        </IntercomButton>
      </Actions>
    </Wrapper>
  );
};
