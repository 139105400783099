import type { FC } from 'react';

import { Redirect } from '../../components/common/Redirect';
import { useUser } from '../../contexts/user/useUser';

import { ProjectSearchFilterProvider } from '../../contexts/projectSearchFilter/ProjectSearchFilterProvider';
import { HomePage } from './HomePage';

export const Home: FC = () => {
  const { user } = useUser();

  if (!user) {
    return <Redirect to="signin" />;
  }

  return (
    <ProjectSearchFilterProvider>
      <HomePage />
    </ProjectSearchFilterProvider>
  );
};
