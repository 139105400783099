import { useCallback } from 'react';

import { type RecoverPasswordMutationVariables, useRecoverPasswordMutation } from '../../generated';

export const useRecoverPassword = () => {
  const [recoverPassword] = useRecoverPasswordMutation();
  return useCallback(
    (email: RecoverPasswordMutationVariables['email']) => {
      return recoverPassword({
        variables: {
          email,
        },
      });
    },
    [recoverPassword]
  );
};
