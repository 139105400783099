import { type FC, useMemo } from 'react';

import { Icon, PopoverMenuTriggerIcon, Responsive, withPopoverMenu } from '@cofenster/web-components';

import type { ThemeVideoType } from '../../../../../api/hooks/user/useMe';
import { RouterButton } from '../../../../../components/button/RouterButton';
import type { Project } from '../../../../../contexts/project/useProject';
import { useI18n } from '../../../../../i18n';
import { useIntroOutroActionsPopoverContent } from '../../EditorSceneAssetThumbs/hooks/useIntroOutroActionsPopoverContent';

export const EditorToolsIntroOutro: FC<{ project: Project; themeVideoType: ThemeVideoType }> = ({
  project,
  themeVideoType,
}) => {
  const { locale } = useI18n();
  const themeVideoTypeLabel =
    locale === 'EN' ? (themeVideoType.toLowerCase() as Lowercase<ThemeVideoType>) : themeVideoType;

  const actions = useIntroOutroActionsPopoverContent({ project, themeVideoType });
  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: actions,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [actions]
  );

  return (
    <>
      <RouterButton
        to="projectDesign"
        params={{ projectId: project.id }}
        variant="tertiary"
        startIcon={<Icon type="PencilIcon" size="s" />}
        i18nParams={{ type: themeVideoTypeLabel }}
      >
        i18n.projectEditor.tools.introOutro
      </RouterButton>

      {actions.length > 0 && (
        <Responsive down="sm">
          <ActionsPopoverTrigger />
        </Responsive>
      )}
    </>
  );
};
