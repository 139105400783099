import { useCallback } from 'react';

import { type InitAudioUploadMutationVariables, useInitAudioUploadMutation } from '../../generated';

export const useInitAudioUpload = () => {
  const [initAudioUpload] = useInitAudioUploadMutation();
  return useCallback(
    (duration: InitAudioUploadMutationVariables['duration'], filename: InitAudioUploadMutationVariables['filename']) =>
      initAudioUpload({ variables: { duration, filename } }),
    [initAudioUpload]
  );
};
