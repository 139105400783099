import { styled } from '@cofenster/web-components';

export const CardSeparator = styled('hr')<{ fullWidth?: boolean }>(({ theme, fullWidth = true }) => ({
  border: 0,
  borderBottomWidth: 1,
  borderBottomColor: theme.palette.brand.grey200,
  borderBottomStyle: 'solid',
  padding: 0,
  marginLeft: fullWidth ? theme.spacing(-4) : undefined,
  marginRight: fullWidth ? theme.spacing(-4) : undefined,
  marginTop: theme.spacing(fullWidth ? 3 : 1),
  marginBottom: theme.spacing(fullWidth ? 3 : 1),
}));
