import { type ChangeEventHandler, type FC, useCallback } from 'react';

import { Icon, Switch, type SwitchProps, Text, styled } from '@cofenster/web-components';

import { AudioBox } from './AudioBox';
import { useAudioOverlayContext } from './Context';

const StyledSwitch = styled(Switch)(() => ({
  width: '100%',
}));

export const MuteSwitch: FC<Partial<SwitchProps>> = (props) => {
  const { localStatus, setLocalStatus, videoSceneAsset } = useAudioOverlayContext();

  const nonMutedStatus = videoSceneAsset?.asset?.videoAsset?.id ? 'SCENE_AUDIO' : 'UPLOADED_AUDIO';
  const isAudioMuted = localStatus === 'MUTED_AUDIO';

  const handleMuteChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setLocalStatus(event.target.checked ? 'MUTED_AUDIO' : nonMutedStatus),
    [setLocalStatus, nonMutedStatus]
  );

  return (
    <AudioBox>
      <Icon type="SpeakerMutedIcon" size="m" />
      <StyledSwitch
        {...props}
        checked={isAudioMuted}
        onChange={handleMuteChange}
        id="muteScene"
        data-testid="mute-scene-switch"
        switchPosition="right"
        isBlock
      >
        <Text variant="l">i18n.projectEdit.SceneAudioPanelContent.sceneAudioMute.title</Text>
      </StyledSwitch>
    </AudioBox>
  );
};
