import { useContext } from 'react';

import { ProjectRenderDescriptionContext } from './ProjectRenderDescriptionContext';

export type { ProjectRenderDescriptionContext } from './ProjectRenderDescriptionContext';

export const useProjectRenderDescription = () => {
  const context = useContext(ProjectRenderDescriptionContext);
  if (!context) throw new Error('ProjectRenderDescriptionContext missing in tree');
  return context;
};
