import { useLocation } from 'react-router-dom';

import { Intercom } from '@cofenster/intercom';

import { useUser } from '../../contexts/user/useUser';

export const IntercomWidget = () => {
  const { user } = useUser();
  const { pathname } = useLocation();

  return <Intercom user={user} pathname={pathname} />;
};
