import { type FC, type PropsWithChildren, useEffect, useRef } from 'react';

import { I18nProvider as GenericI18nProvider } from '@cofenster/web-components';

import { useUpdateMe } from '../api/hooks/user/useUpdateMe';
import { useUser } from '../contexts/user/useUser';

import { TRANSLATIONS } from './translations';
import { useI18n } from './useI18n';

const SetUserLocale: FC = () => {
  const { user } = useUser();
  const { locale, setLocale } = useI18n();
  const updateMe = useUpdateMe();
  const userRef = useRef(user);

  // save user locale when the user has no locale yet
  useEffect(() => {
    if (user && !user.isImpersonated && !user.locale) {
      updateMe(user.id, { locale: locale });
    }
  }, [user, locale, updateMe]);

  // set page locale on user login
  useEffect(() => {
    if (!userRef.current && user?.locale) {
      setLocale(user.locale);
    }
    userRef.current = user;
  }, [user, setLocale]);

  return null;
};

export const I18nProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const { user } = useUser();
  const locale = user?.locale ?? navigator.language.split('-')[0]?.toUpperCase();

  useEffect(() => {
    if (locale) document.documentElement.setAttribute('lang', locale.toLowerCase());
  }, [locale]);

  return (
    <GenericI18nProvider translations={TRANSLATIONS} userLocale={locale} defaultLocale="EN">
      <SetUserLocale />
      {children}
    </GenericI18nProvider>
  );
};
