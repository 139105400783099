import { useFormikContext } from 'formik';
import { type CSSProperties, type FC, useCallback, useEffect, useState } from 'react';

import { Button, GridContainer, GridItem, SidePanel } from '@cofenster/web-components';

export type { Template } from '../../../../api/hooks/template/useTemplatesByProject';
import { useTemplatesByProject } from '../../../../api/hooks/template/useTemplatesByProject';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';
import type { Project } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

import { TemplateCard } from './TemplateCard';

type Props = {
  isOpen: boolean;
  project: Project;
  close: () => unknown;
};

export const TemplatePanel: FC<Props> = ({ isOpen, project, close }) => {
  const { openDialog } = useDialogs();
  const { templatesByProject } = useTemplatesByProject(project.id);
  const { setFieldValue } = useFormikContext();
  const { scenes } = useScenes();
  const textElementsCount = scenes.reduce((acc, scene) => acc + scene.sceneTexts.length, 0);

  const tracking = useWebManagerTracking();

  const currentTemplateId = project.template.id;
  const [selectedTemplateId, setSelectedTemplateId] = useState(currentTemplateId);

  useEffect(() => setSelectedTemplateId(currentTemplateId), [currentTemplateId]);

  const trackDesignTemplateUpdated = useCallback(
    (templateId: string) => {
      tracking.trackEvent({
        event: 'designTemplateUpdated',
        details: { templateId },
      });
    },
    [tracking]
  );

  const updateDesignTemplate = useCallback(
    (templateId: string | undefined) => {
      if (!templateId) return;
      setFieldValue('templateId', templateId);
      trackDesignTemplateUpdated(templateId);
    },
    [setFieldValue, trackDesignTemplateUpdated]
  );

  const handleSave = useCallback(() => {
    const shouldSkipSaving = selectedTemplateId === currentTemplateId || !currentTemplateId;

    if (shouldSkipSaving) return close();
    if (textElementsCount === 0) return updateDesignTemplate(selectedTemplateId);

    openDialog('ChangeAnimationStyleDialog', {
      onAction: () => updateDesignTemplate(selectedTemplateId),
      textElementsCount,
    });
  }, [currentTemplateId, textElementsCount, updateDesignTemplate, selectedTemplateId, openDialog, close]);

  return (
    <SidePanel
      isOpen={isOpen}
      close={close}
      headline="i18n.projectDesign.panel.headline"
      footer={
        <Button fullWidth onClick={handleSave} data-testid="project-theme-save-button">
          i18n.global.save
        </Button>
      }
      style={{ '--drawer-top-offset': 'var(--project-header-height, 88px)' } as CSSProperties}
    >
      <GridContainer>
        {templatesByProject.map((template) => (
          <GridItem key={template.id} xs={6}>
            <TemplateCard
              template={template}
              onClick={() => setSelectedTemplateId(template.id)}
              selected={selectedTemplateId === template.id}
            />
          </GridItem>
        ))}
      </GridContainer>
    </SidePanel>
  );
};
