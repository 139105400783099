import type { VideoFormat } from '@cofenster/constants';
import type { FeatureFlagName, IconType } from '@cofenster/web-components';

export type VideoFormatOptionConfiguration = {
  flag?: FeatureFlagName;
  useCases: { iconType: IconType; text: string }[];
};

export const VIDEO_FORMATS_FIELD_OPTIONS: Record<VideoFormat, VideoFormatOptionConfiguration> = {
  Horizontal: {
    useCases: [
      { iconType: 'YoutubeIcon', text: 'YouTube' },
      { iconType: 'FacebookIcon', text: 'FB Cover' },
      { iconType: 'BrowserIcon', text: 'Website' },
      { iconType: 'MonitorIcon', text: 'Desktop' },
    ],
  },
  Vertical: {
    useCases: [
      { iconType: 'InstagramIcon', text: 'IG Stories' },
      { iconType: 'FacebookIcon', text: 'FB Stories' },
      { iconType: 'InstagramIcon', text: 'IG Reels' },
      { iconType: 'TikTokIcon', text: 'TikTok' },
    ],
  },
  Square: {
    flag: 'SPECIAL_FORMATS',
    useCases: [
      { iconType: 'InstagramIcon', text: 'IG Post' },
      { iconType: 'BagIcon', text: 'Carousel Ads' },
      { iconType: 'FacebookIcon', text: 'FB Video' },
    ],
  },
  SocialMedia: {
    flag: 'SPECIAL_FORMATS',
    useCases: [
      { iconType: 'FacebookIcon', text: 'FB Feed' },
      { iconType: 'LinkedinIcon', text: 'LI Feed' },
    ],
  },
};
