import { Chip as MuiChip, type ChipProps as MuiChipProps, styled } from '@mui/material';
import { type CSSProperties, type PropsWithChildren, forwardRef } from 'react';

import { type I18nParams, Translatable } from '../../../services/i18n';
import { preventForwardProps } from '../../../utilities/preventForwardProps';
import { Icon, type IconType } from '../../assets/icons/Icon';
import { IconButton } from '../../controls/Button';
import { LoadingSpinner } from '../../feedback/LoadingSpinner';

export type ChipProps = Omit<MuiChipProps, 'variant' | 'color' | 'icon' | 'children'> & {
  color: 'dark' | 'light';
  icon?: IconType;
  i18nParams?: I18nParams;
  withDeleteTooltip?: boolean;
  loadingDelete?: boolean;
};

const DeleteIconButton = styled(IconButton)(() => ({
  borderRadius: '50%',
  color: 'inherit',
  '&:hover': { color: 'inherit' },
}));

const StyledChip = styled(
  MuiChip,
  preventForwardProps(['chipColor'])
)<{
  chipColor: ChipProps['color'];
  clickable?: boolean;
}>(({ theme, chipColor, clickable }) => {
  const isLight = chipColor === 'light';

  const baseStyles: CSSProperties = {
    font: `12px / 1 'Beatrice'`,
    padding: theme.spacing(1.25, 0),
  };

  if (isLight) {
    baseStyles.color = theme.palette.brand.carbon;
    baseStyles.backgroundColor = 'var(--chip-color, transparent)';
    baseStyles.borderColor = 'currentcolor';
  } else {
    baseStyles.color = theme.palette.brand.white;
    baseStyles.backgroundColor = `var(--chip-color, ${theme.palette.brand.carbon})`;
    baseStyles.borderColor = theme.palette.brand.carbon;
  }

  return {
    ...baseStyles,

    // Adjust the styles of the icon within the chip
    '& .MuiChip-icon': {
      color: 'inherit',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-0.5),
    },

    // Adjust the styles of the delete button within the chip
    '& .MuiChip-deleteIcon': {
      color: 'inherit',
      marginRight: 0,
      '&:hover': { color: 'inherit' },
    },

    // Apply the usual focus ring when the chip receives focus
    '&:focus-visible': theme.mixins.focusRing,

    // When the chip receives the `clickable` prop and is interacted with, apply
    // some background color change. This is typically done when the chip serves
    // as a link or a tab — anything interactive that doesn’t receive `onClick`;
    // the `clickable` prop instructs MUI that the Chip can be interacted with.
    // See: https://mui.com/material-ui/react-chip/#clickable-link
    '&:hover, &.Mui-focusVisible': clickable
      ? {
          backgroundColor: isLight ? theme.palette.brand.carbon_alpha5 : theme.palette.brand.grey700,
        }
      : undefined,
  };
});

export const Chip = forwardRef<HTMLDivElement, PropsWithChildren<ChipProps>>(function Chip(
  { color = 'light', icon, children, i18nParams, withDeleteTooltip = true, loadingDelete, ...rest },
  ref
) {
  return (
    <StyledChip
      {...rest}
      ref={ref}
      variant={color === 'light' ? 'outlined' : 'filled'}
      label={<Translatable i18nParams={i18nParams}>{children}</Translatable>}
      icon={icon && <Icon type={icon} size="s" />}
      chipColor={color}
      deleteIcon={
        loadingDelete ? (
          <LoadingSpinner size={16} />
        ) : (
          <span>
            <DeleteIconButton
              icon="CloseIcon"
              iconSize="xs"
              label="i18n.global.delete"
              data-testid="chip-delete-button"
              tooltip={withDeleteTooltip ? 'i18n.global.delete' : null}
            />
          </span>
        )
      }
    />
  );
});
