import { type FC, type PropsWithChildren, useMemo } from 'react';

import { ProjectFolderContext } from './ProjectFolderContext';

export type RootProjectFolderProviderProps = PropsWithChildren<{
  projectFolder: ProjectFolderContext['projectFolder'];
}>;

export const ProjectFolderProvider: FC<RootProjectFolderProviderProps> = ({ children, projectFolder }) => {
  const context = useMemo(() => ({ projectFolder }), [projectFolder]);

  return <ProjectFolderContext.Provider value={context}>{children}</ProjectFolderContext.Provider>;
};
