import { useCallback } from 'react';

import { type ContributionRequestsByProjectQuery, useSortContributionRequestsMutation } from '../../generated';

export type ContributionRequest = ContributionRequestsByProjectQuery['contributionRequestsByProject'][0];

export const useSortContributionRequests = () => {
  const [sortContributionRequests] = useSortContributionRequestsMutation({
    refetchQueries: ['ContributionRequestsByProject'],
    awaitRefetchQueries: true,
  });

  return useCallback(
    (contributionRequestIds: string[]) =>
      sortContributionRequests({
        variables: {
          ids: contributionRequestIds,
        },
      }),
    [sortContributionRequests]
  );
};
