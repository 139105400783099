export const PexelsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.44 0H2.56C1.14615 0 0 1.14615 0 2.56V13.44C0 14.8538 1.14615 16 2.56 16H13.44C14.8538 16 16 14.8538 16 13.44V2.56C16 1.14615 14.8538 0 13.44 0Z"
      fill="#07A081"
    />
    <path
      d="M5.9716 10.7937H8.24072V8.61934H8.92488C9.40498 8.61934 9.86542 8.42862 10.2049 8.08914C10.5444 7.74965 10.7351 7.28921 10.7351 6.8091C10.7351 6.329 10.5444 5.86856 10.2049 5.52907C9.86542 5.18958 9.40498 4.99886 8.92488 4.99886H5.9716V10.7937ZM9.41575 11.9528H4.79688V3.84014H8.92488C9.67642 3.82947 10.404 4.10445 10.9606 4.60954C11.5172 5.11462 11.8614 5.81214 11.9236 6.56119C11.9857 7.31023 11.7613 8.05495 11.2955 8.64488C10.8298 9.23481 10.1575 9.62598 9.41448 9.73934L9.41575 11.9528Z"
      fill="white"
    />
  </svg>
);
