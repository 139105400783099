import { useEffect, useMemo } from 'react';

import { getRenderJobStatus } from '../../../hooks/project/useProjectExportStatus';
import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import { RENDER_JOB_POLLING_INTERVAL } from '../../../pages/ProjectVideo/RenderProgress';
import { type RenderJobsByProjectQueryVariables, useRenderJobsByProjectQuery } from '../../generated';

export const useRenderJobsByProject = (
  projectId: RenderJobsByProjectQueryVariables['projectId'],
  refetchProject: () => unknown
) => {
  const { loading, error, data, startPolling, stopPolling } = useRenderJobsByProjectQuery({
    variables: { projectId },
    skipPollAttempt: () => document.hidden,
  });
  const renderJobs = data?.renderJobsByProject;
  const hasAnyRenderJobInProgress = useMemo(() => {
    if (!renderJobs) return false;
    return renderJobs.map((job) => getRenderJobStatus(job)).some((status) => status === 'RENDERING');
  }, [renderJobs]);

  useEffect(() => {
    if (hasAnyRenderJobInProgress) {
      startPolling(RENDER_JOB_POLLING_INTERVAL);
    } else {
      refetchProject();
      stopPolling();
    }
  }, [hasAnyRenderJobInProgress, startPolling, stopPolling, refetchProject]);

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({ renderJobs, loading, error, startPolling, stopPolling }),
    [renderJobs, loading, error, startPolling, stopPolling]
  );
};
