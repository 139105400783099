import { styled } from '@mui/material';
import type { ComponentProps, PropsWithChildren } from 'react';

import { Icon, type IconType } from '../../../assets/icons/Icon';
import { Typography } from '../../../typography/Typography';

import { BaseButton, type BaseButtonProps } from './BaseButton';
import { IconContainer } from './SectionHeader';

const StyledBaseButton = styled(BaseButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
}));

export type SectionHeaderButtonProps = BaseButtonProps & {
  startIcon?: IconType;
  endIcon?: IconType;
} & ComponentProps<'button'>;

export const SectionHeaderButton = ({
  children,
  startIcon,
  endIcon,
  ...rest
}: PropsWithChildren<SectionHeaderButtonProps>) => (
  <StyledBaseButton {...rest}>
    {startIcon && (
      <IconContainer>
        <Icon type={startIcon} size="s" />
      </IconContainer>
    )}
    <Typography variant="h6" component="span">
      {children}
    </Typography>
    {endIcon && <Icon type={endIcon} size="m" />}
  </StyledBaseButton>
);
