import { useCallback } from 'react';

import { useToggleSceneVisibility } from './useToggleSceneVisibility';

export const useIncludeScene = (onToggle?: (sceneId: string) => unknown) => {
  const toggleSceneVisibility = useToggleSceneVisibility();

  return useCallback(
    async (sceneId: string) => {
      await toggleSceneVisibility({ excluded: false }, sceneId);
      onToggle?.(sceneId);
    },
    [toggleSceneVisibility, onToggle]
  );
};
