import { type FC, useCallback } from 'react';

import { Chip, Text } from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useDeleteContributionRequestFromList } from '../../../../api/hooks/contributionRequestList/useDeleteContributionRequestFromList';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';

type LightContributionRequestList = Pick<ContributionRequestList, 'id' | 'name'>;

type Props = {
  contributionRequestId: string;
  list: LightContributionRequestList;
};

export const ExistingContributionRequestListChipSection: FC<Props> = ({ list, contributionRequestId }) => {
  const { deleteContributionRequestFromList, loading } = useDeleteContributionRequestFromList({
    contributionRequestListId: list.id,
    contributionRequestId,
  });

  const { openDialog } = useDialogs();

  const onDelete = useCallback(() => {
    openDialog('ConfirmDialog', {
      onConfirm: deleteContributionRequestFromList,
      title: 'i18n.projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.title',
      content: (
        <Text variant="l" i18nParams={{ name: list.name }}>
          i18n.projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.description
        </Text>
      ),
    });
  }, [openDialog, deleteContributionRequestFromList, list]);

  return (
    <Chip
      data-testid="contribution-request-list-chip"
      color="dark"
      disabled={loading}
      onDelete={onDelete}
      withDeleteTooltip={false}
      loadingDelete={loading}
    >
      {list.name}
    </Chip>
  );
};
