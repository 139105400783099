import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Translatable } from '@cofenster/web-components';

import { PageHeadline, PageSubline, UnauthenticatedPage } from '../../components/page/UnauthenticatedPage';

import { ForgotPasswordForm } from './ForgotPasswordForm';

type LocationState = {
  error?: 'invalid' | 'expired';
};

const translations = {
  invalid: 'forgot.error.invalid',
  expired: 'forgot.error.expired',
};

export const ForgotPassword: FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  return (
    <UnauthenticatedPage title="i18n.forgot.title">
      <PageHeadline variant="h1">i18n.forgot.headline</PageHeadline>
      {state?.error ? (
        <PageSubline variant="xl" color="negative">
          <Translatable i18nParams={{ error: <Translatable>{translations[state.error]}</Translatable> }}>
            i18n.forgot.error
          </Translatable>
        </PageSubline>
      ) : (
        <PageSubline variant="xl">i18n.forgot.subline</PageSubline>
      )}
      <ForgotPasswordForm />
    </UnauthenticatedPage>
  );
};
