import { useMemo } from 'react';

import {
  type UpdateContributionRequestListInput as Input,
  type UpdateContributionRequestListMutationVariables,
  useUpdateContributionRequestListMutation,
} from '../../generated';

export type UpdateContributionRequestListInput = Input;

export const useUpdateContributionRequestList = () => {
  const [updateContributionRequestList, { loading, error }] = useUpdateContributionRequestListMutation({
    refetchQueries: ['ContributionRequestListByProject', 'ContributionRequestsByProject'],
    awaitRefetchQueries: true,
  });
  return useMemo(
    () => ({
      updateContributionRequestList: async (
        id: UpdateContributionRequestListMutationVariables['id'],
        input: UpdateContributionRequestListMutationVariables['input']
      ) =>
        updateContributionRequestList({
          variables: { id, input },
        }),
      loading,
      error,
    }),
    [updateContributionRequestList, loading, error]
  );
};
