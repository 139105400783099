import { useCallback } from 'react';

import { type UpdateProjectFolderMutationVariables, useUpdateProjectFolderMutation } from '../../generated';

export type ProjectFolderId = UpdateProjectFolderMutationVariables['id'];

export const useUpdateProjectFolder = () => {
  const [updateProjectFolder] = useUpdateProjectFolderMutation({
    refetchQueries: ['ProjectFolder', 'ProjectFolders'],
  });
  return useCallback(
    (id: UpdateProjectFolderMutationVariables['id'], input: UpdateProjectFolderMutationVariables['input']) => {
      return updateProjectFolder({
        variables: {
          id,
          input,
        },
      });
    },
    [updateProjectFolder]
  );
};
